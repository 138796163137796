import { render, staticRenderFns } from "./CloseRegisterReport.vue?vue&type=template&id=3573996a&scoped=true&"
import script from "./CloseRegisterReport.vue?vue&type=script&lang=js&"
export * from "./CloseRegisterReport.vue?vue&type=script&lang=js&"
import style0 from "./CloseRegisterReport.vue?vue&type=style&index=0&id=3573996a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3573996a",
  null
  
)

export default component.exports