<template>
  <div>
    <div style="display:flex; justify-content:space-between" class="mt-2">
      <div>
        <h2 v-if="product" class="mt-3 mb-2 ml-2 " style="text-align: left">
          {{ $t('Historial de movimientos').toUpperCase() }}
        </h2>
        <div style="display:flex" class="ml-2">
          <b-field :label="$t('Fecha inicial')">
            <b-datetimepicker
              v-model="start_date"
              :show-week-number="false"
              :first-day-of-week="1"
              :locale="this.$i18n.locale"
              :placeholder="$t('Fecha inicial')"
              trap-focus
            >
            </b-datetimepicker>
          </b-field>
          <b-field :label="$t('Fecha final')" class="ml-4">
            <b-datetimepicker
              v-model="end_date"
              :show-week-number="false"
              :first-day-of-week="1"
              :locale="this.$i18n.locale"
              :placeholder="$t('Fecha final')"
              trap-focus
            >
            </b-datetimepicker>
          </b-field>
        </div>
      </div>
      <div class="mr-2 mt-2" v-if="product">
        <div v-if="product.imagenes.length > 0">
          <img
            :src="product.imagenes[0].img"
            style="height:120px;object-fit: cover; border-radius:6px;"
            class="w3-hover-opacity"
          />
        </div>
        <div v-else>
          <img
            src="@/assets/nofoto.png"
            style="height:120px;object-fit: cover; border-radius:6px;"
            class="w3-hover-opacity"
          />
        </div>
        <h6
          class="line-clamp"
          style="text-align:center;font-size: 15px;  font-weight: 350; color:black; margin-bottom:10px"
        >
          {{ product.titulo }}
        </h6>
      </div>
    </div>

    <div>
      <b-table
        class="mb-4 mr-2"
        style="text-align: left"
        :data="elements"
        :checkable="false"
        :paginated="true"
        :per-page="perPage"
      >
        <b-table-column
          field="employee_name"
          :label="$t('Empleado')"
          sortable
          v-slot="props"
        >
          {{ props.row.employee_name }}
        </b-table-column>

        <b-table-column
          field="created"
          :label="$t('Fecha')"
          sortable
          centered
          v-slot="props"
        >
          <span>
            <span v-if="props.row.created">
              {{ format_date(props.row.created, 'hh:mm dd/MM/yyyy') }}
            </span>
          </span>
        </b-table-column>

        <b-table-column
          field="type"
          :label="$t('Tipo')"
          sortable
          v-slot="props"
        >
          {{ props.row.type }}
        </b-table-column>

        <b-table-column
          field="quantity"
          :label="$t('Cantidad')"
          sortable
          v-slot="props"
        >
          {{ props.row.quantity }}
        </b-table-column>

        <b-table-column
          field="new_level"
          :label="$t('Total')"
          sortable
          v-slot="props"
        >
          {{ props.row.new_level }}
        </b-table-column>

        <b-table-column
          field="note"
          :label="$t('Nota')"
          sortable
          v-slot="props"
        >
          {{ props.row.note }}
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
import APIDispensaryPro from '@/services/APIDispensaryPro.js'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'

export default {
  data() {
    return {
      product: undefined,
      elements: [],
      totalElements: 0,
      perPage: 15,
      page: 1,
      start_date: new Date(new Date().setDate(new Date().getDate() - 7)),
      // end_date: new Date(new Date().setDate(new Date().getHours() + 1)),
      end_date: new Date(),

      locale: 'es-ES'
    }
  },
  mixins: [ToolsMixin, InitViewMixin],
  methods: {
    downloadData(pid) {
      this.validateAccess(['software_gestion_club'])
      this.validateRoles(['Administrador', 'Manager'])

      APIDispensaryPro.getProductHistory(
        Date.parse(this.start_date) / 1000,
        Date.parse(this.end_date) / 1000,
        this.currentClub.nid,
        pid
      ).then(response => {
        console.log(response.data)
        this.elements = response.data.data
      })

      APIDispensaryPro.getDispensaryItemByPid(this.currentClub.nid, pid).then(
        response => {
          if (response.data.length > 0) {
            this.product = response.data[0]
            console.log(this.product)
          }
        }
      )
    }
  },
  created() {
    // this.downloadData()
  },
  watch: {
    '$route.params.pid': {
      handler: function(search) {
        console.log(search)
        // const loadingComponent = this.$buefy.loading.open({
        //   container: null
        // })
        this.downloadData(this.$route.query.pid)
      },
      deep: true,
      immediate: true
    },
    currentClub() {
      this.downloadData(this.$route.query.pid)
    },
    start_date() {
      this.downloadData(this.$route.query.pid)
    },
    end_date() {
      this.downloadData(this.$route.query.pid)
    }
  }
}
</script>

<style lang="scss" scoped></style>
