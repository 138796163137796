import session from '@/services/Session.js'

export default {
  getEvents(asocid) {
    return session.get('/api/event/retrieve?association=' + asocid)
  },
  getEvent(asocid, nid) {
    return session.get(
      '/api/event/retrieve?association=' + asocid + '&nid=' + nid
    )
  },
  deleteEvent(nid, asocid) {
    return session.delete('/api/event/' + nid + '?association=' + asocid)
  },
  postEvent(json) {
    return session.post('/api/event', json)
  },
  attendEvent(json) {
    return session.post('/api/event/attend', json)
  }
}
