/* eslint-disable */
export const ACTIVATION_BEGIN = 'ACTIVATION_BEGIN'
export const ACTIVATION_CLEAR = 'ACTIVATION_CLEAR'
export const ACTIVATION_FAILURE = 'ACTIVATION_FAILURE'
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS'
export const LOGIN_BEGIN = 'LOGIN_BEGIN'
export const LOGIN_CLEAR = 'LOGIN_CLEAR'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGOUT = 'LOGOUT'
export const PASSWORD_EMAIL_BEGIN = 'PASSWORD_EMAIL_BEGIN'
export const PASSWORD_EMAIL_CLEAR = 'PASSWORD_EMAIL_CLEAR'
export const PASSWORD_EMAIL_FAILURE = 'PASSWORD_EMAIL_FAILURE'
export const PASSWORD_EMAIL_SUCCESS = 'PASSWORD_EMAIL_SUCCESS'
export const PASSWORD_RESET_BEGIN = 'PASSWORD_RESET_BEGIN'
export const PASSWORD_RESET_CLEAR = 'PASSWORD_RESET_CLEAR'
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE'
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS'
export const REGISTRATION_BEGIN = 'REGISTRATION_BEGIN'
export const REGISTRATION_CLEAR = 'REGISTRATION_CLEAR'
export const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE'
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_USER = 'SET_USER'
export const SET_SHOW_NAVIGATION_BAR = 'SET_SHOW_NAVIGATION_BAR'
export const SET_CREDENTIALS = 'SET_CREDENTIALS'
export const REMOVE_TOKEN = 'REMOVE_TOKEN'
export const ADD_ORDER = 'ADD_ORDER'
export const REMOVE_ORDER = 'REMOVE_ORDER'
export const REMOVE_ALL_ORDERS = 'REMOVE_ALL_ORDERS'
export const SET_ORDERS = 'SET_ORDERS'
export const SET_CURRENT_ORDER = 'SET_CURRENT_ORDER'
export const CLEAR_CURRENT_ORDER = 'CLEAR_CURRENT_ORDER'
export const SET_CLUBS = 'SET_CLUBS'
export const SET_CLUB = 'SET_CLUB'
export const SET_DISPENSARY = 'SET_DISPENSARY'
export const SET_DISPENSARY_ITEM = 'SET_DISPENSARY_ITEM'
export const CLEAR_DISPENSARY = 'CLEAR_DISPENSARY'
export const CLEAR_DISPENSARY_ITEM = 'CLEAR_DISPENSARY_ITEM'
export const SET_SMOKAPP_PROFILE = 'SET_SMOKAPP_PROFILE'
export const CLEAR_SMOKAPP_PROFILE = 'CLEAR_SMOKAPP_PROFILE'
export const CLEAR_CLUB = 'CLEAR_CLUB'
export const CLEAR_CATEGORIES = 'CLEAR_CATEGORIES'
export const SET_CURRENT_LIST = 'SET_CURRENT_LIST'
export const SET_CURRENT_CATEGORY = 'SET_CURRENT_CATEGORY'
export const CLEAR_CURRENT_LIST = 'CLEAR_CURRENT_LIST'
export const CLEAR_CURRENT_CATEGORY = 'CLEAR_CURRENT_CATEGORY'
export const SET_QRVALUE = 'SET_QRVALUE'
export const SET_MICROBLINK_DATA = 'SET_MICROBLINK_DATA'
export const SET_USERS = 'SET_USERS'
export const SET_USER_DATA = 'SET_USER_DATA'
export const SET_TV_CAROUSEL_SETTINGS = 'SET_TV_CAROUSEL_SETTINGS'
export const SET_CONSULTS = 'SET_CONSULTS'
export const SET_CURRENT_CONSULT = 'SET_CURRENT_CONSULT'
export const DELETE_CONSULT = 'DELETE_CONSULT'
export const SET_NEWS = 'SET_NEWS'
export const SET_CURRENT_NEWS = 'SET_CURRENT_NEWS'
export const DELETE_NEWS = 'DELETE_NEWS'
export const SET_EVENTS = 'SET_EVENTS'
export const SET_CURRENT_EVENT = 'SET_CURRENT_EVENT'
export const DELETE_EVENT = 'DELETE_EVENT'
export const SET_PATIENTS = 'SET_PATIENTS'
export const CLEAR_PATIENTS = 'CLEAR_PATIENTS'
export const REMOVE_PATINT_FROM_STORE = 'REMOVE_PATINT_FROM_STORE'
export const SET_CURRENT_PATIENT = 'SET_CURRENT_PATIENT'
export const REMOVE_PRODUCT_FROM_STORE = 'REMOVE_PRODUCT_FROM_STORE'
export const ADD_PRODUCT_TO_DISPENSARY = 'ADD_PRODUCT_TO_DISPENSARY'
export const SET_INVENTORY = 'SET_INVENTORY'
export const SET_INVENTORY_ITEM = 'SET_INVENTORY_ITEM'
export const CLEAR_INVENTORY = 'CLEAR_INVENTORY'
export const CLEAR_INVENTORY_ITEM = 'CLEAR_INVENTORY_ITEM'
export const REMOVE_INVENTORY_FROM_STORE = 'REMOVE_INVENTORY_FROM_STORE'
export const ADD_INVENTORY_ITEM_TO_INVENTORY = 'ADD_INVENTORY_ITEM_TO_INVENTORY'
export const SET_PATIENT_CATEGORIES = 'SET_PATIENT_CATEGORIES'
export const REMOVE_PATIENT_CATEGORY_FROM_STORE =
  'REMOVE_PATIENT_CATEGORY_FROM_STORE'
export const ADD_PATIENT_CATEGORY = 'ADD_PATIENT_CATEGORY'
export const EDIT_PATIENT_CATEGORY = 'EDIT_PATIENT_CATEGORY'
export const SET_DISPENSARY_CATEGORIES = 'SET_DISPENSARY_CATEGORIES'
export const REMOVE_DISPENSARY_CATEGORY_FROM_STORE =
  'REMOVE_DISPENSARY_CATEGORY_FROM_STORE'
export const ADD_DISPENSARY_CATEGORY = 'ADD_DISPENSARY_CATEGORY'
export const EDIT_DISPENSARY_CATEGORY = 'EDIT_DISPENSARY_CATEGORY'
export const ADD_CLUB_CATEGORY = 'ADD_CLUB_CATEGORY'
export const EDIT_CLUB_CATEGORY = 'EDIT_CLUB_CATEGORY'
export const REMOVE_CLUB_CATEGORY = 'REMOVE_CLUB_CATEGORY'
export const SET_CLUB_CATEGORIES = 'SET_CLUB_CATEGORIES'
export const SET_SELECTED_PATIENTS = 'SET_SELECTED_PATIENTS'
export const CLEAR_CURRENT_PATIENT = 'CLEAR_CURRENT_PATIENT'
export const CLEAR_SELECTED_PATIENTS = 'CLEAR_SELECTED_PATIENTS'
export const SET_FEE_CATEGORIES = 'SET_FEE_CATEGORIES'
export const ADD_FEE_CATEGORY = 'ADD_FEE_CATEGORY'
export const EDIT_FEE_CATEGORY = 'EDIT_FEE_CATEGORY'
export const DELETE_FEE_CATEGORY = 'DELETE_FEE_CATEGORY'
export const CLEAR_FEE_CATEGORY = 'CLEAR_FEE_CATEGORY'
export const CLEAR_SELECTED_PATIENT = 'CLEAR_SELECTED_PATIENT'
export const SET_ACCESS_HISTORY = 'SET_ACCESS_HISTORY'
export const CLEAR_ACCESS_HISTORY = 'CLEAR_ACCESS_HISTORY'
export const SET_PRESET_VARIETIES = 'SET_PRESET_VARIETIES'
export const SET_CASHMOVEMENTS = 'SET_CASHMOVEMENTS'
export const SET_CLOSE_REGISTER_HISOTRY = 'SET_CLOSE_REGISTER_HISOTRY'
export const SET_STOCK = 'SET_STOCK'
export const ADD_NEW_PATIENT_TO_SELECTED = 'ADD_NEW_PATIENT_TO_SELECTED'
export const SET_REPORT_CASH = 'SET_REPORT_CASH'
export const SET_MY_MEMBERSHIP_CARD = 'SET_MY_MEMBERSHIP_CARD'
export const SET_SIDE_MENU_IS_ACTIVE = 'SET_SIDE_MENU_IS_ACTIVE'
export const SET_CASH_PERMISSION = 'SET_CASH_PERMISSION'
export const SET_CARD_PERMISSION = 'SET_CARD_PERMISSION'
export const SET_WALLET_PERMISSION = 'SET_WALLET_PERMISSION'
export const SET_CASHDRO_PERMISSION = 'SET_CASHDRO_PERMISSION'
export const SET_CASHDRO_IP = 'SET_CASHDRO_IP'

export const SET_NEW_NOVELTIES = 'SET_NEW_NOVELTIES'

export default {
  EMPTY: 'EMPTY',
  QUERY_BEGIN: 'QUERY_BEGIN',
  QUERY_ERR: 'QUERY_ERR',
  QUERY_OK: 'QUERY_OK'
}
