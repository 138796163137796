import { SET_CLUBS, SET_CLUB } from './types'
import APIService from '@/services/APIService.js'
import tools from '@/Tools/tools.js'

const initialState = {
  notifications: [],
  currentClub: Object
}
const getters = {
  // getCurrentClub: state => {
  //   return state.currentClub
  // }
}
const mutations = {
  //   [SET_CLUBS](state, clubs) {
  //     state.clubs = clubs
  //   },
  //   [SET_CLUB](state, club) {
  //     localStorage.setItem('currentClub', JSON.stringify(club))
  //     state.currentClub = club
  //   }
}
const actions = {
  //   initialize({ commit, state }) {
  //     if (state.currentClub != Object) {
  //       return
  //     }
  //     if (state.currentClub) {
  //       const currentClub = JSON.parse(localStorage.getItem('currentClub'))
  //       if (currentClub) {
  //         commit(SET_CLUB, currentClub)
  //       }
  //     }
  //   },
  //   fetchClubs({ commit, getters }) {
  //     let promise = APIService.clubs()
  //       .then(response => {
  //         commit(SET_CLUBS, response.data)
  //         if (getters.getCurrentClub == Object) {
  //           commit(SET_CLUB, response.data[0])
  //         }
  //       })
  //       .catch(error => {
  //         console.log('Ha habido un error:' + error)
  //       })
  //     return promise
  //   },
  //   fetchClub({ commit, asocid }) {
  //     let promise = APIService.getClub(asocid)
  //       .then(response => {
  //         commit(SET_CLUB, response.data[0])
  //       })
  //       .catch(error => {
  //         console.log('Ha habido un error:' + error)
  //       })
  //     return promise
  //   },
  //   setClub({ commit }, club) {
  //     commit(SET_CLUB, club.club)
  //   }
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}
