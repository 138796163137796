<template>
  <div>
    <div v-if="currentClub != Object && currentClub.full_html == ''">
      <p class="logo" v-if="currentClub != Object">
        <img :src="currentClub.logo.img" />
      </p>
      <b-carousel
        :indicator-inside="false"
        :autoplay="true"
        :arrow="false"
        :animated="'slide'"
        :interval="12000"
      >
        <b-carousel-item v-for="(item, i) in currentClub.images" :key="i">
          <span class="bigimage">
            <img :src="item.img" />
          </span>
        </b-carousel-item>
        <template
          slot="indicators"
          slot-scope="props"
          v-if="props.i < currentClub.images.length"
        >
          <span class="al image">
            <img :src="currentClub.images[props.i].img" :title="props.i" />
          </span>
        </template>
      </b-carousel>
      <p v-html="currentClub.body"></p>
    </div>
    <div v-else>
      <p v-html="currentClub.full_html"></p>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'

export default {
  data() {
    return {}
  },
  mixins: [InitViewMixin],
  methods: {},

  created() {
    this.$analytics.logEvent('Club.vue')
    this.$store.dispatch('setShowNavigationBar', true)
  },
  mounted() {
    // this.initview()
  }
}
</script>

<style scoped>
h1 {
  font-style: bold;
  font-size: x-large;
}
.is-active .al img {
  filter: grayscale(0%);
}
.al img {
  filter: grayscale(100%);
  width: 100px;
}
.bigimage img {
  width: 340px;
  height: auto;
  margin: auto;
}
.logo img {
  width: 100px;
  height: auto;
  margin: auto;
  border-radius: 50%;
  padding: 5px;
}
</style>
