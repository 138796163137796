<template>
  <div>
    <div class="col-2">
      <b-field :label="$t('Dispensario')"> </b-field>
      <b-field>
        <b-table
          style="text-align: left;"
          :data="this.dispensary"
          :sticky-header="true"
          :paginated="isPaginated"
          :per-page="perPage"
          :current-page.sync="currentPage"
          :pagination-simple="isPaginationSimple"
          :pagination-position="paginationPosition"
          :default-sort-direction="defaultSortDirection"
          :pagination-rounded="isPaginationRounded"
          default-sort="list_name"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Actual"
          :selected.sync="selected"
          hoverable
          striped
          height="calc(100vh - 133px)"
        >
          <b-table-column field="code" label="Foto" v-slot="props">
            <img
              v-if="props.row.imagenes.length > 0"
              style="border-radius:50%; max-height:35px; max-width:35px"
              :src="props.row.imagenes[0].img"
            />
          </b-table-column>

          <b-table-column
            field="code"
            :label="$t('Código')"
            sortable
            v-slot="props"
            searchable
            width="50"
          >
            {{ props.row.code }}
          </b-table-column>

          <b-table-column
            field="titulo"
            :label="$t('Producto')"
            sortable
            v-slot="props"
            searchable
          >
            {{ props.row.titulo }}
          </b-table-column>

          <b-table-column
            field="categorias"
            :label="$t('Categoría')"
            sortable
            v-slot="props"
            searchable
          >
            {{ props.row.categorias }}
          </b-table-column>

          <b-table-column
            field="precio"
            :label="$t('Precio')"
            sortable
            v-slot="props"
            width="50"
          >
            {{ props.row.precio.toFixed(2) }}
          </b-table-column>

          <b-table-column
            field="coste"
            :label="$t('Coste')"
            sortable
            v-slot="props"
            width="50"
          >
            {{ props.row.coste.toFixed(2) }}
          </b-table-column>

          <b-table-column
            field="stock"
            :label="$t('Stock')"
            sortable
            v-slot="props"
            width="50"
          >
            {{ props.row.stock }}
          </b-table-column>

          <b-table-column
            field="type"
            :label="$t('Tipo')"
            sortable
            v-slot="props"
            width="50"
          >
            {{ translateProductType(props.row.type) }}
            
          </b-table-column>

          <b-table-column
            field="props.row.menu_export"
            :label="$t('Exportar al menu')"
            v-slot="props"
            width="50"
          >
            <b-checkbox v-model="props.row.menu_export" disabled></b-checkbox>
          </b-table-column>

          <b-table-column
            field="props.row.thc"
            :label="$t('THC')"
            v-slot="props"
            width="50"
          >
            <b-checkbox v-model="props.row.thc" disabled></b-checkbox>
          </b-table-column>

          <b-table-column
            field="props.row.status"
            :label="$t('Publicado')"
            v-slot="props"
            width="50"
          >
            <b-checkbox v-model="props.row.status" disabled></b-checkbox>
          </b-table-column>

          <b-table-column
            field="props.row.discount"
            :label="$t('Descuento')"
            v-slot="props"
            width="50"
          >
            <b-checkbox v-model="props.row.discount" disabled></b-checkbox>
          </b-table-column>

          <b-table-column
            field="props.row.unlimited_stock"
            :label="$t('Stock ilimitado')"
            v-slot="props"
            width="50"
          >
            <b-checkbox
              v-model="props.row.unlimited_stock"
              disabled
            ></b-checkbox>
          </b-table-column>
        </b-table>
      </b-field>

      <b-field
        class="ml-4"
        v-if="this.checkRoles(['Manager', 'Administrador'])"
      >
        <download-excel
          :data="this.dispensary"
          :fields="json_fields"
          :header="xlsHeader()"
          name="productos.xls"
        >
          {{ $t('Descargar Excel') }}
          <b-icon icon="file-excel" size="is-medium" type="is-success"></b-icon>
        </download-excel>
        <download-excel
          :header="csvHeader()"
          :data="this.dispensary"
          type="csv"
          :fields="json_fields"
          name="productos.csv"
          :escapeCsv="true"
        >
        {{ $t('Descargar CSV') }}
          <b-icon icon="file-csv" size="is-medium" type="is-success"></b-icon>
        </download-excel>
      </b-field>
    </div>

    <div class="col-3 actions">
      <h4>{{ $t('Producto') }}</h4>
      <div class="aside">
        <div v-if="selected">
          <div v-if="selected.imagenes.length > 0">
            <img
              class="imgRigth"
              style="border-radius:50%; margin-top:20px; max-width:150px; max-height:150px;"
              :src="selected.imagenes[0].img"
            />
          </div>
          <h4>{{ selected.titulo }}</h4>
          <p v-html="selected.cuerpo"></p>
          <p>SKU: {{ selected.code }}</p>
          <p v-if="selected.type == unidad">
            {{ $t('Tipo') }}: {{ $t('Por unidades') }}
          </p>
          <p v-if="selected.type == peso">
            {{ $t('Tipo') }}: {{ $t('A peso') }}
          </p>
          <p v-if="selected.type == ambos">
            {{ $t('Tipo') }}: {{ $t('Ambos') }}
          </p>
          <p v-if="selected.dominance">
            {{ $t('Dominacia') }}: {{ selected.dominance }}
          </p>
          <p>{{ $t('Precio') }}: {{ selected.precio }}</p>
          <p>{{ $t('Coste') }}: {{ selected.coste }}</p>
          <p>{{ $t('Stock') }}: {{ selected.stock }}</p>
          <p>THC: {{ selected.thc }}</p>
          <p>{{ $t('Exportar menu') }}: {{ selected.menu_export }}</p>
          <p>{{ $t('Publicado') }}: {{ selected.status }}</p>
          <p>{{ $t('Descuento') }}: {{ selected.discount }}</p>
          <p>
            {{ $t('Peso del bote') }}:
            {{ selected.jar_weight ? selected.jar_weight : 0 }}
          </p>
          <p v-if="selected.unit_weight">
            {{ $t('Peso unitario') }}: {{ selected.unit_weight }}
          </p>

          <!-- <p>{{ selected.valoration }} <i class="far fa-star"></i></p> -->
        </div>
        <div v-else>{{ $t('SELECCIONA UN PRODUCTO') }}</div>
      </div>
      <hr />
      <div class="menu right">
        <ul>
          <!-- <li @click="manageCategories()">
              <div>
                <h6>Gestionar categorías</h6>
              </div>
            </li> -->
          <li
            @click="openStockModal()"
            v-if="
              this.checkRoles(['Manager', 'Administrador', 'Inventario']) &&
                selected &&
                selected.unlimited_stock == false
            "
          >
            <div>
              <h6>{{ $t('Modificar Stock') }}</h6>
            </div>
          </li>
          <li
            @click="showHistoryReport()"
            v-if="this.checkRoles(['Manager', 'Administrador']) && selected"
          >
            <div>
              <h6>{{ $t('Historial de movimientos') }}</h6>
            </div>
          </li>
          <li
            @click="createNewProduct()"
            v-if="this.checkRoles(['Manager', 'Administrador', 'Inventario'])"
          >
            <div>
              <h6>{{ $t('Publicar nuevo producto') }}</h6>
            </div>
          </li>
          <li
            @click="updateProduct()"
            v-if="
              this.checkRoles(['Manager', 'Administrador', 'Inventario']) &&
                selected
            "
          >
            <div>
              <h6>{{ $t('Modificar un producto') }}</h6>
            </div>
          </li>
          <li
            @click="qrProduct()"
            v-if="
              this.checkRoles([
                'Manager',
                'Administrador',
                'Inventario',
                'Budtender'
              ]) && selected
            "
          >
            <div>
              <h6>{{ $t('Generar QR') }}</h6>
            </div>
          </li>
          <li
            style="background-color: #ed6a5a; color:#fff"
            @click="deleteProduct()"
            v-if="
              this.checkRoles(['Manager', 'Administrador', 'Inventario']) &&
                selected
            "
          >
            <div>
              <h6>{{ $t('Eliminar producto') }}</h6>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- <div class="footer">
      <p>
        Resize the browser window to see how the content respond to the
        resizing.
      </p>
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'
import DispensaryCategories from '@/views/Dispensary/DispensaryCategories.vue'
import StockModal from '@/views/POS/StockModal.vue'
import QRGenerator from '@/components/QRGenerator.vue'

export default {
  data() {
    return {
      json_fields: {},
      listIndex: 0,
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 14,
      selected: null,
      selectedOptions: []
    }
  },
  mixins: [ToolsMixin, InitViewMixin],
  computed: {
    ...mapState('dispensaryPro', ['dispensary', 'current_list_index'])
  },
  methods: {
    translateProductType(type) {
      if (type === 'peso') {
        return this.$t('Peso')
      } else if (type === 'unidad') {
        return this.$t('Unidad')
      } else if (type === 'ambos') {
        return this.$t('Ambos')
      }
    },
    xlsHeader() {
      return this.$t('PRODUCTOS')
    },
    csvHeader() {
      return ''
      // return this.$t('SOCIOS')
    },
    setupJsonFields() {
      this.json_fields[this.$t('Código')] = 'code'
      this.json_fields[this.$t('Producto')] = 'titulo'
      this.json_fields[this.$t('Categoría')] = 'categorias'
      this.json_fields[this.$t('Precio')] = {
        field: 'precio',
        callback: value => {
          return value.toFixed(2)
        }
      }
      this.json_fields[this.$t('Coste')] = {
        field: 'coste',
        callback: value => {
          return value.toFixed(2)
        }
      }
      this.json_fields[this.$t('Stock')] = 'stock'
      this.json_fields[this.$t('Tipo')] = 'type'

      this.json_fields[this.$t('Exportar al menu')] = 'menu_export'

      this.json_fields[this.$t('THC')] = 'thc'
      this.json_fields[this.$t('Publicado')] = 'status'
      this.json_fields[this.$t('Descuento')] = 'discount'
      this.json_fields[this.$t('Stock ilimitado')] = 'unlimited_stock'
    },
    qrProduct() {
      this.$buefy.modal.open({
        parent: this,
        component: QRGenerator,
        props: {
          code: this.selected.code
        },
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true
      })
    },
    showHistoryReport() {
      this.$emit('close')
      this.$router.push({
        name: 'productHistoryReport',
        query: { pid: this.selected.pid, product: this.selected.titulo }
      })
    },
    selectedList(event) {
      this.$store.dispatch('dispensaryPro/setCurrentListIndex', this.listIndex)
    },
    createNewProduct() {
      this.$router.push({ name: 'dispensaryproductcreatepro' })
    },
    updateProduct() {
      this.$router.push({
        name: 'dispensaryproductcreatepro',
        query: { pid: this.selected.pid }
      })
    },
    manageCategories() {
      this.$buefy.modal.open({
        parent: this,
        component: DispensaryCategories,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true
      })
    },
    deleteProduct() {
      if (this.selected == null) {
        this.$buefy.toast.open({
          duration: 5000,
          message: `Primero debes seleccionar un producto`,
          position: 'is-bottom',
          type: 'is-danger'
        })
      } else {
        this.$buefy.dialog.confirm({
          title: this.$t('Eliminar producto'),
          message: this.$t(
            '¿Estás seguro de que deseas eliminar el producto? (Esta acción no se puede deshacer)'
          ),
          confirmText: this.$t('Sí'),
          cancelText: this.$t('No'),
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            const loadingComponent = this.$buefy.loading.open({
              container: null
            })
            this.$store
              .dispatch('dispensaryPro/deleteProduct', {
                asocid: this.currentClub.nid,
                productsArray: [this.selected]
              })
              .then(() => {
                loadingComponent.close()
              })
          }
        })
      }
    },
    parseProducts(dispensary) {
      var tmpArr = []
      dispensary.forEach(list => {
        list.item.forEach(item => {
          item.list_name = list.title
          tmpArr.push(item)
        })
      })
      return tmpArr
    },
    downloadDispensary() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })

      this.$store
        .dispatch('dispensaryPro/fetchDispensary', {
          asocid: this.currentClub.nid,
          status: null
        })
        .then(() => {
          loadingComponent.close()
          this.setupJsonFields()
        })
    },
    openStockModal() {
      this.$buefy.modal.open({
        parent: this,
        component: StockModal,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
        props: {
          currentClub: this.currentClub,
          product: this.selected
        },
        onCancel: function() {},
        events: {
          onCapture: value => {}
        }
      })
    }
  },
  created() {
    this.validateAccess(['software_gestion_club', 'comunicacion'])
    this.validateRoles(['Administrador', 'Manager', 'Budtender', 'Inventario'])
    this.$store.dispatch('dispensaryPro/fetchCategories', {
      asocid: this.currentClub.nid,
      type: 'dispensary'
    })
    this.$analytics.logEvent('DispensaryManagerPro.vue')
    this.$store.dispatch('setShowNavigationBar', true)

    this.downloadDispensary()
  },
  watch: {
    currentClub() {
      this.validateAccess(['software_gestion_club', 'comunicacion'])
      this.validateRoles([
        'Administrador',
        'Manager',
        'Budtender',
        'Inventario'
      ])
      this.downloadDispensary()
    }
    // dispensary() {
    //   this.products = this.parseProducts(this.dispensary)
    // }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.row::after {
  content: '';
  clear: both;
  // display: flex;
}

[class*='col-'] {
  float: left;
  padding: 15px;
}

.imgRigth {
  object-fit: cover;
  width: 230px;
  height: 230px;
}

html {
  font-family: 'Lucida Sans', sans-serif;
}

.my-table {
  // max-height: 100%;
  overflow-y: auto;
  height: calc(100vh - 150px);
  // height: 1000px;
  border-radius: 6px;
}

.header {
  background-color: #9933cc;
  color: #ffffff;
  padding: 15px;
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  top: 10px;
  // height: 5vh;
  overflow: auto;
}

.menu li {
  padding: 8px;
  margin-bottom: 7px;
  background-color: #f7f3f3;
  color: #666666;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
  cursor: pointer;
}

.right li {
  // background-color: #33b5e5;
  // color: #fff;
  background-color: #f3f3f3;
}

.left li {
  background-color: #f3f3f3;
  // color: #fff;
}

.menu li:hover {
  background-color: #e1e1e1;
  // color: #fff;
}

.aside {
  background-color: #f3f3f3;
  padding: 15px;
  color: #666666;
  text-align: center;
  font-size: 14px;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
}

.footer {
  background-color: #0099cc;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  padding: 15px;
}

/* For desktop: */

.col-2 {
  width: 75%;
  background-color: #f9f9f9;
  border-radius: 6px;
  // height: calc(100vh - 10px);
}
.col-3 {
  width: 25%;
  background-color: #f9f9f9;
  border-radius: 6px;
}

.consultsList {
  height: calc(100vh - 160px);
  overflow: auto;
}

.chat {
  height: calc(100vh - 190px);
  overflow: auto;
}

.actions {
  height: 100vh;
  overflow: auto;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*='col-'] {
    width: 100%;
  }
}
</style>
