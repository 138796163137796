<template>
  <fullscreen ref="fullscreen" wrap="true" background="#FFFFFF">
    <section
      class="outerSection"
      :style="
        ' background:' +
          carouselSettings.backgroundcolor +
          ';color:' +
          carouselSettings.textcolor
      "
    >
      <p class="logo" v-if="currentClub != Object">
        <img :src="currentClub.logo.img" />
      </p>
      <div
        class="centerview"
        v-if="this.dispensary.length > 0"
        style="display:flex;flex-wrap: wrap;text-align: left; justify-content:space-around;row-gap: 30px;column-gap: 50px; "
      >
        <div
          class=" cell"
          v-for="list in this.dispensary"
          :key="list.nid"
          :item="list"
        >
          <div>
            <h3>
              <b>{{ list.title }}</b>
            </h3>
          </div>
          <div v-for="item in list.item" :key="item.nid" :item="item">
            <h4 >{{ item.title }}</h4>

            <!-- <router-link
              class="dispensary-link"
              :to="{ name: 'DispensaryDetail', params: { id: item.nid } }"
            >
              <div>
                <h3>{{ item.title }}</h3>
              </div>
            </router-link> -->
          </div>
        </div>
      </div>
      <!-- <button type="button" @click="toggle">
        <i class="fas fa-expand-arrows-alt fa-2x"></i>
      </button> -->
    </section>
  </fullscreen>
</template>

<script>
import { mapState } from 'vuex'
import fullscreen from 'vue-fullscreen'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'

export default {
  data() {
    return {
      allItemsArray: []
    }
  },
  methods: {
    generateAllItemsArray() {
      this.dispensary.forEach(element => {
        element.item.forEach(item => {
          this.allItemsArray.push(item)
        })
      })
    },
    fetchDispensary() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.$store
        .dispatch('dispensaryBasic/fetchDispensary', this.currentClub.nid)
        .then(() => {
          this.generateAllItemsArray()
          loadingComponent.close()
          this.confirmFullScreen()
        })
    }
  },
  mixins: [ToolsMixin, InitViewMixin],
  computed: {
    ...mapState('dispensaryBasic', ['dispensary']),
    ...mapState('tvshow', ['carouselSettings'])
  },
  created() {
    this.validateAccess(['comunicacion'])
    this.$analytics.logEvent('TVShow3.vue')

    this.$store.dispatch('tvshow/initialize')
    this.$store.dispatch('setShowNavigationBar', false)
    this.fetchDispensary()
  },
  watch: {
    currentClub() {
      this.validateAccess(['comunicacion'])
      this.fetchDispensary()
    }
  }
}
</script>
<style lang="scss">
$bg-classroom: #232323;
.classroom {
  background: $bg-classroom;
}
</style>
<style scoped>
h1 {
  bottom: 0px;
  font-size: 4vw;
  /* width: 65%; */
  right: 0px;
}
h2 {
  font-size: calc(1em + 2vw);
}
h3 {
  bottom: 0px;
  font-size: calc(1vw);
  /* width: 65%; */
  right: 0px;
}
h4 {
  font-size: calc(1vw);
  line-height: 1;
}
.logo img {
  width: 120px;
  height: auto;
  margin: auto;
  padding: 20px;
  float: right;
}
.outerSection {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.centerview {
  position: absolute;
  top: 4%;
  left: 50%;
  transform: translate(-50%, 10%);
  width: 100%;
}
.cell {
  height: auto;
  padding: 20px;
}
</style>
