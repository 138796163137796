import {
  SET_PATIENTS,
  SET_CURRENT_PATIENT,
  ADD_NEW_PATIENT_TO_SELECTED,
  CLEAR_PATIENTS,
  REMOVE_PATINT_FROM_STORE,
  SET_PATIENT_CATEGORIES,
  REMOVE_PATIENT_CATEGORY_FROM_STORE,
  ADD_PATIENT_CATEGORY,
  EDIT_PATIENT_CATEGORY,
  SET_SELECTED_PATIENTS,
  CLEAR_CURRENT_PATIENT,
  CLEAR_SELECTED_PATIENTS,
  CLEAR_SELECTED_PATIENT,
  SET_MY_MEMBERSHIP_CARD
} from './types'
import APIPatients from '@/services/APIPatients.js'
import tools from '@/Tools/tools.js'

const initialState = {
  patients: [],
  selectedPatients: [],
  currentPatient: null,
  categories: [],
  myMembershipCard: null
}
const getters = {
  getPtientById: state => id => {
    return state.patients.find(patient => patient.nid === id)
  }
}
const mutations = {
  [SET_MY_MEMBERSHIP_CARD](state, myMembershipCard) {
    if (myMembershipCard.length > 0) {
      state.myMembershipCard = myMembershipCard[0]
      localStorage.setItem(
        'myMembershipCard',
        JSON.stringify(myMembershipCard[0])
      )
    }
  },
  [SET_PATIENTS](state, patients) {
    state.patients = patients
  },
  [SET_SELECTED_PATIENTS](state, selectedPatients) {
    localStorage.setItem('selectedPatients', JSON.stringify(selectedPatients))
    state.selectedPatients = selectedPatients
  },
  [SET_CURRENT_PATIENT](state, currentPatient) {
    localStorage.setItem('currentPatient', JSON.stringify(currentPatient))
    state.currentPatient = currentPatient
  },
  [ADD_NEW_PATIENT_TO_SELECTED](state, patient) {
    state.selectedPatients.push(patient)
    localStorage.setItem('currentPatient', JSON.stringify(patient))
    state.currentPatient = patient
  },
  [REMOVE_PATINT_FROM_STORE](state, patient) {
    state.patients.splice(state.patients.indexOf(patient), 1)
  },
  [CLEAR_PATIENTS](state) {
    state.patients = []
  },
  [CLEAR_CURRENT_PATIENT](state) {
    if (state.currentPatient) {
      state.selectedPatients.splice(
        state.selectedPatients.indexOf(state.currentPatient),
        1
      )
      localStorage.setItem(
        'selectedPatients',
        JSON.stringify(state.selectedPatients)
      )
    }
    state.currentPatient = null
    localStorage.removeItem('currentPatient')
  },
  [CLEAR_SELECTED_PATIENT](state, patient) {
    console.log(patient)
    state.selectedPatients.splice(state.selectedPatients.indexOf(patient), 1)
    localStorage.setItem(
      'selectedPatients',
      JSON.stringify(state.selectedPatients)
    )
    if (state.currentPatient.nid == patient.nid) {
      if (state.selectedPatients.length > 0) {
        state.currentPatient =
          state.selectedPatients[state.selectedPatients.length - 1]
        localStorage.setItem(
          'currentPatient',
          JSON.stringify(state.currentPatient)
        )
      } else {
        state.currentPatient = null
        localStorage.removeItem('currentPatient')
      }
    }
  },
  [CLEAR_SELECTED_PATIENTS](state) {
    state.selectedPatients = []
    localStorage.removeItem('selectedPatients')
  },
  [SET_PATIENT_CATEGORIES](state, categories) {
    state.categories = categories
  },
  [ADD_PATIENT_CATEGORY](state, category) {
    state.categories.push(category)
  },
  [REMOVE_PATIENT_CATEGORY_FROM_STORE](state, patientCategory) {
    state.categories.splice(state.categories.indexOf(patientCategory), 1)
  },
  [EDIT_PATIENT_CATEGORY](state, { category, newcategory }) {
    var index = state.categories.indexOf(category)
    if (index !== -1) {
      state.categories[index].title = newcategory.title
      state.categories[index].descuento = newcategory.descuento
    }
  }
}
const actions = {
  fetchPatients({ commit, state }, { asocid, is_staff }) {
    // if (state.patients.length > 0) {
    //   return
    // }
    let promise = APIPatients.getPatients(asocid, is_staff)
      .then(response => {
        commit(SET_PATIENTS, response.data)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  fetchPatientByDni({ commit }, { asocid, dni }) {
    let promise = APIPatients.getPatientByDNI(asocid, dni)
      // .then(response => {
      //   commit(SET_PATIENT_DATA, response.data)
      //   console.log(response)
      // })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  fetchPatientByMembership({ commit }, { asocid, nid }) {
    let promise = APIPatients.getPatientByMembership(asocid, nid)
      // .then(response => {
      //   commit(SET_PATIENT_DATA, response.data)
      //   console.log(response)
      // })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  fetchPatientByUid({ commit }, { asocid, uid }) {
    let promise = APIPatients.getPatientByUid(asocid, uid)
      // .then(response => {
      //   commit(SET_PATIENT_DATA, response.data)
      //   console.log(response)
      // })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  fetchMyMembershipCard({ commit }, { asocid, uid, router }) {
    let promise = APIPatients.getPatientByUid(asocid, uid).then(response => {
      commit(SET_MY_MEMBERSHIP_CARD, response.data.data)
    })

    return promise
  },
  createPatient({ commit }, json) {
    let promise = APIPatients.createPatient(json)
    // .then(response => {
    //   commit(SET_PATIENTS, response.data)
    // })
    // .catch(error => {
    //   console.log('Ha habido un error:' + error)
    // })
    // .catch(error => {
    //   console.log('Ha habido un error:' + error)
    //   console.log(error.response.data)
    //   console.log(error.response.status)
    //   console.log(error.response.headers)
    //   tools.manageAxiosError(error)
    // })
    return promise
  },
  editPatient({ commit }, json) {
    let promise = APIPatients.editPatient(json)
    // .then(response => {
    //   // commit(SET_PATIENTS, response.data)
    // })
    // .catch(error => {
    //   console.log('Ha habido un error:' + error)
    //   console.log(error.response.data)
    //   console.log(error.response.status)
    //   console.log(error.response.headers)
    //   tools.manageAxiosError(error)
    // })
    return promise
  },
  deletePatient({ commit }, { asocid, patient }) {
    let promise = APIPatients.deletePatient(asocid, patient.nid)
      .then(response => {
        commit(REMOVE_PATINT_FROM_STORE, patient)
      })

      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  clearPatients({ commit }) {
    commit(CLEAR_PATIENTS)
  },
  fetchPatientCategories({ commit }, asocid) {
    let promise = APIPatients.getCategories(asocid)
      .then(response => {
        commit(SET_PATIENT_CATEGORIES, response.data)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  createPatientCategory({ commit }, json) {
    let promise = APIPatients.createPatientCategory(json)
    // .then(response => {
    //   json.nid = response.nid
    //   commit(ADD_PATIENT_CATEGORY, json)
    // })
    // .catch(error => {
    //   tools.manageAxiosError(error)
    //   console.log('Ha habido un error:' + error)
    // })
    return promise
  },
  updatePatientCategory({ commit }, { category, newcategory }) {
    let promise = APIPatients.updatePatientCategory(newcategory)
      .then(response => {
        commit(EDIT_PATIENT_CATEGORY, { category, newcategory })
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  deletePatientCategory({ commit }, { asocid, patientCategory }) {
    let promise = APIPatients.deletePatientCategory(asocid, patientCategory.nid)
      .then(response => {
        commit(REMOVE_PATIENT_CATEGORY_FROM_STORE, patientCategory)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  createFile({ commit }, { files, asocid }) {
    let promise = APIPatients.createFile(files, asocid)
    // .then(response => {
    //   console.log(response)
    // })
    // .catch(error => {
    //   tools.manageAxiosError(error)
    //   console.log('Ha habido un error:' + error)
    // })
    return promise
  },
  setSelectedPatients({ commit }, patients) {
    commit(SET_SELECTED_PATIENTS, patients)
  },
  fetchSelectedPatients({ commit }) {
    const saved = JSON.parse(localStorage.getItem('selectedPatients'))

    if (saved) {
      //TODO: Esto es parche. Hay que solucionar por qué a veces mete un null en el array selectedPatients
      saved.forEach(patient => {
        if (patient == null) {
          saved.splice(saved.indexOf(patient), 1)
        }
      })
      commit(SET_SELECTED_PATIENTS, saved)
    }
  },
  setCurrentPatient({ commit }, currentPatient) {
    commit(SET_CURRENT_PATIENT, currentPatient)
  },
  addNewPatientToSelected({ commit, store }, patient) {
    commit(ADD_NEW_PATIENT_TO_SELECTED, patient)
  },
  fetchCurrentPatient({ state, commit }) {
    if (!state.currentPatient) {
      commit(
        SET_CURRENT_PATIENT,
        JSON.parse(localStorage.getItem('currentPatient'))
      )
    }
  },
  chargeWallet({ commit }, { asocid, patient, operation, amount, pay_type }) {
    let promise = APIPatients.chargeWallet(
      asocid,
      patient,
      operation,
      amount,
      pay_type
    )
      .then(response => {
        console.log(response)
        patient.monedero = (Number(patient.monedero) + Number(amount)).toFixed(
          2
        )
        commit(SET_CURRENT_PATIENT, patient)
        // commit(EDIT_PATIENT_CATEGORY, { category, newcategory })
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  sendSMSCode({ commit }, { asocid, uid, tlf, country_code }) {
    const lang = JSON.parse(localStorage.getItem('language'))
    let selectedLanguage = ''
    if (lang && lang.language) {
      selectedLanguage = lang.language
    } else {
      selectedLanguage = 'es'
    }
    let json = {
      asocid: asocid,
      uid: uid,
      tlf: tlf,
      country_code: country_code,
      lang: selectedLanguage
    }
    console.log('================-------------================')
    console.log(json)
    let promise = APIPatients.sendSMSCode(json)
      .then(response => {
        console.log(response)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}
