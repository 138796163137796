import { SET_NEWS, SET_CURRENT_NEWS, DELETE_NEWS } from './types'
import APINews from '@/services/APINews.js'
import tools from '@/Tools/tools.js'

const initialState = {
  news: [],
  currentNews: {}
}

const mutations = {
  [SET_NEWS](state, news) {
    state.news = news
  },
  [SET_CURRENT_NEWS](state, currentNews) {
    state.currentNews = currentNews
  },
  [DELETE_NEWS](state, news) {
    state.news.splice(state.news.indexOf(news), 1)
  }
}

const actions = {
  fetchNews({ commit }, asocid) {
    let promise = APINews.getNews(asocid)
      .then(response => {
        //Ordenamos por fecha de creacion
        // response.data.sort((a, b) => (a.created < b.created ? 1 : -1))
        commit(SET_NEWS, response.data)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  //   fetchNew({ commit }, { asocid, nid }) {
  //     let promise = APINews.getNew(asocid, nid)
  //       .then(response => {
  //         console.log(response)
  //         if (response.data.length > 0) {
  //           commit(SET_CURRENT_NEWS, response.data[0])
  //         }
  //       })
  //       .catch(error => {
  //         console.log('Ha habido un error:' + error)
  //       })
  //     return promise
  //   },
  setCurrentNews({ commit }, news) {
    commit(SET_CURRENT_NEWS, news)
  },

  deleteNews({ commit }, { asocid, news }) {
    let promise = APINews.deleteNews(asocid, news.nid)
      .then(response => {
        console.log(response.data)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    commit(DELETE_NEWS, news)
    return promise
  },

  createNews({ commit }, { asocid, title, body, images }) {
    let json = {
      cid: asocid,
      title: title,
      body: body,
      images: images
    }

    let promise = APINews.postNews(json)
      .then(response => {
        // Añadir nueva noticia al store
        // commit(SET_USERS, response.data)
        TODO: console.log(response)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  }
}

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations
}
