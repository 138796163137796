<template>
  <div>
    <h2 class="mt-3 mb-2" style="text-align: left">
      {{ $t('INFORME DE RETIRADAS') }}
    </h2>
    <div style="display:flex">
      <b-field :label="$t('Fecha inicial')">
        <b-datetimepicker
          v-model="start_date"
          :show-week-number="false"
          :first-day-of-week="1"
          :locale="this.$i18n.locale"
          :placeholder="$t('Fecha inicial')"
          trap-focus
          :disabled="start_date_disabled"
        >
        </b-datetimepicker>
      </b-field>
      <b-field :label="$t('Fecha final')" class="ml-4">
        <b-datetimepicker
          v-model="end_date"
          :show-week-number="false"
          :first-day-of-week="1"
          :locale="this.$i18n.locale"
          :placeholder="$t('Fecha final')"
          trap-focus
          :disabled="end_date_disabled"
        >
        </b-datetimepicker>
      </b-field>

      <b-field
        :label="$t('Descargar')"
        class="ml-4"
        style=" position: absolute;right: .5em;top: 40px;"
      >
        <download-excel
          :data="this.data"
          :fields="json_fields"
          :header="xlsHeader()"
          name="dispensation.xls"
        >
          <b-icon icon="file-excel" size="is-medium" type="is-success"></b-icon>
        </download-excel>
        <download-excel
          :data="this.data"
          type="csv"
          :fields="json_fields"
          name="dispensation.csv"
        >
          <b-icon icon="file-csv" size="is-medium" type="is-success"></b-icon>
        </download-excel>
      </b-field>
    </div>
    <b-table
      style="text-align: left;"
      class="mr-2"
      :data="this.data"
      :sticky-header="true"
      :paginated="isPaginated"
      :per-page="perPage"
      :current-page.sync="currentPage"
      :pagination-simple="isPaginationSimple"
      :pagination-position="paginationPosition"
      :default-sort-direction="defaultSortDirection"
      :pagination-rounded="isPaginationRounded"
      filters-event="input"
      @filters-event-input="filtersChange"
      default-sort="order_changed"
      aria-next-label="Siguiente"
      aria-previous-label="Anterior"
      aria-page-label="Página"
      aria-current-label="Actual"
      :selected.sync="selected"
      hoverable
      striped
      height="calc(100vh - 210px)"
    >
      <b-table-column
        field="order_id"
        :label="$t('ID Pedido')"
        sortable
        v-slot="props"
        searchable
        width="50"
        subheading="Total"
      >
        {{ props.row.order_id }}
      </b-table-column>

      <b-table-column
        field="order_changed"
        :label="$t('Fecha')"
        sortable
        v-slot="props"
        searchable
        width="50"
      >
        {{ format_date(props.row.order_changed, 'hh:mm dd/MM/yyyy') }}
      </b-table-column>

      <b-table-column
        field="product.title"
        :label="$t('Producto')"
        sortable
        v-slot="props"
        searchable
      >
        {{ props.row.product.title }}
      </b-table-column>

      <b-table-column
        field="product.code"
        :label="$t('SKU')"
        sortable
        v-slot="props"
        searchable
      >
        {{ props.row.product.code }}
      </b-table-column>

      <b-table-column
        field="product.category"
        :label="$t('Categoría')"
        sortable
        v-slot="props"
        searchable
      >
        {{ props.row.product.category }}
      </b-table-column>

      <b-table-column
        field="quantity"
        :label="$t('Cantidad')"
        sortable
        v-slot="props"
        :subheading="this.qtyTotal.toFixed(2)"
      >
        {{ props.row.quantity }}
      </b-table-column>

      <b-table-column
        field="real_quantity"
        :label="$t('Peso real')"
        sortable
        v-slot="props"
        :subheading="totalReal.toFixed(2)"
      >
        {{ props.row.real_quantity }}
      </b-table-column>

      <b-table-column
        field="total"
        :label="$t('Total')"
        sortable
        v-slot="props"
        :subheading="totalTotal.toFixed(2)"
      >
        {{ props.row.total.toFixed(2) }}
      </b-table-column>

      <!-- <b-table-column
        field="order_status"
        :label="'Status'"
        sortable
        v-slot="props"
        searchable
      >
        <img
          v-if="props.row.imagenes.length > 0"
          class="imgRigth"
          style="border-radius:50%; max-height:4vh; max-width:4vh"
          :src="props.row.imagenes[0].img"
        />
        {{ props.row.order_status }}
      </b-table-column> -->
      <b-table-column
        field="membership_card.number"
        :label="$t('Nº de socio')"
        sortable
        v-slot="props"
        searchable
      >
        {{ props.row.membership_card.number }}
      </b-table-column>

      <b-table-column
        field="membership_card.nombre"
        :label="$t('Nombre')"
        sortable
        v-slot="props"
        searchable
      >
        {{ props.row.membership_card.nombre }}
      </b-table-column>

      <b-table-column
        field="membership_card.surname"
        :label="$t('Apellidos')"
        sortable
        v-slot="props"
        searchable
      >
        {{ props.row.membership_card.surname }}
      </b-table-column>

      <!-- <b-table-column
        field="precio"
        :label="$t('Precio')"
        sortable
        v-slot="props"
        width="50"
      >
        {{ props.row.precio.toFixed(2) }}
      </b-table-column> -->
    </b-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'

export default {
  data() {
    return {
      json_fields: {},
      start_date_disabled: false,
      end_date_disabled: false,
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'desc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 19,
      selected: null,
      data: [],
      qtyTotal: 0,
      totalTotal: 0,
      totalReal: 0,
      start_date: new Date(new Date().setDate(new Date().getDate() - 7)),
      end_date: new Date(),
      locale: 'es-ES'
    }
  },
  mixins: [InitViewMixin, ToolsMixin],
  computed: {
    ...mapState('orders', ['orders'])
  },
  methods: {
    filtersChange(event) {
      var filteredDataArray = this.data
      if (event.filters['product.title']) {
        filteredDataArray = filteredDataArray.filter(prod => {
          return (
            prod.product.title
              .toString()
              .toLowerCase()
              .indexOf(event.filters['product.title'].toLowerCase()) >= 0
          )
        })
      }
      if (event.filters['product.code']) {
        filteredDataArray = filteredDataArray.filter(prod => {
          return (
            prod.product.code
              .toString()
              .toLowerCase()
              .indexOf(event.filters['product.code'].toLowerCase()) >= 0
          )
        })
      }
      if (event.filters['product.category']) {
        filteredDataArray = filteredDataArray.filter(prod => {
          return (
            prod.product.category
              .toString()
              .toLowerCase()
              .indexOf(event.filters['product.category'].toLowerCase()) >= 0
          )
        })
      }
      if (event.filters['membership_card.number']) {
        filteredDataArray = filteredDataArray.filter(prod => {
          return (
            prod.membership_card.number
              .toString()
              .toLowerCase()
              .indexOf(event.filters['membership_card.number'].toLowerCase()) >=
            0
          )
        })
      }
      if (event.filters['membership_card.nombre']) {
        filteredDataArray = filteredDataArray.filter(prod => {
          return (
            prod.membership_card.nombre
              .toString()
              .toLowerCase()
              .indexOf(event.filters['membership_card.nombre'].toLowerCase()) >=
            0
          )
        })
      }
      if (event.filters['membership_card.surname']) {
        filteredDataArray = filteredDataArray.filter(prod => {
          return (
            prod.membership_card.surname
              .toString()
              .toLowerCase()
              .indexOf(
                event.filters['membership_card.surname'].toLowerCase()
              ) >= 0
          )
        })
      }
      this.qtyTotal = 0
      this.totalTotal = 0
      this.totalReal = 0
      filteredDataArray.forEach(product => {
        this.qtyTotal = this.qtyTotal + Number(product.quantity)
        this.totalTotal = this.totalTotal + product.total
        this.totalReal = this.totalReal + Number(product.real_quantity)
      })
    },
    htmlHeader() {
      let fi =
        this.$t('Fecha inicial') + ': ' + this.datestring(this.start_date)
      let ff = this.$t('Fecha final') + ': ' + this.datestring(this.end_date)
      return (
        '<div>' +
        this.$t('INFORME DE RETIRADAS') +
        '    \n' +
        fi +
        '  \n' +
        ff +
        '</div>'
      )
    },
    xlsHeader() {
      let fi =
        this.$t('Fecha inicial') + ': ' + this.datestring(this.start_date)
      let ff = this.$t('Fecha final') + ': ' + this.datestring(this.end_date)
      return [this.$t('INFORME DE RETIRADAS'), fi, ff]
    },
    csvHeader() {
      let fi = this.datestring(this.start_date)
      let ff = this.datestring(this.end_date)
      return this.$t('INFORME DE RETIRADAS') + '   ' + fi + ' / ' + ff
    },
    setupJsonFields() {
      this.json_fields[this.$t('ID Pedido')] = 'order_id'
      this.json_fields[this.$t('Fecha')] = {
        field: 'order_changed',
        callback: value => {
          return this.format_date(value, 'hh:mm dd/MM/yyyy')
        }
      }
      this.json_fields[this.$t('Producto')] = 'product.title'
      this.json_fields[this.$t('SKU')] = 'product.code'
      this.json_fields[this.$t('Categoría')] = 'product.category'
      this.json_fields[this.$t('Cantidad')] = {
        field: 'quantity',
        callback: value => {
          return Number(value).toFixed(2)
        }
      }
      this.json_fields[this.$t('Peso real')] = {
        field: 'real_quantity',
        callback: value => {
          return Number(value).toFixed(2)
        }
      }
      this.json_fields[this.$t('Total')] = {
        field: 'total',
        callback: value => {
          return Number(value).toFixed(2)
        }
      }
      this.json_fields[this.$t('Nº Socio')] = 'membership_card.number'
      this.json_fields[this.$t('Nombre')] = 'membership_card.nombre'
      this.json_fields[this.$t('Apellidos')] = 'membership_card.surname'
    },
    downloadData(patient_id) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.start_date_disabled = this.end_date_disabled = true
      this.data = []
      let json = {
        asocid: this.currentClub.nid,
        start_date: Date.parse(this.start_date) / 1000,
        end_date: Date.parse(this.end_date) / 1000
      }
      if (patient_id) {
        console.log('patient_id')
        console.log(patient_id)
        json.membership_id = patient_id
      }
      this.$store
        .dispatch('orders/fetchClubOrdersCompleted', json)
        .then(() => {
          loadingComponent.close()
          this.start_date_disabled = this.end_date_disabled = false
          this.qtyTotal = 0
          this.totalTotal = 0
          this.totalReal = 0
          this.orders.forEach(order => {
            order.products.forEach(product => {
              product.order_id = order.order_id
              product.membership_card = order.membership_card
              product.order_changed = order.order_changed
              this.data.push(product)
              this.qtyTotal = this.qtyTotal + Number(product.quantity)
              this.totalTotal = this.totalTotal + product.total
              this.totalReal = this.totalReal + Number(product.real_quantity)
            })
          })
          this.setupJsonFields()
        })
        .catch(error => {
          loadingComponent.close()
        })

      // this.qtyTotal = this.qtyTotal.toFixed(2)
    }
  },
  created() {
    this.validateAccess(['software_gestion_club'])
    this.validateRoles(['Administrador', 'Manager'])
    this.$analytics.logEvent('OrdersReport.vue')
    this.$store.dispatch('setShowNavigationBar', true)

    this.downloadData(this.$route.query.ms_id)
  },
  watch: {
    currentClub() {
      this.validateAccess(['software_gestion_club'])
      this.validateRoles(['Administrador', 'Manager'])
      this.downloadData()
    },
    start_date() {
      this.downloadData()
    },
    end_date() {
      this.downloadData()
    }
  }
}
</script>

<style lang="scss" scoped>
.my-table {
  // max-height: 100%;
  overflow-y: auto;
  height: calc(100vh - 140px);
  // height: 1000px;
}
</style>
