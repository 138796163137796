<template>
  <div>
    <b-field :label="$t('Cupones')"> </b-field>
    <b-field>
      <b-table
        v-if="cuponuses"
        style="text-align: left;"
        :data="this.cuponuses"
        :sticky-header="true"
        :paginated="isPaginated"
        :per-page="perPage"
        :current-page.sync="currentPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :default-sort-direction="defaultSortDirection"
        :pagination-rounded="isPaginationRounded"
        default-sort="created"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="Página"
        aria-current-label="Actual"
        :selected.sync="selected"
        hoverable
        striped
        height="calc(100vh - 133px)"
      >
        <b-table-column
          field="code"
          :label="$t('ID Cupón')"
          sortable
          v-slot="props"
          searchable
          width="50"
        >
          {{ props.row.coupon_id }}
        </b-table-column>

        <b-table-column
          field="title"
          :label="$t('Socio')"
          sortable
          v-slot="props"
          searchable
          width="50"
        >
          {{ props.row.title }}
        </b-table-column>

        <b-table-column
          field="to_date"
          :label="$t('Fecha de uso')"
          sortable
          v-slot="props"
          width="50"
        >
          {{ format_date(props.row.use_date, 'hh:mm dd/MM/yyyy') }}
        </b-table-column>
      </b-table>
    </b-field>
  </div>
</template>

<script>
import session from '@/services/Session.js'
import { mapState } from 'vuex'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'

export default {
  data() {
    return {
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'desc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 15,
      selected: null,
      cuponuses: []
    }
  },
  mixins: [ToolsMixin, InitViewMixin],
  computed: {
    ...mapState('club', ['currentClub'])
  },

  methods: {
    downloadData(coupon_id) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      session
        .get(
          '/api/smokapp_coupon_use?asocid=' +
            this.currentClub.nid +
            '&coupon_id=' +
            coupon_id
        )
        .then(response => {
          this.cuponuses = response.data
          loadingComponent.close()
        })
    }
  },
  mounted() {
    // this.downloadData(this.$route.query.coupon_id)
  },
  watch: {
    '$route.params.coupon_id': {
      handler: function(search) {
        console.log(search)
        // const loadingComponent = this.$buefy.loading.open({
        //   container: null
        // })
        this.downloadData(this.$route.query.coupon_id)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.row::after {
  content: '';
  clear: both;
  // display: flex;
}

[class*='col-'] {
  float: left;
  padding: 15px;
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  top: 10px;
  // height: 5vh;
  overflow: auto;
}

.menu li {
  padding: 8px;
  margin-bottom: 7px;
  background-color: #f7f3f3;
  color: #666666;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
  cursor: pointer;
}

.right li {
  // background-color: #33b5e5;
  // color: #fff;
  background-color: #f3f3f3;
}

.left li {
  background-color: #f3f3f3;
  // color: #fff;
}

.menu li:hover {
  background-color: #e1e1e1;
  // color: #fff;
}

.aside {
  background-color: #f3f3f3;
  padding: 15px;
  color: #666666;
  text-align: center;
  font-size: 14px;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
}

/* For desktop: */

.col-2 {
  width: 75%;
  background-color: #f9f9f9;
  border-radius: 6px;
  // height: calc(100vh - 10px);
}
.col-3 {
  width: 25%;
  background-color: #f9f9f9;
  border-radius: 6px;
}

.actions {
  height: 100vh;
  overflow: auto;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*='col-'] {
    width: 100%;
  }
}
</style>
