<template>
  <div>
    <div class="backButton">
      <b-button class="button is-primary" @click="$router.go(-1)"
        >Volver</b-button
      >
    </div>

    <b-carousel
      :indicator-inside="false"
      :autoplay="false"
      :arrow="false"
      :animated="'slide'"
      :interval="8000"
      @click="fotoclick(dispensary_item.nid)"
    >
      <b-carousel-item v-for="(item, i) in dispensary_item.images" :key="i">
        <span class="bigimage">
          <img :src="item.img" />
        </span>
      </b-carousel-item>
      <template slot="indicators" slot-scope="props">
        <span class="al image">
          <img :src="dispensary_item.images[props.i].img" :title="props.i" />
        </span>
      </template>
    </b-carousel>

    <h1>{{ dispensary_item.title }}</h1>
    <h2>{{ dispensary_item.subtitle }}</h2>
    <p>{{ dispensary_item.body }}</p>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CreateOrder from '@/views/POS/CreateOrder.vue'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'

export default {
  props: {
    id: {
      type: String,
      required: false
    },
    asocid: {
      type: String,
      required: false
    }
  },
  mixins: [InitViewMixin],
  computed: {
    ...mapState(['showNavigationBar']),
    ...mapState('dispensaryPro', ['dispensary_item'])
  },
  methods: {
    fotoclick(nid) {
      console.log(nid)
    },
    createOrder() {
      this.$buefy.modal.open({
        parent: this,
        component: CreateOrder,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
        props: {
          item: this.dispensary_item
        }
      })
    },
    fetchItem(asocid, itemId) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.$store
        .dispatch('dispensaryPro/fetchDispensaryItem', {
          asocid: asocid,
          itemId: itemId
        })
        .then(() => {
          loadingComponent.close()
        })
    }
  },
  created() {
    this.$analytics.logEvent('DispensaryDetail.vue')
    this.$store.dispatch('setShowNavigationBar', false)

    this.fetchItem(this.currentClub.nid, this.id)
  }
}
</script>

<style scoped>
h1 {
  font-weight: bold;
}
.is-active .al img {
  filter: grayscale(0%);
}
.al img {
  filter: grayscale(100%);
  width: 100px;
}
.bigimage img {
  width: 340px;
  height: auto;
  margin: auto;
}
.backButton {
  width: 100px;
  padding: 10px;
}
</style>
