<template>
  <div>
    <div class="row">
      <div class="col-2">
        <!-- <b-field label="Inventario"> </b-field> -->
        <b-field>
          <b-table
            :data="this.inventory"
            ref="table"
            paginated
            per-page="24"
            :opened-detailed="defaultOpenedDetails"
            detailed
            detail-key="iid"
            :show-detail-icon="showDetailIcon"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            style="  text-align: left;"
          >
            <b-table-column
              field="iid"
              label="ID"
              width="70"
              numeric
              v-slot="props"
              searchable
            >
              {{ props.row.iid }}
            </b-table-column>

            <b-table-column
              field="title"
              :label="$t('Título')"
              sortable
              v-slot="props"
              searchable
            >
              <template v-if="showDetailIcon">
                {{ props.row.title }}
              </template>
              <!-- <template v-else>
                <a @click="props.toggleDetails(props.row)">
                  {{ props.row.user.first_name }}
                </a>
              </template> -->
            </b-table-column>

            <b-table-column
              field="category"
              :label="$t('Categoría')"
              sortable
              v-slot="props"
              searchable
            >
              {{ props.row.category }}
            </b-table-column>

            <b-table-column
              field="type"
              :label="$t('Tipo')"
              sortable
              v-slot="props"
              searchable
            >
              {{ props.row.type }}
            </b-table-column>

            <b-table-column
              field="dominance"
              label="Dominancia"
              sortable
              v-slot="props"
              searchable
            >
              {{ props.row.dominance }}
            </b-table-column>

            <b-table-column
              field="status"
              :label="'Status'"
              sortable
              v-slot="props"
            >
              {{ props.row.status }}
            </b-table-column>

            <b-table-column
              field="add_total"
              label="Total añadido"
              sortable
              v-slot="props"
            >
              {{ props.row.inventory_movement.add_total }}
            </b-table-column>
            <b-table-column
              field="remove_total"
              label="Total quitado"
              sortable
              v-slot="props"
            >
              {{ props.row.inventory_movement.remove_total }}
            </b-table-column>
            <b-table-column
              field="move_total"
              label="Total movido"
              sortable
              v-slot="props"
            >
              {{ props.row.inventory_movement.move_total }}
            </b-table-column>

            <b-table-column
              field="total"
              :label="$t('Total')"
              sortable
              v-slot="props"
            >
              <strong> {{ props.row.inventory_movement.amount_actual }}</strong>
            </b-table-column>
            <b-table-column v-slot="props">
              <b-button
                class="is-small"
                icon-left="plus"
                @click="addMovement(props.row.iid)"
              ></b-button>
              <b-button class="is-small" icon-left="trash-alt"></b-button>
            </b-table-column>

            <template #detail="props">
              <b-table
                v-if="props.row.inventory_movement.movement"
                :data="props.row.inventory_movement.movement"
              >
                <b-table-column field="iid" label="IID" sortable v-slot="props">
                  {{ props.row.iid }}
                </b-table-column>
                <b-table-column field="iid" label="MID" sortable v-slot="props">
                  {{ props.row.mid }}
                </b-table-column>
                <b-table-column
                  field="action"
                  :label="$t('Acción')"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.action }}
                </b-table-column>
                <b-table-column
                  field="amount"
                  :label="$t('Cantidad')"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.amount }}
                </b-table-column>
                <b-table-column
                  field="cost"
                  :label="$t('Coste')"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.cost }}
                </b-table-column>

                <b-table-column
                  field="location"
                  label="Locacalización"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.location }}
                </b-table-column>

                <b-table-column
                  field="note"
                  :label="$t('Nota')"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.note }}
                </b-table-column>

                <b-table-column :label="$t('Acción')">
                  <b-button class="is-small">Añadir</b-button>
                  <b-button class="is-small">Retirar</b-button>
                  <b-button class="is-small">Mover</b-button>
                  <b-button class="is-small">Convertir</b-button>
                </b-table-column>
              </b-table>
            </template>
          </b-table>
        </b-field>
      </div>

      <div class="col-3 actions">
        <div class="menu right">
          <ul>
            <li @click="createNewList()">
              <div>
                <h6>{{ $t('Crear nueva categoría') }}</h6>
                <p>Crear nueva categoría de inventario</p>
              </div>
            </li>
            <li @click="createNewInventory()">
              <div>
                <h6>Publicar nuevo item inventario</h6>
                <p>
                  Publica un nuevo item inventario para generar nuevos productos
                </p>
              </div>
            </li>
            <li
              style="background-color: #ed6a5a; color:#fff"
              @click="deleteProduct()"
            >
              <div>
                <h6>Eliminar item de inventario</h6>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- <div class="footer">
      <p>
        Resize the browser window to see how the content respond to the
        resizing.
      </p>
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'
import InventoryItemCreate from '@/views/Inventory/InventoryItemCreate.vue'
import InventoryMovement from '@/views/Inventory/InventoryMovement.vue'

export default {
  data() {
    return {
      defaultOpenedDetails: [1],
      showDetailIcon: true
    }
  },
  mixins: [ToolsMixin, InitViewMixin],
  computed: {
    ...mapState('inventory', ['inventory', 'inventory_item'])
  },
  methods: {
    createNewInventory() {
      this.$buefy.modal.open({
        parent: this,
        component: InventoryItemCreate,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true
      })
    },
    addMovement(iid) {
      this.$buefy.modal.open({
        parent: this,
        component: InventoryMovement,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
        props: {
          iid: iid
        }
      })
    },
    createNewList() {},

    downloadInventory() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })

      this.$store
        .dispatch('inventory/fetchInventory', this.currentClub.nid)
        .then(() => {
          loadingComponent.close()
        })
    }
  },
  created() {
    this.validateAccess(['software_gestion_club'])
    this.validateRoles(['Administrador', 'Manager'])
    this.$analytics.logEvent('InventoryManager.vue')
    this.$store.dispatch('setShowNavigationBar', true)

    this.downloadInventory()
  },
  watch: {
    currentClub() {
      this.validateAccess(['software_gestion_club'])
      this.validateRoles(['Administrador', 'Manager'])
      this.downloadInventory()
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.row::after {
  content: '';
  clear: both;
  // display: flex;
}

[class*='col-'] {
  float: left;
  padding: 15px;
}

.imgRigth {
  object-fit: cover;
  width: 230px;
  height: 230px;
}

html {
  font-family: 'Lucida Sans', sans-serif;
}

.my-table {
  // max-height: 100%;
  overflow-y: auto;
  height: calc(100vh - 150px);
  // height: 1000px;
}

.header {
  background-color: #9933cc;
  color: #ffffff;
  padding: 15px;
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  top: 10px;
  // height: 5vh;
  overflow: auto;
}

.menu li {
  padding: 8px;
  margin-bottom: 7px;
  background-color: #f7f3f3;
  color: #666666;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
  cursor: pointer;
}

.right li {
  // background-color: #33b5e5;
  // color: #fff;
  background-color: #f3f3f3;
}

.left li {
  background-color: #f3f3f3;
  // color: #fff;
}

.menu li:hover {
  background-color: #e1e1e1;
  // color: #fff;
}

.aside {
  background-color: #f3f3f3;
  padding: 15px;
  color: #666666;
  text-align: center;
  font-size: 14px;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
}

.footer {
  background-color: #0099cc;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  padding: 15px;
}

/* For desktop: */

.col-2 {
  width: 80%;
  background-color: #f9f9f9;
  border-radius: 6px;
}
.col-3 {
  width: 20%;
  background-color: #f9f9f9;
  border-radius: 6px;
}

.consultsList {
  height: calc(100vh - 160px);
  overflow: auto;
}

.chat {
  height: calc(100vh - 190px);
  overflow: auto;
}

.actions {
  height: calc(100vh - 85px);
  overflow: auto;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*='col-'] {
    width: 100%;
  }
}
</style>
