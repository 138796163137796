<template>
  <div>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <div v-if="showDNIReader"><DNIRead> </DNIRead></div>
        <p class="modal-card-title">Registrar nuevo socio</p>
      </header>

      <section class="modal-card-body">
        <b-field :label="$t('Nombre')" style="color:#fff;">
          <b-input v-model="name" :placeholder="$t('Nombre')"></b-input>
        </b-field>
        <b-field :label="$t('Apellidos')">
          <b-input v-model="surname"></b-input>
        </b-field>
        <b-field :label="$t('DNI')" :type="dni_type">
          <b-input v-model="dni"> </b-input>
        </b-field>
        <b-field label="Email">
          <b-input v-model="email" type="email"> </b-input>
        </b-field>
        <b-field label="Teléfono" :type="tlf_type">
          <b-input v-model="tlf"> </b-input>
        </b-field>
        <b-field label="MJID">
          <b-input v-model="mjid"></b-input>
        </b-field>
        <b-field label="Fecha de nacimiento">
          <b-datepicker
            v-model="birthdate"
            :show-week-number="false"
            :locale="this.$i18n.locale"
            placeholder="Click para seleccionar fecha..."
            trap-focus
            :first-day-of-week="1"
          >
          </b-datepicker>
        </b-field>
        <b-field>
          <b-switch v-model="sendsms">Enviar SMS</b-switch>
        </b-field>
        <footer class="modal-card-foot">
          <b-button @click="dnirReaderToggle()" class="dniButton"
            ><i class="fas fa-id-card fa-2x"></i>
            <h4>Reconocer Documento</h4></b-button
          >

          <b-button @click="sendUser()" class="sendButton"
            ><i class="fas fa-paper-plane fa-2x"></i>
            <h4>Enviar</h4></b-button
          >
        </footer>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DNIRead from '@/components/DNIRead.vue'
import VueMoment from 'vue-moment'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'

export default {
  components: { DNIRead },
  computed: {
    ...mapState('club', ['currentClub']),
    ...mapState(['microblink_data'])
  },
  data() {
    return {
      locale: 'es-ES',
      showDNIReader: false,
      name: '',
      surname: '',
      dni: '',
      email: '',
      tlf: '',
      mjid: '',
      birthdate: new Date(),
      sendsms: false,
      tlf_type: '',
      dni_type: ''
    }
  },
  mixins: [InitViewMixin],
  methods: {
    initializedata() {
      this.name = ''
      this.surname = ''
      this.dni = ''
      this.email = ''
      this.tlf = ''
      this.mjid = ''
      this.birthdate = new Date()
      this.sendsms = false
      this.tlf_type = ''
      this.dni_type = ''
    },
    sendUser() {
      if (this.validateData()) {
        const loadingComponent = this.$buefy.loading.open({
          container: null
        })
        let patient = {
          name: this.name,
          surname: this.surname,
          dni: this.dni,
          mjid: this.mjid,
          email: this.email,
          tlf: this.tlf,
          birthdate: +this.birthdate / 1000
        }
        this.$store
          .dispatch('users/createUser', {
            asocid: this.currentClub.nid,
            patient: patient,
            sendsms: false
          })
          .then(response => {
            loadingComponent.close()
            this.initializedata()
            this.$buefy.notification.open({
              message: 'El socio se a registrado correctamente',
              position: 'is-bottom',
              hasIcon: false,
              duration: 5000
            })
            this.$matomo.trackEvent(
              'User Invite',
              'Click',
              'create_user',
              this.patient.email
            )
          })
          .catch(error => {
            loadingComponent.close()

            this.$buefy.notification.open({
              message: error,
              position: 'is-bottom',
              hasIcon: false,
              duration: 5000,
              type: 'is-danger'
            })
          })
      }
    },
    dnirReaderToggle() {
      window.scrollTo(0, 0)
      this.showDNIReader = !this.showDNIReader
      this.$matomo.trackEvent('User Invite', 'Click', 'dniReader', 1)
    },
    validateData() {
      if (this.dni == '') {
        this.dni_type = 'is-danger'
      }
      if (this.telefono == '') {
        this.tlf_type = 'is-danger'
      }
      if (this.dni == '' || this.telefono == '') {
        this.$buefy.notification.open({
          message: 'Los campos DNI y Teléfono son obligatorios',
          position: 'is-bottom',
          hasIcon: false,
          duration: 5000,
          type: 'is-danger'
        })
        return false
      }
      return true
    }
  },
  created() {
    this.$analytics.logEvent('UserInvite.vue')
    this.$store.dispatch('setShowNavigationBar', true)
  },
  watch: {
    microblink_data() {
      this.name = this.microblink_data.name
      this.surname = this.microblink_data.surname
      this.dni = this.microblink_data.dni
      this.mjid = this.microblink_data.dni
      this.birthdate = this.microblink_data.birthdate
      this.showDNIReader = false
    }
  }
}
</script>

<style lang="scss" scoped>
.dniButton {
  width: 276px;
  height: 120px;
  top: 10px;
  background: #e4c436;
  color: #ffffff;
}
.sendButton {
  width: 276px;
  height: 120px;
  top: 10px;
  background: #247ba0;
  color: #ffffff;
}
</style>
