<template>
  <div>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t('Firma') }}</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <pre v-if="selected == 1"><VueSignaturePad
                    id="signature"
                    width="400px"
                    height="170px"
                    ref="signaturePad"
                    :options="options"
                  /></pre>
        <b-tabs v-model="selected">
          <b-tab-item :label="$t('Tableta')" :value="'0'"> </b-tab-item>

          <b-tab-item :label="$t('Táctil')" :value="'1'"> </b-tab-item>
        </b-tabs>
        <pre v-if="selected == 0">
            <TopazRead @stringSignature="stringSignature" />
        </pre>
      </section>

      <footer class="modal-card-foot">
        <button class="button" type="button" @click="$emit('close')">
          {{ $t('Cerrar') }}
        </button>

        <b-button class="button is-primary" @click="save()">
          {{ $t('Terminar') }}
        </b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import TopazRead from '@/components/TopazRead.vue'
export default {
  data() {
    return {
      options: {
        penColor: '#002'
      },
      selected: '1'
    }
  },
  components: { TopazRead },
  methods: {
    stringSignature(input) {
      this.$emit('recieveSign', input)
      this.$emit('close')
    },
    undo() {
      this.$refs.signaturePad.undoSignature()
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()

      //   alert('Open DevTools see the save data.')
      //   console.log(isEmpty)
      //   console.log(data)

      this.$emit('recieveSign', data)
      this.$emit('close')
    },
    change() {
      this.options = {
        penColor: '#002'
      }
    },
    resume() {
      this.options = {
        penColor: '#002'
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
