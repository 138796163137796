import session from '@/services/Session.js'
// import axios from 'axios'

export default {
  clubs() {
    return session.get('/api/association/retrieve')
  },
  getClub(asocid) {
    return session.get('/api/association/retrieve?association=' + asocid)
  },
  updateClub(asocid, club) {
    console.log(asocid)
    console.log(club)
    return session.put('/api/association/' + asocid, club)
  }
}
