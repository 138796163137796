<template>
  <div>
    <div class="modal-card" style="width: 800px">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t('Completar pedido') }}</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div style="background-color: #fff;" v-if="currentOrder">
          <div>
            <h3>{{ $t('Subtotal') }}: {{ currentOrder.order_base_price }}€</h3>
            <h3>{{ $t('Descuentos') }}: {{ currentOrder.order_discount }}€</h3>
          </div>
          <div style="display:flex; justify-content:space-between ">
            <h1>{{ $t('Total') }}: {{ currentOrder.order_total }}€</h1>
            <h1 class="ml-4" v-if="change_qty">
              {{ $t('Cambio') }}: {{ this.change_qty.toFixed(2) }}€
            </h1>
          </div>
        </div>
      </section>
      <!-- <iframe
        v-show="show_cashdro_iframe"
        height="600"
        style="background: #585858"
        id="framesplash"
        class="frame"
        :src="'https://' + cashdroIP + '/Cashdro3Web/index.html#/splash/true'"
      ></iframe> -->
      <footer class="modal-card-foot" style="display:block">
        <div class="columns" v-if="!is_cash_payment && !is_autopay_payment">
          <!-- <div class="column">
            <b-button @click="autopayPayment" size="is-large">{{
              $t('Autopago')
            }}</b-button>
          </div> -->

          <div class="column" v-if="cashPermission">
            <b-button @click="cashPayment" size="is-large">{{
              $t('Efectivo')
            }}</b-button>
          </div>
          <div class="column">
            <b-button
              v-if="
                (this.currentClub.functionalities.funciones_avanzadas_gestion ||
                  this.currentClub.functionalities.monedero) &&
                  walletPermission
              "
              @click="walletPayment"
              size="is-large"
              >{{ $t('Monedero') }}</b-button
            >
          </div>
          <div class="column">
            <b-button
              @click="tpvPayment"
              size="is-large"
              v-if="cardPermission"
              >{{ $t('Tarjeta') }}</b-button
            >
          </div>
        </div>
        <div v-if="is_cash_payment">
          <b-field :label="$t('Efectivo')" style="width:50%">
            <b-input
              ref="cash_qty"
              v-model="cash_qty"
              :value="cash_qty"
              :placeholder="$t('Cantidad')"
              @focus="cashFocused()"
            >
            </b-input>
          </b-field>
          <div style="width: 100%;">
            <span v-for="category in categories" :key="category.nid">
              <b-button style="width: 33%;" @click="setCategory(category)"
                >{{ category.title }} €</b-button
              >
            </span>
          </div>
          <SimpleKeyboard
            @onChange="onChange"
            @onKeyPress="onKeyPress"
            :input="String(input)"
            :withNext="false"
          />
          <!-- <b-button @click="cashCalculateChange" size="is-large"
            >Calcular cambio</b-button
          > -->
          <b-button
            v-if="showPayButton"
            @click="cashFinishPayment"
            size="is-large"
            style="background-color: #ed6a5a; color:#fff; width:48%; height:60px"
            >{{ $t('Pagar') }}</b-button
          >
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SimpleKeyboard from '@/components/SimpleKeyboard'
import SignatureReader from '@/views/Utils/SignatureReader.vue'
import tools from '@/Tools/tools.js'
import { ToastProgrammatic as Toast } from 'buefy'
import { DialogProgrammatic as Dialog } from 'buefy'

export default {
  data() {
    return {
      showPayButton: true,
      is_cash_payment: false,
      is_autopay_payment: false,
      show_cashdro_iframe: false,
      cash_qty: '',
      input: '',
      change_qty: null,
      categories: [],
      intervalId: null
    }
  },
  computed: {
    ...mapState('cashdro', ['cashdroCurrentOperationID', 'cashdroPosID']),
    ...mapState('patients', ['currentPatient']),
    ...mapState('orders', ['orders', 'currentOrder']),
    ...mapState('club', ['currentClub']),
    ...mapState('club', [
      'cashPermission',
      'cardPermission',
      'walletPermission',
      'cashdroPermission',
      'cashdroIP'
    ])
  },
  components: {
    SimpleKeyboard
  },
  methods: {
    setCategory(category) {
      this.cash_qty = Number(this.cash_qty) + Number(category.title)
    },
    cashFocused() {
      this.input = this.cash_qty
    },
    onChange(input) {},
    onKeyPress(input) {
      switch (input) {
        case '{bksp}':
          //Borrar carcater
          this.cash_qty = String(this.cash_qty).slice(0, -1)

          break
        case 'Siguiente':
          break
        default:
          //Añadir caracter
          this.cash_qty = this.cash_qty + input
      }
    },
    // calculateSubTotal() {
    //   var subtotal = 0
    //   if (this.currentOrder) {
    //     this.currentOrder.products.forEach(product => {
    //       subtotal = subtotal + product.total
    //     })
    //   }
    //   return subtotal
    // },
    autopayPayment() {
      this.is_autopay_payment = true
      this.$store.dispatch('cashdro/setCashdroUserName', 'camarero')
      this.$store.dispatch('cashdro/setCashdroPassword', '0')
      this.$store.dispatch('cashdro/setCashdroPosID', '1')
      console.log('currentOrder')
      console.log(this.currentOrder)
      this.$store
        .dispatch('cashdro/getTransactionExecuting', { ip: this.cashdroIP })
        .then(response => {
          console.log('getTransactionExecuting')
          console.log(response)
          if (response.data.response.operation.OperationId === -1) {
            // Si no hay operación en curso, iniciamos la venta
            console.log('No hay operación en curso, iniciamos la venta')
            this.$store
              .dispatch('cashdro/sellOperation', {
                amount: this.currentOrder.order_total,
                posUser: 'manuel',
                aliasID: this.currentOrder.order_id,
                ip: this.cashdroIP
              })
              .then(response => {
                this.show_cashdro_iframe = true

                console.log('Iniciamos la venta')
                console.log(response)
                console.log(
                  'Current OperationId: ' + this.cashdroCurrentOperationID
                )
                this.$store.dispatch(
                  'orders/setOrderOperationId',
                  this.cashdroCurrentOperationID
                )
                //TODO: Hay que hacer que el post de la order solo modifique el estado de los campos que se envían
                this.$store
                  .dispatch('cashdro/setCashdroCurrentOperationIdIntoOrder', {
                    asocid: this.currentClub.nid,
                    oid: this.currentOrder.order_id,
                    operation_id: this.cashdroCurrentOperationID
                  })
                  .then(() => {})
                this.startAutopayStatus()
              })
          } else {
            this.show_cashdro_iframe = true

            console.log('Ya hay una operación en curso')
            // if (response.data.response.operation.PosId === this.cashdroPosID) {

            //   // Si la operación en curso es nuestra, lanzamos el intervalo
            //   console.log('La operación en curso es nuestra')

            //   this.$store.dispatch('cashdro/setcashdroCurrentOperationID', response.data.response.operation.OperationId)
            //   this.startAutopayStatus()
            // }else{
            //   //TODO: Esto hay que quitarlo y dejar solo la comprobación del aliasid con la order
            //   // Si la operación en curso no es nuestra, mostramos mensaje
            //   console.log('La operación en curso no es nuestra')
            //   Dialog.alert({
            //     title: 'Hay una operación en curso',
            //     message:
            //       'Debe esperar a que finalice la operación en curso para poder realizar una nueva',
            //     confirmText: 'OK',
            //     type: 'is-danger',
            //     hasIcon: false,
            //     onConfirm: () => {}
            //   })
            // }
            this.$store.dispatch(
              'cashdro/setcashdroCurrentOperationID',
              response.data.response.operation.OperationId
            )
            this.startAutopayStatus()
          }
        })
    },
    startAutopayStatus() {
      if (this.intervalId === null) {
        this.intervalId = setInterval(this.autopayStatus, 3000)
      }
    },
    stopAutopayStatus() {
      // Limpiamos el intervalo
      clearInterval(this.intervalId)
      this.intervalId = null
    },
    autopayStatus() {
      this.$store
        .dispatch('cashdro/gettransactionStatus', { ip: this.cashdroIP })
        .then(response => {
          console.log('gettransactionStatus')
          console.log(response)

          if (
            response.data.response.operation.operation.aliasid !==
            this.currentOrder.order_id
          ) {
            // Si la operación no corresponde con la orden actual, mostramos mensaje
            console.log('Order id: ' + this.currentOrder.order_id)
            console.log(
              'Alias id: ' + response.data.response.operation.operation.aliasid
            )
            Dialog.confirm({
              title: 'Hay una operación en curso',
              message:
                'La operación no corresponde con la orden actual. Puede cerrar la orden correspondiente o cancelarla. TODO: Hay que buscar la order por el aliasid y abrir el pedido.',
              confirmText: 'Ir al pedido',
              cancelText: 'Cancelar el cobro',
              type: 'is-danger',
              hasIcon: false,
              // canCancel: false,
              onConfirm: () => {
                console.log('Pagar la orden')
                //TODO; Hay que buscar la order por el aliasid para cerrar el pago
              },
              onCancel: () => {
                console.log('Cancelar el cobro')
                this.$store
                  .dispatch('cashdro/finishTransaction', {
                    type: 2,
                    operationId:
                      response.data.response.operation.operation.operationid
                  })
                  .then(response => {
                    console.log('Cancelamos la operación')
                    console.log(response)
                    this.$store.dispatch('cashdro/importTransaction', {
                      ip: this.cashdroIP
                    })
                    this.show_cashdro_iframe = false
                    this.$emit('close')
                  })
              }
            })
            this.stopAutopayStatus()
          }

          // Cuando el status marque operación finalizada, lanzamos stopAutopayStatus y pagamos en el drupal
          else if (response.data.code !== 1) {
            // Hay que mostrar mensaje de error
            Dialog.alert({
              title: 'Error en la operación',
              message:
                'gettransactionStatus: ' + response.data.response.errorMessage,
              confirmText: 'OK',
              type: 'is-danger',
              hasIcon: false,
              onConfirm: () => {}
            })
            this.stopAutopayStatus()
          } else if (response.data.response.operation.operation.state === 'F') {
            this.stopAutopayStatus()
            this.show_cashdro_iframe = false
            console.log('ESAQUI ip:', this.cashdroIP)

            this.$store
              .dispatch('cashdro/importTransaction', {
                ip: this.cashdroIP
              })
              .then(response => {
                console.log('importedTransaction')
                console.log(response)
              })
            if (
              response.data.response.operation.operation.finalStatus === '1'
            ) {
              // Si la operación ha sido correcta, pagamos en el drupal
              this.pay('cash') //TODO: Hay que cambiar el tipo de pago a autopay
            } else {
              // Si la operación se ha cancelado, mostramos mensaje
              Dialog.alert({
                title: 'Operación cancelada',
                message:
                  'La operación ha sido cancelada. No se ha realizado el pago',
                confirmText: 'OK',
                type: 'is-info',
                hasIcon: false,
                onConfirm: () => {}
              })
              this.is_autopay_payment = false
            }
          }
        })
    },

    walletPayment() {
      this.pay('wallet')
    },
    tpvPayment() {
      this.pay('tpv')
    },
    cashPayment() {
      // console.log('cash payment selected')
      this.is_cash_payment = true
      this.$store
        .dispatch('club/fetchCategories', {
          asocid: this.currentClub.nid,
          type: 'cash-register'
        })
        .then(response => {
          this.categories = response.data
        })
      // this.pay('cash')
    },
    cashCalculateChange() {
      this.change_qty = this.cash_qty - this.currentOrder.order_total
      if (this.cash_qty < this.currentOrder.order_total) {
        // this.$buefy.snackbar.open({
        //   duration: 10000,
        //   message:
        //     'La cantidad es insuficiente. Faltan ' +
        //     (this.cash_qty - this.currentOrder.order_total) +
        //     '€.',
        //   type: 'is-danger',
        //   position: 'is-top',
        //   actionText: 'Ok',
        //   queue: false
        // })
        return
      }
    },
    cashFinishPayment() {
      this.pay('cash')
    },
    pay(type) {
      if (
        this.currentClub.functionalities.firma_pedido ||
        this.currentClub.functionalities.firma_digital
      ) {
        this.$buefy.modal.open({
          parent: this,
          component: SignatureReader,
          hasModalCard: true,
          customClass: 'custom-class custom-class-2',
          trapFocus: true,
          events: {
            recieveSign: value => {
              // console.log(value)
              if (value == null) {
                Dialog.alert({
                  title: 'No has completado la firma',
                  message:
                    'El socio debe firmar la retirada para poder completar el pedido',
                  confirmText: 'OK',
                  type: 'is-danger',
                  hasIcon: false,
                  onConfirm: () => {}
                })
                return
              }
              const loadingComponent = this.$buefy.loading.open({
                container: null
              })
              this.$store
                .dispatch('orders/payOrder', {
                  asocid: this.currentClub.nid,
                  type: type,
                  oid: this.currentOrder.order_id,
                  amount: this.currentOrder.order_total,
                  membership_id: this.currentPatient.nid,
                  signature: value.split(',')[1]
                })
                .then(() => {
                  // this.$store.commit('orders/REMOVE_ORDER', this.currentOrder)
                  this.loadDispensary()
                  Toast.open({
                    duration: 4000,
                    message: this.$t('El pago se ha realizado correctamente'),
                    position: 'is-top',
                    type: 'is-success'
                  })
                  this.$store.commit('patients/CLEAR_CURRENT_PATIENT', null, {
                    root: true
                  })
                  loadingComponent.close()

                  this.$emit('close')
                })
                .catch(error => {
                  console.log(error)
                  tools.manageAxiosError(error)
                  loadingComponent.close()
                  // this.$emit('close')
                })
            }
          }
        })
      } else {
        const loadingComponent = this.$buefy.loading.open({
          container: null
        })
        this.$store
          .dispatch('orders/payOrder', {
            asocid: this.currentClub.nid,
            type: type,
            oid: this.currentOrder.order_id,
            amount: this.currentOrder.order_total,
            membership_id: this.currentPatient.nid
          })
          .then(() => {
            // this.$store.commit('orders/REMOVE_ORDER', this.currentOrder)
            this.loadDispensary()
            Toast.open({
              duration: 4000,
              message: this.$t('El pago se ha realizado correctamente'),
              position: 'is-top',
              type: 'is-success'
            })
            this.$store.commit('patients/CLEAR_CURRENT_PATIENT', null, {
              root: true
            })
            loadingComponent.close()

            this.$emit('close')
          })
          .catch(error => {
            console.log(error)
            tools.manageAxiosError(error)
            loadingComponent.close()
            // this.$emit('close')
          })
      }
    },
    loadDispensary() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.$store.dispatch('dispensaryPro/fetchCategories', {
        asocid: this.currentClub.nid,
        type: 'dispensary'
      })
      this.$store
        .dispatch('dispensaryPro/fetchDispensary', {
          asocid: this.currentClub.nid,
          status: true
        })
        .then(() => {
          // this.loadItems()
          loadingComponent.close()
        })
    }
  },
  created() {
    console.log('cashdrIP:' + this.cashdroIP)
  },
  beforeDestroy() {
    // Asegurarse de limpiar el intervalo cuando se destruya el componente
    clearInterval(this.intervalId)
  },
  watch: {
    cash_qty() {
      this.cashCalculateChange()
    },
    change_qty() {
      // console.log(this.change_qty)
      // console.log(this.change_qty >= 0)
      // this.showPayButton = this.change_qty >= 0
    }
  }
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 30px;
  font-weight: bold;
}
</style>
