<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ $t('Gestionar tipos de cuotas') }}</p>
      <button type="button" class="delete ml-4" @click="$emit('close')" />
    </header>
    <section class="modal-card-body">
      <b-table :data="feeCategories">
        <b-table-column
          field="code"
          :label="$t('Código')"
          sortable
          v-slot="props"
        >
          {{ props.row.code }}
        </b-table-column>
        <b-table-column
          field="title"
          :label="$t('Cuota')"
          sortable
          v-slot="props"
        >
          {{ props.row.title }}
        </b-table-column>
        <b-table-column
          field="price"
          :label="$t('Precio')"
          sortable
          v-slot="props"
        >
          {{ props.row.price }} €
        </b-table-column>
        <b-table-column
          field="days"
          :label="$t('Duración')"
          sortable
          v-slot="props"
        >
          {{ props.row.days }} {{ $t('días') }}
        </b-table-column>
        <b-table-column v-slot="props">
          <b-button
            class="is-small"
            icon-left="pen"
            @click="editCategory(props.row)"
          ></b-button>
          <b-button
            class="is-small"
            icon-left="trash-alt"
            @click="deleteCategory(props.row)"
          ></b-button>
        </b-table-column>
      </b-table>
    </section>
    <footer class="modal-card-foot">
      <!-- <b-button :label="$t('Cerrar')" @click="$emit('close')" /> -->
      <b-field :label="$t('Crear nueva categoría')">
        <b-input v-model="categoryCode" :placeholder="$t('Código')"> </b-input>
        <b-input v-model="categoryName" :placeholder="$t('Nombre')"> </b-input>
        <b-input
          v-model="categoryPrice"
          :placeholder="$t('Precio')"
          type="number"
          min="0"
        >
        </b-input>
        <b-input
          v-model="categoryDays"
          :placeholder="$t('Duración (días)')"
          type="number"
          min="0"
        >
        </b-input>
        <b-button
          v-if="is_editing"
          label="Modificar"
          type="is-primary"
          @click="updateData()"
        />
        <b-button
          v-else
          :label="$t('Crear')"
          type="is-primary"
          @click="sendData()"
        />
      </b-field>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      is_editing: false,
      categoryCode: null,
      categoryName: null,
      categoryPrice: null,
      categoryDays: null,
      categoryPid: null,
      categoryToModify: null
    }
  },
  computed: {
    ...mapState('club', ['currentClub']),
    ...mapState('club', ['feeCategories'])
  },
  methods: {
    deleteCategory(category) {
      console.log(category)
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.$store
        .dispatch('club/deleteFeeCategory', {
          asocid: this.currentClub.nid,
          feeCategory: category
        })
        .then(() => {
          loadingComponent.close()
          //   this.$emit('close')
        })
    },
    editCategory(category) {
      this.categoryToModify = category
      this.categoryName = category.title
      this.categoryPid = category.pid
      this.categoryCode = category.code
      this.categoryPrice = category.price
      this.categoryDays = category.days
      this.is_editing = true
    },
    updateData() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      let json = {
        pid: this.categoryPid,
        asocid: this.currentClub.nid,
        code: this.categoryCode,
        title: this.categoryName,
        price: this.categoryPrice,
        days: this.categoryDays,
        status: true
      }
      this.$store
        .dispatch('club/updateFeeCategory', {
          feeCategory: this.categoryToModify,
          json: json
        })
        .then(() => {
          loadingComponent.close()
          this.clearFields()
        })
    },
    sendData() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      let json = {
        asocid: this.currentClub.nid,
        code: this.categoryCode,
        title: this.categoryName,
        price: this.categoryPrice,
        days: this.categoryDays,
        status: true
      }
      this.$store.dispatch('club/createFeeCategory', json).then(() => {
        loadingComponent.close()
        this.clearFields()
      })
    },
    clearFields() {
      this.categoryCode = null
      this.categoryName = null
      this.categoryPrice = null
      this.categoryDays = null
      this.categoryPid = null
      this.is_editing = false
    }
  },
  created() {
    this.$store.dispatch('club/fetchFeeCategories', {
      asocid: this.currentClub.nid
    })
  }
}
</script>

<style lang="scss" scoped></style>
