<template>
  <div>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Movimiento de inventario</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <b-field label="Movimiento">
          <!-- <b-input v-model="action" placeholder="Acción"> </b-input> -->
          <b-select
            :placeholder="$t('Acción')"
            icon="directions"
            v-model="action"
            value="add"
          >
            <option value="add">Añadir</option>
            <option value="remove">Quitar</option>
            <option value="remove">Mover</option>
          </b-select>

          <b-input v-model="cost" :placeholder="$t('Coste')"> </b-input>
          <b-input v-model="amount" :placeholder="$t('Cantidad')"> </b-input>

          <b-select
            placeholder="Localización"
            icon="globe-americas"
            v-model="location"
          >
            <option value="almacen_interno">Almacén interno</option>
            <option value="almacen_externo">Almacén externo</option>
            <option value="dispensario">Dispensario</option>
            <option value="barra">Barra</option>
          </b-select>
        </b-field>
        <b-field :label="$t('Nota')">
          <b-input
            v-model="note"
            :placeholder="$t('Nota')"
            maxlength="200"
            type="textarea"
          >
          </b-input>
        </b-field>
        <b-field label="Añade unas imágenes del producto">
          <b-upload v-model="dropFiles" multiple drag-drop>
            <section class="section">
              <div class="content has-text-centered">
                <p>
                  <b-icon icon="upload" size="is-large"> </b-icon>
                </p>
                <p>Arrastra aquí las imágenes o pulsa para selccionar</p>
              </div>
            </section>
          </b-upload>
          <div class="tags">
            <span
              v-for="(file, index) in dropFiles"
              :key="index"
              class="tag is-primary"
            >
              {{ file.name }}
              <button
                class="delete is-small"
                type="button"
                @click="deleteDropFile(index)"
              ></button>
            </span>
          </div>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-button :label="$t('Cerrar')" @click="$emit('close')" />
        <b-button :label="$t('Enviar')" type="is-primary" @click="sendData()" />
      </footer>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    iid: String
  },
  data() {
    return {
      dropFiles: [],
      images: [],
      action: 'add',
      cost: '',
      amount: '',
      location: 'almacen_interno',
      note: ''
    }
  },
  computed: {
    ...mapState('club', ['currentClub'])
  },
  methods: {
    sendData() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.$store
        .dispatch('inventory/doMovement', {
          asocid: this.currentClub.nid,
          iid: this.iid,
          action: this.action,
          images: this.images,
          cost: this.cost,
          amount: this.amount,
          location: this.location,
          note: this.note
        })
        .then(() => {
          this.$store.dispatch('inventory/clearInventory')
          this.$store
            .dispatch('inventory/fetchInventory', this.currentClub.nid)
            .then(() => {
              loadingComponent.close()
              this.$emit('close')
            })
        })
    }
  },
  watch: {
    dropFiles() {
      this.dropFiles.forEach(file => {
        const comp = this
        function readerOnload(e) {
          var base64 = btoa(e.target.result)
          comp.images.push(base64)
        }
        var reader = new FileReader()
        reader.onload = readerOnload
        reader.readAsBinaryString(file)
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
