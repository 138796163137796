<template>
  <form @submit.prevent="addToCart">
    <div class="modal-card" style="width: 800px; height:900px">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t('Añadir al pedido') }}</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body" style="display:flex">
        <div style="width:550px">
          <div v-if="item.imagenes.length > 0">
            <img
              class="imgRigth"
              style="border-radius:50%;  max-height:20vh"
              :src="item.imagenes[0].img"
            />
          </div>
          <h3>{{ item.titulo }}</h3>
          <h5>{{ $t('Precio') }}: {{ item.precio }}</h5>
          <h5 v-if="item.discount == true">
            {{ $t('Precio con descuento') }}:
            {{ calculateDiscountedPrice(item).toFixed(2) }}
          </h5>
          <h5 v-if="item.type == 'ambos'">
            {{ $t('Peso') }}: {{ item.unit_weight }}
          </h5>
          <h6>{{ $t('SKU') }}: {{ item.code }}</h6>
          <!-- <p>{{ $t('Coste') }}: {{ item.coste }}</p> -->
          <p v-if="item.unlimited_stock == false">
            {{ $t('Stock') }}: {{ item.stock }}
          </p>
          <p v-else>{{ $t('Stock ilimitado') }}</p>
          <p>{{ $t('THC') }}: {{ item.thc ? $t('Sí') : $t('No') }}</p>
          <p v-html="item.cuerpo" class="mt-3"></p>
          <!-- <p>{{ item.valoration }} <i class="far fa-star"></i></p> -->

          <!-- <div
          v-for="(price_options, index) in item.price_options"
          :value="index"
          :key="price_options.id"
        >
          <section v-if="price_options.in_stock == 'true'">
            <div class="field">
              <b-radio v-model="radio" :native-value="index">
                {{ price_options.description }} {{ price_options.price }} € ---
                Member price: {{ price_options.member_price }}
              </b-radio>
            </div>
          </section>
        </div> -->
        </div>
        <div class="ml-5" style="width:100%; ">
          <b-field :label="$t('Importe')" style="width:50%">
            <b-input
              ref="price"
              v-model="price"
              :value="price"
              :placeholder="$t('Precio')"
              @focus="priceFocused()"
              @change.native="priceChanged()"
              :disabled="item.type != 'peso'"
            >
            </b-input>
          </b-field>
          <b-field :label="$t('Cantidad')">
            <b-input
              ref="qty"
              v-model="qty"
              :value="qty"
              :placeholder="$t('Cantidad')"
              @focus="qtyFocused()"
              @change.native="qtyChanged()"
            >
            </b-input>
            <!-- <b-input
              v-if="item.type == 'peso'"
              ref="realqty"
              v-model="realqty"
              :value="realqty"
              placeholder="Cantidad real"
              @focus="realqtyFocused()"
              type="number"
              step=".01"
            >
            </b-input> -->
          </b-field>

          <SimpleKeyboard
            @onChange="onChange"
            @onKeyPress="onKeyPress"
            :input="String(input)"
            :withNext="true"
          />
        </div>
      </section>

      <footer class="modal-card-foot" style="display:block">
        <span>
          <button class="button is-primary" type="submit" value="Submit">
            {{ $t('Añadir') }}
          </button>
        </span>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex'
import SimpleKeyboard from '@/components/SimpleKeyboard'

export default {
  data() {
    return {
      qty: '',
      price: '',
      radio: null,
      input: '',
      qtyfocused: false,
      pricefocused: true
    }
  },
  props: {
    item: Object,
    currentPatient: Object,
    currentClub: Object,
    currentOrder: Object
  },
  components: {
    SimpleKeyboard
  },
  methods: {
    hasFullDiscount() {
      return (
        this.item.discount &&
        this.currentPatient.category_member &&
        Number(
          this.currentPatient.category_member.field_member_category_descuento
        ) == 100
      )
    },
    calculateDiscountedPrice(producto) {
      if (
        producto.discount &&
        this.currentPatient.category_member &&
        Number(
          this.currentPatient.category_member.field_member_category_descuento
        ) != 0
      ) {
        return (
          producto.precio -
          producto.precio *
            (Number(
              this.currentPatient.category_member
                .field_member_category_descuento
            ) /
              100)
        )
      } else {
        return producto.precio
      }
    },
    calculateStep() {
      if (this.item.type == 'peso') {
        return '.00001'
      } else {
        return '1'
      }
    },

    qtyFocused() {
      this.qtyfocused = true
      this.pricefocused = false
      this.input = this.qty
    },
    priceFocused() {
      this.qtyfocused = false
      this.pricefocused = true
      this.input = this.price
    },
    onChange(input) {},
    onKeyPress(input) {
      switch (input) {
        case '{bksp}':
          //Borrar carcater
          if (this.qtyfocused == true) {
            this.qty = String(this.qty).slice(0, -1)
          }
          if (this.pricefocused == true) {
            this.price = String(this.price).slice(0, -1)
          }

          break
        case 'Siguiente':
          // if (this.priceFocused) {
          //   this.$refs.realqty.focus()
          // }
          // else {
          //   if (this.qtyFocused) {
          //     this.$refs.realqty.focus()
          //   }
          // }
          break
        default:
          //Añadir caracter
          if (this.qtyfocused == true) {
            this.qty = this.qty + input
            this.qtyChanged()
          }
          if (this.pricefocused == true) {
            this.price = String(this.price) + input
            this.priceChanged()
          }
      }
    },
    prepareDiscountCuponOrderArray() {
      // if (this.currentOrder.discount_coupon_order == null) {
      //   this.currentOrder.discount_coupon_order = []
      // }

      var tmp = []
      for (
        let index = 0;
        index < this.currentOrder.discount_coupon_order.length;
        index++
      ) {
        tmp.push(Number(this.currentOrder.discount_coupon_order[index].id))
      }
      return tmp
    },
    addToCart() {
      if (this.validateData()) {
        let order = {
          membership_id: this.currentPatient.nid,
          asocid: this.currentClub.nid
        }
        if (this.currentOrder) {
          order.discount_coupon_order = this.prepareDiscountCuponOrderArray()
          order.manual_discount_order = this.currentOrder.manual_discount_order
        }
        var products = []
        if (this.currentOrder) {
          var productUpdated = false
          this.currentOrder.products.forEach(product => {
            //TODO: Añadir un setting configurable por el usuario para que pueda seleccionar entre que se sume el producto o se cree una nueva fila
            // if (this.item.pid == product.product.pid) {
            //   console.log('HA ENTRADO')
            //   productUpdated = true
            //   products.push({
            //     pid: this.item.pid,
            //     total: Number(product.total_base_price) + Number(this.price),
            //     quantity: Number(product.quantity) + Number(this.qty),
            //     real_quantity: product.real_quantity
            //   })
            // } else {
            //   products.push({
            //     pid: product.product.pid,
            //     total: product.total_base_price,
            //     quantity: product.quantity,
            //     real_quantity: product.real_quantity
            //   })
            // }
            if (this.hasFullDiscount()) {
              products.push({
                pid: product.product.pid,
                total: 0,
                quantity: product.quantity,
                real_quantity: product.real_quantity
              })
            } else {
              products.push({
                pid: product.product.pid,
                total: product.total_base_price,
                quantity: product.quantity,
                real_quantity: product.real_quantity
              })
            }
          })
          if (productUpdated == false) {
            if (this.hasFullDiscount()) {
              products.push({
                pid: this.item.pid,
                total: 0,
                quantity: this.qty
              })
            } else {
              products.push({
                pid: this.item.pid,
                total: this.price,
                quantity: this.qty
              })
            }
          }
        } else {
          if (this.hasFullDiscount()) {
            products.push({
              pid: this.item.pid,
              total: 0,
              quantity: this.qty
            })
          } else {
            products.push({
              pid: this.item.pid,
              total: this.price,
              quantity: this.qty
            })
          }
        }

        order.products = products

        if (this.currentOrder) {
          //Actualizamos order si ya existe una creada
          order.oid = this.currentOrder.order_id
          this.$store.dispatch('orders/postOrder', order).then(() => {
            this.$root.$emit('setSeatchFocused', 'hola')
            this.$emit('close')
            // loadingComponent.close()
          })
        } else {
          const loadingComponent = this.$buefy.loading.open({
            container: null
          })
          this.$store.dispatch('orders/postOrder', order).then(() => {
            loadingComponent.close()
            this.$root.$emit('setSeatchFocused', 'hola')
            this.$emit('close')
          })
        }

        // this.$emit('close')
      }
    },
    qtyChanged() {
      if (this.hasFullDiscount()) {
        this.price = (0.0).toFixed(3)
        return
      }
      // this.qty = this.qty.replace(/\s/g, '').replace('/\s/g', '')
      this.price = (
        this.calculateDiscountedPrice(this.item) * this.qty
      ).toFixed(2)
      if (this.price == 0) {
        this.price = ''
      }
    },
    priceChanged() {
      if (this.price == 0) {
        this.qty = ''
        return
      }
      if (this.hasFullDiscount()) {
        this.qty = (Number(this.price) / Number(this.item.precio)).toFixed(3)

        return
      }
      this.qty = (
        this.price / this.calculateDiscountedPrice(this.item)
      ).toFixed(3)
      if (this.qty == 0) {
        this.qty = ''
      }
    },
    validateData() {
      if (!this.isNumeric(this.qty) || !this.isNumeric(this.price)) {
        this.$buefy.snackbar.open({
          duration: 5000,
          message: 'Introduce valores numéricos',
          type: 'is-primary',
          position: 'is-top',
          actionText: 'Ok',
          queue: false
        })
        return false
      }
      if (this.item.type == 'peso') {
        if (this.qty != '' && this.price != '') {
          if (
            Number(this.qty) > Number(this.item.stock) &&
            this.item.unlimited_stock == false
          ) {
            this.$buefy.snackbar.open({
              duration: 5000,
              message: this.$t('No hay stock suficiente'),
              type: 'is-primary',
              position: 'is-top',
              actionText: 'Ok',
              queue: false
            })
            return false
          }
          return true
        } else {
          this.$buefy.snackbar.open({
            duration: 5000,
            message: this.$t('Debes completar todos los campos'),
            type: 'is-primary',
            position: 'is-top',
            actionText: 'Ok',
            queue: false
          })
          return false
        }
      } else if (this.qty != '') {
        if (
          Number(this.qty) > Number(this.item.stock) &&
          this.item.unlimited_stock == false
        ) {
          this.$buefy.snackbar.open({
            duration: 5000,
            message: this.$t('No hay stock suficiente'),
            type: 'is-primary',
            position: 'is-top',
            actionText: 'Ok',
            queue: false
          })
          return false
        }
        return true
      } else {
        this.$buefy.snackbar.open({
          duration: 5000,
          message: this.$t('Debes completar todos los campos'),
          type: 'is-primary',
          position: 'is-top',
          actionText: 'Ok',
          queue: false
        })
        return false
      }
    },
    isNumeric(str) {
      if (typeof str != 'string') return false // we only process strings!
      return (
        !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      ) // ...and ensure strings of whitespace fail
    }
  },
  created() {
    console.log(this.currentOrder)
  },
  watch: {
    qty() {
      this.qty = this.qty
        .replace(/\s/g, '')
        .replace('g', '')
        .replace('G', '')
        .replace('+', '')
        .replace('net', '')
      console.log(this.qty)
      if (typeof this.qty === 'string' || this.qty instanceof String) {
        this.qty = this.qty.replace(',', '.')
        if (this.qtyfocused == true) {
          if (this.qty != '' && !this.isNumeric(this.qty)) {
            this.$buefy.snackbar.open({
              duration: 2000,
              message: 'Introduce valores numéricos',
              type: 'is-primary',
              position: 'is-top',
              actionText: 'Ok',
              queue: false
            })
            return
          }
          this.qtyChanged()
        }
      }
    },
    price() {
      if (typeof this.price === 'string' || this.price instanceof String) {
        this.price = this.price.replace(',', '.')
        if (this.pricefocused == true) {
          if (this.price != '' && !this.isNumeric(this.price)) {
            this.$buefy.snackbar.open({
              duration: 2000,
              message: 'Introduce valores numéricos',
              type: 'is-primary',
              position: 'is-top',
              actionText: 'Ok',
              queue: false
            })
            return
          }
          this.priceChanged()
        }
      }
    }
  },
  mounted() {
    if (this.item.type == 'peso') {
      this.$refs.price.focus()
    } else {
      this.$refs.qty.focus()
    }
  }
}
</script>

<style lang="scss" scoped></style>
