<template>
  <div>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t('Control de acceso') }}</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <section>
          <b-field>
            <b-input
              v-model="numberInput"
              :placeholder="$t('NºSoc')"
              @keyup.native.enter="searchEvent()"
            ></b-input>
            <b-input
              v-model="nameInput"
              :placeholder="$t('Nombre')"
              @keyup.native.enter="searchEvent()"
            ></b-input>
            <b-input
              v-model="surnameInput"
              :placeholder="$t('Apellidos')"
              @keyup.native.enter="searchEvent()"
            ></b-input>
            <b-input
              v-model="dniInput"
              :placeholder="$t('DNI')"
              @keyup.native.enter="searchEvent()"
            ></b-input>
            <b-input
              ref="keychain"
              v-model="keychainInput"
              :placeholder="$t('Llavero')"
              @keyup.native.enter="searchEvent()"
            ></b-input>
            <div v-if="checkNFC()">
              <b-button @click="readNFC()">
                <b-icon icon="wifi"> </b-icon>
              </b-button>
            </div>
            <b-input
              v-model="emailInput"
              :placeholder="$t('Email')"
              @keyup.native.enter="searchEvent()"
            ></b-input>
            <b-button @click="searchEvent()">
              {{ $t('Buscar') }}
            </b-button>
          </b-field>

          <b-table
            :data="filteredUsers"
            :checkable="true"
            :checked-rows.sync="checkedRows"
            :paginated="true"
            :per-page="perPage"
            @page-change="onPageChange"
            :total="totalElements"
            backend-pagination
            @click="showPatient($event)"
            hoverable
          >
            <b-table-column field="foto" label="Foto" v-slot="props">
              <img
                v-if="props.row.foto"
                style="border-radius:50%; height: 40px; width: 40px; object-fit:cover;"
                :src="props.row.foto.url"
              />
            </b-table-column>

            <b-table-column
              field="number"
              :label="$t('NºSoc')"
              sortable
              v-slot="props"
              width="40"
            >
              {{ props.row.number }}
            </b-table-column>

            <b-table-column
              field="nombre"
              :label="$t('Nombre')"
              sortable
              v-slot="props"
            >
              <span class="ml-3"> {{ props.row.nombre }}</span>
            </b-table-column>

            <b-table-column
              field="surname"
              :label="$t('Apellidos')"
              sortable
              v-slot="props"
            >
              {{ props.row.surname }}
            </b-table-column>

            <b-table-column
              field="dni"
              :label="$t('DNI')"
              sortable
              v-slot="props"
            >
              {{ props.row.dni ? props.row.dni.toUpperCase() : '' }}
            </b-table-column>

            <b-table-column
              field="email"
              :label="$t('Email')"
              sortable
              v-slot="props"
              width="80"
            >
              {{ props.row.email }}
            </b-table-column>

            <b-table-column
              field="telefono"
              :label="$t('Tel')"
              sortable
              v-slot="props"
            >
              {{ props.row.telefono }}
            </b-table-column>

            <b-table-column
              field="end_date"
              :label="$t('Expiración')"
              sortable
              centered
              v-slot="props"
            >
              <span
                v-if="
                  props.row.end_date &&
                    props.row.end_date < Math.floor(Date.now() / 1000)
                "
              >
                <span v-if="props.row.end_date" class="tag is-danger">
                  {{ format_date(props.row.end_date, 'dd/MM/yyyy') }}
                </span>
              </span>
              <span v-else>
                <div v-if="props.row.end_date" class="tag is-success">
                  {{ format_date(props.row.end_date, 'dd/MM/yyyy') }}
                </div>
              </span>
            </b-table-column>

            <b-table-column
              field="type"
              :label="$t('Tipo')"
              sortable
              v-slot="props"
            >
              {{ props.row.type }}
            </b-table-column>
          </b-table>
        </section>
      </section>

      <footer class="modal-card-foot">
        <b-button class="button is-success" type="button" @click="checkin()">
          {{ $t('ENTRA') }}
        </b-button>
        <b-button class="button is-danger" @click="checkout()">{{
          $t('SALE')
        }}</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import QRReader from '@/components/QRReader.vue'
import TrustedComms from 'twilio/lib/rest/preview/TrustedComms'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'
import APIPatients from '@/services/APIPatients.js'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import PatientDetail from '@/views/Users/PatientDetail.vue'

export default {
  mixins: [InitViewMixin, ToolsMixin],
  data() {
    return {
      nameInput: '',
      surnameInput: '',
      keychainInput: '',
      emailInput: '',
      numberInput: '',

      dniInput: '',
      totalElements: 0,
      perPage: 5,
      page: 1,

      filteredUsers: [],
      tabIndex: 1,
      incheck: true,
      outcheck: false,
      keychain: '',
      checkedRows: []
    }
  },
  // components: {
  //   QRReader
  // },
  computed: {
    ...mapState('club', ['currentClub']),
    ...mapState('patients', ['patients', 'currentPatient']),
    showCheckboxes() {
      if (this.tabIndex == 1 || this.tabIndex == 2) {
        return true
      }
      return false
    }
  },
  methods: {
    showPatient(patient) {
      this.$buefy.modal.open({
        parent: this,
        component: PatientDetail,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
        props: {
          currentPatient: patient,
          currentClub: this.currentClub
        },
        onCancel: function() {},
        events: {
          reloadPatients: value => {
            this.fetchPatients()
          }
        }
      })
    },
    checkNFC() {
      if ('NDEFReader' in window) {
        return true
      } else {
        return false
      }
    },
    readNFC() {
      this.$buefy.toast.open({
        duration: 4000,
        message: `Acerca la tarjeta o allavero`,
        position: 'is-top',
        type: 'is-success'
      })
      const ndef = new NDEFReader()
      return new Promise((resolve, reject) => {
        const ctlr = new AbortController()
        ctlr.signal.onabort = reject
        ndef.addEventListener(
          'reading',
          event => {
            this.keychainInput = event.serialNumber
            this.searchEvent()
            ctlr.abort()
            // resolve(event)
          },
          { once: true }
        )
        ndef.scan({ signal: ctlr.signal }).catch(err => reject(err))
      })
    },
    searchEvent() {
      this.page = 1
      this.fetchPatients()
    },
    fetchPatients() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      let parameters =
        '?association=' +
        this.currentClub.nid +
        '&limit=' +
        this.perPage +
        '&page=' +
        this.page +
        '&'
      if (this.numberInput) {
        parameters = parameters + 'number=' + this.numberInput + '&'
      }
      if (this.nameInput) {
        parameters = parameters + 'nombre=' + this.nameInput + '&'
      }
      if (this.surnameInput) {
        parameters = parameters + 'surname=' + this.surnameInput + '&'
      }
      if (this.dniInput) {
        parameters = parameters + 'dni=' + this.dniInput + '&'
      }
      if (this.keychainInput) {
        parameters = parameters + 'keychain=' + this.keychainInput + '&'
      }
      if (this.emailInput) {
        parameters = parameters + 'email=' + this.emailInput + '&'
      }

      APIPatients.fetchPatientsWith(parameters)
        .then(response => {
          if (response.data.data.length == 0) {
            this.$buefy.toast.open({
              duration: 3000,
              message: this.$t('Socio no encontrado'),
              position: 'is-top',
              type: 'is-danger'
            })
          } else {
            this.totalElements = response.data.total_fichas
            this.filteredUsers = response.data.data
          }
          loadingComponent.close()
        })
        .catch(error => {
          tools.manageAxiosError(error)
          console.log('Ha habido un error:' + error)
          loadingComponent.close()
        })
    },
    onPageChange(page) {
      this.page = page
      this.fetchPatients()
    },
    check(st) {
      if (st == 'in') {
        this.outcheck = false
      } else {
        this.incheck = false
      }
    },
    checkin() {
      if (this.checkedRows.length == 0) {
        this.$buefy.toast.open({
          duration: 3000,
          message: `Selecciona un socio al menos`,
          position: 'is-top',
          type: 'is-danger'
        })
        return
      }
      this.checkedRows.forEach(patient => {
        this.$store.dispatch('accesscontrol/addNewAccess', {
          patient_id: patient.nid,
          association: this.currentClub.nid,
          datetime: Date.parse(new Date()) / 1000,
          action: 'check-in'
        })
      })
      this.$emit('close')
    },
    checkout() {
      if (this.checkedRows.length == 0) {
        this.$buefy.toast.open({
          duration: 3000,
          message: `Selecciona un socio al menos`,
          position: 'is-top',
          type: 'is-danger'
        })
        return
      }
      this.checkedRows.forEach(patient => {
        this.$store.dispatch('accesscontrol/addNewAccess', {
          patient_id: patient.nid,
          association: this.currentClub.nid,
          datetime: Date.parse(new Date()) / 1000,
          action: 'check-out'
        })
      })
      this.$emit('close')
    }
  },
  created() {
    this.validateAccess(['socios'])
    this.validateRoles(['Administrador', 'Manager', 'Recepción'])
    this.fetchPatients()
  },
  mounted() {
    this.$refs.keychain.focus()
  }
}
</script>

<style lang="scss" scoped></style>
