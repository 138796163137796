<template>
  <div>
    <div id="screen-initial" v-if="showLoadingComponents">
      <h6 id="msg">Cargando componentes...</h6>
      <progress id="load-progress" value="0" max="100"></progress>
    </div>

    <div id="screen-start" class="hidden">
      <a href="#" id="start-scan">Empezar escaneo</a>
    </div>

    <div id="screen-scanning" class="hidden">
      <video id="camera-feed" playsinline></video>
      <!-- Recognition events will be drawn here. -->
      <canvas id="camera-feedback"></canvas>
      <p id="camera-guides">
        Apunte la cámara hacia el documento
      </p>
    </div>
  </div>
</template>

<script>
import * as BlinkIDSDK from '@microblink/blinkid-in-browser-sdk'
// const cameraFeed = document.getElementById('camera-feed')
export default {
  data() {
    return {
      scanFeedbackLock: false,
      showLoadingComponents: true
    }
  },

  created() {
    navigator.mediaDevices.enumerateDevices().then(deviceInfos => {
      deviceInfos.forEach(devceInfo => {
        if (devceInfo.kind === 'videoinput') {
          console.log('devceInfo')
          console.log(devceInfo)

          // this.cameras.push(deviceInfo);
        }
      })
    })

    // Check if browser is supported
    if (BlinkIDSDK.isBrowserSupported()) {
      const loadSettings = new BlinkIDSDK.WasmSDKLoadSettings(
        'sRwAAAYSd2ViLXN0Zy5zbW9rYXBwLmVzHWyne/j5FNk9x76Y0o5dL5Y0B2GMwELdk3ZiRBNQp/+D0uUJ4rrkr6umbz1Ajy+ON0QWQveWOdHH2cab3TOaFDfMQ0kF9yPR5HS1lwlCQKtulHtsZDDln19cW0hl+CIqx3QWw5r+8Yph1dmClvsrXyHgqxAHzwURB5/yYW48uHpQfnH4eE9nCtFQ7w0DIXinCgEtAXHMwzqnHa7sp01QR4dD+TmR6H8='
      )

      loadSettings.allowHelloMessage = true
      loadSettings.engineLocation = ''
      //   BlinkIDSDK.loadWasmModule(loadSettings).then()

      BlinkIDSDK.loadWasmModule(loadSettings)
        .then(sdk => {
          // The SDK was initialized successfully, save the wasmSDK for future use
          console.log(sdk)
          this.startScan(sdk)
        })
        .catch(error => {
          // Error happened during the initialization of the SDK
          console.log('Error during the initialization of the SDK!', error)
        })
    } else {
      console.log('This browser is not supported by the SDK!')
    }
  },
  // beforeDestroy() {
  //   this.editor.destroy()
  // },
  methods: {
    cameraFeed() {
      return document.getElementById('camera-feed')
    },
    async startScan(sdk) {
      // const cameraFeed = document.getElementById('camera-feed')
      // const initialMessageEl = document.getElementById('msg')

      document.getElementById('screen-start')?.classList.add('hidden')
      document.getElementById('screen-scanning')?.classList.remove('hidden')

      // 1. Create a recognizer objects which will be used to recognize single image or stream of images.
      //
      // Generic ID Recognizer - scan various ID documents
      // ID Barcode Recognizer - scan barcodes from various ID documents
      const genericIDRecognizer = await BlinkIDSDK.createBlinkIdRecognizer(sdk)
      const idBarcodeRecognizer = await BlinkIDSDK.createIdBarcodeRecognizer(
        sdk
      )

      // [OPTIONAL] Create a callbacks object that will receive recognition events, such as detected object location etc.
      const callbacks = {
        onQuadDetection: quad => this.drawQuad(quad),
        onDetectionFailed: () =>
          this.updateScanFeedback('Detection failed', true)
      }

      // 2. Create a RecognizerRunner object which orchestrates the recognition with one or more
      //    recognizer objects.
      const recognizerRunner = await BlinkIDSDK.createRecognizerRunner(
        // SDK instance to use
        sdk,
        // List of recognizer objects that will be associated with created RecognizerRunner object
        // [genericIDRecognizer, idBarcodeRecognizer],
        [genericIDRecognizer],
        // [OPTIONAL] Should recognition pipeline stop as soon as first recognizer in chain finished recognition
        true,
        // [OPTIONAL] Callbacks object that will receive recognition events
        callbacks
      )

      // 3. Create a VideoRecognizer object and attach it to HTMLVideoElement that will be used for displaying the camera feed
      const videoRecognizer = await BlinkIDSDK.VideoRecognizer.createVideoRecognizerFromCameraStream(
        this.cameraFeed(),
        recognizerRunner,
        'e067de2b85f8cdb8a79848ed0772d4505a97fcd4783336afa03abead19e37c7f'
      )

      // 4. Start the recognition and await for the results
      const processResult = await videoRecognizer.recognize()
      console.log('BlinkIDGeneric processResult', processResult)
      this.showLoadingComponents = false
      // 5. If recognition was successful, obtain the result and display it
      if (processResult !== BlinkIDSDK.RecognizerResultState.Empty) {
        const genericIDResults = await genericIDRecognizer.getResult()
        if (genericIDResults.state !== BlinkIDSDK.RecognizerResultState.Empty) {
          console.log('BlinkIDGeneric results', genericIDResults)

          const firstName =
            genericIDResults.firstName || genericIDResults.mrz.secondaryID
          const lastName =
            genericIDResults.lastName || genericIDResults.mrz.primaryID
          const dni =
            genericIDResults.personalIdNumber ||
            genericIDResults.mrz.sanitizedOpt1
          // alert(`${firstName} ${lastName}!\n ${dni}`)

          const birthdate = {
            year:
              genericIDResults.dateOfBirth.year ||
              genericIDResults.mrz.dateOfBirth.year,
            month:
              genericIDResults.dateOfBirth.month - 1 ||
              genericIDResults.mrz.dateOfBirth.month - 1,
            day:
              genericIDResults.dateOfBirth.day ||
              genericIDResults.mrz.dateOfBirth.day
          }

          this.$store.dispatch('setQRValue', dni)
          this.$store.dispatch('setMicroblinkData', {
            name: firstName,
            surname: lastName,
            dni: dni,
            birthdate: new Date(
              Date.UTC(birthdate.year, birthdate.month, birthdate.day, 0, 0, 0)
            )
          })
        }

        // const idBarcodeResult = await idBarcodeRecognizer.getResult()
        // if (idBarcodeResult.state !== BlinkIDSDK.RecognizerResultState.Empty) {
        //   console.log('IDBarcode results', idBarcodeResult)

        //   const firstName = idBarcodeResult.firstName
        //   const lastName = idBarcodeResult.lastName
        //   const dateOfBirth = {
        //     year: idBarcodeResult.dateOfBirth.year,
        //     month: idBarcodeResult.dateOfBirth.month,
        //     day: idBarcodeResult.dateOfBirth.day
        //   }

        //   alert(
        //     `Hello, ${firstName} ${lastName}!\nYou were born on ${dateOfBirth.year}-${dateOfBirth.month}-${dateOfBirth.day}.`
        //   )
        // }
      } else {
        alert('No se ha podido extraer información')
      }

      // 7. Release all resources allocated on the WebAssembly heap and associated with camera stream

      // Release browser resources associated with the camera stream
      videoRecognizer?.releaseVideoFeed()

      // Release memory on WebAssembly heap used by the RecognizerRunner
      recognizerRunner?.delete()

      // Release memory on WebAssembly heap used by the recognizer
      genericIDRecognizer?.delete()
      idBarcodeRecognizer?.delete()

      // Clear any leftovers drawn to canvas
      this.clearDrawCanvas()

      // Hide scanning screen and show scan button again
      document.getElementById('screen-start')?.classList.add('hidden')
      document.getElementById('screen-scanning')?.classList.add('hidden')
    },
    drawQuad(quad) {
      const cameraFeedback = document.getElementById('camera-feedback')
      const drawContext = cameraFeedback.getContext('2d')

      this.clearDrawCanvas()

      // Based on detection status, show appropriate color and message
      this.setupColor(quad)
      this.setupMessage(quad)

      this.applyTransform(quad.transformMatrix)
      drawContext.beginPath()
      drawContext.moveTo(quad.topLeft.x, quad.topLeft.y)
      drawContext.lineTo(quad.topRight.x, quad.topRight.y)
      drawContext.lineTo(quad.bottomRight.x, quad.bottomRight.y)
      drawContext.lineTo(quad.bottomLeft.x, quad.bottomLeft.y)
      drawContext.closePath()
      drawContext.stroke()
    },
    updateScanFeedback(message, force) {
      const scanFeedback = document.getElementById('camera-guides')

      if (this.scanFeedbackLock && !force) {
        return
      }

      this.scanFeedbackLock = true
      scanFeedback.innerText = message

      window.setTimeout(() => (this.scanFeedbackLock = false), 1000)
    },
    clearDrawCanvas() {
      const cameraFeedback = document.getElementById('camera-feedback')
      const drawContext = cameraFeedback.getContext('2d')

      cameraFeedback.width = cameraFeedback.clientWidth
      cameraFeedback.height = cameraFeedback.clientHeight

      drawContext.clearRect(0, 0, cameraFeedback.width, cameraFeedback.height)
    },
    setupColor(displayable) {
      const cameraFeedback = document.getElementById('camera-feedback')
      const drawContext = cameraFeedback.getContext('2d')

      let color = '#FFFF00FF'

      if (displayable.detectionStatus === 0) {
        color = '#FF0000FF'
      } else if (displayable.detectionStatus === 1) {
        color = '#00FF00FF'
      }

      drawContext.fillStyle = color
      drawContext.strokeStyle = color
      drawContext.lineWidth = 5
    },
    setupMessage(displayable) {
      switch (displayable.detectionStatus) {
        case BlinkIDSDK.DetectionStatus.Fail:
          this.updateScanFeedback('Scanning...')
          break
        case BlinkIDSDK.DetectionStatus.Success:
        case BlinkIDSDK.DetectionStatus.FallbackSuccess:
          this.updateScanFeedback('Detection successful')
          break
        case BlinkIDSDK.DetectionStatus.CameraAtAngle:
          this.updateScanFeedback('Adjust the angle')
          break
        case BlinkIDSDK.DetectionStatus.CameraTooHigh:
          this.updateScanFeedback('Move document closer')
          break
        case BlinkIDSDK.DetectionStatus.CameraTooNear:
        case BlinkIDSDK.DetectionStatus.DocumentTooCloseToEdge:
        case BlinkIDSDK.DetectionStatus.Partial:
          this.updateScanFeedback('Move document farther')
          break
        default:
          console.warn(
            'Unhandled detection status!',
            displayable.detectionStatus
          )
      }
    },
    applyTransform(transformMatrix) {
      const cameraFeedback = document.getElementById('camera-feedback')
      const drawContext = cameraFeedback.getContext('2d')
      // const cameraFeed = document.getElementById('camera-feed')

      const canvasAR = cameraFeedback.width / cameraFeedback.height
      const videoAR =
        this.cameraFeed().videoWidth / this.cameraFeed().videoHeight

      let xOffset = 0
      let yOffset = 0
      let scaledVideoHeight = 0
      let scaledVideoWidth = 0

      if (canvasAR > videoAR) {
        // pillarboxing: https://en.wikipedia.org/wiki/Pillarbox
        scaledVideoHeight = cameraFeedback.height
        scaledVideoWidth = videoAR * scaledVideoHeight
        xOffset = (cameraFeedback.width - scaledVideoWidth) / 2.0
      } else {
        // letterboxing: https://en.wikipedia.org/wiki/Letterboxing_(filming)
        scaledVideoWidth = cameraFeedback.width
        scaledVideoHeight = scaledVideoWidth / videoAR
        yOffset = (cameraFeedback.height - scaledVideoHeight) / 2.0
      }

      // first transform canvas for offset of video preview within the HTML video element (i.e. correct letterboxing or pillarboxing)
      drawContext.translate(xOffset, yOffset)
      // second, scale the canvas to fit the scaled video
      drawContext.scale(
        scaledVideoWidth / this.cameraFeed().videoWidth,
        scaledVideoHeight / this.cameraFeed().videoHeight
      )

      // finally, apply transformation from image coordinate system to
      // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setTransform
      drawContext.transform(
        transformMatrix[0],
        transformMatrix[3],
        transformMatrix[1],
        transformMatrix[4],
        transformMatrix[2],
        transformMatrix[5]
      )
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
}

html {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 24px;
  font-family: sans-serif;
}

body {
  display: flex;
  min-height: 100%;
  margin: 0;
  padding: 1.5rem;
  justify-content: center;
  align-items: center;
}

#screen-initial,
#screen-scanning {
  display: block;
  width: 100%;
  height: 100%;
}

/* Rules for better readability */
img {
  display: block;
  width: 100%;
  max-width: 320px;
  height: auto;
}

video {
  width: 100%;
  height: 100%;
}

textarea {
  display: block;
}

/* Camera feedback */
#screen-scanning {
  position: relative;
}

#camera-feedback {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;
}

#camera-guides {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: bold;
}

/* Auxiliary classes */
.hidden {
  display: none !important;
}
</style>
