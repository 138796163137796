<template>
  <div>
    <div style="margin-top: 130px;"></div>

    <div class="icono">
      <i class="fas fa-times-circle fa-10x"> </i>
      <h1 style="padding:20px">USUARIO NO ENCONTRADO</h1>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.icono {
  color: rgb(223, 0, 0);
  margin-top: 130px;
}
</style>
