import axios from 'axios'
import session from '@/services/Session.js'

export default {
  startOperation(
    ip,
    userName,
    password,
    posID,
    posUser,
    aliasID,
    amount,
    type
  ) {
    return axios.get(
      'https://' +
        ip +
        '/Cashdro3WS/index3.php?operation=startOperation&name=' +
        userName +
        '&password=' +
        password +
        '&type=' +
        type +
        '&posid=' +
        posID +
        '&parameters={"amount":' +
        amount +
        '}&startnow=true&posuser=' +
        posUser +
        '' +
        (aliasID ? '&aliasid=' + aliasID : '')
    )
  },
  executeTransaction(ip, userName, password, operationId) {
    return axios.get(
      'https://' +
        ip +
        '/Cashdro3WS/index3.php?operation=acknowledgeOperationId&name=' +
        userName +
        '&password=' +
        password +
        '&operationId=' +
        operationId
    )
  },
  transactionStatus(ip, userName, password, operationId) {
    return axios.get(
      'https://' +
        ip +
        '/Cashdro3WS/index3.php?operation=askOperation&name=' +
        userName +
        '&password=' +
        password +
        '&operationId=' +
        operationId
    )
  },
  importTransaction(ip, userName, password, operationId) {
    return axios.get(
      'https://' +
        ip +
        '/Cashdro3WS/index3.php?operation=setOperationImported&name=' +
        userName +
        '&password=' +
        password +
        '&operationId=' +
        operationId
    )
  },
  finishTransaction(ip, userName, password, operationId, type) {
    //Type 1 = Finish, Type 2 = Cancel
    return axios.get(
      'https://' +
        ip +
        '/Cashdro3WS/index3.php?operation=finishOperation&name=' +
        userName +
        '&password=' +
        password +
        '&operationId=' +
        operationId +
        '&type=' +
        type
    )
  },
  transactionExecuting(ip, userName, password) {
    return axios.get(
      'https://' +
        ip +
        '/Cashdro3WS/index3.php?operation=askOperationExecuting&name=' +
        userName +
        '&password=' +
        password
    )
  },
  pendingOperations(ip, userName, password) {
    return axios.get(
      'https://' +
        ip +
        '/Cashdro3WS/index3.php?operation=askPendingOperations&name=' +
        userName +
        '&password=' +
        password
    )
  },
  setOperationToOrder(order) {
    return session.post('/api/order/order_operation', order)
  }
}
