<template>
  <div>
    <div>
      <div v-if="currentPatient && currentOrder && orders && orders.length > 0">
        <div v-if="orders && orders.length > 0 && orders.length > 1">
          {{ $t('Este socio tiene varios pedidos abiertos:') }}
          <b-select
            @change.native="selectCurrentOrder($event)"
            class="form-control"
            v-model="selectedCurrentOrder"
          >
            <option
              v-for="order in orders"
              :value="order"
              :key="order.order_id"
            >
              {{ format_date(order.order_created, 'hh:mm dd/MM/yyyy') }}
            </option>
          </b-select>
        </div>
        <section>
          <b-table
            style="text-align:left; margin-top:25px;"
            :data="this.currentOrder.products"
            :sticky-header="false"
            :paginated="false"
            :per-page="perPage"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :default-sort-direction="defaultSortDirection"
            :pagination-rounded="isPaginationRounded"
            default-sort="titulo"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Actual"
            hoverable
            striped
          >
            <b-table-column
              field="product.title"
              :label="$t('Producto')"
              sortable
              v-slot="props"
            >
              <!-- <img
                v-if="props.row.product.images"
                class="imgRigth"
                style="border-radius:50%; max-height:4vh; max-width:4vh"
                :src="props.row.product.images[0].img"
              /> -->
              {{ props.row.product.title }}
            </b-table-column>

            <b-table-column
              field="quantity"
              :label="$t('Cant.')"
              sortable
              v-slot="props"
            >
              {{ props.row.quantity }}
            </b-table-column>

            <b-table-column
              field="price"
              :label="$t('Precio')"
              sortable
              v-slot="props"
            >
              {{ calculateDiscountedPrice(props.row.product).toFixed(2) }}€
            </b-table-column>

            <b-table-column
              field="total_discount"
              :label="$t('Cupón')"
              sortable
              v-slot="props"
            >
              <div
                v-if="props.row.total_discount && props.row.total_discount != 0"
              >
                {{ props.row.total_discount.toFixed(2) }}€
              </div>
            </b-table-column>

            <b-table-column
              field="price"
              :label="$t('Total')"
              sortable
              v-slot="props"
            >
              {{ props.row.total.toFixed(2) }}€
            </b-table-column>

            <b-table-column
              field="price"
              :label="$t('Peso real')"
              sortable
              v-slot="props"
            >
              <!-- <b-numberinput
                type="is-light"
                v-model="props.row.real_quantity"
                v-on:blur="updateOrder(true)"
              ></b-numberinput> -->

              <!-- <b-input
                v-model="props.row.real_quantity"
                :placeholder="$t('P.real')"
                v-on:blur="updateOrder(true)"
                v-on:click.native.self="updateOrder(true)"
                v-if="props.row.product.type == 'peso'"
                type="number"
                step=".01"
              >
              </b-input> -->

              <div class="realq">
                <input
                  v-model="props.row.real_quantity"
                  :placeholder="$t('P.real')"
                  v-on:focusout="updateOrder(true)"
                  v-if="props.row.product.type == 'peso'"
                  class="myinput"
                  :style="{ color: rqOverflow(props.row) }"
                  @keydown.enter="focusNext"
                />

                <div>
                  <b-button
                    v-if="props.row.product.type == 'peso'"
                    class="is-small"
                    icon-left="plus"
                    @click="incrementProductQty(props.row)"
                    tabindex="-1"
                  ></b-button>
                </div>
                <div>
                  <b-button
                    v-if="props.row.product.type == 'peso'"
                    class="is-small"
                    icon-left="minus"
                    @click="decrementProductQty(props.row)"
                    tabindex="-1"
                  ></b-button>
                </div>
              </div>
            </b-table-column>

            <b-table-column width="30" v-slot="props">
              <b-button
                class="is-small"
                icon-left="trash-alt"
                @click="deleteProduct(props.row)"
                tabindex="-1"
              ></b-button>
            </b-table-column>

            <template #footer>
              <div style="display:flex; justify-content:space-between;">
                <div>
                  <p
                    v-if="
                      currentOrder.discount_coupon_order &&
                        currentOrder.discount_coupon_order.length > 0
                    "
                  >
                    {{ $t('Descuentos') }}
                  </p>
                  <div
                    v-for="cupon in currentOrder.discount_coupon_order"
                    :key="cupon.id"
                    :value="cupon"
                  >
                    <span style="font-weight: 350; ">
                      {{ cupon.title }}
                    </span>
                    <span
                      >{{ cupon.discount }}
                      {{ cupon.type == 0 ? '€' : '%' }}</span
                    >
                    <b-button
                      class="mt-0"
                      style="height:20px; width:5%; background-color: Transparent;background-repeat:no-repeat;border: none;cursor:pointer;overflow: hidden;outline:none;"
                      @click="removeCupon(cupon)"
                    >
                      <i class="fas fa-times-circle" style="font-size:12px"></i>
                    </b-button>
                  </div>
                  <div v-if="currentOrder.manual_discount_order">
                    <b-button
                      class="mt-0"
                      style="height:20px;  background-color: Transparent;background-repeat:no-repeat;border: none;cursor:pointer;overflow: hidden;outline:none;"
                      @click="
                        currentOrder.manual_discount_order = 0
                        updateOrder(true)
                      "
                    >
                      {{ $t('Eliminar descuento manual') }}
                      <i class="fas fa-times-circle" style="font-size:12px"></i>
                    </b-button>
                  </div>
                </div>
                <div style="display:flex has-text-right">
                  <div class="has-text-right" style="font-weight: 350">
                    {{ $t('Subtotal') }}: {{ currentOrder.order_base_price }} €
                  </div>
                  <div
                    v-if="
                      currentOrder.order_discount &&
                        currentOrder.order_discount != 0
                    "
                    class="has-text-right"
                    style="font-weight: 350"
                  >
                    {{ $t('Cupones') }}: {{ currentOrder.order_discount }} €
                  </div>
                  <div class="has-text-right" style="font-weight: bold">
                    {{ $t('Total') }}: {{ currentOrder.order_total }} €
                  </div>
                  <hr v-if="walletPermission" />

                  <div
                    v-if="walletPermission"
                    class="has-text-right"
                    style="font-weight: 350"
                  >
                    {{ $t('Restante monedero') }}:
                    {{
                      (
                        currentPatient.monedero - currentOrder.order_total
                      ).toFixed(2)
                    }}
                    €
                  </div>
                </div>
              </div>
            </template>
          </b-table>
        </section>
        <div class="mt-3">
          <b-button
            @click="completeOrder()"
            class="mr-2"
            style="width:48%; height:60px"
          >
            {{ $t('Completar pedido') }}
          </b-button>
          <b-button
            @click="cancelOrder()"
            style="background-color: #ed6a5a; color:#fff; width:48%; height:60px"
          >
            {{ $t('Cancelar pedido') }}
          </b-button>
        </div>

        <!-- <div class="menu right" v-if="currentPatient">
          <ul>
            <li @click="completeOrder()">
              <a style="font-weight: bold;">
                <h6>Completar pedido</h6>
              </a>
            </li>
            <li
              style="background-color: #ed6a5a; color:#fff"
              @click="cancelOrder()"
            >
              <a style="font-weight: bold;">
                <h6>Cancelar pedido</h6>
              </a>
            </li>
          </ul>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import OrderComplete from '@/views/POS/OrderComplete.vue'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import { DialogProgrammatic as Dialog } from 'buefy'

export default {
  data() {
    return {
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 20,
      selectedCurrentOrder: null,
      weigthDiscrepancy: undefined
    }
  },
  props: {
    currentPatient: Object,
    currentClub: Object
  },
  mixins: [ToolsMixin],

  computed: {
    // ...mapState('patients', ['currentPatient']),
    ...mapState('orders', ['orders', 'currentOrder']),
    ...mapState('club', [
      'cashPermission',
      'cardPermission',
      'walletPermission'
    ])
  },
  methods: {
    focusNext(e) {
      const inputs = Array.from(
        e.target.form.querySelectorAll('input[type="text"]')
      )
      const index = inputs.indexOf(e.target)

      if (index < inputs.length) {
        inputs[index + 1].focus()
      }
    },
    rqOverflow(product) {
      let parsed_rq = this.parseBascule(product.real_quantity)
      if (
        Number(parsed_rq) >
        Number(product.quantity) +
          (Number(product.quantity) * this.weigthDiscrepancy) / 100
      ) {
        return 'red'
      }
    },
    deleteProduct(product) {
      product.quantity = 0
      console.log(this.currentOrder.products)
      if (this.currentOrder.products.length == 1) {
        this.cancelOrder()
      } else {
        this.updateOrder(true)
      }
    },
    incrementProductQty(product) {
      product.real_quantity = String(Number(product.real_quantity) + 0.01)
      this.updateOrder(true)
    },
    decrementProductQty(product) {
      if (product.real_quantity > 0.01) {
        product.real_quantity = String(Number(product.real_quantity) - 0.01)
        this.updateOrder(true)
      }
    },
    isNumeric(str) {
      if (typeof str != 'string') return false // we only process strings!
      return (
        !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      ) // ...and ensure strings of whitespace fail
    },
    parseBascule(weigth) {
      console.log(weigth)
      if (!weigth) {
        return null
      }
      let w = String(weigth)
        .replace(/\s/g, '')
        .replace('g', '')
        .replace('G', '')
        .replace('+', '')
        .replace('net', '')
        .replace(',', '.')

      if (w != '' && !this.isNumeric(w)) {
        this.$buefy.snackbar.open({
          duration: 2000,
          message: 'Introduce valores numéricos',
          type: 'is-primary',
          position: 'is-top',
          actionText: 'Ok',
          queue: false
        })
        return
      } else {
        return w
      }
    },
    updateOrder(withLoadingComponent) {
      // if (withLoadingComponent) {
      //   const loadingComponent = this.$buefy.loading.open({
      //     container: null
      //   })
      // }
      // Dialog.alert({
      //   title: this.$t('ENTRA'),
      //   message: this.$t('TEST'),
      //   confirmText: 'OK',
      //   type: 'is-danger',
      //   hasIcon: false,
      //   onConfirm: () => {}
      // })
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      var updatedOrder = {
        membership_id: this.currentPatient.nid,
        asocid: this.currentClub.nid,
        oid: this.currentOrder.order_id,
        discount_coupon_order: this.prepareDiscountCuponOrderArray(),
        manual_discount_order: this.currentOrder.manual_discount_order
      }
      var products = []
      var problems = false
      // console.log(this.currentOrder)
      this.currentOrder.products.forEach(product => {
        let parsed_rq = this.parseBascule(product.real_quantity)
        // if (!parsed_rq) {
        //   return
        // }
        if (
          this.weigthDiscrepancy &&
          Number(parsed_rq) >
            Number(product.quantity) +
              (Number(product.quantity) * this.weigthDiscrepancy) / 100
        ) {
          problems = true
          // product.real_quantity = product.real_quantity - 0.01
        }
        products.push({
          pid: product.product.pid,
          quantity: product.quantity,
          real_quantity: Number(parsed_rq),
          total: product.total_base_price
        })
      })

      if (problems) {
        Dialog.alert({
          title: this.$t('Advertencia'),
          message:
            this.$t('El peso real no debe exceder en ') +
            this.weigthDiscrepancy +
            '%',
          confirmText: 'OK',
          type: 'is-danger',
          hasIcon: false,
          onConfirm: () => {}
        })
      }

      updatedOrder.products = products

      this.$store.dispatch('orders/postOrder', updatedOrder).then(() => {
        // if (withLoadingComponent) {
        loadingComponent.close()
        // }
      })
    },
    prepareDiscountCuponOrderArray() {
      if (!this.currentOrder.discount_coupon_order) {
        return []
      }
      var tmp = []
      for (
        let index = 0;
        index < this.currentOrder.discount_coupon_order.length;
        index++
      ) {
        tmp.push(Number(this.currentOrder.discount_coupon_order[index].id))
      }
      return tmp
    },
    selectCurrentOrder() {
      console.log(this.currentOrder)
    },
    completeOrder() {
      let problems = 0

      this.currentOrder.products.forEach(product => {
        let parsed_rq = this.parseBascule(product.real_quantity)
        // if (!parsed_rq) {
        //   return
        // }
        if (
          this.weigthDiscrepancy &&
          parsed_rq &&
          Number(parsed_rq) >
            Number(product.quantity) +
              (Number(product.quantity) * this.weigthDiscrepancy) / 100
        ) {
          problems++
        }
      })
      if (problems == 0) {
        this.updateOrder(false)

        console.log(this.currentOrder)

        this.$buefy.modal.open({
          parent: this,
          component: OrderComplete,
          hasModalCard: true,
          customClass: 'custom-class custom-class-2',
          trapFocus: true
        })
      } else {
        Dialog.alert({
          title: this.$t('Advertencia'),
          message:
            this.$t('El peso real no debe exceder en ') +
            this.weigthDiscrepancy +
            '%',
          confirmText: 'OK',
          type: 'is-danger',
          hasIcon: false,
          onConfirm: () => {}
        })
      }

      // this.$store.commit('patients/CLEAR_CURRENT_PATIENT')
    },
    cancelOrder() {
      this.$buefy.dialog.confirm({
        title: this.$t('Cancelar pedido'),
        message: this.$t(
          '¿Estás seguro de que deseas <b>CANCELAR</b> el pedido?'
        ),
        confirmText: 'Sí, cancelar',
        cancelText: 'No',
        type: 'is-danger',
        hasIcon: false,
        onConfirm: () => {
          console.log(' borrar pedido')
          console.log(this.currentOrder)
          this.$store.dispatch('orders/deleteOrder', {
            asocid: this.currentOrder.asocid,
            oid: this.currentOrder.order_id
          })
        }
      })
    },
    fetchOrders() {
      if (this.currentClub && this.currentPatient) {
        this.$store.dispatch('orders/fetchPatientOrders', {
          asocid: this.currentClub.nid,
          fichaid: this.currentPatient.nid
        })
      }
    },
    calculateSubTotal() {
      var subtotal = 0
      this.currentOrder.products.forEach(product => {
        subtotal = subtotal + product.total
      })
      return subtotal
    },
    calculateDiscountedPrice(producto) {
      if (
        producto.discount &&
        this.currentPatient.category_member &&
        Number(
          this.currentPatient.category_member.field_member_category_descuento
        ) != 0
      ) {
        return (
          producto.precio -
          producto.precio *
            (Number(
              this.currentPatient.category_member
                .field_member_category_descuento
            ) /
              100)
        )
      } else {
        return producto.precio
      }
    },
    removeCupon(cupon) {
      console.log(cupon)

      var index = this.currentOrder.discount_coupon_order
        .map(x => {
          return x.id
        })
        .indexOf(cupon.id)

      this.currentOrder.discount_coupon_order.splice(index, 1)
      this.updateOrder(true)
    }
  },
  created() {
    this.$store
      .dispatch('club/fetchCategories', {
        asocid: this.currentClub.nid,
        type: 'weight_discrepancy'
      })
      .then(response => {
        console.log(response)

        if (response.data.length != 0) {
          this.weigthDiscrepancy = response.data[0].title
        }
      })
  },
  watch: {
    currentPatient() {
      this.fetchOrders()
    },
    orders() {
      if (this.orders && this.orders.length >= 1) {
        // this.currentOrder = this.orders[0]
        this.$store.dispatch('orders/setCurrentOrder', this.orders[0])
        this.selectedCurrentOrder = this.orders[0]
      }
    },
    selectedCurrentOrder() {
      this.$store.dispatch('orders/setCurrentOrder', this.selectedCurrentOrder)
    }
    // currentOrder() {
    //   console.log(this.currentOrder)
    // }
  }
}
</script>

<style lang="scss" scoped>
.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  top: 10px;
  // height: 5vh;
  overflow: auto;
}

.menu li {
  padding: 8px;
  margin-bottom: 7px;
  background-color: #f7f3f3;
  color: #666666;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
  cursor: pointer;
}

.right li {
  // background-color: #33b5e5;
  // color: #fff;
  background-color: #fff;
}

.left li {
  background-color: #f3f3f3;
  // color: #fff;
}

.menu li:hover {
  background-color: #e1e1e1;
  // color: #fff;
}

.imgRigth {
  object-fit: cover;
  width: 230px;
  height: 230px;
}
// .table {
//   height: calc(100vh - 160px);
// }
.my-table {
  max-height: 100vh;
  overflow-y: auto;
}

input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  border-bottom-color: #e6e6e6;
  background-color: #eee;
}
.no-outline:focus {
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.myinput {
  background-color: white;
  height: 24px;
  font-size: 15px;
  vertical-align: 10px;
  color: #717a81;
  width: 50px;
}
.realq {
  // width: 100px;
  display: flex;
}
.redtex {
  color: red;
}
</style>
