<template>
  <div>
    <div class="col-1">
      <product-grid-picker> </product-grid-picker>
    </div>
    <div class="col-2">
      <patient-picker
        style="overflow-y: hidden;"
        v-on:selectedPatient="selectedPatient"
      >
      </patient-picker>
      <div v-if="!currentPatient">
        <h3>{{ $t('PRIMERO SELECCIONA UN SOCIO') }}</h3>
      </div>
      <pos-patient-component
        style="min-width:300px;"
        :currentClub="currentClub"
      >
      </pos-patient-component>

      <pos-order :currentClub="currentClub" :currentPatient="currentPatient">
      </pos-order>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'
import PatientPicker from '@/components/PatientPicker.vue'
import ProductGridPicker from '@/views/POS/ProductGridPicker.vue'
import PosPatientComponent from './PosPatientComponent.vue'
import PosOrder from './PosOrder.vue'

export default {
  mixins: [ToolsMixin, InitViewMixin],
  components: {
    PatientPicker,
    ProductGridPicker,
    PosPatientComponent,
    PosOrder
  },
  props: ['order', 'patient'],
  methods: {
    selectedPatient(patient) {
      // this.$root.$emit('setSeatchFocused', 'hola')
      // console.log('------------------------')
      console.log(patient)
      // this.$store.dispatch('patients/setCurrentPatient', patient).then(() => {
      this.$store.dispatch('patients/setCurrentPatient', patient)

      this.$store.dispatch('orders/fetchPatientOrders', {
        asocid: this.currentClub.nid,
        fichaid: patient.nid
      })
      // })
    },
    addNewPatientToList(patient) {
      this.$store.dispatch('patients/addNewPatientToSelected', patient)
      this.$store.dispatch('orders/fetchPatientOrders', {
        asocid: this.currentClub.nid,
        fichaid: patient.nid
      })
      // })
    },
    fetchPatient(membership_id) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.$store
        .dispatch('patients/fetchPatientByMembership', {
          asocid: this.currentClub.nid,
          nid: membership_id
        })
        .then(response => {
          console.log(response)
          //TODO: HAY QUE AÑADIR UN FILTRO PARA QUE NO META AL SOCIO EN LA LISTA SI YA ESTÁ EN ELLA

          var filteredDataArray = this.selectedPatients.filter(
            patient => patient.nid == membership_id
          )
          if (filteredDataArray.length == 0) {
            this.selectedPatients.push(response.data.data[0])
            this.$store.dispatch(
              'patients/setSelectedPatients',
              this.selectedPatients
            )
            this.$store.dispatch(
              'patients/setCurrentPatient',
              response.data.data[0]
            )
          }

          loadingComponent.close()
        })
        .catch(error => {
          console.log('Ha habido un error:' + error)
          loadingComponent.close()
        })
    }
  },
  computed: {
    ...mapState('patients', ['patients', 'currentPatient', 'selectedPatients']),
    ...mapState('orders', ['orders'])
  },

  created() {
    this.validateAccess(['software_gestion_club'])
    this.validateRoles(['Administrador', 'Manager', 'Budtender'])
    this.$analytics.logEvent('POS.vue')
    this.$store.dispatch('setShowNavigationBar', true)

    //TODO lanzar esto al arrancar las vistas
    this.$store.dispatch('auth/sessionToken')

    if (this.order) {
      //Hay que buscar el paciente por el id de la url
      this.fetchPatient(this.order.membership_id)
    } else {
      if (this.patient) {
        this.fetchPatient(this.patient.nid)
      }
      if (this.currentPatient) {
        this.$store.dispatch('orders/fetchPatientOrders', {
          asocid: this.currentClub.nid,
          fichaid: this.currentPatient.nid
        })
      }
    }
  },
  watch: {
    currentClub() {
      this.validateAccess(['software_gestion_club'])
      this.validateRoles(['Administrador', 'Manager', 'Budtender'])
    }
    // currentPatient() {
    //   this.$store.dispatch('orders/fetchPatientOrders', {
    //     asocid: this.currentClub.nid,
    //     fichaid: this.currentPatient.nid
    //   })
    // }
  }
}
</script>
<style lang="scss" scoped>
[class*='col-'] {
  float: left;
  padding: 5px;
}

/* For desktop: */
.col-1 {
  width: 45%;
  // background-color: #fff;
  background-color: #f9f9f9;
  height: 100vh;
}
.col-2 {
  width: 55%;
  background-color: #f9f9f9;
  height: 100vh;
  overflow: auto;
  // height: calc(100vh - 160px);
}

/* For mobile phones: */
@media only screen and (max-width: 767px) {
  [class*='col-'] {
    width: 100%;
  }
}
</style>
<style scoped></style>
