<template>
  <div>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t('Repositorio de variedades') }}</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div class="aside" v-if="selected">
          <div v-if="selected.images.length > 0">
            <img :src="selected.images[0].img" />
          </div>
          <!-- <div v-else>
            <div v-if="image_urls.length > 0">
              <img
                class="imgRigth"
                style="border-radius:50%; margin-top:20px; max-height:20vh"
                :src="image_urls[0].img"
              />
            </div>
          </div> -->
          <h3>{{ selected.title }}</h3>
          <p v-html="selected.body"></p>
        </div>
        <section>
          <b-table
            :data="presetVarieties"
            :selected.sync="selected"
            default-sort="title"
          >
            <b-table-column field="image" v-slot="props">
              <div v-if="props.row.images.length > 0">
                <img
                  style="border-radius:50%; max-height:40px"
                  :src="props.row.images[0].img"
                />
              </div>
            </b-table-column>

            <b-table-column
              field="title"
              :label="$t('Título')"
              sortable
              v-slot="props"
              searchable
            >
              {{ props.row.title }}
            </b-table-column>

            <b-table-column
              field="dominance"
              label="Dominancia"
              sortable
              v-slot="props"
              searchable
            >
              {{ props.row.dominance }}
            </b-table-column>

            <b-table-column
              field="props.row.thc"
              :label="$t('THC')"
              v-slot="props"
              width="50"
            >
              <b-checkbox :value="props.row.thc" disabled></b-checkbox>
            </b-table-column>
          </b-table>
        </section>
      </section>

      <footer class="modal-card-foot">
        <button class="button" type="button" @click="$emit('close')">
          {{ $t('Cerrar') }}
        </button>

        <b-button class="button is-primary" @click="returnSelectedProduct()">
          {{ $t('Seleccionar') }}
        </b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      selected: null
    }
  },
  computed: {
    ...mapState('dispensaryPro', ['presetVarieties'])
  },
  methods: {
    returnSelectedProduct() {
      this.$emit('seletedProduct', this.selected)
      this.$emit('close')
    }
  },
  mounted() {
    this.$store.dispatch('dispensaryPro/fetchPresetVarieties')
  }
}
</script>

<style lang="scss" scoped></style>
