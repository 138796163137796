<template>
  <div v-if="this.itemsToShow">
    <div v-if="showQRReader">
      <QRBarCodeReader @qrResult="qrResult"> </QRBarCodeReader>
    </div>
    <div style="display:flex">
      <b-input
        ref="input"
        class="mb-3"
        v-model="searchProductText"
        :placeholder="$t('Buscar producto')"
        rounded
        @keyup.native.enter="enter"
      ></b-input>
      <b-button
        @click="qrtoggle()"
        style="margin-top:5px; margin-left:3px; width:30px;  height:30px; background:#ffffff; color:#000000"
        ><i class="fas fa-barcode fa"></i>
      </b-button>
    </div>
    <div style="padding:10px; width: 160px">
      <b-select class="form-control" v-model="currentCategory">
        <option
          v-for="category in categories"
          :value="category"
          :key="category.nid"
        >
          {{ category.title }}
        </option>
        <option :value="null">
          {{ $t('Todo') }}
        </option>
      </b-select>
    </div>

    <div
      class="collection"
      style="display:flex; flex-wrap: wrap;align-content:flex-start"
      v-if="this.itemsToShow.length > 0"
    >
      <div
        v-for="item in itemsToShow"
        :key="item.nid"
        :item="item"
        style="height:200px;width:140px;padding:4px"
      >
        <a
          class="cells"
          style="font-weight: bold; max-height:100%;"
          @click="addItemToCart(item)"
        >
          <p
            style="color: gray; text-align: right; font-size: 10px; margin-right:2px"
          >
            <span v-if="item.discount" style="font-size: 9px; ">
              {{ calculateDiscountedPrice(item.precio).toFixed(2) }}€
            </span>
            <span v-else> {{ item.precio.toFixed(2) }}€</span>
          </p>

          <div v-if="item.imagenes.length > 0">
            <img
              :src="item.imagenes[0].img"
              style="height:120px;object-fit: cover; border-radius:6px;"
              class="w3-hover-opacity"
            />
          </div>
          <div v-else>
            <img
              src="@/assets/nofoto.png"
              style="height:120px;object-fit: cover; border-radius:6px;"
              class="w3-hover-opacity"
            />
          </div>

          <h6
            class="line-clamp"
            style="text-align:center;font-size: 15px;  font-weight: 350; color:black; margin-bottom:10px"
          >
            {{ item.titulo }}
          </h6>
        </a>
        <!-- <router-link :to="'/dispensary_item?id=' + item.nid"> -->

        <!-- </router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'
import CreateOrder from '@/views/POS/CreateOrder.vue'
import QRBarCodeReader from '@/components/QRBarCodeReader.vue'

export default {
  data() {
    return {
      currentCategory: null,
      itemsToShow: null,
      searchProductText: '',
      showQRReader: false
    }
  },
  computed: {
    ...mapState('dispensaryPro', ['dispensary', 'categories']),
    ...mapState('patients', ['currentPatient']),
    ...mapState('orders', ['orders', 'currentOrder'])
  },
  components: {
    QRBarCodeReader
  },
  mixins: [InitViewMixin],
  methods: {
    qrtoggle() {
      if (!this.showQRReader) {
        //this.qrButtonTitle = 'Cerrar QR'
      } else {
        //this.qrButtonTitle = 'Validar mediante QR'
      }
      this.showQRReader = !this.showQRReader
      this.$matomo.trackEvent('ProductPicker', 'Click', 'qrReader', 1)
    },
    qrResult(result) {
      console.log('este es el resultado')
      console.log(result)
      this.searchProductText = result
      this.showQRReader = !this.showQRReader
      setTimeout(() => {
        this.enter()
      }, 2000)
    },
    enter() {
      if (this.itemsToShow && this.itemsToShow.length > 0) {
        this.addItemToCart(this.itemsToShow[0])
      }
      this.searchProductText = ''
    },
    calculateDiscountedPrice(basePrice) {
      if (
        this.currentPatient &&
        this.currentPatient.category_member &&
        Number(
          this.currentPatient.category_member.field_member_category_descuento
        ) != 0
      ) {
        return (
          basePrice -
          basePrice *
            (Number(
              this.currentPatient.category_member
                .field_member_category_descuento
            ) /
              100)
        )
      } else {
        return basePrice
      }
    },
    addItemToCart(item) {
      if (this.currentPatient) {
        this.$buefy.modal.open({
          parent: this,
          component: CreateOrder,
          hasModalCard: true,
          customClass: 'custom-class custom-class-2',
          trapFocus: true,
          props: {
            item: item,
            currentClub: this.currentClub,
            currentPatient: this.currentPatient,
            currentOrder: this.currentOrder
          }
        })
      } else {
        this.$buefy.snackbar.open({
          duration: 3000,
          message: this.$t('Selecciona primero a un socio'),
          type: 'is-danger',
          position: 'is-top',
          actionText: 'Ok',
          queue: false
        })
      }
    },

    loadItems() {
      this.itemsToShow = null
      this.itemsToShow = this.dispensary
      this.itemsToShow.sort(this.compare)
      var tmpArr = []
      this.itemsToShow.forEach(element => {
        if (element.stock > 0) {
          tmpArr.push(element)
        }
      })
      this.itemsToShow = tmpArr
    },
    compare(a, b) {
      let comparison = 0
      if (a.titulo > b.titulo) {
        comparison = 1
      } else if (a.titulo < b.titulo) {
        comparison = -1
      }
      return comparison
    },
    loadDispensary() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.$store.dispatch('dispensaryPro/fetchCategories', {
        asocid: this.currentClub.nid,
        type: 'dispensary'
      })
      this.$store
        .dispatch('dispensaryPro/fetchDispensary', {
          asocid: this.currentClub.nid,
          status: true
        })
        .then(() => {
          // this.loadItems()
          loadingComponent.close()
        })
    },
    focusInput() {
      this.$refs.input.focus()
    }
  },
  created() {
    this.loadDispensary()
  },
  mounted() {
    this.$root.$on('setSeatchFocused', arg1 => {
      this.focusInput()
    })

    // setTimeout(() => {
    //   this.focusInput()
    // }, 1000)
  },
  watch: {
    dispensary() {
      this.itemsToShow = null
      this.itemsToShow = JSON.parse(JSON.stringify(this.dispensary))
      this.itemsToShow.sort(this.compare)
      var tmpArr = []
      this.itemsToShow.forEach(element => {
        if (element.stock > 0) {
          tmpArr.push(element)
        }
      })
      this.itemsToShow = tmpArr
    },
    currentClub() {
      this.loadDispensary()
      this.searchProductText = ''
    },
    searchProductText() {
      this.itemsToShow = this.dispensary.filter(
        item =>
          item.titulo
            .toLowerCase()
            .includes(this.searchProductText.toLowerCase()) ||
          item.code.toLowerCase().includes(this.searchProductText.toLowerCase())
      )
    },
    currentCategory() {
      this.$store.dispatch(
        'dispensaryPro/setCurrentCategory',
        this.currentCategory
      )
      console.log(this.currentCategory)
      if (this.currentCategory) {
        this.itemsToShow = this.dispensary.filter(item =>
          item.categorias
            .toLowerCase()
            .includes(this.currentCategory.title.toLowerCase())
        )
      } else {
        this.loadItems()
      }

      // this.loadItems()
    }
  }
}
</script>

<style lang="scss" scoped>
.cells {
  background-color: #fff;
  padding: 6px;
  color: #666666;
  text-align: center;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
  display: inline-block;
  // justify-content: center;
  width: 100%;
  height: 100%;

  // margin-left: 5px;
}
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
h6 {
  font-weight: 200;
  font-size: 1em;
}
.collection {
  overflow: scroll;
  height: calc(100vh - 120px);
}
// .cells {
//   background-color: #fff;
//   color: #666666;
//   box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
//   border-radius: 6px;
// }
</style>
