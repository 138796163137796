<template>
  <div style="text-align: justify;">
    <div id="printMe">
      <h3>
        <b> {{ $t('SOLICITUD DE INSCRIPCIÓN / DECLARACIÓN JURADA') }}</b>
      </h3>
      <hr />
      <h5>{{ $t('ASOCIACIÓN') }} {{ currentClub.title }}</h5>

      <h5>{{ $t('Datos de inscripción') }}.</h5>
      <h6>
        {{ name }} {{ surname }} {{ surname2 }}
        {{ $t('mayor de edad con DNI') }} {{ dni }} {{ $t('y dirección en') }}
        {{ memcard_address ? memcard_address.thoroughfare : '' }}
      </h6>
      <h6 v-if="type_member == 'ludico'">
        {{
          $t(
            'Aporta datos, fecha y firma de el/la socio/a aval de la condición de consumidor/a de las sustancias mencionadas en los estatutos en este documento de la solicitante de admisión'
          )
        }}:
      </h6>
      <h6 v-else>
        {{
          $t(
            'Muestra informes médicos que acreditan la condición de Usuario/a Terapéutico, (por estar contenidas estas patologías en la lista de la IACM) o certificado médico emitido por un doctor/a donde le recomienda el uso de los cannabinoides'
          )
        }}.
      </h6>
      <h5>{{ $t('Declaración jurada') }}</h5>
      <h6>{{ $t('Por la presente declara') }}:</h6>

      <h6 v-html="$t('contrato_body')"></h6>

      <div
        v-if="
          currentClub.functionalities.firma_contrato == true ||
            currentClub.functionalities.firma_digital == true
        "
      >
        <h3>
          <b> {{ $t('FIRMA') }}: </b>
        </h3>
        <b-button v-if="signature == null" @click="sign()">
          {{ $t('Firmar') }}
        </b-button>
        <img
          v-if="signatureString"
          :src="signatureString"
          style="width:250px;margin: auto;"
        />
        <img
          v-if="signature"
          class="imgRigth"
          style="height: 80; width: 40; object-fit:cover;"
          :src="signature.url"
        />
      </div>
    </div>

    <b-button @click="print">{{ $t('Imprimir') }}</b-button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SignatureReader from '@/views/Utils/SignatureReader.vue'

export default {
  data() {
    return {
      signatureString: null
    }
  },
  props: {
    name: String,
    surname: String,
    surname2: String,
    dni: String,
    memcard_address: Object,
    club: String,
    type_member: String,
    signature: Object
  },
  computed: {
    ...mapState('club', ['currentClub'])
  },
  methods: {
    sign() {
      this.$buefy.modal.open({
        parent: this,
        component: SignatureReader,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
        events: {
          recieveSign: value => {
            this.signatureString = value
            this.$emit('recieveSignature', value)
            this.$emit('close')
          }
        }
      })
    },

    print() {
      // Pass the element id here
      this.$htmlToPaper('printMe')
    }
  }
}
</script>

<style lang="scss" scoped></style>
