import APICashdro from '../services/APICashdro'

const initialState = {
  cashdroUserName: undefined,
  cashdroPassword: undefined,
  cashdroCurrentOperationID: undefined,
  cashdroPosID: undefined
}

const mutations = {
  ['SET_CASHDRO_USER_NAME'](state, userName) {
    state.cashdroUserName = userName
  },
  ['SET_CASHDRO_PASSWORD'](state, password) {
    state.cashdroPassword = password
  },
  ['SET_CURRENT_OPERATION'](state, operation) {
    state.cashdroCurrentOperationID = operation
  },
  ['SET_POS_ID'](state, posID) {
    state.cashdroPosID = posID
  }
}

const actions = {
  setCashdroUserName({ commit }, userName) {
    commit('SET_CASHDRO_USER_NAME', userName)
  },
  setCashdroPassword({ commit }, password) {
    commit('SET_CASHDRO_PASSWORD', password)
  },
  setcashdroCurrentOperationID({ commit }, operation) {
    commit('SET_CURRENT_OPERATION', operation)
  },
  setCashdroPosID({ commit }, posID) {
    commit('SET_POS_ID', posID)
  },
  // Valores de type en startOperation:
  // 1 = Carga
  // 2 = Retirada
  // 3 = Pago por denominación
  // 4 = Venta
  // 8 = Traspaso a casete
  // 10 = Retirada casete de billetes
  // 11 = Retirada casete de monedas
  // 16 = Ingreso
  // 17 = Ingreso por importe
  // 18 = Cambio
  async sellOperation(
    { commit, state },
    { amount, posID, posUser, aliasID, ip }
  ) {
    const response = await APICashdro.startOperation(
      ip,
      state.cashdroUserName,
      state.cashdroPassword,
      posID ? posID : state.cashdroPosID,
      posUser,
      aliasID,
      amount * 100,
      '4'
    )
    commit(
      'SET_CURRENT_OPERATION',
      response.data.response.operation.operationId
    )

    return APICashdro.executeTransaction(
      ip,
      state.cashdroUserName,
      state.cashdroPassword,
      response.data.response.operation.operationId
    )
  },
  gettransactionStatus({ commit, state }, { ip }) {
    return APICashdro.transactionStatus(
      ip,
      state.cashdroUserName,
      state.cashdroPassword,
      state.cashdroCurrentOperationID
    )
  },
  importTransaction({ commit, state }, { ip }) {
    console.log('importTransaction ip:', ip)
    return APICashdro.importTransaction(
      ip,
      state.cashdroUserName,
      state.cashdroPassword,
      state.cashdroCurrentOperationID
    )
  },
  getTransactionExecuting({ commit, state }, { ip }) {
    return APICashdro.transactionExecuting(
      ip,
      state.cashdroUserName,
      state.cashdroPassword
    )
  },
  getPendingOperations({ commit, state }, { ip }) {
    return APICashdro.pendingOperations(
      ip,
      state.cashdroUserName,
      state.cashdroPassword
    )
  },
  //Valores de type en finishTransaction: Type 1 = Finish, Type 2 = Cancel
  finishTransaction({ commit, state }, { type, operationId }) {
    return APICashdro.finishTransaction(
      ip,
      state.cashdroUserName,
      state.cashdroPassword,
      operationId ? operationId : state.cashdroCurrentOperationID,
      type
    )
  },
  setCashdroCurrentOperationIdIntoOrder(
    { commit },
    { operationId, operationStatus, oid, asocid }
  ) {
    // commit('SET_CURRENT_OPERATION', operation)
    let order = {
      oid: oid,
      operation_id: operationId,
      operation_status: operationStatus,
      asocid: asocid
    }
    return APICashdro.setOperationToOrder(order)
  }
}

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations
}
