<template>
  <div>
    <Chat
      :participants="participants"
      :myself="myself"
      :messages="messages"
      :chat-title="chatTitle"
      :placeholder="placeholder"
      :colors="colors"
      :border-style="borderStyle"
      :hide-close-button="hideCloseButton"
      :close-button-icon-size="closeButtonIconSize"
      :submit-icon-size="submitIconSize"
      :submit-image-icon-size="submitImageIconSize"
      :load-more-messages="toLoad.length > 0 ? loadMoreMessages : null"
      :link-options="linkOptions"
      :async-mode="asyncMode"
      :scroll-bottom="scrollBottom"
      :display-header="true"
      :send-images="false"
      :profile-picture-config="profilePictureConfig"
      :timestamp-config="timestampConfig"
      @onImageClicked="onImageClicked"
      @onImageSelected="onImageSelected"
      @onMessageSubmit="onMessageSubmit"
      @onType="onType"
      @onClose="onClose"
    >
      <template v-slot:header>
        <div style="color:#fff; text-align: left;">
          <span>
            {{ consult.title }}
          </span>
          <h5>
            {{ consult.body }}
          </h5>
          <span v-for="(participant, index) in participants" :key="index">
            {{ participant.name }}
          </span>
        </div>
      </template>
    </Chat>
  </div>
</template>

<script>
import { Chat } from 'vue-quick-chat'
import 'vue-quick-chat/dist/vue-quick-chat.css'
import { mapState } from 'vuex'

export default {
  props: {
    consult: Object
  },
  computed: {
    ...mapState('club', ['currentClub']),
    ...mapState('auth', ['smokappProfile'])
  },
  components: {
    Chat
  },
  watch: {
    consult() {
      this.messages = []
      this.consult.comment.forEach(comment => {
        var date = new Date(comment.created * 1000)
        let m = {
          content: comment.body,
          myself: this.smokappProfile.user.name == comment.name,
          participantId: this.hashCode(comment.name),
          timestamp: {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDay(),
            hour: date.getHours(),
            minute: date.getMinutes(),
            second: date.getSeconds(),
            millisecond: date.getMilliseconds()
          },
          type: 'text'
        }

        this.messages.push(m)
      })
      this.participants = this.parseParticipants(this.consult.comment)
      this.chatTitle = this.consult.title
    }
  },
  data() {
    return {
      submitImageIconSize: 24,
      visible: true,
      participants: [],
      myself: {
        name: 'Yo',
        id: 3,
        profilePicture:
          'https://lh3.googleusercontent.com/-G1d4-a7d_TY/AAAAAAAAAAI/AAAAAAAAAAA/AAKWJJPez_wX5UCJztzEUeCxOd7HBK7-jA.CMID/s83-c/photo.jpg'
      },
      messages: [],
      chatTitle: '',
      placeholder: this.$t('Envía un mensaje'),
      colors: {
        header: {
          bg: '#247ba0',
          text: '#fff'
        },
        message: {
          myself: {
            bg: '#fff',
            text: '#bdb8b8'
          },
          others: {
            bg: '#247ba0',
            text: '#fff'
          },
          messagesDisplay: {
            bg: '#f9f9f9'
          }
        },
        submitIcon: '#b91010',
        submitImageIcon: '#b91010'
      },
      borderStyle: {
        topLeft: '10px',
        topRight: '10px',
        bottomLeft: '10px',
        bottomRight: '10px'
      },
      hideCloseButton: false,
      submitIconSize: 25,
      closeButtonIconSize: '20px',
      asyncMode: false,
      toLoad: [],
      scrollBottom: {
        messageSent: true,
        messageReceived: false
      },
      displayHeader: true,
      profilePictureConfig: {
        others: true,
        myself: true,
        styles: {
          width: '30px',
          height: '30px',
          borderRadius: '50%'
        }
      },
      timestampConfig: {
        format: 'HH:mm',
        relative: false
      },
      // there are other options, you can check them here
      // https://soapbox.github.io/linkifyjs/docs/options.html
      linkOptions: {
        myself: {
          className: 'myLinkClass',
          events: {
            click: function(e) {
              alert('Link clicked!')
            },
            mouseover: function(e) {
              alert('Link hovered!')
            }
          },
          format: function(value, type) {
            if (type === 'url' && value.length > 50) {
              value = value.slice(0, 50) + '…'
            }
            return value
          }
        },
        others: {
          className: 'othersLinkClass',
          events: {
            click: function(e) {
              alert('Link clicked!')
            },
            mouseover: function(e) {
              alert('Link hovered!')
            }
          },
          format: function(value, type) {
            if (type === 'url' && value.length > 50) {
              value = value.slice(0, 50) + '…'
            }
            return value
          }
        }
      }
    }
  },
  methods: {
    onType: function(event) {
      //here you can set any behavior
    },
    loadMoreMessages(resolve) {
      setTimeout(() => {
        // resolve(this.toLoad) //We end the loading state and add the messages
        //Make sure the loaded messages are also added to our local messages copy or they will be lost
        // this.messages.unshift(...this.toLoad)
        // this.toLoad = []
      }, 1000)
    },
    onMessageSubmit: function(message) {
      /*
       * example simulating an upload callback.
       * It's important to notice that even when your message wasn't send
       * yet to the server you have to add the message into the array
       */
      //   this.messages.push(message)

      /*
       * you can update message state after the server response
       */
      // timeout simulating the request

      this.$store
        .dispatch('consults/postComment', {
          asocid: this.currentClub.nid,
          nid: this.consult.nid,
          body: message.content
        })
        .then(() => {
          message.uploaded = true
          this.$matomo.trackEvent('Consults', 'Send', 'sendmessage', 1)
        })
    },
    onClose() {
      this.visible = false
    },
    onImageSelected(files, message) {
      let src =
        'https://149364066.v2.pressablecdn.com/wp-content/uploads/2017/03/vue.jpg'
      this.messages.push(message)
      /**
       * This timeout simulates a requisition that uploads the image file to the server.
       * It's up to you implement the request and deal with the response in order to
       * update the message status and the message URL
       */
      setTimeout(
        res => {
          message.uploaded = true
          message.src = res.src
        },
        3000,
        { src }
      )
    },
    onImageClicked(message) {
      /**
       * This is the callback function that is going to be executed when some image is clicked.
       * You can add your code here to do whatever you need with the image clicked. A common situation is to display the image clicked in full screen.
       */
      console.log('Image clicked', message.src)
    },
    parseParticipants(commments) {
      var participantsNames = []
      commments.forEach(comment => {
        if (participantsNames.indexOf(comment.name) === -1) {
          participantsNames.push(comment.name)
        }
      })

      var participants = []
      participantsNames.forEach(name => {
        var participant = {
          name: name,
          id: this.hashCode(name),
          profilePicture:
            'https://upload.wikimedia.org/wikipedia/en/thumb/a/a1/NafSadh_Profile.jpg/768px-NafSadh_Profile.jpg'
        }
        participants.push(participant)
      })
      return participants
    },
    hashCode(s) {
      return s.split('').reduce(function(a, b) {
        a = (a << 5) - a + b.charCodeAt(0)
        return a & a
      }, 0)
    }
  }
}
</script>

<style lang="scss" scoped></style>
