<template>
  <div>
    <!-- <div class="header">
      <h1>Chania</h1>
    </div> -->
    <div class="row">
      <div class="col-1 menu">
        <h4>{{ $t('Filtrar por') }}:</h4>
        <section class="consultsList">
          <section>
            <div class="columns mb-2 is-variable is-1">
              <div class="column">
                <b-radio-button v-model="filterType" native-value="title" type="is-primary is-light is-outlined">
                  {{ $t('Consulta') }}
                </b-radio-button>
              </div>
              <div class="column">
                <b-radio-button v-model="filterType" native-value="user" type="is-primary is-light is-outlined">
                  {{ $t('Socio') }}
                </b-radio-button>
              </div>
            </div>
          </section>
          <div>
            <b-input class="mb-3" v-model="searchText" :placeholder="$t('Filtrar por título de la consulta')"
              rounded></b-input>
          </div>
          <ul v-for="consult in this.filteredDataArray" :key="consult.nid" :value="consult"
            :class="{ active: currentConsult === consult }">
            <li @click="selectCurrentConsult(consult)">
              <!-- <b-radio
                v-model="currentConsult"
                name="currentConsult"
                :native-value="consult"
                size="is-small"
              >
                <h6>
                  {{ consult.title }}
                </h6>
                <p>
                  {{ consult.name }}
                </p>
                <p>
                  Creación:
                  {{ format_date(consult.created, 'dd/MM/yyyy') }}
                </p>
                <p>
                  Último comentario:
                  {{ format_date(consult.comment_date, 'hh:mm dd/MM/yyyy') }}
                </p>
              </b-radio> -->

              <div>
                <b>
                  {{ consult.title }}
                </b>
                <p>
                  {{ consult.name }}
                  {{ format_date(consult.created, 'dd/MM/yyyy') }}
                </p>
                <p style="font-size:12px">
                  {{ $t('Último comentario') }}:
                  {{ format_date(consult.comment_date, 'hh:mm dd/MM/yyyy') }}
                </p>
              </div>
            </li>
          </ul>
        </section>
      </div>

      <div class="col-2">
        <Chat class="chat" :consult="currentConsult"></Chat>
      </div>

      <div class="col-3 ">
        <div class="aside">
          <v-gravatar email="manuel@xautec.com" alt="Nobody" :size="60" default-img="mm"
            style="border-radius:50%; margin-top:20px;" />
          <h4>{{ $t('CREADOR DE LA CONSULTA') }}</h4>

          <!-- <h6>Manuel Bermúdez 74725561X</h6>
          <p>663996232</p>
          <p>08/09/1981</p>
          <p>Último acceso al local: 18:34 12/10/2020</p>
          <p>Caducidad de la cuota: 24/11/2021</p>
          <p>Saldo: 436,0 €</p>
          <p>Puntos de recompensa: 196</p>
          <p>Previsión: 25Gr per 1 day</p>
          <p>Socio desde el 3/7/2006</p>
          <p>Total de pedidos: 27</p> -->
          <!-- <a style="font-weight: bold;" @click="selectCurrentConsult(consult)">
            Ver historial de pedidos
          </a> -->
        </div>
        <hr />
        <div class="menu right">
          <ul>
            <!-- <li style="background-color: #247ba0;">
              <h6>Crear nueva consulta</h6>
              <p>Iniciar una nueva consulta con un socio</p>
            </li> -->
            <li style="background-color: #ed6a5a;" @click="deleteConsult(currentConsult)">
              <div>
                <h6>{{ $t('Borrar consulta') }}</h6>
              </div>
            </li>
            <!-- <li style="background-color: #70c1b3;" @click="sendSMS()">
              <div>
                <h6>Enviar SMS</h6>
                <p>Envía un sms al creador de la consulta</p>
              </div>
            </li> -->
          </ul>
        </div>
      </div>
    </div>

    <!-- <div class="footer">
      <p>
        Resize the browser window to see how the content respond to the
        resizing.
      </p>
    </div> -->
  </div>
</template>

<script>
import Chat from '@/components/Chat.vue'
import { mapState } from 'vuex'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import APINotifications from '@/services/APINotifications.js'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'

export default {
  data() {
    return {
      // currentConsult: {},
      searchText: '',
      filteredDataArray: this.consults,
      filterType: 'title'
    }
  },
  computed: {
    ...mapState('consults', ['consults', 'currentConsult'])
  },
  components: {
    Chat
  },
  mixins: [ToolsMixin, InitViewMixin],
  methods: {
    sendSMS() {
      this.$buefy.dialog.prompt({
        message: `Enviar SMS`,
        inputAttrs: {
          placeholder: 'Escribe aquí el mensaje',
          maxlength: 10
        },
        trapFocus: true,
        onConfirm: value => APINotifications.sendSMS('663996232', 'test')
      })
      this.$matomo.trackEvent('Buttons', 'Click', 'sendsms', 1)
    },
    selectCurrentConsult(consult) {
      // this.currentConsult = consult
      this.$store.dispatch('consults/setCurrentConsult', consult)
    },
    deleteConsult() {
      this.$buefy.dialog.confirm({
        title: this.$t('Borrar consulta'),
        message: this.$t(
          '¿Estás seguro de que deseas <b>ELIMINAR</b> la consulta?'
        ),
        confirmText: this.$t('Eliminar'),
        cancelText: this.$t('Cancelar'),
        type: 'is-danger',
        hasIcon: false,
        onConfirm: () => {
          this.$store.dispatch('consults/deleteConsult', this.currentConsult)
          this.selectFirstConsult()
          this.$buefy.notification.open({
            message: this.$t('La consulta se ha eliminado correctamente'),
            position: 'is-top',
            hasIcon: false,
            duration: 3000,
            type: 'is-success'
          })
          this.$matomo.trackEvent(
            'Consults',
            'Click',
            'delete',
            this.currentConsult.nid
          )
        }
      })
    },
    selectFirstConsult() {
      if (this.consults.length > 0) {
        // this.currentConsult = this.consults[0]
        this.$store.dispatch('consults/setCurrentConsult', this.consults[0])
      }
    },
    downloadConsults() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.$store
        .dispatch('consults/fetchConsults', this.currentClub.nid)
        .then(() => {
          loadingComponent.close()
          this.filteredDataArray = this.consults
          this.filteredDataArray.sort(this.compare)
          this.selectFirstConsult()
        })
    },
    compare(a, b) {
      let comparison = 0
      if (a.created > b.created) {
        comparison = -1
      } else if (a.created < b.created) {
        comparison = 1
      }
      return comparison
    }
  },
  created() {
    this.validateAccess(['comunicacion'])
    this.validateRoles(['Administrador', 'Manager', 'Social'])
    this.$analytics.logEvent('Consult.vue')
    this.$store.dispatch('setShowNavigationBar', true)
    this.downloadConsults()
  },
  watch: {
    currentClub() {
      this.validateAccess(['comunicacion'])
      this.validateRoles(['Administrador', 'Manager', 'Social'])
      this.downloadConsults()
    },

    searchText() {
      if (this.filterType === 'title') {
        this.filteredDataArray = this.consults.filter(consult => {
          return (
            consult.title
              .toString()
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) >= 0
          )
        })
      } else {
        this.filteredDataArray = this.consults.filter(consult => {
          return (
            consult.name
              .toString()
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) >= 0
          )
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.row::after {
  content: '';
  clear: both;
  // display: flex;
}

[class*='col-'] {
  float: left;
  padding: 15px;
}

html {
  font-family: 'Lucida Sans', sans-serif;
}

.header {
  background-color: #9933cc;
  color: #ffffff;
  padding: 15px;
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  top: 10px;
  // height: 5vh;
  overflow: auto;
}

.menu li {
  padding: 8px;
  margin-bottom: 7px;
  background-color: #f9f9f9;
  color: #666666;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
  cursor: pointer;
}

.right li {
  background-color: #33b5e5;
  color: #fff;
}

.menu li:hover {
  background-color: #d1d1d1;
  // color: #fff;
}

.aside {
  background-color: #f3f3f3;
  padding: 15px;
  color: #666666;
  text-align: center;
  font-size: 14px;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 10px;
}

.footer {
  background-color: #0099cc;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  padding: 15px;
}

/* For desktop: */
.col-1 {
  width: 25%;
  background-color: #f9f9f9;
  border-radius: 10px;
  height: 100vh;
}

.col-2 {
  width: 50%;
  background-color: #f9f9f9;
  border-radius: 10px;
  height: 100vh;
}

.col-3 {
  width: 25%;
  background-color: #f9f9f9;
  border-radius: 10px;
  height: 100vh;
}

.consultsList {
  height: calc(100vh - 160px);
  overflow: auto;
}

.chat {
  height: calc(100vh - 60px);
  overflow: auto;
}

.active li {
  background-color: #d1d1d1;
  // color: #fff;
}

@media only screen and (max-width: 768px) {

  /* For mobile phones: */
  [class*='col-'] {
    width: 100%;
  }
}
</style>
