<template>
  <div class="sidebar-page" v-if="this.currentClub != Object">
    <div style="text-align:left;">
      <tasty-burger-button
        :type="buttonType"
        :active="isActive"
        :size="size"
        v-on:toggle="onToggle"
        style="margin-left:0px; padding:15px;"
        v-if="this.$device.ios || this.$device.android"
      />
    </div>
    <!-- <transition name="slide-fade"> -->
    <section class="sidebar-layout ">
      <b-sidebar
        position="static"
        :expand-on-hover="!this.$device.ios && !this.$device.android"
        :reduce="!this.$device.ios && !this.$device.android"
        type="is-light"
        :open="this.active"
        scroll="clip"
      >
        <!-- <b-menu :style="{ left: menuwidth }"> -->
        <!-- <div class="p-1 fullheight" style="width: 100px"> -->
        <div class="p-1 fullheight">
          <b-menu class="is-custom-mobile">
            <b-menu-list :label="$t('Gestión Club')">
              <b-menu-item
                icon="house-user"
                :active="false"
                v-if="currentClub != Object"
              >
                <template #label="props">
                  <span>{{ $t('Mis Clubes') }}</span>
                  <b-icon
                    class="is-pulled-right"
                    :icon="props.expanded ? 'caret-down' : 'caret-up'"
                  ></b-icon>
                </template>
                <b-menu-item
                  v-for="(club, index) in clubs"
                  :value="index"
                  :key="club.nid"
                  @click="selectedClub(index)"
                  :active="selectedIndex == index"
                >
                  <template #label="props">
                    <div style="display:flex">
                      <span v-if="currentClub != Object">
                        <img
                          class="minilogo"
                          :src="props.expanded ? club.logo.img : club.logo.img"
                        />
                      </span>
                      <span style="padding:7px">
                        {{ club.title }}
                      </span>
                    </div>
                  </template>
                </b-menu-item>
              </b-menu-item>
              <b-menu-item
                icon="user-circle"
                :active="false"
                :disabled="
                  !(
                    (this.currentClub.functionalities.socios ||
                      this.currentClub.functionalities.comunicacion) &&
                    this.checkRoles(['Manager', 'Administrador', 'Recepción'])
                  )
                "
              >
                <template #label="props">
                  <span>
                    {{ $t('Socios') }}
                  </span>
                  <b-icon
                    class="is-pulled-right"
                    :icon="props.expanded ? 'caret-down' : 'caret-up'"
                  ></b-icon>
                </template>

                <b-menu-item
                  @click="openPos('patientcreate')"
                  :disabled="
                    !(
                      this.currentClub.functionalities.socios ||
                      this.currentClub.functionalities.comunicacion
                    )
                  "
                >
                  <template #label="props">
                    <b-icon :icon="props.expanded ? 'plus' : 'plus'"></b-icon>
                    <span>
                      {{ $t('Añadir socio') }}
                    </span>
                  </template>
                </b-menu-item>

                <!-- <b-menu-item
                  @click="openAccessControl()"
                  :disabled="!this.currentClub.functionalities.socios"
                >
                  <template #label="props">
                    <b-icon
                      :icon="props.expanded ? 'braille' : 'braille'"
                    ></b-icon>
                    <span>
                      {{ $t('Control de acceso') }}
                    </span>
                  </template>
                </b-menu-item> -->
                <b-menu-item @click="openPos('patientmanager')">
                  <template #label="props">
                    <b-icon
                      :icon="props.expanded ? 'user-circle' : 'user-circle'"
                    ></b-icon>
                    <span>
                      {{ $t('Gestionar socios') }}
                    </span>
                  </template>
                </b-menu-item>
                <!-- <b-menu-item
              icon="user-astronaut"
              label="Gestionar cuentas"
              tag="router-link"
              to="/usersmanager"
            ></b-menu-item> -->

                <!-- <b-menu-item icon="mobile-alt">
                <template #label>
                  Devices
                  <b-dropdown
                    aria-role="list"
                    class="is-pulled-right"
                    position="is-bottom-left"
                  >
                    <template #trigger>
                      <b-icon icon="ellipsis-v"></b-icon>
                    </template>
                    <b-dropdown-item aria-role="listitem"
                      >Action</b-dropdown-item
                    >
                    <b-dropdown-item aria-role="listitem"
                      >Another action</b-dropdown-item
                    >
                    <b-dropdown-item aria-role="listitem"
                      >Something else</b-dropdown-item
                    >
                  </b-dropdown>
                </template>
              </b-menu-item> -->
              </b-menu-item>

              <b-menu-item
                icon="store"
                :active="false"
                :disabled="
                  !(
                    (this.currentClub.functionalities.software_gestion_club ||
                      this.currentClub.functionalities.socios) &&
                    this.checkRoles(['Manager', 'Administrador', 'Budtender'])
                  )
                "
              >
                <template #label="props">
                  <span>{{ $t('POS') }}</span>
                  <b-icon
                    class="is-pulled-right"
                    :icon="props.expanded ? 'caret-down' : 'caret-up'"
                  ></b-icon>
                </template>
                <b-menu-item
                  @click="openPos('pos')"
                  :disabled="
                    !(
                      this.currentClub.functionalities.software_gestion_club ||
                      this.currentClub.functionalities.socios
                    )
                  "
                >
                  <template #label="props">
                    <b-icon
                      :icon="props.expanded ? 'calculator' : 'calculator'"
                    ></b-icon>
                    <span>
                      {{ $t('POS') }}
                    </span>
                  </template></b-menu-item
                >
                <b-menu-item
                  @click="openPos('ordersInProcess')"
                  :disabled="
                    !(
                      this.currentClub.functionalities.software_gestion_club ||
                      this.currentClub.functionalities.socios
                    )
                  "
                >
                  <template #label="props">
                    <b-icon :icon="props.expanded ? 'tasks' : 'tasks'"></b-icon>
                    <span>
                      {{ $t('Pedidos en proceso') }}
                    </span>
                  </template>
                </b-menu-item>
                <!-- <b-menu-item icon="users" label="Cola" disabled></b-menu-item> -->
              </b-menu-item>

              <b-menu-item
                icon="warehouse"
                :active="false"
                :disabled="
                  !(
                    this.currentClub.functionalities.software_gestion_club &&
                    this.checkRoles([
                      'Manager',
                      'Administrador',
                      'Inventario',
                      'Budtender'
                    ])
                  )
                "
              >
                <template #label="props">
                  <span>{{ $t('Inventario') }}</span>
                  <b-icon
                    class="is-pulled-right"
                    :icon="props.expanded ? 'caret-down' : 'caret-up'"
                  ></b-icon>
                </template>
                <!-- <b-menu-item
              icon="warehouse"
              label="Almacén"
              disabled
            ></b-menu-item> -->
                <!-- <b-menu-item
              icon="barcode"
              label="Inventario"
              tag="router-link"
              to="/inventorymanager"
              :disabled="
                !this.currentClub.functionalities.software_gestion_club
              "
            ></b-menu-item> -->
                <b-menu-item
                  @click="openPos('dispensarymanagerpro')"
                  :disabled="
                    !(
                      (this.currentClub.functionalities.software_gestion_club ||
                        this.currentClub.functionalities.comunicacion) &&
                      this.checkRoles([
                        'Manager',
                        'Administrador',
                        'Budtender',
                        'Inventario'
                      ])
                    )
                  "
                >
                  <template #label="props">
                    <b-icon :icon="props.expanded ? 'joint' : 'joint'"></b-icon>
                    <span>
                      {{ $t('Productos') }}
                    </span>
                  </template>
                </b-menu-item>
                <!-- <b-menu-item
              icon="joint"
              label="Productos Basic"
              tag="router-link"
              to="/dispensarymanagerbasic"
            ></b-menu-item> -->
                <b-menu-item
                  @click="openPos('stockmanager')"
                  :disabled="
                    !(
                      this.currentClub.functionalities.software_gestion_club &&
                      this.checkRoles([
                        'Manager',
                        'Administrador',
                        'Inventario'
                      ])
                    )
                  "
                >
                  <template #label="props">
                    <b-icon
                      :icon="props.expanded ? 'chart-bar' : 'chart-bar'"
                    ></b-icon>
                    <span>
                      {{ $t('Stock') }}
                    </span>
                  </template>
                </b-menu-item>
                <!-- <b-menu-item
              icon="angle-double-right"
              label="Convertir"
              disabled
            ></b-menu-item> -->
                <!-- <b-menu-item
              icon="sort-amount-up-alt"
              label="Pedidos de compra"
              disabled
            ></b-menu-item> -->
              </b-menu-item>
              <b-menu-item
                icon="cash-register"
                :active="false"
                :disabled="
                  !(
                    (this.currentClub.functionalities.software_gestion_club ||
                      this.currentClub.functionalities.socios) &&
                    this.checkRoles(['Manager', 'Administrador', 'Caja'])
                  )
                "
              >
                <template #label="props">
                  <span>
                    {{ $t('Caja') }}
                  </span>

                  <b-icon
                    class="is-pulled-right"
                    :icon="props.expanded ? 'caret-down' : 'caret-up'"
                  ></b-icon>
                </template>
                <b-menu-item
                  @click="openCloseRegistrer()"
                  :disabled="
                    !(
                      (this.currentClub.functionalities.software_gestion_club ||
                        this.currentClub.functionalities.socios) &&
                      this.checkRoles(['Manager', 'Administrador', 'Caja'])
                    )
                  "
                >
                  <template #label="props">
                    <b-icon
                      :icon="props.expanded ? 'store-slash' : 'store-slash'"
                    ></b-icon>
                    <span>
                      {{ $t('Cerrar caja') }}
                    </span>
                  </template>
                </b-menu-item>
                <b-menu-item
                  @click="openCashMovement()"
                  :disabled="
                    !(
                      (this.currentClub.functionalities.software_gestion_club ||
                        this.currentClub.functionalities.socios) &&
                      this.checkRoles([
                        'Manager',
                        'Administrador',
                        'Caja',
                        'Budtender'
                      ])
                    )
                  "
                >
                  <template #label="props">
                    <b-icon
                      :icon="
                        props.expanded ? 'money-bill-wave' : 'money-bill-wave'
                      "
                    ></b-icon>
                    <span>
                      {{ $t('Movimiento de efectivo') }}
                    </span>
                  </template>
                </b-menu-item>
                <b-menu-item
                  @click="openPos('movementsreport')"
                  :disabled="
                    !(
                      (this.currentClub.functionalities.software_gestion_club ||
                        this.currentClub.functionalities.socios) &&
                      this.checkRoles([
                        'Manager',
                        'Administrador',
                        'Caja',
                        'Budtender'
                      ])
                    )
                  "
                >
                  <template #label="props">
                    <b-icon
                      :icon="props.expanded ? 'history' : 'history'"
                    ></b-icon>
                    <span>
                      {{ $t('Historial de movimientos') }}
                    </span>
                  </template>
                </b-menu-item>
                <b-menu-item
                  @click="openPos('closingregisterreport')"
                  :disabled="
                    !(
                      (this.currentClub.functionalities.software_gestion_club ||
                        this.currentClub.functionalities.socios) &&
                      this.checkRoles([
                        'Manager',
                        'Administrador',
                        'Caja',
                        'Budtender'
                      ])
                    )
                  "
                >
                  <template #label="props">
                    <b-icon
                      :icon="props.expanded ? 'chart-line' : 'chart-line'"
                    ></b-icon>
                    <span>
                      {{ $t('Informe cierre de caja') }}
                    </span>
                  </template>
                </b-menu-item>
                <b-menu-item
                  @click="openPos('closeregisterhistory')"
                  :disabled="
                    !(
                      (this.currentClub.functionalities.software_gestion_club ||
                        this.currentClub.functionalities.socios) &&
                      this.checkRoles([
                        'Manager',
                        'Administrador',
                        'Caja',
                        'Budtender'
                      ])
                    )
                  "
                >
                  <template #label="props">
                    <b-icon
                      :icon="props.expanded ? 'history' : 'history'"
                    ></b-icon>
                    <span>
                      {{ $t('Historial cierres de caja') }}
                    </span>
                  </template>
                </b-menu-item>
              </b-menu-item>
              <b-menu-item
                icon="tasks"
                :active="false"
                :disabled="
                  !(
                    (this.currentClub.functionalities.software_gestion_club ||
                      this.currentClub.functionalities.comunicacion ||
                      this.currentClub.functionalities.socios) &&
                    this.checkRoles(['Manager', 'Administrador'])
                  )
                "
              >
                <template #label="props">
                  <span>
                    {{ $t('Administración') }}
                  </span>
                  <b-icon
                    class="is-pulled-right"
                    :icon="props.expanded ? 'caret-down' : 'caret-up'"
                  ></b-icon>
                </template>
                <b-menu-item
                  @click="openPos('cupons')"
                  :disabled="
                    !(
                      (this.currentClub.functionalities
                        .funciones_avanzadas_gestion ||
                        this.currentClub.functionalities.cupones) &&
                      this.checkRoles(['Manager', 'Administrador'])
                    )
                  "
                >
                  <template #label="props">
                    <b-icon
                      :icon="props.expanded ? 'ticket-alt' : 'ticket-alt'"
                    ></b-icon>
                    <span>
                      {{ $t('Cupones') }}
                    </span>
                  </template>
                </b-menu-item>
                <b-menu-item
                  @click="openWalletManager()"
                  :disabled="
                    !(
                      this.currentClub.functionalities
                        .funciones_avanzadas_gestion ||
                      this.currentClub.functionalities.monedero
                    )
                  "
                >
                  <template #label="props">
                    <b-icon
                      :icon="props.expanded ? 'wallet' : 'wallet'"
                    ></b-icon>
                    <span>
                      {{ $t('Monedero') }}
                    </span>
                  </template>
                </b-menu-item>
                <b-menu-item
                  @click="openFeeCategoryManager()"
                  :disabled="
                    !(
                      this.currentClub.functionalities.software_gestion_club ||
                      this.currentClub.functionalities.socios
                    )
                  "
                >
                  <template #label="props">
                    <b-icon
                      :icon="props.expanded ? 'handshake' : 'handshake'"
                    ></b-icon>
                    <span>
                      {{ $t('Cuotas') }}
                    </span>
                  </template>
                </b-menu-item>
                <!-- <b-menu-item
              icon="cog"
              label="Configuración POS"
              disabled
            ></b-menu-item> -->
                <b-menu-item
                  @click="openPatientCategoryManager()"
                  :disabled="
                    !(
                      this.currentClub.functionalities.software_gestion_club ||
                      this.currentClub.functionalities.socios
                    )
                  "
                >
                  <template #label="props">
                    <b-icon
                      :icon="props.expanded ? 'user-tie' : 'user-tie'"
                    ></b-icon>
                    <span>
                      {{ $t('Categorías de socio') }}
                    </span>
                  </template>
                </b-menu-item>
                <b-menu-item
                  @click="openProductCategoryManager()"
                  :disabled="
                    !(
                      this.currentClub.functionalities.software_gestion_club ||
                      this.currentClub.functionalities.comunicacion
                    )
                  "
                >
                  <template #label="props">
                    <b-icon :icon="props.expanded ? 'joint' : 'joint'"></b-icon>
                    <span>
                      {{ $t('Categorías de producto') }}
                    </span>
                  </template>
                </b-menu-item>
                <b-menu-item
                  @click="openCashManager()"
                  :disabled="
                    !(
                      this.currentClub.functionalities.software_gestion_club ||
                      this.currentClub.functionalities.socios
                    )
                  "
                >
                  <template #label="props">
                    <b-icon :icon="props.expanded ? 'coins' : 'coins'"></b-icon>
                    <span>
                      {{ $t('Cantidades defecto POS') }}
                    </span>
                  </template>
                </b-menu-item>
                <!-- <b-menu-item icon="percent" label="Cupones" disabled></b-menu-item>
            <b-menu-item
              icon="award"
              label="Recompensas"
              disabled
            ></b-menu-item> -->
                <b-menu-item
                  @click="openWeightDiscrepanceManager()"
                  :disabled="
                    !this.currentClub.functionalities.software_gestion_club
                  "
                >
                  <template #label="props">
                    <b-icon
                      :icon="props.expanded ? 'percent' : 'percent'"
                    ></b-icon>
                    <span>
                      {{ $t('Discrepancia de peso') }}
                    </span>
                  </template>
                </b-menu-item>
                <b-menu-item
                  @click="openPaymentMethodsManager()"
                  :disabled="
                    !this.currentClub.functionalities.software_gestion_club
                  "
                >
                  <template #label="props">
                    <b-icon
                      :icon="props.expanded ? 'money-bill' : 'money-bill'"
                    ></b-icon>
                    <span>
                      {{ $t('Método de pago') }}
                    </span>
                  </template>
                </b-menu-item>
                <b-menu-item
                  @click="openConsumptionForecastManager()"
                  :disabled="
                    !this.currentClub.functionalities.software_gestion_club
                  "
                >
                  <template #label="props">
                    <b-icon
                      :icon="props.expanded ? 'cannabis' : 'cannabis'"
                    ></b-icon>
                    <span>
                      {{ $t('Previsión de consumo') }}
                    </span>
                  </template>
                </b-menu-item>

                <b-menu-item @click="openPos('staffmanager')">
                  <template #label="props">
                    <b-icon :icon="props.expanded ? 'users' : 'users'"></b-icon>
                    <span>
                      {{ $t('Staff') }}
                    </span>
                  </template>
                </b-menu-item>
                <!-- <b-menu-item
              icon="cart-arrow-down"
              label="Pedidos de compra"
              disabled
            ></b-menu-item> -->
                <!-- <b-menu-item
              icon="cog"
              label="Configuración general"
              disabled
            ></b-menu-item> -->

                <!-- <b-menu-item
              icon="cog"
              label="Configuración Invetario"
              disabled
            ></b-menu-item> -->
              </b-menu-item>

              <b-menu-item
                icon="flag-checkered"
                :active="false"
                :disabled="
                  !(
                    (this.currentClub.functionalities.software_gestion_club ||
                      this.currentClub.functionalities.socios) &&
                    this.checkRoles(['Manager', 'Administrador'])
                  )
                "
              >
                <template #label="props">
                  <span>
                    {{ $t('Informes') }}
                  </span>
                  <b-icon
                    class="is-pulled-right"
                    :icon="props.expanded ? 'caret-down' : 'caret-up'"
                  ></b-icon>
                </template>
                <b-menu-item
                  @click="openPos('closingreport')"
                  :disabled="
                    !(
                      this.currentClub.functionalities.software_gestion_club ||
                      this.currentClub.functionalities.socios
                    )
                  "
                >
                  <template #label="props">
                    <b-icon
                      :icon="props.expanded ? 'store-slash' : 'store-slash'"
                    ></b-icon>
                    <span>
                      {{ $t('Cierre') }}
                    </span>
                  </template>
                </b-menu-item>
                <b-menu-item
                  @click="openPos('ordersReport')"
                  :disabled="
                    !(
                      this.currentClub.functionalities.software_gestion_club ||
                      this.currentClub.functionalities.socios
                    )
                  "
                >
                  <template #label="props">
                    <b-icon :icon="props.expanded ? 'tasks' : 'tasks'"></b-icon>
                    <span>
                      {{ $t('Pedidos') }}
                    </span>
                  </template>
                </b-menu-item>
                <b-menu-item
                  @click="openPos('dispensationReport')"
                  :disabled="
                    !this.currentClub.functionalities.software_gestion_club
                  "
                >
                  <template #label="props">
                    <b-icon :icon="props.expanded ? 'pills' : 'pills'"></b-icon>
                    <span>
                      {{ $t('Retiradas') }}
                    </span>
                  </template>
                </b-menu-item>
                <b-menu-item
                  @click="openPos('efficiencyReport')"
                  :disabled="
                    !this.currentClub.functionalities.software_gestion_club
                  "
                >
                  <template #label="props">
                    <b-icon
                      :icon="props.expanded ? 'chart-line' : 'chart-line'"
                    ></b-icon>
                    <span>
                      {{ $t('Rendimiento') }}
                    </span>
                  </template>
                </b-menu-item>
                <b-menu-item
                  @click="openPos('walletReport')"
                  :disabled="
                    !(
                      (this.currentClub.functionalities
                        .funciones_avanzadas_gestion ||
                        this.currentClub.functionalities.monedero) &&
                      walletPermission
                    )
                  "
                >
                  <template #label="props">
                    <b-icon
                      :icon="props.expanded ? 'wallet' : 'wallet'"
                    ></b-icon>
                    <span>
                      {{ $t('Monederos') }}
                    </span>
                  </template>
                </b-menu-item>
                <b-menu-item
                  @click="openPos('accessControlReport')"
                  :active="false"
                  :disabled="
                    !this.currentClub.functionalities.software_gestion_club
                  "
                >
                  <template #label="props">
                    <b-icon
                      :icon="props.expanded ? 'receipt' : 'receipt'"
                    ></b-icon>
                    <span>
                      {{ $t('Control de acceso') }}
                    </span>
                  </template>
                </b-menu-item>

                <b-menu-item
                  @click="openPos('stockHistoryReport')"
                  :active="false"
                  :disabled="
                    !this.currentClub.functionalities.software_gestion_club
                  "
                >
                  <template #label="props">
                    <b-icon
                      :icon="props.expanded ? 'chart-bar' : 'chart-bar'"
                    ></b-icon>
                    <span>
                      {{ $t('Movimientos de stock') }}
                    </span>
                  </template>
                </b-menu-item>

                <!-- <b-menu-item
              icon="chart-bar"
              label="Inventario"
              disabled
            ></b-menu-item> -->
              </b-menu-item>
            </b-menu-list>

            <b-menu-list :label="$t('Social')">
              <b-menu-item
                icon="mobile"
                :active="false"
                :disabled="
                  !(
                    this.currentClub.functionalities.comunicacion &&
                    this.checkRoles(['Manager', 'Administrador', 'Social'])
                  )
                "
              >
                <template #label="props">
                  <span>
                    {{ $t('Comunicación') }}
                  </span>
                  <b-icon
                    class="is-pulled-right"
                    :icon="props.expanded ? 'caret-down' : 'caret-up'"
                  ></b-icon>
                </template>
                <b-menu-item
                  @click="openPos('clubmanager')"
                  :disabled="
                    !(
                      this.currentClub.functionalities.comunicacion &&
                      this.checkRoles(['Manager', 'Administrador', 'Social'])
                    )
                  "
                >
                  <template #label="props">
                    <b-icon
                      :icon="props.expanded ? 'address-book' : 'address-book'"
                    ></b-icon>
                    <span>
                      {{ $t('Bio Cub') }}
                    </span>
                  </template>
                </b-menu-item>
                <!-- <b-menu-item
              icon="bong"
              :label="$t('Dispensario')"
              tag="router-link"
              to="/dispensary"
              :disabled="
                !(
                  this.currentClub.functionalities.comunicacion &&
                  this.checkRoles(['Manager', 'Administrador', 'Social'])
                )
              "
            ></b-menu-item> -->
                <b-menu-item
                  @click="openPos('tvshows')"
                  :disabled="
                    !(
                      this.currentClub.functionalities.comunicacion_avanzada &&
                      this.checkRoles(['Manager', 'Administrador', 'Social'])
                    )
                  "
                >
                  <template #label="props">
                    <b-icon :icon="props.expanded ? 'tv' : 'tv'"></b-icon>
                    <span>
                      {{ $t('TV Shows') }}
                    </span>
                  </template>
                </b-menu-item>
                <b-menu-item
                  @click="openPos('news')"
                  :disabled="
                    !(
                      this.currentClub.functionalities.comunicacion &&
                      this.checkRoles(['Manager', 'Administrador', 'Social'])
                    )
                  "
                >
                  <template #label="props">
                    <b-icon
                      :icon="props.expanded ? 'newspaper' : 'newspaper'"
                    ></b-icon>
                    <span>
                      {{ $t('Noticias') }}
                    </span>
                  </template>
                </b-menu-item>
                <b-menu-item
                  @click="openPos('events')"
                  :disabled="
                    !(
                      this.currentClub.functionalities.comunicacion &&
                      this.checkRoles(['Manager', 'Administrador', 'Social'])
                    )
                  "
                >
                  <template #label="props">
                    <b-icon
                      :icon="props.expanded ? 'calendar-alt' : 'calendar-alt'"
                    ></b-icon>
                    <span>
                      {{ $t('Eventos') }}
                    </span>
                  </template>
                </b-menu-item>
                <b-menu-item
                  @click="openPos('consults')"
                  :disabled="
                    !(
                      this.currentClub.functionalities.comunicacion &&
                      this.checkRoles(['Manager', 'Administrador', 'Social'])
                    )
                  "
                >
                  <template #label="props">
                    <b-icon
                      :icon="props.expanded ? 'comments' : 'comments'"
                    ></b-icon>
                    <span>
                      {{ $t('Consultas') }}
                    </span>
                  </template>
                </b-menu-item>
                <!-- <b-menu-item icon="sms" label="Campañas SMS" disabled></b-menu-item> -->
                <!-- <b-menu-item
              icon="bell"
              label="Campañas Push"
              tag="router-link"
              to="/pushnotificationsmanager"
              :disabled="!this.currentClub.functionalities.comunicacion"
            ></b-menu-item> -->
              </b-menu-item>

              <!-- <b-menu-item icon="plug" :active="false">
            <template #label="props">
              Gestiones online
              <b-icon
                class="is-pulled-right"
                :icon="props.expanded ? 'caret-down' : 'caret-up'"
              ></b-icon>
            </template>
            <b-menu-item
              icon="dolly-flatbed"
              label="Reservas Online"
              disabled
            ></b-menu-item>
            <b-menu-item
              icon="share-square"
              label="Pago cuotas"
              disabled
            ></b-menu-item>
            <b-menu-item
              icon="wallet"
              label="Recarga monedero"
              disabled
            ></b-menu-item>
          </b-menu-item> -->

              <b-menu-item icon="user-alt" :active="false">
                <template #label="props">
                  <span>
                    {{ $t('Mi Perfil') }}
                  </span>
                  <b-icon
                    class="is-pulled-right"
                    :icon="props.expanded ? 'caret-down' : 'caret-up'"
                  ></b-icon>
                </template>

                <!-- <b-menu-item
              icon="address-card"
              label="Mi carnet de socio"
              tag="router-link"
              to="/userprofile"
              :disabled="
                !this.currentClub.functionalities.gestion_avanzada_socios
              "
            ></b-menu-item> -->
                <!-- <b-menu-item
              icon="chart-bar"
              label="Mis pedidos"
              disabled
            ></b-menu-item> -->
                <b-menu-item disabled>
                  <template #label="props">
                    <b-icon
                      :icon="props.expanded ? 'wallet' : 'wallet'"
                    ></b-icon>
                    <span>
                      {{ $t('Cuenta de usuario') }}
                    </span>
                  </template>
                </b-menu-item>
                <b-menu-item
                  @click="openLanguageSelector()"
                  :disabled="!this.currentClub.functionalities.comunicacion"
                >
                  <template #label="props">
                    <b-icon
                      :icon="
                        props.expanded ? 'globe-americas' : 'globe-americas'
                      "
                    ></b-icon>
                    <span>
                      {{ $t('Idioma') }}
                    </span>
                  </template>
                </b-menu-item>
                <b-menu-item @click="changePassword()">
                  <template #label="props">
                    <b-icon :icon="props.expanded ? 'key' : 'key'"></b-icon>
                    <span>
                      {{ $t('Modificar contraseña') }}
                    </span>
                  </template>
                </b-menu-item>
                <b-menu-item @click="closeSession()">
                  <template #label="props">
                    <b-icon
                      :icon="props.expanded ? 'sign-out-alt' : 'sign-out-alt'"
                    ></b-icon>
                    <span>
                      {{ $t('Cerrar sesión') }}
                    </span>
                  </template>
                </b-menu-item>
              </b-menu-item>
            </b-menu-list>
            <b-menu-list :label="$t('Proveedores')">
              <b-menu-item @click="openHighSupply()">
                <template #label="props">
                  <b-icon :icon="props.expanded ? 'rocket' : 'rocket'"></b-icon>
                  <span>
                    HighSupply
                  </span>
                </template>
              </b-menu-item>
            </b-menu-list>

            <b-menu-list label="Smokapp">
              <b-menu-item @click="$router.push('novelties')">
                <template #label="props">
                  <b-icon
                    v-if="newNovelties"
                    type="is-danger"
                    :icon="props.expanded ? 'bell' : 'bell'"
                  ></b-icon>
                  <b-icon
                    v-else
                    :icon="props.expanded ? 'bell' : 'bell'"
                  ></b-icon>
                  <span>
                    {{ $t('Novedades') }}
                  </span>
                </template>
              </b-menu-item>
            </b-menu-list>

            <div v-if="myMembershipCard">
              <hr />

              <img
                v-if="myMembershipCard.foto"
                class="ml-2 minifoto"
                :src="myMembershipCard.foto.url"
              />
              <!-- <img
            v-if="myMembershipCard.foto"
            class="imgRigth ml-2"
            style="border-radius:50%; height: 50px; width: 50px; object-fit:cover;"
            v-auth-image="myMembershipCard.foto.url"
          /> -->
              <!-- {{ myMembershipCard.foto.url }} -->
              <h1 class="username ml-2">
                {{ myMembershipCard.nombre }}
                {{ myMembershipCard.surname }}
              </h1>
              <h2 class="userdni ml-2">
                {{ myMembershipCard.dni }}
              </h2>
            </div>
          </b-menu>
        </div>
      </b-sidebar>
    </section>
    <!-- </transition> -->
  </div>
</template>

<script>
import { InitViewMixin } from '@/mixins/InitViewMixin.js'
import { mapState } from 'vuex'
import WalletCategoriesManager from '@/views/Club/WalletCategoriesManager.vue'
import FeeCategoriesManager from '@/components/FeeCategoriesManager.vue'
import CashCategoriesManager from '@/components/CashCategoriesManager.vue'
import LanguageSelector from '@/components/LanguageSelector.vue'
import AccessControl from '@/views/AccessControl/AccessControl.vue'
import PatientCategories from '@/views/Users/PatientCategories.vue'
import DispensaryCategories from '@/views/Dispensary/DispensaryCategories.vue'
import CloseRegister from '@/views/Register/CloseRegister.vue'
import CashMovement from '@/views/Register/CashMovement.vue'
import APIPatients from '@/services/APIPatients.js'
import tools from '@/Tools/tools.js'
import ConsumptionForecastManager from '../views/Club/ConsumptionForecastManager.vue'
import WeightDiscrepancyManager from '../views/Club/WeightDiscrepancyManager.vue'
import PaymentMethodsManager from '../views/Club/PaymentMethodsManager.vue'
import * as versionjson from '@/version.json'

export default {
  data() {
    return {
      buttonType: 'elastic',
      size: 'xs',
      color: 'green',
      activeColor: 'orange',
      active: false,
      isActive: false
    }
  },
  mixins: [InitViewMixin],
  computed: {
    ...mapState(['sideMenuIsActive']),
    ...mapState('patients', ['myMembershipCard']),
    ...mapState('club', [
      'cashPermission',
      'cardPermission',
      'walletPermission',
      'newNovelties'
    ]),
    sociosorcomunicacion() {
      console.log(this.currentClub.functionalities.socios)
      console.log(this.currentClub.functionalities.comunicacion)
      console.log(
        !(
          this.currentClub.functionalities.socios ||
          this.currentClub.functionalities.comunicacion
        )
      )
      return !(
        this.currentClub.functionalities.socios ||
        this.currentClub.functionalities.comunicacion
      )
    }
  },

  methods: {
    changePassword() {
      this.updateSidebarState()

      this.$buefy.dialog.prompt({
        message: this.$t('Introduzca su actual contraseña'),
        confirmText: this.$t('Continuar'),
        inputAttrs: {
          type: 'password',
          maxlength: 30,
          passwordreveal: true
        },
        trapFocus: true,
        onConfirm: old => {
          this.$buefy.dialog.prompt({
            message: this.$t('Introduzca la NUEVA contraseña'),
            confirmText: this.$t('Cambiar'),
            inputAttrs: {
              maxlength: 30
            },
            trapFocus: true,
            onConfirm: value => {
              APIPatients.changePassword(old, value)
                .then(response => {})
                .catch(error => {
                  tools.manageAxiosError(error)
                  console.log('Ha habido un error:' + error)
                })

              // this.$buefy.toast.open(`Su nueva contraseña es: ${value}`)
            }
          })
        }
      })
    },
    openPos(page) {
      this.updateSidebarState()

      this.$router.replace(page)
    },

    openConsumptionForecastManager() {
      this.updateSidebarState()
      this.$buefy.modal.open({
        parent: this,
        component: ConsumptionForecastManager,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true
      })
    },
    openWeightDiscrepanceManager() {
      this.updateSidebarState()
      this.$buefy.modal.open({
        parent: this,
        component: WeightDiscrepancyManager,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true
      })
    },
    openPaymentMethodsManager() {
      this.updateSidebarState()
      this.$buefy.modal.open({
        parent: this,
        component: PaymentMethodsManager,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true
      })
    },
    openProductCategoryManager() {
      this.updateSidebarState()
      this.$buefy.modal.open({
        parent: this,
        component: DispensaryCategories,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true
      })
    },
    openPatientCategoryManager() {
      this.updateSidebarState()
      this.$buefy.modal.open({
        parent: this,
        component: PatientCategories,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true
      })
    },
    openAccessControl() {
      this.updateSidebarState()
      this.$buefy.modal.open({
        parent: this,
        component: AccessControl,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
        props: {
          users: this.users
        },
        events: {
          seletedUsrs: value => {
            console.log(value)
            // this.selectedUsers = value
          }
        }
      })
    },
    openCloseRegistrer() {
      this.updateSidebarState()
      this.$buefy.modal.open({
        parent: this,
        component: CloseRegister,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
        props: {
          currentPatient: this.currentUser,
          currentClub: this.currentClub
        },
        onCancel: function() {},
        events: {
          onCapture: value => {}
        }
      })
    },
    openCashMovement() {
      this.updateSidebarState()
      this.$buefy.modal.open({
        parent: this,
        component: CashMovement,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
        props: {
          currentPatient: this.currentUser,
          currentClub: this.currentClub
        },
        onCancel: function() {},
        events: {
          onCapture: value => {}
        }
      })
    },
    openCashManager() {
      this.updateSidebarState()
      this.$buefy.modal.open({
        parent: this,
        component: CashCategoriesManager,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
        props: {
          currentPatient: this.currentUser,
          currentClub: this.currentClub
        },
        onCancel: function() {},
        events: {
          onCapture: value => {}
        }
      })
    },
    openWalletManager() {
      this.updateSidebarState()

      this.$buefy.modal.open({
        parent: this,
        component: WalletCategoriesManager,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
        props: {
          currentPatient: this.currentUser,
          currentClub: this.currentClub
        },
        onCancel: function() {},
        events: {
          onCapture: value => {}
        }
      })
    },
    openFeeCategoryManager() {
      this.updateSidebarState()
      this.$buefy.modal.open({
        parent: this,
        component: FeeCategoriesManager,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
        props: {
          currentPatient: this.currentUser,
          currentClub: this.currentClub
        },
        onCancel: function() {},
        events: {
          onCapture: value => {}
        }
      })
    },
    openLanguageSelector() {
      this.updateSidebarState()
      this.$buefy.modal.open({
        parent: this,
        component: LanguageSelector,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true
      })
    },
    openHighSupply() {
      this.updateSidebarState()
      window.open('https://highsupply.es')
      this.$matomo.trackEvent('HighSupply', 'Click', 'link', 1)
    },
    updateSidebarState() {
      if (this.$device.ios || this.$device.android) {
        this.isActive = !this.isActive
        this.active = !this.active
      }
    },
    onToggle(active) {
      this.active = active
      this.$store.commit('SET_SIDE_MENU_IS_ACTIVE', active)
    },
    closeSession() {
      this.updateSidebarState()
      this.$store.dispatch('auth/doLogout').then(() => {
        this.$router.replace('/')
      })
    }
  },
  created() {
    if (this.$route.name !== 'Login') {
      this.initview()
    }
    if (!this.$device.ios && !this.$device.android) {
      this.active = true
    }
  },
  mounted() {
    this.$store.dispatch(
      'club/setNovelties',
      versionjson.default.version !=
        localStorage.getItem('novelties_current_version')
    )
  }
  // watch: {
  //   currentClub() {
  //     console.log('SELECTED CLUB:')
  //     console.log(this.currentClub)
  //     // this.downloadMyMembershipCard()
  //   }
  // }
}
</script>

<style lang="scss" scoped>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  // transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  transition: all 0.1s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-10px);
  opacity: 0;
}

.p-1 {
  padding: 1em;
  height: 100vh;
}
.sidebar-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  text-align: left;

  // min-height: 100vh;
  .sidebar-layout {
    display: flex;
    flex-direction: row;
    min-height: 100%;

    // min-height: 100vh;
  }

  .fullheight {
    height: 100vh !important;
    // overflow: scroll;
    overflow-y: scroll;
  }
  .test {
    max-height: 10px !important;
    // overflow: scroll;
  }
}

// @media screen and (max-width: 1023px) {
//   .b-sidebar {
//     .sidebar-content {
//       &.is-mini-mobile {
//         &:not(.is-mini-expand),
//         &.is-mini-expand:not(:hover):not(.is-mini-delayed) {
//           .menu-list {
//             li {
//               a {
//                 span:nth-child(2) {
//                   display: none;
//                 }
//               }
//               ul {
//                 padding-left: 0;
//                 li {
//                   a {
//                     display: inline-block;
//                   }
//                 }
//               }
//             }
//           }
//           .menu-label:not(:last-child) {
//             margin-bottom: 0;
//           }
//         }
//       }
//     }
//   }
// }
// @media screen and (min-width: 1024px) {
.b-sidebar {
  padding-left: 2px;
  padding-top: 5px;
  .sidebar-content {
    &.is-mini {
      &:not(.is-mini-expand),
      &.is-mini-expand:not(:hover):not(.is-mini-delayed) {
        .username {
          display: none;
        }
        .userdni {
          display: none;
        }
        .menu-list {
          li {
            a {
              span:nth-child(1) {
                margin-left: -7px;
              }
              span:nth-child(2) {
                display: none;
              }
              span:nth-child(3) {
                display: none;
              }
            }
          }
        }
        .menu-label {
          overflow: hidden;
          white-space: nowrap; /* Don't forget this one */
          text-overflow: ellipsis;
        }
        .menu-label:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }
  }
}
// }

.is-mini-expand {
  .menu-list a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.minilogo {
  height: auto;
  width: 30px;
  border-radius: 50%;
}

.minifoto {
  border-radius: 50%;
  height: 55px;
  width: 55px;
  object-fit: cover;
  border: 3px solid rgb(129, 191, 179);
}
</style>
