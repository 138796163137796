import {
  SET_DISPENSARY,
  SET_DISPENSARY_ITEM,
  CLEAR_DISPENSARY,
  CLEAR_DISPENSARY_ITEM,
  SET_CURRENT_LIST,
  CLEAR_CURRENT_LIST
} from './types'
import APIService from '@/services/APIService.js'
import tools from '@/Tools/tools.js'

const initialState = {
  dispensary: [],
  dispensary_item: Object,
  current_list_index: 0
}
const getters = {
  getItemById: state => id => {
    if (initialState.dispensary == []) {
      return initialState.dispensary[initialState.current_list_index].item.find(
        item => item.nid === id
      )
    }
    return false
  }
}

const mutations = {
  [SET_DISPENSARY](state, dispensary) {
    state.dispensary = dispensary
  },
  [SET_DISPENSARY_ITEM](state, dispensaryItem) {
    state.dispensary_item = dispensaryItem
  },
  [SET_CURRENT_LIST](state, current_list_index) {
    state.current_list_index = current_list_index
  },
  [CLEAR_DISPENSARY](state) {
    state.dispensary = []
  },
  [CLEAR_DISPENSARY_ITEM](state) {
    state.dispensary_item = Object
  },
  [CLEAR_CURRENT_LIST](state) {
    state.current_list_index = 0
  }
}
const actions = {
  initialize({ commit, state }) {},

  fetchDispensary({ commit }, asocid) {
    if (initialState.dispensary.length) {
      return initialState.dispensary
    }
    let promise = APIService.dispensary(asocid)
      .then(response => {
        commit(SET_DISPENSARY, response.data)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  fetchDispensaryItem({ commit, getters }, { asocid, itemId }) {
    var item = getters.getItemById(itemId, initialState.current_list_index)
    if (item) {
      commit(SET_DISPENSARY_ITEM, item)
      return
    }

    commit(CLEAR_DISPENSARY_ITEM)
    let promise = APIService.getDispensaryItem(asocid, itemId)
      .then(response => {
        commit(SET_DISPENSARY_ITEM, response.data)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  createProduct(
    { commit },
    { asocid, listnid, title, subtitle, body, images }
  ) {
    let json = {
      cid: asocid,
      title: title,
      subtitle: subtitle,
      body: body,
      images: images,
      lid: listnid
    }
    console.log(json)
    let promise = APIService.createDispensaryProduct(json)
      .then(response => {
        // Añadir nuevo item al store
        // commit(SET_USERS, response.data)
        TODO: console.log(response)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  clearDispensary({ commit }) {
    commit(CLEAR_DISPENSARY)
  },
  setCurrentListIndex({ commit }, current_list_index) {
    commit(SET_CURRENT_LIST, current_list_index)
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}
