<template>
  <div>
    <header class="centered">
      <b-field label="Enviar notificación push">
        <b-input v-model="body" maxlength="200" type="textarea" placeholder="Escribe aquí el mensaje"></b-input>
      </b-field>
    </header>

    <body>
      <span v-if="this.selectedUsers.length == 0">
        <b-button @click="showSelectUsers()"
          style="width:80%; height:150px; top:10px; background: #C1DAE5; color: #000000"><i
            class="fas fa-paper-plane fa-4x"></i>
          <h4>Seleccionar socios</h4>
        </b-button>
      </span>
      <span v-if="this.selectedUsers.length > 0">
        <h6>Vamos a enviar una notificación a los siguientes socios:</h6>
        <section>
          <span class="field" v-for="usr in this.selectedUsers" :key="usr.uid" :value="usr">
            <b-tag type="is-primary" closable aria-close-label="Close tag" @close="closeTag(usr)">
              {{ usr.name }}
            </b-tag>
          </span>
        </section>
        <b-button @click="sendPushToSelectedUsers()"
          style="width:80%; height:150px; top:10px; background: #247ba0;; color: #ffffff">
          <i class="fas fa-paper-plane fa-2x"></i>
          <h4>Enviar</h4>
        </b-button>
      </span>
      <div v-if="this.selectedUsers.length == 0">
        <b-button @click="sendMassivePush()"
          style="width:80%; height:150px; top:30px; background: #118675;; color: #ffffff"><i
            class="fas fa-flag-checkered fa-4x"></i>
          <h4>Notificación masiva</h4>
        </b-button>
      </div>
    </body>
  </div>
</template>

<script>
import APINotifications from '@/services/APINotifications.js'
import { mapState } from 'vuex'
import UsersList from '@/components/UsersList.vue'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'

export default {
  data() {
    return {
      body: '',
      selectedUsers: []
    }
  },
  mixins: [InitViewMixin],
  computed: {
    ...mapState('club', ['currentClub']),
    ...mapState('users', ['users'])
  },
  methods: {
    closeTag(usr) {
      this.selectedUsers.splice(this.selectedUsers.indexOf(usr), 1)
    },
    sendMassivePush() {
      if (!this.validateText()) {
        return
      }
      this.$buefy.dialog.confirm({
        title: 'Envío masivo',
        message:
          '¿Estás seguro de que quieres <b>ENVIAR</b> una notificación a todos los socios del club?',
        confirmText: 'Enviar',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: false,
        onConfirm: () => {
          if (this.users == 0) {
            this.$store
              .dispatch('users/fetchUsers', this.currentClub.nid)
              .then(response => {
                this.sendPushToUsers(this.users)
              })
          } else {
            this.sendPushToUsers(this.users)
          }
        }
      })
    },

    showSelectUsers() {
      if (!this.validateText()) {
        return
      }
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.$store
        .dispatch('users/fetchUsers', this.currentClub.nid)
        .then(response => {
          loadingComponent.close()
          console.log(this.users)
          this.$buefy.modal.open({
            parent: this,
            component: UsersList,
            hasModalCard: true,
            customClass: 'custom-class custom-class-2',
            trapFocus: true,
            props: {
              users: this.users
            },
            events: {
              seletedUsrs: value => {
                console.log(value)
                this.selectedUsers = value
              }
            }
          })
        })
        .catch(error => {
          console.log('Ha habido un error:' + error)
          loadingComponent.close()
        })
    },

    sendPushToSelectedUsers() {
      this.sendPushToUsers(this.selectedUsers)
    },

    sendPushToUsers(users) {
      let uids = []
      users.forEach(usr => {
        uids.push(usr.uid)
      })
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      APINotifications.sendPushNotification(
        this.currentClub.nid,
        this.body,
        uids
      )
        .then(response => {
          this.selectedUsers = []
          this.body = ''
          loadingComponent.close()
          this.$buefy.notification.open({
            message: $t('Notificación enviada correctamente'),
            position: 'is-top',
            type: 'is-success',
            hasIcon: false,
            duration: 5000
          })
          this.$matomo.trackEvent(
            'PushNotificationManger',
            'Click',
            'sendPush',
            users.length
          )
        })
        .catch(error => {
          console.log('Ha habido un error:' + error)
          loadingComponent.close()
          this.$buefy.notification.open({
            message: error,
            position: 'is-top-right',
            type: 'is-danger',
            hasIcon: false,
            duration: 5000
          })
        })
    },

    validateText() {
      if (this.body == '') {
        this.$buefy.notification.open({
          message: 'Tienes que escribir algo antes',
          position: 'is-top-right',
          type: 'is-danger',
          hasIcon: false
        })
        return false
      }
      return true
    },
    fetchUsers() {
      this.$store
        .dispatch('users/fetchUsers', this.currentClub.nid)
        .then(response => { })
    }
  },
  created() {
    this.$analytics.logEvent('PushNotificationManager.vue')
    this.$store.dispatch('setShowNavigationBar', true)

    this.fetchUsers()
  },
  watch: {
    currentClub() {
      this.fetchUsers()
    }
  }
}
</script>

<style lang="scss" scoped>
.centered {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}
</style>
