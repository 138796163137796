<template>
  <div>
    <!-- <div class="header">
      <h1>Chania</h1>
    </div> -->
    <div v-if="showQRReader">
      <QRBarCodeReader @qrResult="qrResult"> </QRBarCodeReader>
    </div>
    <div class="row">
      <div class="col-2" style="text-align:left">
        <section>
          <h4 v-if="isUpdating">{{ $t('Modificar producto') }}</h4>
          <h4 v-else>{{ $t('Crear nuevo producto') }}</h4>
          <b-button icon-left="cannabis" @click="showVarietiesRepository()">{{
            $t('Repositorio de variedades')
          }}</b-button>
          <b-field :label="$t('Información del producto')" style="color:#fff;">
            <b-field>
              <b-button
                @click="qrtoggle()"
                style="margin-top:5px; margin-left:3px; width:30px;  height:30px; background:#ffffff; color:#000000"
                ><i class="fas fa-barcode fa"></i>
              </b-button>
              <b-input ref="code" v-model="code" :placeholder="$t('Código')">
              </b-input>
            </b-field>
            <b-field>
              <b-input
                ref="title"
                v-model="title"
                :placeholder="$t('Título')"
                required
                :validation-message="$t('Obligatorio')"
                :autofocus="true"
              >
              </b-input>
            </b-field>
            <b-field>
              <b-select
                :placeholder="$t('Dominacia')"
                icon="cannabis"
                v-model="dominance"
                ref="dominance"
              >
                <option value="indica">{{ $t('Índica') }}</option>
                <option value="sativa">{{ $t('Sativa') }}</option>
                <option value="hibrido">{{ $t('Hibrido') }}</option>
              </b-select>
            </b-field>
            <b-field>
              <b-select
                :placeholder="$t('Categoría')"
                required
                v-model="category"
                ref="category"
                :validation-message="$t('Obligatorio')"
              >
                <option
                  v-for="(category, i) in categories"
                  :key="i"
                  :value="category"
                >
                  {{ category.title }}
                </option>
              </b-select>
            </b-field>
          </b-field>

          <b-field :label="$t('Descripción del producto')" style="color:#fff;">
            <b-input
              v-model="body"
              :placeholder="$t('Escribe la descripción del producto')"
              type="textarea"
            ></b-input>
          </b-field>

          <div style="color:#fff;">
            <b-field :label="$t('Precio')">
              <b-input
                ref="price"
                v-model="price"
                :placeholder="$t('Precio')"
                type="number"
                step=".01"
                min="0"
                required
                :validation-message="$t('Obligatorio')"
              >
              </b-input>
            </b-field>
            <b-field :label="$t('Coste')">
              <b-input
                v-model="cost"
                :placeholder="$t('Coste')"
                type="number"
                min="0"
                step=".01"
              >
              </b-input>
            </b-field>

            <!-- <b-field :label="$t('Stock')">
              <b-input
                ref="stock"
                v-model="stock"
                placeholder="Stock"
                type="number"
                step=".01"
                min="0"
                required
                :validation-message="$t('Obligatorio')"
              >
              </b-input>
            </b-field> -->
            <b-field :label="$t('Tipo')" class="mb-4">
              <b-select
                :placeholder="$t('Tipo')"
                v-model="type"
                ref="type"
                required
                :validation-message="$t('Obligatorio')"
              >
                <option value="peso">{{ $t('A peso') }}</option>
                <option value="unidad">{{ $t('Por unidades') }}</option>
                <option value="ambos">{{ $t('Ambos') }}</option>
              </b-select>
            </b-field>
            <b-field
              v-if="type === 'ambos'"
              :label="$t('Peso por unidad')"
              class="mb-4"
            >
              <b-input
                ref="unit_weight"
                v-model="unit_weight"
                :placeholder="$t('Peso por unidad')"
                type="number"
                step=".01"
                min="0"
                required
                :validation-message="$t('Obligatorio')"
              >
              </b-input>
            </b-field>
            <b-field
              :label="$t('Peso del bote')"
              v-if="this.type === 'peso'"
              class="mb-4"
            >
              <b-input
                v-model="jarWeight"
                :placeholder="$t('Peso del bote')"
                type="number"
                min="0"
                step=".01"
              >
              </b-input>
            </b-field>
          </div>
          <b-field>
            <b-switch v-model="unlimited_stock">
              {{ $t('Stock ilimitado') }}
            </b-switch>
          </b-field>
          <b-field>
            <b-switch v-model="inventory_tracking">
              {{ $t('Hacer seguimiento') }}
            </b-switch>
          </b-field>

          <b-field :label="$t('Añade unas imágenes del producto')">
            <b-upload v-model="dropFiles" multiple drag-drop>
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="upload" size="is-large"> </b-icon>
                  </p>
                  <p>
                    {{
                      $t('Arrastra aquí las imágenes o pulsa para selccionar')
                    }}
                  </p>
                </div>
              </section>
            </b-upload>
          </b-field>

          <div class="tags">
            <span
              v-for="(file, index) in dropFiles"
              :key="index"
              class="tag is-primary"
            >
              {{ file.name }}
              <button
                class="delete is-small"
                type="button"
                @click="deleteDropFile(index)"
              ></button>
            </span>
          </div>

          <b-field :label="$t('Propiedades')">
            <div style="display:flex;">
              <b-switch v-model="status"> {{ $t('Publicar') }} </b-switch>
              <b-switch v-model="thc"> {{ $t('Tiene THC') }}</b-switch>
              <b-switch v-model="menu_export">
                {{ $t('Exportar menu') }}</b-switch
              >
              <b-switch v-model="discount">
                {{ $t('Tiene Descuento') }}</b-switch
              >
            </div>
          </b-field>
        </section>

        <div class="mt-5">
          <b-button
            v-if="isUpdating"
            type="is-primary"
            class="bt-3"
            @click="publishProduct()"
          >
            {{ $t('Modificar Producto') }}</b-button
          >
          <b-button
            v-else
            type="is-primary"
            class="bt-3"
            @click="publishProduct()"
          >
            {{ $t('Publicar Producto') }}</b-button
          >
        </div>
      </div>

      <div class="col-3 actions">
        <h5>{{ $t('Previsualización del producto') }}</h5>
        <hr />
        <div class="aside">
          <div v-if="dropFiles.length > 0">
            <img :src="fileToImage(dropFiles[0])" />
          </div>
          <div v-else>
            <div v-if="image_urls.length > 0">
              <img
                class="imgRigth"
                style="border-radius:50%; margin-top:20px; max-height:20vh"
                :src="image_urls[0].img"
              />
            </div>
          </div>
          <h3>{{ title }}</h3>
          <p v-html="body"></p>
        </div>

        <div class="menu right">
          <!-- <ul>
            <li style="background-color: #717a81; color:#fff;">
              <div @click="createNews()">
                <h6>Publicar nueva noticia</h6>
                <p>Publica una nueva noticia en tu club</p>
              </div>
            </li>
            <li style="background-color: #ed6a5a; color:#fff">
              <h6>Eliminar noticia</h6>
            </li>
          </ul> -->
        </div>
      </div>
    </div>

    <!-- <div class="footer">
      <p>
        Resize the browser window to see how the content respond to the
        resizing.
      </p>
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'
import VarietiesRepository from '@/components/VarietiesRepository.vue'
import QRBarCodeReader from '@/components/QRBarCodeReader.vue'
import tools from '@/Tools/tools.js'

export default {
  data() {
    return {
      unlimited_stock: false,
      dropFiles: [],
      title: '',
      body: '',
      price: undefined,
      cost: '',
      jarWeight: undefined,
      code: '',
      // stock: '',
      unit_weight: '',
      dominance: null,
      type: null,
      thc: true,
      menu_export: true,
      status: true,
      inventory_tracking: true,
      images: [],
      image_urls: [],
      category: null,
      disabledCategorySelector: true,
      discount: false,
      isUpdating: false,
      showQRReader: false
    }
  },
  mixins: [InitViewMixin],
  computed: {
    ...mapState('dispensaryBasic', ['dispensary']),
    ...mapState('dispensaryPro', ['categories'])
  },
  components: {
    QRBarCodeReader
  },
  methods: {
    qrtoggle() {
      this.$matomo.trackEvent('ProductPicker', 'Click', 'qrReader', 1)
      if (!this.showQRReader) {
        //this.qrButtonTitle = 'Cerrar QR'
      } else {
        //this.qrButtonTitle = 'Validar mediante QR'
      }
      this.showQRReader = !this.showQRReader
    },
    qrResult(result) {
      console.log('este es el resultado')
      console.log(result)
      this.code = result
      this.showQRReader = !this.showQRReader
    },
    showVarietiesRepository() {
      this.$buefy.modal.open({
        parent: this,
        component: VarietiesRepository,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
        events: {
          seletedProduct: preset => {
            this.title = preset.title
            this.body = preset.body
            this.dominance = preset.dominance
            console.log(preset.dominance)
          }
        }
      })
    },
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1)
    },
    showAdvancedBodyEditor() {
      this.$router.push({ name: 'htmleditor' })
    },
    fileToImage(file) {
      return URL.createObjectURL(file)
    },
    publishProduct() {
      if (
        // this.$refs.code.checkHtml5Validity() &&
        this.$refs.title.checkHtml5Validity() &&
        this.$refs.dominance.checkHtml5Validity() &&
        this.$refs.category.checkHtml5Validity() &&
        this.$refs.price.checkHtml5Validity() &&
        // this.$refs.stock.checkHtml5Validity() &&
        this.$refs.type.checkHtml5Validity()
      ) {
        // if (this.category == 'ambos') {
        //   this.$refs.unit_weight.checkHtml5Validity()
        //   return
        // }
        const loadingComponent = this.$buefy.loading.open({
          container: null
        })
        this.$store
          .dispatch('dispensaryPro/createProduct', {
            asocid: this.currentClub.nid,
            category: this.category,
            title: this.title,
            body: this.body,
            images: this.images,
            code: this.code,
            price: this.price,
            cost: this.cost,
            jar_weight: this.jarWeight ? this.jarWeight : 0,
            // stock: this.stock,
            thc: this.thc,
            menuexport: this.menu_export,
            status: this.status,
            discount: this.discount,
            dominance: this.dominance,
            type: this.type,
            unit_weight: Number(this.unit_weight),
            unlimited_stock: this.unlimited_stock,
            pid: this.$route.query.pid //si se manda el pid se modifica el producto
          })
          .then(() => {
            this.$store.dispatch('dispensaryPro/clearDispensary')
            this.$store
              .dispatch('dispensaryPro/fetchDispensary', {
                asocid: this.currentClub.nid,
                status: null
              })
              .then(() => {
                loadingComponent.close()
                this.$router.push({ name: 'dispensarymanagerpro' })
              })
          })
          .catch(error => {
            loadingComponent.close()
            tools.manageAxiosError(error)

            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data)
              console.log(error.response.status)
              console.log(error.response.headers)

              // this.$buefy.snackbar.open({
              //   duration: 15000,
              //   message:
              //     this.$t('Ha habido un problemilla') +
              //     ':' +
              //     error.response.data,
              //   type: 'is-danger',
              //   position: 'is-top',
              //   actionText: 'Ok',
              //   queue: false
              // })
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request)
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message)
            }
            console.log(error.config)

            console.log('Ha habido un error:' + error)
          })
      }
    },

    fetchItem() {
      this.$store
        .dispatch('dispensaryPro/fetchProductByPid', this.$route.query.pid)
        .then(response => {
          console.log('fetchProductByPid')
          console.log(response)
          this.title = response.titulo
          this.body = response.cuerpo
          this.price = response.precio
          this.cost = response.coste
          this.code = response.code
          this.jarWeight = Number(response.jar_weight)
          // this.stock = response.stock
          this.dominance = response.dominance
          this.discount = response.discount
          this.type = response.type
          this.thc = response.thc
          this.unlimited_stock = response.unlimited_stock
          this.menu_export = response.menu_export
          this.status = response.status
          this.unit_weight = response.unit_weight
          this.image_urls = response.imagenes
          this.category = this.$store.getters[
            'dispensaryPro/getCategoryByName'
          ](response.categorias)
        })
    }
  },
  watch: {
    currentClub() {
      this.validateRoles(['Administrador', 'Manager', 'Inventario'])
      this.$store.dispatch('dispensaryPro/fetchCategories', {
        asocid: this.currentClub.nid,
        type: 'dispensary'
      })
    },
    dropFiles() {
      this.dropFiles.forEach(file => {
        const comp = this
        function readerOnload(e) {
          var base64 = btoa(e.target.result)
          comp.images.push(base64)
        }
        var reader = new FileReader()
        reader.onload = readerOnload
        reader.readAsBinaryString(file)
      })
    }
  },
  created() {
    this.validateRoles(['Administrador', 'Manager', 'Inventario'])
    this.$analytics.logEvent('DispensaryProductCreateBasic.vue')
    this.$store.dispatch('setShowNavigationBar', true)

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })
    this.$store
      .dispatch('dispensaryPro/fetchCategories', {
        asocid: this.currentClub.nid,
        type: 'dispensary'
      })
      .then(() => {
        if (this.$route.query.pid) {
          this.isUpdating = true
          console.log(this.$route.query.pid)
          if (this.dispensary.length === 0) {
            this.$store
              .dispatch('dispensaryPro/fetchDispensary', {
                asocid: this.currentClub.nid,
                status: null
              })
              .then(() => {
                this.fetchItem()
                loadingComponent.close()
              })
          } else {
            this.fetchItem()
            loadingComponent.close()
          }
        } else {
          this.isUpdating = false
          loadingComponent.close()
        }
      })
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.row::after {
  content: '';
  clear: both;
  // display: flex;
}

[class*='col-'] {
  float: left;
  padding: 15px;
}

html {
  font-family: 'Lucida Sans', sans-serif;
}

.header {
  background-color: #9933cc;
  color: #ffffff;
  padding: 15px;
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  top: 10px;
  // height: 5vh;
  overflow: auto;
}

.menu li {
  padding: 8px;
  margin-bottom: 7px;
  background-color: #f7f3f3;
  color: #666666;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
  cursor: pointer;
}

.right li {
  // background-color: #33b5e5;
  // color: #fff;
  background-color: #f3f3f3;
}

.left li {
  background-color: #f3f3f3;
  // color: #fff;
}

.menu li:hover {
  background-color: #e1e1e1;
  // color: #fff;
}

.aside {
  background-color: #f3f3f3;
  padding: 15px;
  color: #666666;
  text-align: center;
  font-size: 14px;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
}

.footer {
  background-color: #0099cc;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  padding: 15px;
}

/* For desktop: */

.col-2 {
  width: 60%;
  background-color: #f9f9f9;
  border-radius: 6px;
  height: calc(100vh - 40px);
  overflow: auto;
}
.col-3 {
  width: 40%;
  background-color: #f9f9f9;
  // border-radius: 6px;
  // height: 100vh;
  // height: calc(100vh - 140px);
}

.actions {
  // height: calc(100vh - 40px);
  height: 100vh;
  // overflow: auto;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*='col-'] {
    width: 100%;
  }
}
</style>
