<template>
  <div>
    <canvas id="cnv" name="cnv" width="500" height="100"></canvas><br />
    <button id="Clear" name="Clear" type="button" v-on:click="onClear()">
      {{ $t('Limpiar') }}
    </button>
    <button id="Done" name="Done" type="button" v-on:click="image64()">
      {{ $t('Hecho') }}</button
    ><br />
    <img id="imgFirma" />
  </div>
</template>

<script>
var tmr
var testimage
export default {
  name: 'TopazRead',
  mounted: function() {
    this.$nextTick(function() {
      if (!IsSigWebInstalled()) {
        if (
          window.confirm(
            'No detectamos SigWeb instalado.\nEs necesario tenerlo intsalado para firmar.\nPulsa aceptar para descargarlo.'
          )
        ) {
          window.location.href =
            'https://www.topazsystems.com/software/sigweb.exe'
        }
      } else {
        this.onSign()
      }
    })
  },
  methods: {
    onSign() {
      if (IsSigWebInstalled()) {
        var ctx = document.getElementById('cnv').getContext('2d')
        SetDisplayXSize(500)
        SetDisplayYSize(100)
        SetTabletState(0, tmr)
        SetJustifyMode(0)
        ClearTablet()
        SetKeyString('0000000000000000')
        SetEncryptionMode(0)
        if (tmr == null) {
          tmr = SetTabletState(1, ctx, 50)
        } else {
          SetTabletState(0, tmr)
          tmr = null
          tmr = SetTabletState(1, ctx, 50)
        }
      } else {
        if (
          window.confirm(
            'No detectamos SigWeb instalado.\nEs necesario tenerlo intsalado para firmar.\nPulsa aceptar para ir a descargarlo.'
          )
        ) {
          window.location.href =
            'https://www.topazsystems.com/software/sigweb.exe'
        }
      }
    },
    onClear() {
      //para limpiar la firma realizada
      if (NumberOfTabletPoints() != 0) {
        ClearTablet()
      } else {
        this.onSign()
      }
    },
    image64() {
      if (NumberOfTabletPoints() == 0) {
        alert('No has ingresado ninguna firma')
      } else {
        SetTabletState(0, tmr)
        var CryptoData = ''
        CryptoData =
          'This represents sample data the signer reads and is agreeing to when signing.'
        AutoKeyAddANSIData(CryptoData) //PASS THE DATA IN TO BE USED FOR AUTOKEY
        SetEncryptionMode(2)
        SetSigCompressionMode(1)
        SetImageXSize(500)
        SetImageYSize(100)
        SetImagePenWidth(5)
        GetSigImageB64(this.SigImageCallback)
      }
    },

    SigImageCallback(str) {
      testimage = str
      //this.onSign();  //si se quita el comentario, limpia la tablet al hcompletar la imagen
      this.stringImage()
    },

    stringImage() {
      this.$emit('stringSignature', 'data:image/jpeg;base64,' + testimage)
      document.getElementById('imgFirma').src =
        'data:image/jpeg;base64,' + testimage
    }
  }
}
</script>

<style>
canvas {
  border: 1px solid grey;
}
</style>
