import { render, staticRenderFns } from "./EventCreate.vue?vue&type=template&id=c8d6f938&scoped=true&"
import script from "./EventCreate.vue?vue&type=script&lang=js&"
export * from "./EventCreate.vue?vue&type=script&lang=js&"
import style0 from "./EventCreate.vue?vue&type=style&index=0&id=c8d6f938&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8d6f938",
  null
  
)

export default component.exports