<template>
  <div>
    <!-- <div class="header">
      <h1>Chania</h1>
    </div> -->
    <div class="row">
      <div class="col-1 menu">
        <h4>Escanear</h4>
        <section class="consultsList">
          <div class="menu left">
            <ul>
              <li>
                <h6>QR</h6>
                <p>Busca a un socio mediate QR</p>
              </li>
              <li>
                <h6>Documento</h6>
                <p>
                  Busca a un socio utilizando el reconocimiento de documentos
                  (DNI, pasaporte, carné de conducir)
                </p>
              </li>
              <hr />
              <h4>Registrar</h4>
              <li @click="showCreateUser()">
                <div>
                  <h6>Registrar socio</h6>
                  <p>Registrar un nuevo socio en el club</p>
                </div>
              </li>
              <hr />
              <h4>Notificaciones</h4>
              <li @click="sendPush()">
                <div>
                  <h6>Enviar Push</h6>
                  <p>Enviar push a un socio del club</p>
                </div>
              </li>
            </ul>
          </div>
        </section>
      </div>

      <div class="col-2">
        <b-field label="Socios"> </b-field>
        <b-field>
          <b-table
            class="my-table"
            style="text-align: left;"
            :data="this.filteredDataArray"
            :sticky-header="true"
            :paginated="isPaginated"
            :per-page="perPage"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :default-sort-direction="defaultSortDirection"
            :pagination-rounded="isPaginationRounded"
            default-sort="name"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Actual"
            :selected.sync="selected"
            hoverable
            striped
            height="90%"
          >
            <b-table-column
              field="mail"
              label="Email"
              sortable
              v-slot="props"
              width="80"
              searchable
            >
              {{ props.row.mail }}
            </b-table-column>

            <b-table-column
              field="field_user_name"
              :label="$t('Nombre')"
              sortable
              v-slot="props"
              searchable
            >
              {{ props.row.field_user_name }}
            </b-table-column>

            <b-table-column
              field="field_user_surname"
              :label="$t('Apellidos')"
              sortable
              v-slot="props"
              searchable
            >
              {{ props.row.field_user_surname }}
            </b-table-column>

            <b-table-column
              field="name"
              label="Username"
              sortable
              v-slot="props"
              searchable
            >
              {{ props.row.name }}
            </b-table-column>

            <b-table-column
              field="field_user_dni"
              :label="$t('DNI')"
              sortable
              v-slot="props"
              searchable
            >
              {{ props.row.field_user_dni }}
            </b-table-column>

            <b-table-column
              field="field_smokapp_cuota_expire"
              :label="$t('Expiración')"
              sortable
              centered
              v-slot="props"
            >
              <span
                class="tag is-success"
                v-if="props.row.field_smokapp_cuota_expire"
              >
                {{
                  format_date(
                    props.row.field_smokapp_cuota_expire,
                    'dd/MM/yyyy'
                  )
                }}
              </span>
            </b-table-column>
          </b-table>
        </b-field>
      </div>

      <div class="col-3 actions">
        <h4>Socio</h4>
        <div class="aside">
          <v-gravatar
            :email="currentUser.mail"
            alt="Nobody"
            default-img="mm"
            style="border-radius:50%; margin-top:20px;"
          />
          <h4>{{ currentUser.name }} {{ currentUser.field_user_dni }}</h4>
          <p>{{ currentUser.mail }}</p>
          <p>Id de socio: {{ currentUser.idsocio }}</p>
          <p>MJID: {{ currentUser.field_mj_id }}</p>
          <p>{{ currentUser.field_user_tlf }}</p>
          <p>
            {{ format_date(currentUser.field_user_birthdate, 'dd/MM/yyyy') }}
          </p>
          <p>
            Último acceso al local:
            {{ format_date(currentUser.login, 'hh:mm dd/MM/yyyy') }}
          </p>
          <p>
            Caducidad de la cuota:
            {{
              format_date(currentUser.field_smokapp_cuota_expire, 'dd/MM/yyyy')
            }}
          </p>
          <p>Saldo: 0,0 €</p>
          <p>Puntos de recompensa: 0</p>
          <p>Previsión: 0Gr per 0 day</p>
          <p>
            Socio desde el {{ format_date(currentUser.created, 'dd/MM/yyyy') }}
          </p>
          <p>Total de pedidos: 0</p>
          <p></p>
          <a style="font-weight: bold;" @click="selectCurrentConsult(consult)">
            Ver historial de pedidos
          </a>
          <p>
            <a
              style="font-weight: bold;"
              @click="selectCurrentConsult(consult)"
            >
              Enviar Notificación push
            </a>
          </p>

          <p>
            <a
              style="font-weight: bold;"
              @click="selectCurrentConsult(consult)"
            >
              Envía un SMS
            </a>
          </p>

          <p>
            <a
              style="font-weight: bold;"
              @click="selectCurrentConsult(consult)"
            >
              Reenviar código de invitación
            </a>
          </p>
        </div>
        <hr />
        <div class="menu right">
          <ul>
            <li
              style="background-color: #f8d133;"
              @click="deleteConsult(currentConsult)"
            >
              <div>
                <h6>Recarga monedero</h6>
                <p>Recargar el monedero del socio</p>
              </div>
            </li>
            <li
              style="background-color: #f8d133;"
              @click="deleteConsult(currentConsult)"
            >
              <div>
                <h6>Renovar cuota</h6>
                <p>Modificar la fecha de fin de cuota</p>
              </div>
            </li>
            <li style="background-color: #717a81; color:#fff;">
              <h6>Crear consulta</h6>
              <p>Crea una nueva consulta con el socio</p>
            </li>
            <li style="background-color: #70c1b3;">
              <h6>Modificar socio</h6>
              <!-- <p>Modifica los datos personales del socio</p> -->
            </li>
            <li style="background-color: #70c1b3;">
              <h6>Desactivar socio</h6>
            </li>
            <li style="background-color: #ed6a5a; color:#fff">
              <h6>Eliminar socio del club</h6>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- <div class="footer">
      <p>
        Resize the browser window to see how the content respond to the
        resizing.
      </p>
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import UserInvite from '@/views/Users/UserInvite.vue'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'

export default {
  data() {
    return {
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 24,
      selectedOptions: [],
      currentUser: {},
      // searchText: '',
      filteredDataArray: this.users,
      selected: {}
    }
  },
  computed: {
    ...mapState('club', ['currentClub']),
    ...mapState('users', ['users'])
  },

  mixins: [ToolsMixin, InitViewMixin],
  methods: {
    selectCurrentUser(user) {
      this.currentUser = user
    },
    deleteUser() {},
    selectFirstUser() {
      if (this.users.length > 0) {
        this.currentUser = this.users[0]
      }
    },
    showCreateUser() {
      // this.$router.push({ name: 'userinvite' })
      this.$buefy.modal.open({
        parent: this,
        component: UserInvite,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
        props: {
          item: this.dispensary_item
        }
      })
    },
    fetchUsers() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.$store
        .dispatch('users/fetchUsers', this.currentClub.nid)
        .then(response => {
          this.selectFirstUser()
          loadingComponent.close()
        })
        .catch(error => {
          console.log('Ha habido un error:' + error)
          loadingComponent.close()
        })
    }
  },
  created() {
    this.$analytics.logEvent('UsersManager.vue')
    this.$store.dispatch('setShowNavigationBar', true)

    this.fetchUsers()
  },
  watch: {
    currentClub() {
      this.fetchUsers()
    },
    selected() {
      this.currentUser = this.selected
    },
    selectedOptions() {
      this.currentUser = this.selectedOptions[0]
    },

    users() {
      this.filteredDataArray = this.users
    }

    // searchText() {
    //   this.filteredDataArray = this.users.filter(user => {
    //     return (
    //       user.name
    //         .toString()
    //         .toLowerCase()
    //         .indexOf(this.searchText.toLowerCase()) >= 0
    //     )
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.row::after {
  content: '';
  clear: both;
  // display: flex;
}

[class*='col-'] {
  float: left;
  padding: 15px;
}

html {
  font-family: 'Lucida Sans', sans-serif;
}

.my-table {
  // max-height: 100%;
  overflow-y: auto;
  height: calc(100vh - 150px);
  // height: 1000px;
}

.header {
  background-color: #9933cc;
  color: #ffffff;
  padding: 15px;
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  top: 10px;
  // height: 5vh;
  overflow: auto;
}

.menu li {
  padding: 8px;
  margin-bottom: 7px;
  background-color: #f7f3f3;
  color: #666666;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
  cursor: pointer;
}

.right li {
  // background-color: #33b5e5;
  // color: #fff;
  background-color: #f3f3f3;
}

.left li {
  background-color: #f3f3f3;
  // color: #fff;
}

.menu li:hover {
  background-color: #e1e1e1;
  // color: #fff;
}

.aside {
  background-color: #f3f3f3;
  padding: 15px;
  color: #666666;
  text-align: center;
  font-size: 14px;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
}

.footer {
  background-color: #0099cc;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  padding: 15px;
}

/* For desktop: */
.col-1 {
  width: 15%;
  background-color: #f9f9f9;
  border-radius: 6px;
}
.col-2 {
  width: 67%;
  background-color: #f9f9f9;
  border-radius: 6px;
}
.col-3 {
  width: 18%;
  background-color: #f9f9f9;
  border-radius: 6px;
}

.consultsList {
  height: calc(100vh - 160px);
  overflow: auto;
}

.actions {
  height: calc(100vh - 85px);
  overflow: auto;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*='col-'] {
    width: 100%;
  }
}
</style>
