<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ $t('Gestionar categorías de socio') }}</p>
      <button type="button" class="delete" @click="$emit('close')" />
    </header>
    <section class="modal-card-body">
      <b-table :data="categories">
        <b-table-column
          field="title"
          :label="$t('Categoría')"
          sortable
          v-slot="props"
        >
          {{ props.row.title }}
        </b-table-column>

        <b-table-column
          field="descuento"
          :label="$t('Descuento')"
          sortable
          v-slot="props"
        >
          {{ props.row.descuento }}%
        </b-table-column>

        <b-table-column v-slot="props">
          <b-button
            class="is-small"
            icon-left="pen"
            @click="editCategory(props.row)"
          ></b-button>
          <b-button
            class="is-small"
            icon-left="trash-alt"
            @click="deleteCategory(props.row)"
          ></b-button>
        </b-table-column>
      </b-table>
    </section>
    <footer class="modal-card-foot">
      <!-- <b-button :label="$t('Cerrar')" @click="$emit('close')" /> -->
      <b-field :label="$t('Crear nueva categoría')">
        <b-input v-model="categoryName" :placeholder="$t('Nombre')"> </b-input>
        <b-input
          v-model="categoryDiscount"
          :placeholder="$t('Descuento')"
          type="number"
          min="0"
          max="100"
        >
        </b-input>
        <b-button
          v-if="is_editing"
          :label="$t('Modificar')"
          type="is-primary"
          @click="updateData()"
        />
        <b-button
          v-else
          :label="$t('Crear')"
          type="is-primary"
          @click="sendData()"
        />
      </b-field>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      is_editing: false,
      categoryName: null,
      categoryDiscount: null,
      categoryNid: null,
      categoryToModify: null
    }
  },
  computed: {
    ...mapState('club', ['currentClub']),
    ...mapState('patients', ['categories'])
  },
  methods: {
    deleteCategory(category) {
      console.log(category)
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.$store
        .dispatch('patients/deletePatientCategory', {
          asocid: this.currentClub.nid,
          patientCategory: category
        })
        .then(() => {
          loadingComponent.close()
          //   this.$emit('close')
        })
    },
    editCategory(category) {
      this.categoryToModify = category
      this.categoryName = category.title
      this.categoryDiscount = category.descuento
      this.categoryNid = category.nid
      this.is_editing = true
    },
    updateData() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      let json = {
        association: this.currentClub.nid,
        title: this.categoryName,
        descuento: this.categoryDiscount,
        nid: this.categoryNid
      }
      this.$store
        .dispatch('patients/updatePatientCategory', {
          category: this.categoryToModify,
          newcategory: json
        })
        .then(() => {
          loadingComponent.close()
          this.is_editing = false
          this.categoryName = null
          this.categoryDiscount = null

          //   this.$emit('close')
        })
    },
    sendData() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      let json = {
        association: this.currentClub.nid,
        title: this.categoryName,
        descuento: this.categoryDiscount
      }
      this.$store
        .dispatch('patients/createPatientCategory', json)
        .then(response => {
          loadingComponent.close()
          this.categoryName = null
          this.categoryDiscount = null
          json.nid = response.data.nid
          this.categories.push(json)
          //   this.$emit('close')
        })
    }
  },
  created() {
    this.$store.dispatch(
      'patients/fetchPatientCategories',
      this.currentClub.nid
    )
  }
}
</script>

<style lang="scss" scoped></style>
