<template>
  <div>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t('Renovar cuota') }}</p>
        <button type="button" class="delete ml-5" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <!-- {{ currentPatient }} -->
        <p>{{ $t('Nombre') }}: {{ currentPatient.nombre }}</p>
        <p>{{ $t('Apellidos') }}: {{ currentPatient.surname }}</p>
        <p>{{ $t('DNI') }}: {{ currentPatient.dni }}</p>
        <p>
          {{ $t('Expiración cuota') }}:
          {{ format_date(currentPatient.end_date, 'dd/MM/yyyy') }}
        </p>
        <p>{{ $t('Monedero') }}: {{ currentPatient.monedero }}</p>

        <!-- <SimpleKeyboard @onKeyPress="onKeyPress" :input="String(input)" /> -->
      </section>

      <footer class="modal-card-foot" style="display:block">
        <div style="width: 100%;">
          <span v-for="fee in feeCategories" :key="fee.nid">
            <b-button style="height:auto; width: 33%;" @click="selectFee(fee)">
              <div>
                <p>{{ fee.title }}</p>
                <p>{{ fee.days }} {{ $t('días') }}</p>
                <p>{{ fee.price }} €</p>
              </div>
            </b-button>
          </span>
        </div>
        <hr />
        <div v-if="selectedFee">
          <b-button class="button is-primary" @click="returnSelectedUsers()">
            {{ $t('Efectivo') }}
          </b-button>

          <b-button class="button is-primary" @click="returnSelectedUsers()">{{
            $t('Monedero')
          }}</b-button>

          <b-button class="button is-primary" @click="returnSelectedUsers()">{{
            $t('Tarjeta')
          }}</b-button>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
// import SimpleKeyboard from '@/components/SimpleKeyboard'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import { mapState } from 'vuex'
import { DialogProgrammatic as Dialog } from 'buefy'

export default {
  props: {
    currentPatient: null,
    currentClub: null
  },
  data() {
    return {
      selectedFee: null
    }
  },
  mixins: [ToolsMixin],
  computed: {
    ...mapState('club', ['feeCategories']),
    ...mapState('orders', ['orders', 'currentOrder'])
  },
  // components: {
  //   SimpleKeyboard
  // },
  methods: {
    // selectFee(fee) {
    //   this.selectedFee = fee
    // },
    selectFee(fee) {
      if (this.currentOrder) {
        if (
          (this.currentOrder.discount_coupon_order &&
            this.currentOrder.discount_coupon_order.length > 0) ||
          (this.currentOrder.manual_discount_order &&
            this.currentOrder.manual_discount_order > 0)
        ) {
          Dialog.alert({
            title: this.$t('Advertencia'),
            message: this.$t(
              'No se puede añadir un pago de cuota a un pedido que ya tiene aplicado un descuento'
            ),
            confirmText: 'OK',
            type: 'is-danger',
            hasIcon: false,
            onConfirm: () => {}
          })
          return
        }
      }

      let order = {
        membership_id: this.currentPatient.nid,
        asocid: this.currentClub.nid
      }
      var products = []
      if (this.currentOrder) {
        this.currentOrder.products.forEach(product => {
          products.push({
            pid: product.product.pid,
            quantity: product.quantity,
            total: product.total
          })
        })
      }

      products.push({
        pid: fee.pid,
        quantity: 1,
        total: fee.price
      })

      order.products = products
      if (this.currentOrder) {
        //Actualizamos order si ya existe una creada
        order.oid = this.currentOrder.order_id
      }
      this.$store.dispatch('orders/postOrder', order).then(() => {})
      this.$emit('close')
    },
    returnSelectedUsers() {
      this.$emit('seletedUsrs', this.checkedRows)
      this.$emit('close')
    }
  },
  created() {
    this.$store.dispatch('club/fetchFeeCategories', {
      asocid: this.currentClub.nid
    })
  }
}
</script>

<style lang="scss" scoped></style>
