<template>
  <div>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t('Recargar monedero') }}</p>
        <button type="button" class="delete ml-5" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <b-field :label="$t('Recarga')" style="width:50%">
          <b-input
            v-model="amount"
            :placeholder="$t('€')"
            @focus="amountFocused()"
          >
          </b-input>
        </b-field>

        <div style="width: 100%;">
          <span v-for="category in categories" :key="category.nid">
            <b-button style="width: 33%;" @click="setCategory(category)"
              >{{ category.title }} €</b-button
            >
          </span>
        </div>

        <div class="mt-3" v-if="paytype == 'cash'">
          <b-field :label="$t('Entrega')" style="width:50%">
            <b-input
              v-model="cash"
              :placeholder="$t('€')"
              @focus="cashFocused()"
            >
            </b-input>
          </b-field>
        </div>

        <b-field
          class="mt-3"
          v-if="showPayButton"
          :label="changeLabel"
          style="width:50%"
        >
          <h2>{{ paymentChange }} €</h2>
        </b-field>
        <div class="mt-3" v-if="showPayButton">
          <b-button
            class="button is-primary"
            type="button"
            @click="chargeWallet('cash')"
          >
            {{ $t('Pagar') }}
          </b-button>
        </div>

        <br />
        <!-- <div style="width: 100%;">
          <span v-for="category in categories" :key="category.nid">
            <b-button style="width: 33%;" @click="setCategory(category)"
              >{{ category.title }} €</b-button
            >
          </span>
        </div> -->
        <SimpleKeyboard @onKeyPress="onKeyPress" :input="String(input)" />
      </section>

      <footer class="modal-card-foot" style="display:block">
        <div v-if="amount">
          <b-button
            class="button is-primary"
            type="button"
            @click="payWithCash()"
            v-if="cashPermission"
          >
            {{ $t('Efectivo') }}
          </b-button>

          <b-button
            class="button is-primary"
            @click="chargeWallet('tpv')"
            v-if="cardPermission"
            >{{ $t('Tarjeta') }}</b-button
          >
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import SimpleKeyboard from '@/components/SimpleKeyboard'
import { mapState } from 'vuex'

export default {
  props: {
    currentPatient: null,
    currentClub: null
  },
  data() {
    return {
      showPayButton: false,
      amount: '',
      cash: '',
      paymentChange: null,
      input: null,
      paytype: null,
      amountfocused: true,
      cashfocused: false,
      changeLabel: this.$t('Cambio'),
      categories: []
    }
  },
  computed: {
    ...mapState('club', [
      'cashPermission',
      'cardPermission',
      'walletPermission'
    ])
  },
  components: {
    SimpleKeyboard
  },
  methods: {
    onKeyPress(input) {
      switch (input) {
        case '{bksp}':
          //Borrar carcater
          if (this.amountfocused == true) {
            this.amount = String(this.amount).slice(0, -1)
          }
          if (this.cashfocused == true) {
            this.cash = String(this.cash).slice(0, -1)
          }

          break
        case 'Siguiente':
          break
        default:
          //Añadir caracter
          if (this.amountfocused == true) {
            this.amount = this.amount + input
          }
          if (this.cashfocused == true) {
            this.cash = this.cash + input
          }
      }
    },
    setCategory(category) {
      this.amount = String(Number(this.amount) + Number(category.title))
    },
    chargeWallet(paymentType) {
      if (!this.isNumeric(this.amount)) {
        this.$buefy.snackbar.open({
          duration: 5000,
          message: 'Introduce valores numéricos',
          type: 'is-primary',
          position: 'is-top',
          actionText: 'Ok',
          queue: false
        })
        return
      }
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.$store
        .dispatch('patients/chargeWallet', {
          asocid: this.currentClub.nid,
          patient: this.currentPatient,
          operation: 'add',
          amount: this.amount,
          pay_type: paymentType
        })
        .then(() => {
          loadingComponent.close()
          this.$emit('close')
        })
        .catch(error => {
          console.log('Ha habido un error:' + error.response.message)
          tools.manageAxiosError(error)
          loadingComponent.close()
        })
    },
    payWithCash() {
      this.paytype = 'cash'
      this.cashFocused()
    },
    amountFocused() {
      this.amountfocused = true
      this.cashfocused = false
      this.input = this.amount
    },
    cashFocused() {
      this.amountfocused = false
      this.cashfocused = true
      this.input = this.cash
    },
    isNumeric(str) {
      if (typeof str != 'string') return false // we only process strings!
      return (
        !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      ) // ...and ensure strings of whitespace fail
    }
  },
  created() {
    this.$store
      .dispatch('club/fetchCategories', {
        asocid: this.currentClub.nid,
        type: 'wallet'
      })
      .then(response => {
        this.categories = response.data
      })
  },
  watch: {
    // amount() {
    //   this.paymentChange = Number(this.amount) - Number(this.cash)
    // },
    cash() {
      this.paymentChange = (Number(this.amount) - Number(this.cash)).toFixed(2)
      if (this.paymentChange > 0) {
        this.changeLabel = 'Faltan'
      } else {
        this.changeLabel = this.$t('Cambio')
      }
    },
    paymentChange() {
      this.showPayButton = this.paymentChange <= 0
    }
  }
}
</script>

<style lang="scss" scoped></style>
