<template>
  <div>
    <!-- <div class="header">
      <h1>Chania</h1>
    </div> -->
    <div class="row">
      <div class="col-2">
        <section>
          <!-- <b-button @click="showAdvancedBodyEditor()">
            Acceder a editor para expertos</b-button
          >
          <hr />
          <h4>Editor básico</h4> -->
          <b-field :label="$t('Título de la noticia')" style="color:#fff;">
            <b-input
              v-model="title"
              :placeholder="$t('Escribe aquí el título')"
            ></b-input>
          </b-field>

          <b-field :label="$t('Cuerpo de la noticia')" style="color:#fff;">
            <b-input
              v-model="body"
              :placeholder="$t('Escribe el cuerpo de la noticia')"
              type="textarea"
            ></b-input>
          </b-field>
          <b-field :label="$t('Añade unas imágenes del producto')">
            <b-upload v-model="dropFiles" multiple drag-drop>
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="upload" size="is-large"> </b-icon>
                  </p>
                  <p>
                    {{
                      $t('Arrastra aquí las imágenes o pulsa para selccionar')
                    }}
                  </p>
                </div>
              </section>
            </b-upload>
          </b-field>

          <div class="tags">
            <span
              v-for="(file, index) in dropFiles"
              :key="index"
              class="tag is-primary"
            >
              {{ file.name }}
              <button
                class="delete is-small"
                type="button"
                @click="deleteDropFile(index)"
              ></button>
            </span>
          </div>
        </section>
        <b-button @click="publishNews()">
          {{ $t('Publicar Noticia') }}</b-button
        >
      </div>

      <div class="col-3 actions">
        <h4>{{ $t('Previsualización de la noticia') }}</h4>
        <hr />
        <div class="aside">
          <div v-if="dropFiles.length > 0">
            <img :src="fileToImage(dropFiles[0])" />
          </div>
          <h3>{{ title }}</h3>
          <p v-html="body"></p>
        </div>

        <div class="menu right">
          <!-- <ul>
            <li style="background-color: #717a81; color:#fff;">
              <div @click="createNews()">
                <h6>Publicar nueva noticia</h6>
                <p>Publica una nueva noticia en tu club</p>
              </div>
            </li>
            <li style="background-color: #ed6a5a; color:#fff">
              <h6>Eliminar noticia</h6>
            </li>
          </ul> -->
        </div>
      </div>
    </div>

    <!-- <div class="footer">
      <p>
        Resize the browser window to see how the content respond to the
        resizing.
      </p>
    </div> -->
  </div>
</template>

<script>
import { InitViewMixin } from '@/mixins/InitViewMixin.js'

export default {
  data() {
    return {
      dropFiles: [],
      title: '',
      body: '',
      images: []
    }
  },
  mixins: [InitViewMixin],
  methods: {
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1)
    },
    showAdvancedBodyEditor() {
      this.$router.push({ name: 'htmleditor' })
    },
    fileToImage(file) {
      return URL.createObjectURL(file)
    },
    publishNews() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.$store
        .dispatch('news/createNews', {
          asocid: this.currentClub.nid,
          title: this.title,
          subtitle: this.subtitle,
          body: this.body,
          images: this.images
        })
        .then(() => {
          loadingComponent.close()
          this.$router.push({ name: 'news' })
        })
    }
  },
  created() {
    this.validateAccess(['comunicacion'])
    this.$analytics.logEvent('News Create.vue')
    this.$store.dispatch('setShowNavigationBar', true)
  },
  watch: {
    currentClub() {
      this.validateAccess(['comunicacion'])
    },
    dropFiles() {
      this.dropFiles.forEach(file => {
        const comp = this
        function readerOnload(e) {
          var base64 = btoa(e.target.result)
          comp.images.push(base64)
        }
        var reader = new FileReader()
        reader.onload = readerOnload
        reader.readAsBinaryString(file)
      })
    }
    // dropFiles: function(o, n) {
    //   var reader = new FileReader()
    //   reader.onload = e => this.$emit('load', e.target.result)
    //   reader.readAsText(o[0]) // This line throws the error.
    //   alert()
    //   console.log(o)
    // }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.row::after {
  content: '';
  clear: both;
  // display: flex;
}

[class*='col-'] {
  float: left;
  padding: 15px;
}

html {
  font-family: 'Lucida Sans', sans-serif;
}

.my-table {
  // max-height: 100%;
  overflow-y: auto;
  height: calc(100vh - 150px);
  // height: 1000px;
}

.header {
  background-color: #9933cc;
  color: #ffffff;
  padding: 15px;
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  top: 10px;
  // height: 5vh;
  overflow: auto;
}

.menu li {
  padding: 8px;
  margin-bottom: 7px;
  background-color: #f7f3f3;
  color: #666666;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
  cursor: pointer;
}

.right li {
  // background-color: #33b5e5;
  // color: #fff;
  background-color: #f3f3f3;
}

.left li {
  background-color: #f3f3f3;
  // color: #fff;
}

.menu li:hover {
  background-color: #e1e1e1;
  // color: #fff;
}

.aside {
  background-color: #f3f3f3;
  padding: 15px;
  color: #666666;
  text-align: center;
  font-size: 14px;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
}

.footer {
  background-color: #0099cc;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  padding: 15px;
}

/* For desktop: */

.col-2 {
  width: 60%;
  background-color: #f9f9f9;
  border-radius: 6px;
  height: 100vh;
}
.col-3 {
  width: 40%;
  background-color: #f9f9f9;
  border-radius: 6px;
}

.consultsList {
  height: calc(100vh - 160px);
  overflow: auto;
}

.chat {
  height: calc(100vh - 190px);
  overflow: auto;
}

.actions {
  height: calc(100vh - 85px);
  overflow: auto;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*='col-'] {
    width: 100%;
  }
}
</style>
