<template>
  <div>
    <div style="text-align: left; width:calc(100vw - 120px);">
      <div
        v-if="showQRReader"
        style="display: flex; align-items: center;justify-content: center;"
      >
        <QRReader @qrResult="qrResult"> </QRReader>
      </div>
      <b-field :label="$t('Busca un socio:')" class="mt-4 mb-4">
        <b-input
          v-model="numberInput"
          :placeholder="$t('NºSoc')"
          @keyup.native.enter="searchEvent()"
        ></b-input>
        <b-input
          v-model="nameInput"
          :placeholder="$t('Nombre')"
          @input="searchEvent"
        ></b-input>
        <b-input
          v-model="surnameInput"
          :placeholder="$t('Apellidos')"
          @input="searchEvent"
        ></b-input>
        <b-input
          v-model="dniInput"
          :placeholder="$t('DNI')"
          @input="searchEvent"
        ></b-input>
        <b-input
          ref="keychain"
          v-model="keychainInput"
          :placeholder="$t('Llavero')"
          @keyup.native.enter="searchEvent()"
        ></b-input>
        <div v-if="checkNFC()">
          <b-button @click="readNFC()">
            <b-icon icon="wifi"> </b-icon>
          </b-button>
        </div>
        <b-input
          v-model="tlfInput"
          :placeholder="$t('Teléfono')"
          @input="searchEvent"
        ></b-input>
        <b-input
          v-model="emailInput"
          :placeholder="$t('Email')"
          @input="searchEvent"
        ></b-input>
        <b-select
          v-model="type_active"
          :value="type_active"
          @input="searchEvent"
        >
          <option value="1">{{ $t('Activos') }}</option>
          <option value="0">{{ $t('Inactivos') }}</option>
          <option value="all">{{ $t('Todos') }}</option>
        </b-select>
        <b-button @click="searchEvent()">
          {{ $t('Buscar') }}
        </b-button>
        <b-button
          v-if="this.currentClub.functionalities.gestion_avanzada_socios"
          @click="qrtoggle()"
          style="margin-top:5px; margin-left:3px; width:30px;  height:30px; background:#ffffff; color:#000000"
          ><i class="fas fa-qrcode fa"></i>
        </b-button>
      </b-field>
    </div>

    <b-table
      class="mb-4 mr-3"
      style="text-align: left"
      :data="this.filteredUsers"
      :sticky-header="true"
      :paginated="true"
      :per-page="perPage"
      @page-change="onPageChange"
      :total="totalElements"
      backend-pagination
      default-sort="name"
      aria-next-label="Siguiente"
      aria-previous-label="Anterior"
      aria-page-label="Página"
      aria-current-label="Actual"
      hoverable
      striped
      height="calc(100vh - 190px)"
      @click="clicked($event)"
    >
      <b-table-column field="foto" :label="$t('Foto')" v-slot="props">
        <!-- {{ props.row.foto.url }}
              <img
                v-auth-image="props.row.foto.url"
                v-if="props.row.foto"
                class="imgRigth"
                style="border-radius:50%; height: 50px; width: 50px; object-fit:cover;"
              /> -->

        <div style="display:flex">
          <img
            v-if="props.row.foto"
            style="border-radius:50%; height: 30px; width: 30px; object-fit:cover;"
            :src="props.row.foto.url"
          />
        </div>
      </b-table-column>

      <!-- <b-table-column
        field="nid"
        label="nid"
        sortable
        v-slot="props"
        width="40"
      >
        {{ props.row.nid }}
      </b-table-column> -->
      <b-table-column
        field="number"
        :label="$t('NºSoc')"
        sortable
        v-slot="props"
        width="40"
      >
        {{ props.row.number }}
      </b-table-column>

      <b-table-column
        field="nombre"
        :label="$t('Nombre')"
        sortable
        v-slot="props"
      >
        <span class="ml-3"> {{ props.row.nombre }}</span>
      </b-table-column>

      <b-table-column
        field="surname"
        :label="$t('Apellidos')"
        sortable
        v-slot="props"
      >
        {{ props.row.surname }}
      </b-table-column>

      <b-table-column field="dni" :label="$t('DNI')" sortable v-slot="props">
        {{ props.row.dni ? props.row.dni.toUpperCase() : '' }}
      </b-table-column>

      <b-table-column
        field="email"
        :label="$t('Email')"
        sortable
        v-slot="props"
      >
        {{ props.row.email }}
      </b-table-column>

      <b-table-column
        field="telefono"
        :label="$t('Tel')"
        sortable
        v-slot="props"
      >
        {{ props.row.telefono }}
      </b-table-column>

      <b-table-column
        field="monedero"
        :label="$t('Monedero')"
        sortable
        v-slot="props"
        :visible="walletPermission"
      >
        {{ props.row.monedero }}
      </b-table-column>

      <b-table-column
        field="end_date"
        :label="$t('Expiración')"
        sortable
        centered
        v-slot="props"
      >
        <span
          v-if="
            props.row.end_date &&
              props.row.end_date < Math.floor(Date.now() / 1000)
          "
        >
          <span v-if="props.row.end_date" class="tag is-danger">
            {{ format_date(props.row.end_date, 'dd/MM/yyyy') }}
          </span>
        </span>
        <span v-else>
          <div v-if="props.row.end_date" class="tag is-success">
            {{ format_date(props.row.end_date, 'dd/MM/yyyy') }}
          </div>
        </span>
      </b-table-column>

      <b-table-column field="type" :label="$t('Tipo')" sortable v-slot="props">
        {{ translateType(props.row.type) }}
      </b-table-column>

      <b-table-column
        field="category_member.title"
        :label="$t('Categoría')"
        sortable
        v-slot="props"
      >
        {{ props.row.category_member ? props.row.category_member.title : '' }}
      </b-table-column>
      <b-table-column
        field="member_created"
        :label="$t('Fecha alta')"
        sortable
        v-slot="props"
      >
        {{ format_date(props.row.member_created, 'dd/MM/yyyy') }}
      </b-table-column>
      <b-table-column
        field="props.row.user"
        :label="$t('Cuenta online')"
        v-slot="props"
      >
        <b-checkbox
          :value="props.row.user ? true : false"
          disabled
        ></b-checkbox>
      </b-table-column>

      <b-table-column
        field="props.row.status"
        :label="$t('Activo')"
        v-slot="props"
      >
        <b-checkbox :value="props.row.status" disabled></b-checkbox>
      </b-table-column>

      <b-table-column
        field="props.row.is_staff"
        :label="$t('Staff')"
        v-slot="props"
      >
        <b-checkbox :value="props.row.is_staff" disabled></b-checkbox>
      </b-table-column>

      <b-table-column
        :visible="walletPermission"
        field="props.row.monedero_negativo"
        :label="$t('Monedero negativo')"
        v-slot="props"
      >
        <b-checkbox :value="props.row.monedero_negativo" disabled></b-checkbox>
      </b-table-column>
    </b-table>

    <!-- <div class="footer">
      <p>
        Resize the browser window to see how the content respond to the
        resizing.
      </p>
    </div> -->
    <b-field class="ml-4" v-if="this.checkRoles(['Manager', 'Administrador'])">
      <download-excel
        :data="this.allData"
        :fields="json_fields"
        :header="xlsHeader()"
        name="socios.xls"
        :fetch="fetchAllData"
      >
        {{ $t('Descargar Excel') }}
        <b-icon icon="file-excel" size="is-medium" type="is-success"></b-icon>
      </download-excel>
      <download-excel
        :header="csvHeader()"
        :data="this.allData"
        type="csv"
        :fields="json_fields"
        name="socios.csv"
        :fetch="fetchAllData"
        :escapeCsv="true"
      >
        {{ $t('Descargar CSV') }}
        <b-icon icon="file-csv" size="is-medium" type="is-success"></b-icon>
      </download-excel>
    </b-field>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'
import APIPatients from '@/services/APIPatients.js'
import PatientDetail from '@/views/Users/PatientDetail.vue'
import QRReader from '@/components/QRReader.vue'

export default {
  data() {
    return {
      json_fields: {},
      keychain: '',
      filteredUsers: [],
      allData: [],
      nameInput: '',
      surnameInput: '',
      keychainInput: '',
      emailInput: '',
      numberInput: '',
      dniInput: '',
      tlfInput: '',
      totalElements: 0,
      perPage: 14,
      page: 1,
      sortIcon: 'arrow-up',
      currentUser: null,
      selected: {},
      type_active: 'all',
      showQRReader: false
    }
  },
  computed: {
    ...mapState('club', ['currentClub']),
    ...mapState('patients', ['currentPatient']),
    ...mapState('club', [
      'cashPermission',
      'cardPermission',
      'walletPermission'
    ])
  },
  props: {
    propdni: String
  },
  components: {
    QRReader
  },
  mixins: [ToolsMixin, InitViewMixin],

  methods: {
    translateType(type) {
      if (type === 'ludico') {
        return this.$t('Lúdico')
      } else if (type === 'terapeutico') {
        return this.$t('Terapéutico')
      }
    },
    qrtoggle() {
      this.$matomo.trackEvent('PatientManager', 'Click', 'qrReader', 1)
      if (!this.showQRReader) {
        //this.qrButtonTitle = 'Cerrar QR'
      } else {
        //this.qrButtonTitle = 'Validar mediante QR'
      }
      this.showQRReader = !this.showQRReader
    },
    qrResult(result) {
      this.keychainInput = result
      this.showQRReader = !this.showQRReader
      this.searchEvent()
    },
    xlsHeader() {
      return this.$t('SOCIOS')
    },
    csvHeader() {
      return ''
      // return this.$t('SOCIOS')
    },
    setupJsonFields() {
      this.json_fields[this.$t('NºSoc')] = 'number'
      this.json_fields[this.$t('Nombre')] = 'nombre'
      this.json_fields[this.$t('DNI')] = 'dni'
      this.json_fields[this.$t('Email')] = 'email'
      this.json_fields[this.$t('Tel')] = 'telefono'
      this.json_fields[this.$t('Monedero')] = 'monedero'
      this.json_fields[this.$t('Expiración')] = {
        field: 'end_date',
        callback: value => {
          return this.format_date(value, 'hh:mm dd/MM/yyyy')
        }
      }
      this.json_fields[this.$t('Tipo')] = 'type'
      this.json_fields[this.$t('Categoría')] = 'category_member.title'
      this.json_fields[this.$t('Fecha Alta')] = {
        field: 'member_created',
        callback: value => {
          return this.format_date(value, 'hh:mm dd/MM/yyyy')
        }
      }
      this.json_fields[this.$t('Cuenta online')] = {
        field: 'user',
        callback: value => {
          return value ? true : false
        }
      }
      this.json_fields[this.$t('Activo')] = 'status'
      this.json_fields[this.$t('Staff')] = 'is_staff'
    },
    checkNFC() {
      if ('NDEFReader' in window) {
        return true
      } else {
        return false
      }
    },
    readNFC() {
      this.$buefy.toast.open({
        duration: 4000,
        message: `Acerca la tarjeta o allavero`,
        position: 'is-top',
        type: 'is-success'
      })
      const ndef = new NDEFReader()
      return new Promise((resolve, reject) => {
        const ctlr = new AbortController()
        ctlr.signal.onabort = reject
        ndef.addEventListener(
          'reading',
          event => {
            this.keychainInput = event.serialNumber
            this.searchEvent()
            ctlr.abort()
            // resolve(event)
          },
          { once: true }
        )
        ndef.scan({ signal: ctlr.signal }).catch(err => reject(err))
      })
    },
    searchEvent() {
      this.page = 1
      this.fetchPatients()
    },

    async fetchAllData() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      let parameters = '?association=' + this.currentClub.nid
      ;('&')
      if (this.numberInput) {
        parameters = parameters + 'number=' + this.numberInput + '&'
      }
      if (this.nameInput) {
        parameters = parameters + 'nombre=' + this.nameInput.trim() + '&'
      }
      if (this.surnameInput) {
        parameters = parameters + 'surname=' + this.surnameInput + '&'
      }
      if (this.dniInput) {
        parameters = parameters + 'dni=' + this.dniInput + '&'
      }
      if (this.keychainInput) {
        parameters = parameters + 'keychain=' + this.keychainInput + '&'
      }
      if (this.emailInput) {
        parameters = parameters + 'email=' + this.emailInput + '&'
      }
      if (this.tlfInput) {
        parameters = parameters + 'telefono=' + this.tlfInput + '&'
      }
      if (this.type_active != 'all') {
        parameters = parameters + 'status=' + this.type_active + '&'
      }

      const response = await APIPatients.fetchPatientsWith(parameters)
      console.log(response)
      loadingComponent.close()
      this.allData = response.data.data

      return response.data.data

      APIPatients.fetchPatientsWith(parameters)
        .then(response => {
          this.allData = response.data.data
          loadingComponent.close()
        })
        .catch(error => {
          tools.manageAxiosError(error)
          console.log('Ha habido un error:' + error)
          loadingComponent.close()
        })
    },
    fetchPatients() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      let parameters =
        '?association=' +
        this.currentClub.nid +
        '&limit=' +
        this.perPage +
        '&page=' +
        this.page +
        '&'
      if (this.numberInput) {
        parameters = parameters + 'number=' + this.numberInput + '&'
      }
      if (this.nameInput) {
        parameters = parameters + 'nombre=' + this.nameInput.trim() + '&'
      }
      if (this.surnameInput) {
        parameters = parameters + 'surname=' + this.surnameInput + '&'
      }
      if (this.dniInput) {
        parameters = parameters + 'dni=' + this.dniInput + '&'
      }
      if (this.keychainInput) {
        parameters = parameters + 'keychain=' + this.keychainInput + '&'
      }
      if (this.emailInput) {
        parameters = parameters + 'email=' + this.emailInput + '&'
      }
      if (this.tlfInput) {
        parameters = parameters + 'telefono=' + this.tlfInput + '&'
      }
      if (this.type_active != 'all') {
        parameters = parameters + 'status=' + this.type_active
      }

      APIPatients.fetchPatientsWith(parameters)
        .then(response => {
          if (response.data.data.length == 0) {
            this.$buefy.toast.open({
              duration: 3000,
              message: this.$t('Socio no encontrado'),
              position: 'is-top',
              type: 'is-danger'
            })
            this.totalElements = response.data.total_fichas
            this.filteredUsers = response.data.data
          } else if (response.data.data.length == 1) {
            this.$buefy.toast.open({
              duration: 2000,
              message: `Socio encontrado`,
              position: 'is-top',
              type: 'is-success'
            })
            this.keychainInput = ''
            this.numberInput = ''
            this.clicked(response.data.data[0])
          } else {
            this.totalElements = response.data.total_fichas
            this.filteredUsers = response.data.data
          }
          this.setupJsonFields()
          loadingComponent.close()
        })
        .catch(error => {
          tools.manageAxiosError(error)
          console.log('Ha habido un error:' + error)
          loadingComponent.close()
        })
    },
    onPageChange(page) {
      this.page = page
      this.fetchPatients()
    },
    clicked(row) {
      this.currentUser = row
      this.$buefy.modal.open({
        parent: this,
        component: PatientDetail,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
        props: {
          currentPatient: row,
          currentClub: this.currentClub
        },
        onCancel: function() {},
        events: {
          reloadPatients: value => {
            this.fetchPatients()
          }
        }
      })
    },

    // haveuser(props) {
    //   if (props.row.user != null) {
    //     return true
    //   }
    //   return false
    // },
    selectCurrentUser(user) {
      this.currentUser = user
    }
  },

  mounted() {
    this.validateAccess(['socios', 'comunicacion'])
    this.validateRoles(['Administrador', 'Manager', 'Recepción'])

    this.$analytics.logEvent('PatientManager.vue')
    this.$store.dispatch('setShowNavigationBar', true)
    this.$refs.keychain.focus()
    // this.fetchPatients()

    if (this.propdni) {
      this.dniInput = this.propdni
    }
    this.fetchPatients()
  },
  watch: {
    '$route.params.ms_id': {
      handler: function(search) {
        if (search) {
          this.fetchPatients()
        }
      },
      deep: true,
      immediate: true
    },

    currentClub() {
      this.validateAccess(['socios', 'comunicacion'])
      this.validateRoles(['Administrador', 'Manager', 'Recepción'])

      this.fetchPatients()
    },
    selected() {
      this.currentUser = this.selected
    },
    selectedOptions() {
      this.currentUser = this.selectedOptions[0]
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.row::after {
  content: '';
  clear: both;
  // display: flex;
}

[class*='col-'] {
  float: left;
  padding: 15px;
}

html {
  font-family: 'Lucida Sans', sans-serif;
}

.my-table {
  // max-height: 100%;
  overflow-y: auto;
  height: calc(100vh - 150px);
  // height: 1000px;
}

.header {
  background-color: #9933cc;
  color: #ffffff;
  padding: 15px;
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  top: 10px;
  // height: 5vh;
  overflow: auto;
}

.menu li {
  padding: 8px;
  margin-bottom: 7px;
  background-color: #f7f3f3;
  color: #666666;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
  cursor: pointer;
}

.right li {
  // background-color: #33b5e5;
  // color: #fff;
  background-color: #f3f3f3;
}

.left li {
  background-color: #f3f3f3;
  // color: #fff;
}

.menu li:hover {
  background-color: #e1e1e1;
  // color: #fff;
}

.aside {
  background-color: #f3f3f3;
  padding: 15px;
  color: #666666;
  text-align: center;
  font-size: 14px;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
}

.footer {
  background-color: #0099cc;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  padding: 15px;
}

/* For desktop: */
.col-1 {
  width: 80%;
  background-color: #f9f9f9;
  border-radius: 6px;
}
.col-2 {
  width: 20%;
  background-color: #f9f9f9;
  border-radius: 6px;
}

.actions {
  height: calc(100vh - 40px);
  overflow: auto;
}

// .b-table .table-wrapper.has-sticky-header {
//   overflow-x: auto;
// }

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*='col-'] {
    width: 100%;
  }
}
</style>
