<template>
  <div>
    <h2 class="mt-3 mb-2" style="text-align: left">
      {{ $t('HISTORIAL DE MOVIMIENTOS DE CAJA') }}
    </h2>
    <div v-if="cashMovements" class="flex-gap">
      <div class="rcorners1">
        <b-field :label="$t('Fecha del primer movimiento')">
          <h5>
            {{
              format_date(
                cashMovements.summary.first_movement,
                'hh:mm dd/MM/yyyy'
              )
            }}
          </h5>
        </b-field>
        <b-field :label="$t('Fecha del último movimiento')">
          <h5>
            {{
              format_date(
                cashMovements.summary.end_movement,
                'hh:mm dd/MM/yyyy'
              )
            }}
          </h5>
        </b-field>
      </div>

      <div class="rcorners1">
        <b-field :label="$t('Manual añadido')">
          <h5>
            {{
              cashMovements.summary.manual_amount
                ? cashMovements.summary.manual_amount.toFixed(2)
                : '0.0'
            }}
            €
          </h5>
        </b-field>
        <!-- <b-field label="Manual retirado">
          <h5>
            {{ cashMovements.summary.remove_manual }}
          </h5>
        </b-field> -->
      </div>

      <div class="rcorners1" v-if="cashPermission">
        <b-field :label="$t('Efectivo')">
          <h5>
            {{
              cashMovements.summary.cash_order_amount
                ? cashMovements.summary.cash_order_amount.toFixed(2)
                : '0.0'
            }}
            €
          </h5>
        </b-field>
        <b-field :label="$t('Nº pedidos efectivo')">
          <h5>
            {{
              cashMovements.summary.cash_order_movement
                ? cashMovements.summary.cash_order_movement
                : '0'
            }}
          </h5>
        </b-field>
      </div>

      <div
        v-if="
          this.currentClub.functionalities.funciones_avanzadas_gestion ||
            this.currentClub.functionalities.monedero ||
            walletPermission
        "
        class="rcorners1"
      >
        <b-field :label="$t('Wallet')">
          <h5>
            {{
              cashMovements.summary.wallet_order_amount
                ? cashMovements.summary.wallet_order_amount.toFixed(2)
                : '0.0'
            }}
            €
          </h5>
        </b-field>
        <b-field :label="$t('Nº pedidos wallet')">
          <h5>
            {{
              cashMovements.summary.wallet_order_movement
                ? cashMovements.summary.wallet_order_movement
                : '0'
            }}
          </h5>
        </b-field>
      </div>

      <div class="rcorners1" v-if="cardPermission">
        <b-field :label="$t('Tarjeta')">
          <h5>
            {{
              cashMovements.summary.tpv_order_amount
                ? cashMovements.summary.tpv_order_amount.toFixed(2)
                : '0.0'
            }}
            €
          </h5>
        </b-field>
        <b-field :label="$t('Nº pedidos tarjeta')">
          <h5>
            {{
              cashMovements.summary.tpv_order_movement
                ? cashMovements.summary.tpv_order_movement
                : '0'
            }}
          </h5>
        </b-field>
      </div>

      <div
        v-if="
          (this.currentClub.functionalities.funciones_avanzadas_gestion ||
            this.currentClub.functionalities.monedero) &&
            walletPermission
        "
        class="rcorners1"
      >
        <b-field :label="$t('Recarga monedero con cash')">
          <h5>
            {{
              cashMovements.summary.wallet_cash_amount
                ? cashMovements.summary.wallet_cash_amount.toFixed(2)
                : '0.0'
            }}
            €
          </h5>
        </b-field>
        <b-field :label="$t('Nº recargas monedero con cash')">
          <h5>
            {{
              cashMovements.summary.wallet_cash_movement
                ? cashMovements.summary.wallet_cash_movement
                : '0'
            }}
          </h5>
        </b-field>
      </div>

      <div
        v-if="
          (this.currentClub.functionalities.funciones_avanzadas_gestion ||
            this.currentClub.functionalities.monedero) &&
            walletPermission &&
            cardPermission
        "
        class="rcorners1"
      >
        <b-field :label="$t('Recarga monedero con TPV')">
          <h5>
            {{
              cashMovements.summary.wallet_tpv_amount
                ? cashMovements.summary.wallet_tpv_amount.toFixed(2)
                : '0.0'
            }}
            €
          </h5>
        </b-field>
        <b-field :label="$t('Nº recargas monedero con TPV')">
          <h5>
            {{
              cashMovements.summary.wallet_tpv_movement
                ? cashMovements.summary.wallet_tpv_movement
                : '0'
            }}
          </h5>
        </b-field>
      </div>

      <div class="rcorners1">
        <b-field :label="$t('Cantidad inicial en caja')">
          <h5>
            {{
              cashMovements.summary.amount_initial
                ? cashMovements.summary.amount_initial.toFixed(2)
                : '0'
            }}
            €
          </h5>
        </b-field>
        <b-field :label="$t('Cantidad total en caja')">
          <h5>
            {{
              cashMovements.summary.cash_box_total_amount
                ? cashMovements.summary.cash_box_total_amount.toFixed(2)
                : '0'
            }}
            €
          </h5>
        </b-field>
      </div>

      <div class="rcorners1">
        <b-field :label="$t('Movimientos')">
          <b-field label="Manual" class="mr-2">
            <h5>
              {{
                cashMovements.summary.type_manual
                  ? cashMovements.summary.type_manual
                  : '0'
              }}
            </h5>
          </b-field>
          <b-field :label="$t('Automático')" class="mr-2">
            <h5>
              {{
                cashMovements.summary.type_automatic
                  ? cashMovements.summary.type_automatic
                  : '0'
              }}
            </h5>
          </b-field>
          <b-field
            v-if="
              (this.currentClub.functionalities.funciones_avanzadas_gestion ||
                this.currentClub.functionalities.monedero) &&
                walletPermission
            "
            :label="$t('Monedero')"
            class="mr-2"
          >
            <h5>
              {{
                cashMovements.summary.type_wallet
                  ? cashMovements.summary.type_wallet
                  : '0'
              }}
            </h5>
          </b-field>
          <b-field :label="$t('Pedido')">
            <h5>
              {{
                cashMovements.summary.type_order
                  ? cashMovements.summary.type_order
                  : '0'
              }}
            </h5>
          </b-field>
        </b-field>
      </div>
    </div>

    <b-table
      v-if="cashMovements"
      style="text-align: left;"
      :data="this.cashMovements.movements"
      :sticky-header="true"
      :paginated="isPaginated"
      :per-page="perPage"
      :current-page.sync="currentPage"
      :pagination-simple="isPaginationSimple"
      :pagination-position="paginationPosition"
      :default-sort-direction="defaultSortDirection"
      :pagination-rounded="isPaginationRounded"
      default-sort="created"
      aria-next-label="Siguiente"
      aria-previous-label="Anterior"
      aria-page-label="Página"
      aria-current-label="Actual"
      :selected.sync="selected"
      hoverable
      striped
      height="calc(100vh - 322px)"
    >
      <b-table-column
        field="mid"
        :label="$t('ID Movimiento')"
        sortable
        v-slot="props"
        searchable
        width="50"
      >
        {{ props.row.mid }}
      </b-table-column>

      <b-table-column
        field="created"
        :label="$t('Fecha')"
        sortable
        v-slot="props"
        searchable
        width="50"
      >
        {{ format_date(props.row.created, 'hh:mm dd/MM/yyyy') }}
      </b-table-column>

      <b-table-column
        field="employee_name"
        :label="$t('Empleado')"
        v-slot="props"
        sortable
        searchable
        width="150"
      >
        {{ props.row.employee_name }}
      </b-table-column>

      <b-table-column
        field="action"
        :label="$t('Tipo')"
        sortable
        v-slot="props"
        searchable
        width="50"
      >
        {{ props.row.action }}
      </b-table-column>

      <b-table-column
        field="amount"
        :label="$t('Cantidad')"
        sortable
        v-slot="props"
        searchable
        width="50"
      >
        {{ calculateCurrentAmout(props.row) }}
      </b-table-column>

      <b-table-column
        field="status"
        :label="'Status'"
        sortable
        v-slot="props"
        searchable
        width="50"
      >
        {{ props.row.status }}
      </b-table-column>

      <!-- <b-table-column
        field="uid"
        :label="$t('Trabajador')"
        sortable
        v-slot="props"
        searchable
        width="50"
      >
        <b-button :label="props.row.employee_membercard_id"> </b-button>
      </b-table-column> -->

      <b-table-column
        field="note"
        :label="$t('Nota')"
        v-slot="props"
        width="50"
      >
        {{ props.row.note }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'

export default {
  data() {
    return {
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'desc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 15,
      selected: null,
      is_editing: false,
      note: null,
      currentAmount: null
    }
  },
  mixins: [ToolsMixin, InitViewMixin],

  computed: {
    ...mapState('club', ['currentClub']),
    ...mapState('register', ['cashMovements']),
    ...mapState('club', [
      'cashPermission',
      'cardPermission',
      'walletPermission'
    ])
  },
  methods: {
    calculateCurrentAmout(row) {
      // if (row.action === 'remove') {
      //   return Number(row.amount) * -1
      // } else {
      //   return row.amount
      // }
      return row.amount
    },
    restCalculate() {
      return this.currentAmount - this.cashMovements.summary.total_amount
    },
    closeRegister() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      let json = {
        asocid: this.currentClub.nid,
        currentAmount: this.currentAmount,
        note: this.note
      }
      this.$store.dispatch('register/closeRegister', json).then(() => {
        loadingComponent.close()
        this.$emit('close')
      })
    },
    downloadData() {
      this.validateAccess(['software_gestion_club'])
      this.validateRoles(['Administrador', 'Manager', 'Caja', 'Budtender'])
      this.$store.dispatch('register/fetechCashMovement', {
        asocid: this.currentClub.nid
      })
    }
  },
  created() {
    this.downloadData()
  },
  watch: {
    currentClub() {
      this.downloadData()
    }
  }
}
</script>

<style lang="scss">
.flex-gap {
  display: inline-flex;
  flex-wrap: wrap;
}

.flex-gap > div {
  margin: 6px;
}

.rcorners1 {
  border-radius: 12px;
  background: #f1f1f1;
  padding: 14px;
  //   width: 200px;
  //   height: 150px;
}
h2 {
  font-size: 18px;
  font-weight: bold;
}
</style>
