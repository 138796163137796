<template>
  <div class="dispensary">
    <div style="padding:10px">
      <b-select
        name="LeaveType"
        @change.native="selectedList($event)"
        class="form-control"
        v-model="listIndex"
      >
        <option
          v-for="(list, index) in dispensary"
          :value="index"
          :key="list.nid"
        >
          {{ list.title }}
        </option>
      </b-select>
    </div>

    <!-- <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" /> -->
    <div class="w3-row-padding" v-if="this.dispensary.length > 0">
      <div
        class="w3-third w3-container w3-margin-bottom"
        v-for="item in this.dispensary[current_list_index].item"
        :key="item.nid"
        :item="item"
        style="height:300px"
      >
        <router-link :to="'/dispensary_item?id=' + item.nid">
          <div v-if="item.images.length > 0">
            <img
              :src="item.images[0].img"
              style="max-height:150px; border-radius:6px"
              class="w3-hover-opacity"
            />
          </div>

          <div class="w3-container w3-white">
            <h4>{{ item.title }}</h4>
            <p>{{ item.subtitle }}</p>
            <p v-html="item.body.substring(0, 150)"></p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'

export default {
  data() {
    return {
      listIndex: 0
    }
  },
  mixins: [InitViewMixin],

  methods: {
    selectedList(event) {
      this.$store.dispatch(
        'dispensaryBasic/setCurrentListIndex',
        this.listIndex
      )
    },
    loadDispensary() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.$store
        .dispatch('dispensaryBasic/fetchDispensary', this.currentClub.nid)
        .then(() => {
          loadingComponent.close()
        })
    }
  },
  computed: {
    ...mapState('dispensaryBasic', ['dispensary', 'current_list_index'])
  },
  created() {
    this.validateAccess(['comunicacion'])
    this.validateRoles(['Administrador', 'Manager', 'Social'])
    this.$analytics.logEvent('Dispensary.vue')
    this.$store.dispatch('setShowNavigationBar', true)
    this.listIndex = this.current_list_index
    this.loadDispensary()
  },
  watch: {
    currentClub() {
      this.validateAccess(['comunicacion'])
      this.validateRoles(['Administrador', 'Manager', 'Social'])
      this.loadDispensary()
    }
  }
}
</script>
<style lang="scss">
$bg-classroom: #232323;
.classroom {
  background: $bg-classroom;
}
</style>
<style scoped></style>
