import session from '@/services/Session.js'

export default {
  getUsers(asocid) {
    return session.get('/api/association_user/retrieve?association=' + asocid)
  },
  getUserByDNI(asocid, dni) {
    return session.get(
      '/api/getpatientbydni/retrieve?association=' + asocid + '&dni=' + dni
    )
  },
  createUser(patient) {
    return session.post('/api/patient_dni', patient)
  }
}
