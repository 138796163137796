<template>
  <div class="ml-5">
    <h2 class="mt-3 mb-2 is-size-2" style="text-align: left">
      {{ $t('NOVEDADES') }}
    </h2>
    <div
      v-for="novelty in this.novelties"
      :key="novelty.nid"
      style="text-align: left;"
    >
      <div
        class="mt-5 has-background-light"
        style="border-radius: 15px;padding:20px"
      >
        <div>
          <b class="is-size-4"> {{ novelty.title }}</b>
        </div>
        <div class="mt-1 is-italic">
          {{ format_date(novelty.date, 'dd/MM/yyyy') }}
        </div>
        <div class="mt-1 mr-5" v-html="novelty.body"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { InitViewMixin } from '@/mixins/InitViewMixin.js'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import session from '@/services/Session.js'
import * as versionjson from '@/version.json'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      novelties: []
    }
  },
  computed: {
    ...mapState('club', ['newNovelties'])
  },
  mixins: [InitViewMixin, ToolsMixin],
  methods: {
    downloadData() {
      session.get('/api/updates_news').then(response => {
        // console.log(response.data.data[0])
        this.novelties = response.data.data.reverse()
      })
    }
  },
  mounted() {
    this.$analytics.logEvent('Novelties.vue')
    this.$store.dispatch('setShowNavigationBar', true)
    this.downloadData()
    localStorage.setItem(
      'novelties_current_version',
      versionjson.default.version
    )
    this.$store.dispatch(
      'club/setNovelties',
      versionjson.default.version !=
        localStorage.getItem('novelties_current_version')
    )
    // this.downloadData(this.$route.query.ms_id)
  }
}
</script>

<style lang="scss" scoped></style>
