import session from '@/services/Session.js'

export default {
  getConsults(asocid) {
    return session.get('/api/consult/retrieve?association=' + asocid)
  },
  getConsult(asocid, nid) {
    return session.get(
      '/api/consult/retrieve?association=' + asocid + '&nid=' + nid
    )
  },
  deleteConsult(nid) {
    return session.delete('/api/consult/' + nid)
  },
  postComment(json) {
    return session.post('/api/consult/create', json)
  }
}
