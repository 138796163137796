import session from '@/services/Session.js'

export default {
  sendPushNotification(asocid, body, uids) {
    let json = {
      association: asocid,
      body: body,
      uids: uids
    }
    console.log(json)
    return session.post('/api/notification/create', json)
  },
  sendSMS(tlfNumber, body) {
    console.log(tlfNumber)
    console.log(body)
    require('dotenv').config()
    console.log(
      'Your environment variable VUE_APP_TWILIO_ACCOUNT_SID has the value: ',
      process.env.VUE_APP_TWILIO_ACCOUNT_SID
    )

    const accountSid = process.env.VUE_APP_TWILIO_ACCOUNT_SID
    const authToken = process.env.VUE_APP_TWILIO_AUTH_TOKEN
    const client = require('twilio')(accountSid, authToken)

    client.messages
      .create({
        body: 'body',
        from: '+34955160457',
        to: '+34662996232'
      })
      .then(message => console.log(message.sid))
  }
}
