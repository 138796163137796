<template>
  <div>
    <h2 class="mt-3 mb-2" style="text-align: left">
      {{ $t('PEDIDOS EN PROCESO') }}
    </h2>
    <b-table
      class="my-table mr-2"
      style="text-align: left;"
      :data="this.orders"
      :sticky-header="true"
      :paginated="isPaginated"
      :per-page="perPage"
      :opened-detailed="defaultOpenedDetails"
      detailed
      detail-key="order_id"
      :show-detail-icon="showDetailIcon"
      :current-page.sync="currentPage"
      :pagination-simple="isPaginationSimple"
      :pagination-position="paginationPosition"
      :default-sort-direction="defaultSortDirection"
      :pagination-rounded="isPaginationRounded"
      default-sort="order_changed"
      aria-next-label="Siguiente"
      aria-previous-label="Anterior"
      aria-page-label="Página"
      aria-current-label="Actual"
      hoverable
      striped
      height="90%"
    >
      <b-table-column
        field="order_id"
        label="ID"
        sortable
        v-slot="props"
        searchable
        width="50"
      >
        {{ props.row.order_id }}
      </b-table-column>

      <b-table-column
        field="order_changed"
        :label="$t('Fecha')"
        sortable
        v-slot="props"
        searchable
        width="150"
      >
        {{ format_date(props.row.order_changed, 'hh:mm dd/MM/yyyy') }}
      </b-table-column>

      <b-table-column
        :label="$t('Nº prod.')"
        sortable
        v-slot="props"
        width="50"
      >
        {{ props.row.products.length }}
      </b-table-column>

      <b-table-column :label="$t('Total')" sortable v-slot="props" width="100">
        {{ calculateTotal(props.row).toFixed(2) }} €
      </b-table-column>

      <b-table-column
        field="order_status"
        :label="'Status'"
        sortable
        v-slot="props"
        searchable
      >
        {{ props.row.order_status }}
      </b-table-column>

      <b-table-column
        field="membership_card.number"
        :label="$t('NºSoc')"
        sortable
        v-slot="props"
        searchable
      >
        {{ props.row.membership_card.number }}
      </b-table-column>

      <b-table-column
        field="membership_card.nombre"
        :label="$t('Nombre')"
        sortable
        v-slot="props"
        searchable
      >
        {{ props.row.membership_card.nombre }}
      </b-table-column>

      <b-table-column
        field="membership_card.surname"
        :label="$t('Apellidos')"
        sortable
        v-slot="props"
        searchable
      >
        {{ props.row.membership_card.surname }}
      </b-table-column>

      <b-table-column
        field="membership_card.dni"
        :label="$t('DNI')"
        sortable
        v-slot="props"
        searchable
      >
        {{ props.row.membership_card.dni }}
      </b-table-column>

      <b-table-column :label="$t('Acción')" v-slot="props">
        <b-button class="is-small" @click="goToPOS(props.row)">
          {{ $t('Ver') }}
        </b-button>
      </b-table-column>

      <template #detail="props">
        <b-table v-if="props.row.products" :data="props.row.products">
          <b-table-column
            field="code"
            :label="$t('SKU')"
            sortable
            v-slot="props"
          >
            {{ props.row.product.code }}
          </b-table-column>
          <b-table-column
            field="title"
            :label="$t('Producto')"
            sortable
            v-slot="props"
          >
            {{ props.row.product.title }}
          </b-table-column>

          <b-table-column
            field="category"
            :label="$t('Categoría')"
            sortable
            v-slot="props"
          >
            {{ props.row.product.category }}
          </b-table-column>

          <b-table-column
            field="quantity"
            :label="$t('Cantidad')"
            sortable
            v-slot="props"
          >
            {{ props.row.quantity }}
          </b-table-column>

          <b-table-column
            field="total"
            :label="$t('Total')"
            sortable
            v-slot="props"
          >
            {{ props.row.total.toFixed(2) }}
          </b-table-column>

          <!-- <b-table-column label="Acción">
                  <b-button class="is-small">Añadir</b-button>
                  <b-button class="is-small">Retirar</b-button>
                  <b-button class="is-small">Mover</b-button>
                  <b-button class="is-small">Convertir</b-button>
                </b-table-column> -->
        </b-table>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'

export default {
  data() {
    return {
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'desc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 24
    }
  },
  mixins: [InitViewMixin, ToolsMixin],
  computed: {
    ...mapState('orders', ['orders'])
  },
  methods: {
    goToPOS(order) {
      this.$router.push({
        name: 'pos',
        params: { order: order }
      })
    },
    calculateTotal(order) {
      let total = 0
      // console.log(order)
      order.products.forEach(product => {
        total = total + Number(product.total)
      })
      return total
    },
    calculateQty(order) {
      let total = 0
      order.products.forEach(product => {
        total = total + Number(product.quantity)
      })
      return total
    },
    downloadData() {
      this.validateAccess(['software_gestion_club'])
      this.validateRoles(['Administrador', 'Manager', 'Budtender'])
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.$store
        .dispatch('orders/fetchClubOrdersInProcess', {
          asocid: this.currentClub.nid
        })
        .then(() => {
          loadingComponent.close()
        })
        .catch(error => {
          loadingComponent.close()
        })
    }
  },
  created() {
    this.$analytics.logEvent('OrdersInProcess.vue')
    this.$store.dispatch('setShowNavigationBar', true)
    this.downloadData()
  },
  watch: {
    currentClub() {
      this.downloadData()
    }
  }
}
</script>

<style lang="scss" scoped></style>
