import {
  SET_INVENTORY,
  SET_INVENTORY_ITEM,
  CLEAR_INVENTORY,
  CLEAR_INVENTORY_ITEM,
  REMOVE_INVENTORY_FROM_STORE,
  ADD_INVENTORY_ITEM_TO_INVENTORY,
  SET_STOCK
} from './types'
import APIInventory from '@/services/APIInventory.js'
import tools from '@/Tools/tools.js'

const initialState = {
  inventory: [],
  inventory_item: Object,
  stock: []
}
const getters = {
  getItemById: state => id => {
    if (initialState.inventory == []) {
      return initialState.inventory.item.find(item => item.nid === id)
    }
    return false
  }
}

const mutations = {
  [SET_INVENTORY](state, inventory) {
    state.inventory = inventory
  },
  [SET_INVENTORY_ITEM](state, inventoryItem) {
    state.inventory_item = inventoryItem
  },
  [ADD_INVENTORY_ITEM_TO_INVENTORY](state, product) {
    state.inventory.push(product)
  },
  [CLEAR_INVENTORY](state) {
    state.inventory = []
  },
  [CLEAR_INVENTORY_ITEM](state) {
    state.inventory_item = Object
  },
  [REMOVE_INVENTORY_FROM_STORE](state, product) {
    state.inventory.splice(state.inventory.indexOf(product), 1)
  },
  [SET_STOCK](state, stock) {
    state.stock = stock
  }
}
const actions = {
  initialize({ commit, state }) {},

  fetchInventory({ commit }, asocid) {
    if (initialState.inventory.length) {
      return initialState.inventory
    }
    let promise = APIInventory.getInventory(asocid)
      .then(response => {
        console.log(response.data)
        commit(SET_INVENTORY, response.data)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  //   fetchDispensaryItem({ commit, getters }, { asocid, itemId }) {
  //     var item = getters.getItemById(itemId, initialState.current_list_index)
  //     if (item) {
  //       commit(SET_INVENTORY_ITEM, item)
  //       return
  //     }

  //     commit(CLEAR_INVENTORY_ITEM)
  //     let promise = APIInventory.getDispensaryItem(asocid, itemId)
  //       .then(response => {
  //         commit(SET_INVENTORY_ITEM, response.data)
  //       })
  //       .catch(error => {
  //         console.log('Ha habido un error:' + error)
  //       })
  //     return promise
  //   },
  createInventoryItem(
    { commit },
    { asocid, title, category, status, dominance, type }
  ) {
    let json = {
      asocid: asocid,
      title: title,
      category: category,
      status: status,
      dominance: dominance,
      type: type
    }

    let promise = APIInventory.createinventoryItem(json)
      .then(response => {
        console.log(response)
        // Añadir nuevo item al store
        // commit(SET_USERS, response.data)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  doMovement(
    { commit },
    { asocid, iid, action, images, amount, location, cost, note }
  ) {
    let json = {
      asocid: asocid,
      iid: iid,
      action: action,
      images: images,
      amount: amount,
      location: location,
      cost: cost,
      note: note
    }
    let promise = APIInventory.doMovement(json)
      .then(response => {
        console.log(response)
        // Añadir nuevo item al store
        // commit(SET_USERS, response.data)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  // deleteProduct({ commit }, { asocid, product }) {
  //   let promise = APIInventory.deleteProduct(asocid, product.code)
  //     .then(response => {
  //       commit(REMOVE_INVENTORY_FROM_STORE, product)
  //     })
  //     .catch(error => {
  //       console.log('Ha habido un error:' + error)
  //     })
  //   return promise
  // },
  clearInventory({ commit }) {
    commit(CLEAR_INVENTORY)
  },

  fetchStock({ commit }, asocid) {
    if (initialState.inventory.length) {
      return initialState.inventory
    }
    let promise = APIInventory.getStock(asocid)
      .then(response => {
        console.log(response.data)
        commit(SET_STOCK, response.data)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  saveStockChanges({ commit }, { asocid, data, stock_type }) {
    console.log('saveStockChanges')
    var products = []
    data.forEach(product => {
      console.log(product.new_stock)
      if (product.new_stock) {
        let newprod = {
          pid: product.pid,
          stock: Number(product.new_stock),
          stock_type: stock_type,
          note: product.note
        }
        products.push(newprod)
      }
    })
    let json = {
      asocid: asocid,
      products: products
    }
    console.log(json)
    let promise = APIInventory.postStock(json)
      .then(response => {
        console.log(response)
        // Añadir nuevo item al store
        // commit(SET_USERS, response.data)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}
