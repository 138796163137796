<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">
        {{ $t('Modificar stock') }}
      </p>
      <button type="button" class="delete ml-4" @click="$emit('close')" />
    </header>
    <section class="modal-card-body">
      <div style="display:flex">
        <b-field :label="$t('Stock Actual')">
          <span>{{ product.stock }}</span>
        </b-field>
        <b-field v-if="new_quantity" class="ml-4" :label="$t('Nuevo stock')">
          <span>{{ new_quantity }}</span>
        </b-field>
      </div>
      <hr />
      <b-field :label="$t('Cantidad')">
        <b-input v-model="quantity" type="number" step="0.01"> </b-input>
        <b-select v-model="action">
          <option value="add">{{ $t('Añadir') }}</option>
          <option value="remove">{{ $t('Retirar') }}</option>
        </b-select>
      </b-field>
      <b-field :label="$t('Nota')">
        <b-input v-model="note" maxlength="200" type="textarea"> </b-input>
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <b-button
        :label="$t('Ajustar')"
        type="is-primary"
        @click="saveChanges()"
      />
    </footer>
  </div>
</template>

<script>
import { InitViewMixin } from '@/mixins/InitViewMixin.js'
export default {
  data() {
    return {
      is_editing: false,
      quantity: null,
      new_quantity: null,
      note: '',
      action: 'add'
    }
  },
  props: {
    product: Object,
    currentClub: Object
  },

  //   mixins: [InitViewMixin],
  methods: {
    saveChanges() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      let obj = {
        pid: this.product.pid,
        note: this.note
      }
      if (this.action == 'add') {
        obj.new_stock = Number(this.quantity)
        let new_stock = Number(this.product.stock) + Number(this.quantity)
        this.product.stock = new_stock.toFixed(2)
      } else {
        obj.new_stock = -1 * Number(this.quantity)
        let new_stock = Number(this.product.stock) - Number(this.quantity)
        this.product.stock = new_stock.toFixed(2)
      }

      this.$store
        .dispatch('inventory/saveStockChanges', {
          asocid: this.currentClub.nid,
          data: [obj],
          stock_type: 'manual'
        })
        .then(() => {
          loadingComponent.close()
          this.$emit('close')
          //   this.downloadData()
        })
    },
    downloadData() {
      //   this.$store.dispatch('register/fetechCashMovement', {
      //     asocid: this.currentClub.nid
      //   })
    },
    recalculate() {
      if (this.action == 'add') {
        this.new_quantity = (
          Number(this.quantity) + Number(this.product.stock)
        ).toFixed(2)
      } else {
        this.new_quantity = (
          Number(this.product.stock) - Number(this.quantity)
        ).toFixed(2)
      }
    }
  },
  created() {
    this.downloadData()
  },
  watch: {
    action() {
      this.recalculate()
    },
    quantity() {
      this.recalculate()
    }
  }
}
</script>

<style lang="scss" scoped></style>
