<template>
  <div :class="keyboardClass"></div>
</template>

<script>
import Keyboard from 'simple-keyboard'
import 'simple-keyboard/build/css/index.css'

export default {
  name: 'SimpleKeyboard',

  props: {
    keyboardClass: {
      default: 'simple-keyboard',
      type: String
    },
    input: {
      type: String
    },
    withNext: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    keyboard: null
  }),
  mounted() {
    if (this.withNext) {
      this.keyboard = new Keyboard({
        onChange: this.onChange,
        onKeyPress: this.onKeyPress,
        display: {
          '{bksp}': this.$t('Borrar')
        },
        layout: {
          default: [
            '1 2 3',
            '4 5 6',
            '7 8 9',
            '{bksp} 0 .',
            this.$t('Siguiente')
          ]
        },
        theme: 'hg-theme-default hg-layout-numeric numeric-theme'
      })
    } else {
      this.keyboard = new Keyboard({
        onChange: this.onChange,
        onKeyPress: this.onKeyPress,
        display: {
          '{bksp}': this.$t('Borrar')
        },
        layout: {
          default: ['1 2 3', '4 5 6', '7 8 9', '{bksp} 0 .']
        },
        theme: 'hg-theme-default hg-layout-numeric numeric-theme'
      })
    }
  },
  methods: {
    onChange(input) {
      this.$emit('onChange', input)
    },
    onKeyPress(button) {
      this.$emit('onKeyPress', button)
      /**
       * If you want to handle the shift and caps lock buttons
       */
      // if (button === '{shift}' || button === '{lock}') this.handleShift()
      // if (button === 'Siguiente') this.handleNext()
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName
      let shiftToggle = currentLayout === 'default' ? 'shift' : 'default'

      this.keyboard.setOptions({
        layoutName: shiftToggle
      })
    },
    handleNext() {
      console.log('NEXT!')
    }
  },
  watch: {
    input(input) {
      this.keyboard.setInput(input)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
