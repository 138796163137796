import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login.vue'
import Dispensary from '@/views/Dispensary/Dispensary.vue'
import DispensaryDetail from '@/views/Dispensary/DispensaryDetail.vue'
import UserProfile from '@/views/Users/UserProfile.vue'
import Club from '@/views/Club/Club.vue'
import SMSManager from '@/views/Utils/SMSManager.vue'
import TVShows from '@/views/TVShows/TVShows.vue'
import TVShow1 from '@/views/TVShows/TVShow1.vue'
import TVShow2 from '@/views/TVShows/TVShow2.vue'
import TVShow3 from '@/views/TVShows/TVShow3.vue'
import TVShowSettings from '@/views/TVShows/TVShowSettings.vue'
import PointOfSale from '@/views/POS/POS.vue'
import UserValidation from '@/views/Users/UserValidation.vue'
import DniRead from '@/components/DNIRead.vue'
import UserInvite from '@/views/Users/UserInvite.vue'
import PushNotificationsManager from '@/views/Utils/PushNotificationsManager.vue'
import Consults from '@/views/Consults/Consults.vue'
import UsersManager from '@/views/Users/UsersManager.vue'
import DescargaApp from '@/views/Utils/DescargaApp.vue'
import News from '@/views/News/News.vue'
import NewsCreate from '@/views/News/NewsCreate.vue'
import HtmlEditor from '@/views/Utils/HtmlEditor.vue'
import Events from '@/views/Events/Events.vue'
import EventCreate from '@/views/Events/EventCreate.vue'
import DispensaryProductCreateBasic from '@/views/Dispensary/DispensaryProductCreateBasic.vue'
import DispensaryProductCreatePro from '@/views/Dispensary/DispensaryProductCreatePro.vue'
import DispensaryManagerPro from '@/views/Dispensary/DispensaryManagerPro.vue'
import DispensaryManagerBasic from '@/views/Dispensary/DispensaryManagerBasic.vue'
import ClubManager from '@/views/Club/ClubManager.vue'
import PatientCreate from '@/views/Users/PatientCreate.vue'
import PatientManager from '@/views/Users/PatientManager.vue'
import InventoryManager from '@/views/Inventory/InventoryManager.vue'
import OrdersReport from '@/views/Reports/OrdersReport.vue'
import OrdersInProcess from '@/views/POS/OrdersInProcess.vue'
import AccessControlReport from '@/views/Reports/AccessControlReport.vue'
import MovementsReport from '@/views/Register/MovementsReport.vue'
import CloseRegisterReport from '@/views/Register/CloseRegisterReport.vue'
import CloseRegisterhistory from '@/views/Register/CloseRegisterHistory.vue'
import CloseRegisterHistoryDetail from '@/views/Register/CloseRegisterHistoryDetail.vue'
import StockManager from '@/views/POS/StockManager.vue'
import StaffManager from '@/views/Users/StaffManager.vue'
import ClosingReport from '@/views/Reports/ClosingReport.vue'
import DispensationReport from '@/views/Reports/DispensationReport.vue'
import WalletReport from '@/views/Reports/WalletReport.vue'
import EfficiencyReport from '@/views/Reports/EfficiencyReport.vue'
import ProductHistoryReport from '@/views/Inventory/ProductHistoryReport.vue'
import Novelties from '@/views/Novelties/Novelties.vue'
import Cupons from '@/views/Cupons/Cupons.vue'
import CuponCreate from '@/views/Cupons/CuponCreate.vue'
import CuponUsesList from '@/views/Cupons/CuponUsesList.vue'
import StockHistoryReport from '@/views/Reports/StockHistoryReport.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/dispensary_item',
    name: 'DispensaryDetail',
    component: DispensaryDetail,
    props: route => ({
      id: route.query.id,
      asocid: route.query.asocid
    })
  },

  {
    path: '/dispensary',
    name: 'dispensary',
    component: Dispensary
  },
  {
    path: '/dispensarymanagerpro',
    name: 'dispensarymanagerpro',
    component: DispensaryManagerPro
  },
  {
    path: '/dispensarymanagerbasic',
    name: 'dispensarymanagerbasic',
    component: DispensaryManagerBasic
  },
  {
    path: '/dispensaryproductcreatebasic',
    name: 'dispensaryproductcreatebasic',
    component: DispensaryProductCreateBasic
  },
  {
    path: '/dispensaryproductcreatepro',
    name: 'dispensaryproductcreatepro',
    component: DispensaryProductCreatePro
  },
  {
    path: '/userprofile',
    name: 'userprofile',
    component: UserProfile
  },
  {
    path: '/club',
    name: 'club',
    component: Club
  },
  {
    path: '/clubmanager',
    name: 'clubmanager',
    component: ClubManager
  },
  {
    path: '/smsmanager',
    name: 'smsmamager',
    component: SMSManager
  },
  {
    path: '/pushnotificationsmanager',
    name: 'pushnotificationsmanager',
    component: PushNotificationsManager
  },
  {
    path: '/tvshows',
    name: 'tvshows',
    component: TVShows
  },
  { path: '/carta', redirect: { name: 'tvshows' } },
  {
    path: '/tvshow1',
    name: 'tvshow1',
    component: TVShow1
  },
  {
    path: '/tvshow2',
    name: 'tvshow2',
    component: TVShow2
  },
  {
    path: '/tvshow3',
    name: 'tvshow3',
    component: TVShow3
  },
  {
    path: '/tvshowsettings',
    name: 'tvshowsettings',
    component: TVShowSettings
  },
  {
    path: '/pos',
    name: 'pos',
    component: PointOfSale,
    props: route => ({
      ...route.params
    })
  },
  {
    path: '/uservalidation',
    name: 'uservalidation',
    component: UserValidation
  },
  {
    path: '/dniread',
    name: 'dniread',
    component: DniRead
  },
  {
    path: '/userinvite',
    name: 'userinvite',
    component: UserInvite
  },
  {
    path: '/patientcreate',
    name: 'patientcreate',
    component: PatientCreate,
    props: true
  },
  {
    path: '/patientmanager',
    name: 'patientmanager',
    component: PatientManager,
    props: true
  },
  {
    path: '/usersmanager',
    name: 'usersmanager',
    component: UsersManager
  },

  {
    path: '/consults',
    name: 'consults',
    component: Consults
  },
  {
    path: '/descarga',
    name: 'descargaapp',
    component: DescargaApp
  },
  {
    path: '/news',
    name: 'news',
    component: News
  },
  {
    path: '/newscreate',
    name: 'newscreate',
    component: NewsCreate
  },
  {
    path: '/htmleditor',
    name: 'htmleditor',
    component: HtmlEditor
  },
  {
    path: '/events',
    name: 'events',
    component: Events
  },
  {
    path: '/eventcreate',
    name: 'eventcreate',
    component: EventCreate
  },
  {
    path: '/inventorymanager',
    name: 'inventorymanager',
    component: InventoryManager
  },
  {
    path: '/ordersReport',
    name: 'ordersReport',
    component: OrdersReport
  },
  {
    path: '/ordersInProcess',
    name: 'ordersInProcess',
    component: OrdersInProcess
  },
  {
    path: '/accesscontrolreport',
    name: 'accessControlReport',
    component: AccessControlReport
  },
  {
    path: '/movementsreport',
    name: 'movementsreport',
    component: MovementsReport,
    props: true
  },
  {
    path: '/closingregisterreport',
    name: 'closingregisterreport',
    component: CloseRegisterReport,
    props: true
  },
  {
    path: '/closeregisterhistory',
    name: 'closeregisterhistory',
    component: CloseRegisterhistory,
    props: true
  },
  {
    path: '/closeregisterhistorydetail',
    name: 'closeregisterhistorydetail',
    component: CloseRegisterHistoryDetail,
    props: true
  },
  {
    path: '/stockmanager',
    name: 'stockmanager',
    component: StockManager,
    props: true
  },
  {
    path: '/staffmanager',
    name: 'staffmanager',
    component: StaffManager
  },
  {
    path: '/closingreport',
    name: 'closingreport',
    component: ClosingReport
  },
  {
    path: '/dispensationReport',
    name: 'dispensationReport',
    component: DispensationReport
  },
  {
    path: '/walletReport',
    name: 'walletReport',
    component: WalletReport
  },
  {
    path: '/efficiencyReport',
    name: 'efficiencyReport',
    component: EfficiencyReport
  },
  {
    path: '/productHistoryReport',
    name: 'productHistoryReport',
    component: ProductHistoryReport
  },
  {
    path: '/novelties',
    name: 'novelties',
    component: Novelties
  },
  {
    path: '/cupons',
    name: 'cupons',
    component: Cupons
  },
  {
    path: '/cuponCreate',
    name: 'cuponcreate',
    component: CuponCreate,
    props: true
  },
  {
    path: '/cuponUsesList',
    name: 'cuponUsesList',
    component: CuponUsesList,
    props: true
  },
  {
    path: '/stockHistoryReport',
    name: 'stockHistoryReport',
    component: StockHistoryReport,
    props: true
  }

  // {
  //   path: '/shoppingcart',
  //   name: 'shoppingcart',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../views/ShoppingCart.vue')
  // }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  // base: '/app/',
  routes
})

export default router
