import { SET_USERS, SET_USER_DATA } from './types'
import APIUsers from '@/services/APIUsers.js'
import tools from '@/Tools/tools.js'

const initialState = {
  users: [],
  userData: {}
}

const mutations = {
  [SET_USERS](state, users) {
    state.users = users
  },
  [SET_USER_DATA](state, userData) {
    state.userData = userData
  }
}
const actions = {
  fetchUsers({ commit }, asocid) {
    let promise = APIUsers.getUsers(asocid)
      .then(response => {
        commit(SET_USERS, response.data)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  fetchUserByDni({ commit }, { asocid, dni }) {
    let promise = APIUsers.getUserByDNI(asocid, dni)
    // .then(response => {
    //   commit(SET_USER_DATA, response.data)
    //   console.log(response)
    // })
    // .catch(error => {
    //   console.log('Ha habido un error:' + error)
    // })
    return promise
  },
  createUser({ commit }, { asocid, patient, sendsms }) {
    let json = {
      cid: asocid,
      name: patient.name,
      surname: patient.surname,
      dni: patient.dni,
      mjid: patient.mjid,
      email: patient.email,
      tlf: patient.tlf,
      birthdate: patient.birthdate,
      sendsms: sendsms
    }
    let promise = APIUsers.createUser(json)
    // .then(response => {
    //   commit(SET_USERS, response.data)
    // })
    // .catch(error => {
    //   console.log('Ha habido un error:' + error)
    // })
    return promise
  }
}

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations
}
