export const ToolsMixin = {
  data() {
    return {}
  },

  methods: {
    datestring(date) {
      return (
        date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
        // + ' ' +
        // date.getHours() +
        // ':' +
        // date.getMinutes()
      )
    },
    format_date(time, fmt) {
      if (!time) {
        return ''
      }
      var date = new Date(time * 1000)

      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + '').substr(4 - RegExp.$1.length)
        )
      }
      let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
      }
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + ''
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : this.padLeftZero(str)
          )
        }
      }
      return fmt
    },
    padLeftZero(str) {
      return ('00' + str).substr(str.length)
    },
    confirmFullScreen() {
      this.$buefy.dialog.confirm({
        title: this.$t('Pantalla completa'),
        message: this.$t('¿Deseas cambiar a modo <b>PANTALLA COMPLETA</b>?'),
        confirmText: this.$t('Sí'),
        cancelText: this.$t('No'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$refs['fullscreen'].toggle() // recommended
        }
      })
    }
  }
}
