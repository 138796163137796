<template>
  <div>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t('Socios') }}</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <!-- <b-field>
          <b-select
            :multiple="is_multiple"
            native-size="20"
            expanded
            v-model="selectedOptions"
            style="height:200px"
          >
            ><option
              v-for="usr in this.filteredDataArray"
              :key="usr.uid"
              :value="usr"
              >{{ usr.name }} ---- {{ usr.mail }}</option
            >
          </b-select>
        </b-field> -->

        <section>
          <b-tabs>
            <b-tab-item :label="$t('Listado')">
              <b-table
                :data="users"
                :columns="columns"
                :checkable="is_multiple"
                :checked-rows.sync="checkedRows"
              >
              </b-table>
            </b-tab-item>

            <b-tab-item :label="$t('QR')">
              <pre>{{ checkedRows }}</pre>
            </b-tab-item>

            <b-tab-item :label="$t('DNI / Pasaporte')">
              <pre>{{ checkedRows }}</pre>
            </b-tab-item>
          </b-tabs>
        </section>
      </section>

      <footer class="modal-card-foot">
        <button class="button" type="button" @click="$emit('close')">
          {{ $t('Cerrar') }}
        </button>

        <b-button class="button is-primary" @click="returnSelectedUsers()">{{
          $t('Terminar')
        }}</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    users: Array,
    is_multiple: Boolean
  },
  data() {
    return {
      checkedRows: [],
      columns: [
        {
          field: 'name',
          label: 'Username',
          searchable: true
        },
        {
          field: 'field_user_dni',
          label: 'DNI',
          searchable: true
        },
        {
          field: 'mail',
          label: 'Email',
          searchable: true
        }
      ]
    }
  },

  methods: {
    returnSelectedUsers() {
      this.$emit('seletedUsrs', this.checkedRows)
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped></style>
