<template>
  <div>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t('Socios activos') }}</p>

        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div
          v-if="showQRReader"
          style="display: flex; align-items: center;justify-content: center;"
        >
          <QRReader @qrResult="qrResult"> </QRReader>
        </div>
        <b-field>
          <b-input
            v-model="numberInput"
            placeholder="NºSoc"
            @keyup.native.enter="searchEvent()"
          ></b-input>
          <b-input
            v-model="nameInput"
            :placeholder="$t('Nombre')"
            @input="searchEvent"
          ></b-input>
          <b-input
            v-model="surnameInput"
            :placeholder="$t('Apellidos')"
            @input="searchEvent"
          ></b-input>
          <b-input
            v-model="dniInput"
            :placeholder="$t('DNI')"
            @input="searchEvent"
          ></b-input>
          <b-input
            ref="keychain"
            v-model="keychainInput"
            :placeholder="$t('Llavero')"
            @keyup.native.enter="searchEvent()"
          ></b-input>
          <div v-if="checkNFC()">
            <b-button @click="readNFC()">
              <b-icon icon="wifi"> </b-icon>
            </b-button>
          </div>
          <!-- <b-input
            v-model="tlfInput"
            :placeholder="$t('Teléfono')"
            @input="searchEvent"
          ></b-input> -->
          <!-- <b-input
            v-model="emailInput"
            placeholder="Email"
            @input="searchEvent"
          ></b-input> -->
          <b-button @click="searchEvent()">
            {{ $t('Buscar') }}
          </b-button>
          <b-button
            v-if="this.currentClub.functionalities.gestion_avanzada_socios"
            @click="qrtoggle()"
            style="margin-top:5px; margin-left:3px; width:30px;  height:30px; background:#ffffff; color:#000000"
            ><i class="fas fa-qrcode fa"></i>
          </b-button>
        </b-field>

        <b-table
          :data="filteredUsers"
          :checkable="is_multiple"
          :checked-rows.sync="checkedRows"
          :selected.sync="selected"
          :paginated="true"
          :per-page="perPage"
          @page-change="onPageChange"
          :total="totalElements"
          backend-pagination
        >
          <b-table-column field="foto" :label="$t('Foto')" v-slot="props">
            <div style="display:flex">
              <img
                v-if="props.row.foto"
                style="border-radius:50%; height: 30px; width: 30px; object-fit:cover;"
                :src="props.row.foto.url"
              />
            </div>
          </b-table-column>

          <b-table-column
            field="number"
            :label="$t('NºSoc')"
            sortable
            v-slot="props"
            width="40"
          >
            {{ props.row.number }}
          </b-table-column>

          <b-table-column
            field="nombre"
            :label="$t('Nombre')"
            sortable
            v-slot="props"
          >
            <span class="ml-3"> {{ props.row.nombre }}</span>
          </b-table-column>

          <b-table-column
            field="surname"
            :label="$t('Apellidos')"
            sortable
            v-slot="props"
          >
            {{ props.row.surname }}
          </b-table-column>

          <b-table-column
            field="dni"
            :label="$t('DNI')"
            sortable
            v-slot="props"
          >
            {{ props.row.dni ? props.row.dni.toUpperCase() : '' }}
          </b-table-column>

          <!-- <b-table-column
            field="email"
            :label="$t('Email')"
            sortable
            v-slot="props"
            width="80"
          >
            {{ props.row.email }}
          </b-table-column> -->

          <!-- <b-table-column field="telefono" label="Tel" sortable v-slot="props">
            {{ props.row.telefono }}
          </b-table-column> -->

          <b-table-column
            field="type"
            :label="$t('Tipo')"
            sortable
            v-slot="props"
          >
            {{ translateType(props.row.type) }}
          </b-table-column>

          <b-table-column
            field="end_date"
            :label="$t('Expiración')"
            sortable
            centered
            v-slot="props"
          >
            <span
              v-if="
                props.row.end_date &&
                  props.row.end_date < Math.floor(Date.now() / 1000)
              "
            >
              <span v-if="props.row.end_date" class="tag is-danger">
                {{ format_date(props.row.end_date, 'dd/MM/yyyy') }}
              </span>
            </span>
            <span v-else>
              <div v-if="props.row.end_date" class="tag is-success">
                {{ format_date(props.row.end_date, 'dd/MM/yyyy') }}
              </div>
            </span>
          </b-table-column>
        </b-table>
      </section>

      <footer v-if="this.is_multiple == true" class="modal-card-foot">
        <button class="button" type="button" @click="$emit('close')">
          Cerrar
          {{ $t('Cerrar') }}
        </button>

        <b-button class="button is-primary" @click="returnSelectedUsers()"
          >{{ $t('Seleccionar') }}
        </b-button>
      </footer>
    </div>
  </div>
</template>

<script>
// import QRReader from '@/components/QRReader.vue'
import APIPatients from '@/services/APIPatients.js'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import QRReader from '@/components/QRReader.vue'

export default {
  props: {
    is_multiple: Boolean,
    filterActived: Boolean,
    currentClub: Object,
    autoselectWithOneResult: Boolean
  },
  mixins: [ToolsMixin],
  data() {
    return {
      nameInput: '',
      surnameInput: '',
      keychainInput: '',
      emailInput: '',
      numberInput: '',
      tlfInput: '',

      dniInput: '',
      totalElements: 0,
      perPage: 5,
      page: 1,

      filteredUsers: [],
      keychain: '',

      checkedRows: [],
      selected: null,
      showQRReader: false
    }
  },
  components: {
    QRReader
  },
  methods: {
    translateType(type) {
      if (type === 'ludico') {
        return this.$t('Lúdico')
      } else if (type === 'terapeutico') {
        return this.$t('Terapéutico')
      }
    },
    qrtoggle() {
      if (!this.showQRReader) {
        //this.qrButtonTitle = 'Cerrar QR'
      } else {
        //this.qrButtonTitle = 'Validar mediante QR'
      }
      this.showQRReader = !this.showQRReader
      this.$matomo.trackEvent('PatientList', 'Click', 'qrReader', 1)
    },
    qrResult(result) {
      console.log('este es el resultado')
      console.log(result)
      this.keychainInput = result
      this.showQRReader = !this.showQRReader
      this.searchEvent()
    },
    checkNFC() {
      if ('NDEFReader' in window) {
        return true
      } else {
        return false
      }
    },
    readNFC() {
      this.$buefy.toast.open({
        duration: 4000,
        message: `Acerca la tarjeta o allavero`,
        position: 'is-top',
        type: 'is-success'
      })
      const ndef = new NDEFReader()
      return new Promise((resolve, reject) => {
        const ctlr = new AbortController()
        ctlr.signal.onabort = reject
        ndef.addEventListener(
          'reading',
          event => {
            this.keychainInput = event.serialNumber
            this.searchEvent()
            ctlr.abort()
            // resolve(event)
          },
          { once: true }
        )
        ndef.scan({ signal: ctlr.signal }).catch(err => reject(err))
      })
    },
    searchEvent() {
      this.page = 1
      this.fetchPatients()
    },
    fetchPatients() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      let parameters =
        '?association=' +
        this.currentClub.nid +
        '&limit=' +
        this.perPage +
        '&page=' +
        this.page +
        '&'
      if (this.numberInput) {
        parameters = parameters + 'number=' + this.numberInput + '&'
      }
      if (this.nameInput) {
        parameters = parameters + 'nombre=' + this.nameInput + '&'
      }
      if (this.surnameInput) {
        parameters = parameters + 'surname=' + this.surnameInput + '&'
      }
      if (this.dniInput) {
        parameters = parameters + 'dni=' + this.dniInput + '&'
      }
      if (this.keychainInput) {
        parameters = parameters + 'keychain=' + this.keychainInput + '&'
      }
      if (this.emailInput) {
        parameters = parameters + 'email=' + this.emailInput + '&'
      }
      if (this.tlfInput) {
        parameters = parameters + 'telefono=' + this.tlfInput + '&'
      }

      APIPatients.fetchPatientsWith(parameters)
        .then(response => {
          if (response.data.data.length == 0) {
            this.$buefy.toast.open({
              duration: 3000,
              message: this.$t('Socio no encontrado'),
              position: 'is-top',
              type: 'is-danger'
            })
          } else if (response.data.data.length == 1) {
            this.$buefy.toast.open({
              duration: 2000,
              message: `Socio encontrado`,
              position: 'is-top',
              type: 'is-success'
            })
            if (this.autoselectWithOneResult == true) {
              this.$emit('seletedUsr', response.data.data[0])
              this.$emit('close')
            } else {
              this.totalElements = response.data.total_fichas
              this.filteredUsers = response.data.data
            }
          } else {
            this.totalElements = response.data.total_fichas
            this.filteredUsers = response.data.data
          }
          loadingComponent.close()
        })
        .catch(error => {
          tools.manageAxiosError(error)
          console.log('Ha habido un error:' + error)
          loadingComponent.close()
        })
    },
    onPageChange(page) {
      this.page = page
      this.fetchPatients()
    },
    returnSelectedUsers() {
      if (this.is_multiple) {
        this.$emit('seletedUsrs', this.checkedRows)
      } else {
        this.$emit('seletedUsr', this.selected)
      }

      this.$emit('close')
    },

    qrresult(value) {
      let filtered = this.filteredUsers.filter(
        item => item.dni && item.dni == value
      )
      if (filtered.length == 1) {
        this.$emit('seletedUsr', filtered[0])
        this.$emit('close')
      }
    }
  },
  mounted() {
    this.$refs.keychain.focus()
    this.fetchPatients()
  },
  watch: {
    selected() {
      if (this.is_multiple == false) {
        this.returnSelectedUsers()
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
