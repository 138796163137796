import {
  SET_DISPENSARY,
  SET_DISPENSARY_ITEM,
  CLEAR_DISPENSARY,
  CLEAR_DISPENSARY_ITEM,
  SET_CURRENT_CATEGORY,
  CLEAR_CURRENT_CATEGORY,
  REMOVE_PRODUCT_FROM_STORE,
  ADD_PRODUCT_TO_DISPENSARY,
  SET_DISPENSARY_CATEGORIES,
  REMOVE_DISPENSARY_CATEGORY_FROM_STORE,
  ADD_DISPENSARY_CATEGORY,
  EDIT_DISPENSARY_CATEGORY,
  CLEAR_CATEGORIES,
  SET_PRESET_VARIETIES
} from './types'
import APIDispensaryPro from '@/services/APIDispensaryPro.js'
import tools from '@/Tools/tools.js'
import APIService from '../services/APIService'
import { DialogProgrammatic as Dialog } from 'buefy'

const initialState = {
  dispensary: [],
  dispensary_item: Object,
  currentCategory: null,
  categories: [],
  presetVarieties: []
}
const getters = {
  getItemByIPid: state => pid => {
    if (initialState.dispensary != []) {
      return initialState.dispensary.find(item => item.pid === pid)
    }
    return false
  },
  getCategoryByName: state => name => {
    if (initialState.categories != []) {
      return initialState.categories.find(item => item.title === name)
    }
    return false
  }
}

const mutations = {
  [SET_PRESET_VARIETIES](state, presetVarieties) {
    state.presetVarieties = presetVarieties
  },
  [SET_DISPENSARY](state, dispensary) {
    state.dispensary = dispensary
  },
  [SET_DISPENSARY_ITEM](state, dispensaryItem) {
    state.dispensary_item = dispensaryItem
  },
  [ADD_PRODUCT_TO_DISPENSARY](state, product) {
    state.dispensary.push(product)
  },
  [SET_CURRENT_CATEGORY](state, currentCategory) {
    state.currentCategory = currentCategory
  },
  [CLEAR_DISPENSARY](state) {
    state.dispensary = []
  },
  [CLEAR_DISPENSARY_ITEM](state) {
    state.dispensary_item = Object
  },
  [CLEAR_CURRENT_CATEGORY](state) {
    state.currentCategory = null
  },
  [CLEAR_CATEGORIES](state) {
    state.categories = []
  },
  [REMOVE_PRODUCT_FROM_STORE](state, product) {
    state.dispensary.splice(state.dispensary.indexOf(product), 1)
  },
  [SET_DISPENSARY_CATEGORIES](state, categories) {
    state.categories = categories
  },
  [ADD_DISPENSARY_CATEGORY](state, category) {
    state.categories.push(category)
  },
  [REMOVE_DISPENSARY_CATEGORY_FROM_STORE](state, category) {
    state.categories.splice(state.categories.indexOf(category), 1)
  },
  [EDIT_DISPENSARY_CATEGORY](state, { category, newcategory }) {
    var index = state.categories.indexOf(category)
    console.log(state.categories[index].title)
    console.log(newcategory)
    if (index !== -1) {
      state.categories[index].title = newcategory.title
      state.categories[index].descuento = newcategory.descuento
    }
  }
}
const actions = {
  initialize({ commit, state }) {},

  fetchPresetVarieties({ commit }) {
    let promise = APIDispensaryPro.getPresetVarieties()
      .then(response => {
        if (response.data) {
          console.log(response.data)
          commit(SET_PRESET_VARIETIES, response.data)
        } else {
          commit(SET_PRESET_VARIETIES, [])
        }
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  fetchDispensary({ commit }, { asocid, status }) {
    if (
      initialState.dispensary.nid == asocid &&
      initialState.dispensary.length > 0
    ) {
      return initialState.dispensary
    }

    let promise = APIDispensaryPro.dispensary(asocid, status)
      .then(response => {
        if (response.data) {
          commit(SET_DISPENSARY, response.data)
        } else {
          commit(SET_DISPENSARY, [])
        }
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  fetchProductByPid({ getters, commit }, pid) {
    if (initialState.dispensary.length > 0) {
      return getters.getItemByIPid(pid)
    } else {
      return false
    }
  },
  createProduct(
    { commit },
    {
      asocid,
      category,
      code,
      title,
      body,
      images,
      price,
      cost,
      jar_weight,
      stock,
      thc,
      menuexport,
      status,
      discount,
      dominance,
      pid,
      type,
      unit_weight,
      unlimited_stock
    }
  ) {
    console.log(category)
    let json = {
      asocid: asocid,
      title: title,
      body: body,
      images: images,
      category: category.title,
      code: code,
      price: price,
      cost: cost,
      jar_weight: jar_weight,
      stock: stock,
      thc: thc,
      menuexport: menuexport,
      status: status,
      discount: discount,
      dominance: dominance,
      type: type,
      unit_weight: unit_weight,
      unlimited_stock: unlimited_stock
    }
    if (pid) {
      json.pid = pid
    }
    console.log(json)
    return APIDispensaryPro.createDispensaryProduct(json)
  },
  deleteProduct({ commit }, { asocid, productsArray }) {
    let pidsArray = []
    productsArray.forEach(product => {
      pidsArray.push(product.pid)
    })
    let promise = APIDispensaryPro.deleteProduct(asocid, pidsArray)
      .then(response => {
        if (response.data.products[0].message) {
          Dialog.alert({
            title: 'Info Smokapp',
            message: response.data.products[0].message,
            confirmText: 'OK',
            type: 'is-danger',
            hasIcon: false
          })
        }

        productsArray.forEach(product => {
          commit(REMOVE_PRODUCT_FROM_STORE, product)
        })
      })
      .catch(error => {
        console.log('Ha habido un error:' + error)
        tools.manageAxiosError(error)
      })
    return promise
  },
  clearDispensary({ commit }) {
    commit(CLEAR_DISPENSARY)
  },
  clearCategories({ commit }) {
    commit(CLEAR_CATEGORIES)
  },
  clearCurrentCategory({ commit }) {
    commit(CLEAR_CURRENT_CATEGORY)
  },
  setCurrentCategory({ commit }, currentCategory) {
    commit(SET_CURRENT_CATEGORY, currentCategory)
  },
  fetchCategories({ commit }, { asocid, type }) {
    let promise = APIService.getCategories(asocid, type)
      .then(response => {
        commit(SET_DISPENSARY_CATEGORIES, response.data)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  fetchCategoryByName({ getters }, categoryName) {
    if (initialState.categories.length > 0) {
      return getters.getCategoryByName(categoryName)
    } else {
      return false
    }
  },
  createCategory({ commit }, json) {
    let promise = APIService.createCategory(json)
      .then(response => {
        json.nid = response.data.nid
        commit(ADD_DISPENSARY_CATEGORY, json)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  updateCategory({ commit }, { category, newcategory }) {
    let promise = APIService.updateCategory(newcategory)
      .then(response => {
        commit(EDIT_DISPENSARY_CATEGORY, { category, newcategory })
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  deleteCategory({ commit }, { asocid, category }) {
    let promise = APIService.deleteCategory(asocid, category.nid)
      .then(response => {
        console.log(response)
        commit(REMOVE_DISPENSARY_CATEGORY_FROM_STORE, category)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}
