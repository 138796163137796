<template>
  <div>
    <h2 class="mt-3 mb-2" style="text-align: left">
      STAFF
    </h2>
    <b-field :label="$t('Busca un socio:')" class="mt-4 mb-4">
      <b-input
        v-model="numberInput"
        placeholder="NºSoc"
        @keyup.native.enter="searchEvent()"
      ></b-input>
      <b-input
        v-model="nameInput"
        :placeholder="$t('Nombre')"
        @keyup.native.enter="searchEvent()"
      ></b-input>
      <b-input
        v-model="surnameInput"
        :placeholder="$t('Apellidos')"
        @keyup.native.enter="searchEvent()"
      ></b-input>
      <b-input
        v-model="dniInput"
        :placeholder="$t('DNI')"
        @keyup.native.enter="searchEvent()"
      ></b-input>
      <b-input
        ref="keychain"
        v-model="keychainInput"
        :placeholder="$t('Llavero')"
        @keyup.native.enter="searchEvent()"
      ></b-input>
      <b-input
        v-model="emailInput"
        placeholder="Email"
        @keyup.native.enter="searchEvent()"
      ></b-input>
      <b-button @click="searchEvent()">
        {{ $t('Buscar') }}
      </b-button>
    </b-field>
    <b-field>
      <b-table
        class="mr-2"
        style="text-align: left;"
        :data="this.filteredUsers"
        :sticky-header="true"
        :paginated="true"
        :per-page="perPage"
        @page-change="onPageChange"
        :total="totalElements"
        backend-pagination
        default-sort="name"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="Página"
        aria-current-label="Actual"
        @click="clicked($event)"
        hoverable
        striped
        height="calc(100vh - 120px)"
      >
        <b-table-column field="number" :label="$t('Foto')" v-slot="props">
          <img
            v-if="props.row.foto"
            class="imgRigth"
            style="border-radius:50%; height: 30px; width: 30px; object-fit:cover;"
            :src="props.row.foto.url"
          />
        </b-table-column>

        <b-table-column
          field="number"
          :label="$t('NºSoc')"
          v-slot="props"
          width="40"
        >
          {{ props.row.number }}
        </b-table-column>

        <b-table-column field="nombre" :label="$t('Nombre')" v-slot="props">
          {{ props.row.nombre }}
        </b-table-column>

        <b-table-column field="surname" :label="$t('Apellidos')" v-slot="props">
          {{ props.row.surname }}
        </b-table-column>

        <b-table-column field="dni" :label="$t('DNI')" v-slot="props">
          {{ props.row.dni }}
        </b-table-column>

        <b-table-column field="email" label="Email" v-slot="props" width="80">
          {{ props.row.email }}
        </b-table-column>

        <b-table-column
          field="telefono"
          :label="$t('Teléfono')"
          v-slot="props"
          width="80"
        >
          {{ props.row.telefono }}
        </b-table-column>

        <b-table-column
          field="monedero"
          :label="$t('Monedero')"
          v-slot="props"
          width="80"
        >
          {{ props.row.monedero }}
        </b-table-column>

        <b-table-column
          field="end_date"
          :label="$t('Expiración')"
          centered
          v-slot="props"
        >
          <span class="tag is-success" v-if="props.row.end_date">
            {{ format_date(props.row.end_date, 'dd/MM/yyyy') }}
          </span>
        </b-table-column>

        <b-table-column
          field="type"
          :label="$t('Tipo')"
          v-slot="props"
          width="80"
        >
          {{ translateType(props.row.type) }}
        </b-table-column>

        <b-table-column
          field="props.row.user"
          :label="$t('Cuenta online')"
          v-slot="props"
          width="50"
        >
          <b-checkbox :value="haveuser(props)" disabled></b-checkbox>
        </b-table-column>

        <b-table-column
          field="props.row.status"
          :label="$t('Activo')"
          v-slot="props"
          width="50"
        >
          <b-checkbox :value="props.row.status" disabled></b-checkbox>
        </b-table-column>
      </b-table>
    </b-field>

    <!-- <div class="footer">
      <p>
        Resize the browser window to see how the content respond to the
        resizing.
      </p>
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'
import WalletRecharge from '@/components/WalletRecharge.vue'
import FeeManager from '@/components/FeeManager.vue'
import APINotifications from '@/services/APINotifications.js'
import tools from '@/Tools/tools.js'
import session from '@/services/Session.js'
import PatientDetail from '@/views/Users/PatientDetail.vue'
import APIPatients from '@/services/APIPatients.js'

export default {
  data() {
    return {
      keychain: '',
      filteredUsers: [],
      nameInput: '',
      surnameInput: '',
      keychainInput: '',
      emailInput: '',
      numberInput: '',
      dniInput: '',
      totalElements: 0,
      perPage: 10,
      page: 1,
      sortIcon: 'arrow-up',
      currentUser: null,
      selected: {}
    }
  },
  computed: {
    ...mapState('club', ['currentClub']),
    ...mapState('patients', ['patients', 'currentPatient'])
  },

  mixins: [ToolsMixin, InitViewMixin],

  methods: {
    translateType(type) {
      if (type === 'ludico') {
        return this.$t('Lúdico')
      } else if (type === 'terapeutico') {
        return this.$t('Terapéutico')
      }
    },
    searchEvent() {
      this.page = 1
      this.fetchPatients()
    },
    fetchPatients() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      let parameters =
        '?is_staff=1&association=' +
        this.currentClub.nid +
        '&limit=' +
        this.perPage +
        '&page=' +
        this.page +
        '&'
      if (this.numberInput) {
        parameters = parameters + 'number=' + this.numberInput + '&'
      }
      if (this.nameInput) {
        parameters = parameters + 'nombre=' + this.nameInput + '&'
      }
      if (this.surnameInput) {
        parameters = parameters + 'surname=' + this.surnameInput + '&'
      }
      if (this.dniInput) {
        parameters = parameters + 'dni=' + this.dniInput + '&'
      }
      if (this.keychainInput) {
        parameters = parameters + 'keychain=' + this.keychainInput + '&'
      }
      if (this.emailInput) {
        parameters = parameters + 'email=' + this.emailInput + '&'
      }

      APIPatients.fetchPatientsWith(parameters)
        .then(response => {
          if (response.data.data.length == 0) {
            this.$buefy.toast.open({
              duration: 3000,
              message: this.$t('Socio no encontrado'),
              position: 'is-top',
              type: 'is-danger'
            })
          } else if (response.data.data.length == 1) {
            this.$buefy.toast.open({
              duration: 2000,
              message: `Socio encontrado`,
              position: 'is-top',
              type: 'is-success'
            })
            this.clicked(response.data.data[0])
          } else {
            this.totalElements = response.data.total_fichas
            this.filteredUsers = response.data.data
          }
          loadingComponent.close()
        })
        .catch(error => {
          tools.manageAxiosError(error)
          console.log('Ha habido un error:' + error)
          loadingComponent.close()
        })
    },
    onPageChange(page) {
      this.page = page
      this.fetchPatients()
    },
    clicked(row) {
      this.$buefy.modal.open({
        parent: this,
        component: PatientDetail,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
        props: {
          currentPatient: row,
          currentClub: this.currentClub
        },
        onCancel: function() {},
        events: {
          onCapture: value => {}
        }
      })
    },
    downloadPDF(url) {
      console.log('ENTRA')
      session(url, {
        method: 'GET',
        responseType: 'blob'
      })
        .then(response => {
          //Create a Blob from the PDF Stream
          const file = new Blob([response.data], { type: 'application/pdf' })
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file)
          //Open the URL on new Window
          window.open(fileURL)
        })
        .catch(error => {
          console.log(error)
        })
    },
    sendPushToPatient() {
      if (this.selected.user && this.selected.user) {
        this.$buefy.dialog.prompt({
          title: $t('Enviar mensaje push'),
          message: $t('Escribe el mensaje a enviar'),
          inputAttrs: {
            maxlength: 100
          },
          confirmText: 'Enviar',
          cancelText: 'Cancelar',
          trapFocus: true,
          onConfirm: value => {
            const loadingComponent = this.$buefy.loading.open({
              container: null
            })
            APINotifications.sendPushNotification(this.currentClub.nid, value, [
              this.selected.user.uid
            ])
              .then(response => {
                loadingComponent.close()
                this.$buefy.notification.open({
                  message: this.$t('Notificación enviada correctamente'),
                  position: 'is-top',
                  type: 'is-success',
                  hasIcon: false,
                  duration: 5000
                })
                this.$matomo.trackEvent('StaffManager', 'Click', 'sendPush', 1)
              })
              .catch(error => {
                console.log('Ha habido un error:' + error)
                console.log(error.response)
                loadingComponent.close()
                tools.manageAxiosError(error)

                // this.$buefy.notification.open({
                //   message: error,
                //   position: 'is-top-right',
                //   type: 'is-danger',
                //   hasIcon: false,
                //   duration: 5000
                // })
              })
          }
        })
      } else {
        this.$buefy.dialog.confirm({
          title: $t('Cuenta online'),
          message: $t('Este socio no dispone de cuenta online'),
          confirmText: 'OK',
          type: 'is-danger',
          hasIcon: false
        })
      }
    },
    sendSMSCode() {
      if (this.selected.user && this.selected.telefono) {
        const loadingComponent = this.$buefy.loading.open({
          container: null
        })
        this.$store
          .dispatch('patients/sendSMSCode', {
            asocid: this.currentClub.nid,
            uid: this.currentPatient.user.uid,
            tlf: this.currentPatient.telefono
          })
          .then(() => {
            this.$buefy.notification.open({
              message: this.$t('SMS de invitación enviado correctamente'),
              position: 'is-top-right',
              type: 'is-success',
              hasIcon: false
            })
            loadingComponent.close()
          })
      } else {
        this.$buefy.dialog.confirm({
          title: this.$t('Cuenta online'),
          message: this.$t(
            'Este socio no dispone de cuenta online, ¿Quieres activarla?'
          ),
          confirmText: 'Sí',
          cancelText: 'No',
          type: 'is-danger',
          hasIcon: false,
          onConfirm: () => {
            if (this.selected.telefono) {
              let json = {
                association: this.currentClub.nid,
                nid: this.selected.nid,
                online_account: true,
                membership_id: this.selected.nid
              }
              this.$store.dispatch('patients/editPatient', json).then(() => {
                // loadingComponent.close()
                this.fetchPatients()
              })
            } else {
              this.$buefy.dialog.prompt({
                title: this.$t('Nº de teléfono'),
                message: this.$t(
                  'El usuario no dispone e nº de teléfono, ¿A qué número de teléfono le enviamos la invitación?'
                ),
                inputAttrs: {
                  maxlength: 16
                },
                confirmText: 'Enviar',
                cancelText: 'Cancelar',
                trapFocus: true,
                onConfirm: value => {
                  let json = {
                    association: this.currentClub.nid,
                    nid: this.selected.nid,
                    online_account: true,
                    telefono: value,
                    membership_id: this.selected.nid
                  }
                  this.$store
                    .dispatch('patients/editPatient', json)
                    .then(() => {
                      // loadingComponent.close()
                      this.fetchPatients()
                    })
                }
              })
            }
          }
        })
      }
    },
    haveuser(props) {
      if (props.row.user != null) {
        return true
      }
      return false
    },
    selectCurrentUser(user) {
      this.currentUser = user
    },
    setPatientStatus(status) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      let json = {
        association: this.currentClub.nid,
        nid: this.selected.nid,
        status_member: status,
        membership_id: this.selected.nid
      }
      this.$store.dispatch('patients/editPatient', json).then(() => {
        loadingComponent.close()
        this.fetchPatients()
      })
    },
    deletePatient() {
      if (this.currentUser == null) {
        this.$buefy.toast.open({
          duration: 5000,
          message: $t('Primero debes seleccionar un socio'),
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }
      this.$buefy.dialog.confirm({
        title: 'Eliiminar socio',
        message: this.$t(
          '¿Estás seguro de que deseas eliminar al socio? (Esta acción no se puede deshacer)'
        ),
        confirmText: this.$t('Sí'),
        cancelText: this.$t('No'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          const loadingComponent = this.$buefy.loading.open({
            container: null
          })
          this.$store
            .dispatch('patients/deletePatient', {
              asocid: this.currentClub.nid,
              patient: this.currentUser
            })
            .then(response => {
              this.selectFirstUser()

              loadingComponent.close()
            })
            .catch(error => {
              console.log('Ha habido un error:' + error)
              loadingComponent.close()
            })
        }
      })
    },
    patchPatient() {
      console.log(this.currentUser)
      this.$router.push({
        name: 'patientcreate',
        params: { patientToPatch: this.currentUser }
      })
    },
    selectFirstUser() {
      // if (this.patients.length > 0) {
      //   this.currentUser = this.patients[0]
      // }
    },

    // fetchPatients(loadingComponent) {
    //   this.$store
    //     .dispatch('patients/fetchPatients', {
    //       asocid: this.currentClub.nid,
    //       is_staff: true
    //     })
    //     .then(response => {
    //       this.selectFirstUser()
    //       loadingComponent.close()
    //     })
    //     .catch(error => {
    //       console.log('Ha habido un error:' + error)
    //       loadingComponent.close()
    //     })
    // },
    walletRecharge() {
      this.$buefy.modal.open({
        parent: this,
        component: WalletRecharge,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
        props: {
          currentPatient: this.currentUser,
          currentClub: this.currentClub
        },
        onCancel: function() {},
        events: {
          onCapture: value => {}
        }
      })
    },
    feeManager() {
      this.$buefy.modal.open({
        parent: this,
        component: FeeManager,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
        props: {
          currentPatient: this.currentUser,
          currentClub: this.currentClub
        },
        onCancel: function() {},
        events: {
          onCapture: value => {}
        }
      })
    }
  },

  created() {
    this.validateAccess(['socios', 'comunicacion'])
    this.validateRoles(['Administrador', 'Manager'])
    // if (
    //   !this.currentClub.functionalities.socios ||
    //   !this.currentClub.functionalities.comunicacion
    // ) {
    //   this.showNoAccessMessage()
    // }
    this.$store.commit('patients/CLEAR_PATIENTS')
    this.$analytics.logEvent('PatientManager.vue')
    this.$store.dispatch('setShowNavigationBar', true)

    this.fetchPatients()
  },
  destroyed() {
    this.$store.commit('patients/CLEAR_PATIENTS')
  },
  watch: {
    currentClub() {
      this.validateAccess(['socios', 'comunicacion'])
      this.validateRoles(['Administrador', 'Manager'])

      this.fetchPatients()
    },
    selected() {
      this.currentUser = this.selected
    }

    // patients() {
    //   this.filteredDataArray = this.patients.data
    //   this.filteredDataArray = this.patients.data.filter(
    //     item => item.is_staff == true
    //   )
    // }

    // searchText() {
    //   this.filteredDataArray = this.patients.filter(user => {
    //     return (
    //       user.name
    //         .toString()
    //         .toLowerCase()
    //         .indexOf(this.searchText.toLowerCase()) >= 0
    //     )
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.row::after {
  content: '';
  clear: both;
  // display: flex;
}

[class*='col-'] {
  float: left;
  padding: 15px;
}

html {
  font-family: 'Lucida Sans', sans-serif;
}

.my-table {
  // max-height: 100%;
  overflow-y: auto;
  height: calc(100vh - 150px);
  // height: 1000px;
}

.header {
  background-color: #9933cc;
  color: #ffffff;
  padding: 15px;
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  top: 10px;
  // height: 5vh;
  overflow: auto;
}

.menu li {
  padding: 8px;
  margin-bottom: 7px;
  background-color: #f7f3f3;
  color: #666666;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
  cursor: pointer;
}

.right li {
  // background-color: #33b5e5;
  // color: #fff;
  background-color: #f3f3f3;
}

.left li {
  background-color: #f3f3f3;
  // color: #fff;
}

.menu li:hover {
  background-color: #e1e1e1;
  // color: #fff;
}

.aside {
  background-color: #f3f3f3;
  padding: 15px;
  color: #666666;
  text-align: center;
  font-size: 14px;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
}

.footer {
  background-color: #0099cc;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  padding: 15px;
}

/* For desktop: */
.col-1 {
  width: 80%;
  background-color: #f9f9f9;
  border-radius: 6px;
}
.col-2 {
  width: 20%;
  background-color: #f9f9f9;
  border-radius: 6px;
}

.actions {
  height: calc(100vh - 40px);
  overflow: auto;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*='col-'] {
    width: 100%;
  }
}
</style>
