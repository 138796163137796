<template>
  <div>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t('Generador QR') }}</p>
        <button type="button" class="delete ml-5" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div id="printMe">
          <qrcode-vue
            :value="code"
            :renderAs="renderAs"
            :size="250"
            level="H"
          ></qrcode-vue>
        </div>
      </section>

      <footer class="modal-card-foot" style="display:block">
        <b-button class="button is-primary" @click="printCode()">{{
          $t('Imprimir')
        }}</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
  props: {
    code: null
  },
  data() {
    return {
      renderAs: 'svg'
    }
  },
  components: {
    QrcodeVue
  },
  methods: {
    printCode() {
      this.$htmlToPaper('printMe')
    }
  }
}
</script>

<style lang="scss" scoped></style>
