<template>
  <div>
    <div class="grid-container">
      <div class="grid-item">1</div>
      <div class="grid-item">2</div>
      <div class="grid-item">3</div>
      <div class="grid-item">4</div>
      <div class="grid-item">5</div>
      <div class="grid-item">6</div>
      <div class="grid-item">7</div>
      <div class="grid-item">8</div>
      <div class="grid-item">9</div>
    </div>

    <!-- <section>
      <b-field label="Números de telefono" :label-position="labelPosition">
        <b-input maxlength="200" type="textarea"> adfdf</b-input>
      </b-field>
    </section>
    <section>
      <b-field label="Mensaje a enviar" :label-position="labelPosition">
        <b-input v-model="body" maxlength="200" type="textarea"></b-input>
      </b-field>
    </section>
    <b-button class="button is-primary" @click="sendSMS()">Enviar</b-button> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      labelPosition: 'on-border',
      tlfs: '',
      body: ''
    }
  },
  methods: {
    sendSMS() {}
  },
  created() {
    this.$analytics.logEvent('SMSManager.vue')
    console.log(this.body)
  }
}
</script>

<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  background-color: #2196f3;
  padding: 10px;
  row-gap: 50px;
  column-gap: 50px;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 20px;
  font-size: 30px;
  text-align: center;
}
</style>
