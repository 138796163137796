import session from '@/services/Session.js'

export default {
  getNews(asocid) {
    return session.get('/api/news/retrieve?association=' + asocid)
  },
  //   getConsult(asocid, nid) {
  //     return session.get(
  //       '/api/consult/retrieve?association=' + asocid + '&nid=' + nid
  //     )
  //   },
  deleteNews(asocid, new_nid) {
    return session.delete('/api/news/' + new_nid + '?association=' + asocid)
  },
  postNews(json) {
    return session.post('/api/news', json)
  }
}
