<template>
  <div>
    <div>
      <!-- <div class="header">
      <h1>Chania</h1>
    </div> -->
      <div class="row">
        <div class="col-2">
          <section>
            <h4>{{ $t('Configurar club') }}</h4>
            <hr />
            <div v-if="currentClub != Object && currentClub.full_html == ''">
              <b-field label="Logo">
                <b-upload v-model="dropLogo" drag-drop>
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-large"> </b-icon>
                      <span>Logo</span>
                    </p>
                  </div>
                </b-upload>
              </b-field>
              <b-field :label="$t('Descripción del club')" style="color:#fff;">
                <b-input
                  style="height:auto"
                  expanded
                  v-model="body"
                  :placeholder="$t('Descripción del club')"
                  type="textarea"
                ></b-input>
              </b-field>
              <b-field :label="$t('Añade unas imágenes del club')">
                <b-upload v-model="dropImages" multiple drag-drop>
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-large"> </b-icon>
                      <span>{{ $t('Imágenes') }}</span>
                    </p>
                  </div>
                </b-upload>
              </b-field>

              <div class="tags">
                <span
                  v-for="(file, index) in dropImages"
                  :key="index"
                  class="tag is-primary"
                >
                  {{ file.name }}
                  <button
                    class="delete is-small"
                    type="button"
                    @click="deleteDropFile(index)"
                  ></button>
                </span>
              </div>
              <b-button type="is-primary" @click="publishProduct()">
                {{ $t('Publicar cambios') }}</b-button
              >
            </div>
            <div v-else>
              <p>
                {{ $t('Editor avanzado activo') }}
              </p>
              <b-button
                style="margin-top:20px"
                type="is-primary"
                @click="basicMode()"
              >
                {{ $t('Volver a modo básico') }}</b-button
              >
            </div>
          </section>
          <div>
            <hr />
            <b-button @click="showAdvancedBodyEditor()">
              {{ $t('Configurar club') }}</b-button
            >
            <hr />
          </div>
        </div>

        <div class="col-3 actions">
          <h4>{{ $t('Previsualización de los cambios') }}</h4>
          <hr />
          <div class="aside">
            <div v-if="currentClub != Object && currentClub.full_html == ''">
              <p class="logo" v-if="currentClub != Object">
                <img :src="currentClub.logo.img" />
                <!-- <img v-auth-image="currentClub.logo.img" /> -->
              </p>
              <b-carousel
                :indicator-inside="false"
                :autoplay="true"
                :arrow="false"
                :animated="'slide'"
                :interval="12000"
              >
                <b-carousel-item
                  v-for="(item, i) in currentClub.images"
                  :key="i"
                >
                  <span class="bigimage">
                    <img :src="item.img" />
                  </span>
                </b-carousel-item>
                <template
                  slot="indicators"
                  slot-scope="props"
                  v-if="props.i < currentClub.images.length"
                >
                  <span class="al image">
                    <img
                      :src="currentClub.images[props.i].img"
                      :title="props.i"
                    />
                  </span>
                </template>
              </b-carousel>

              <p v-html="currentClub.body"></p>
            </div>
            <div v-else>
              <p v-html="currentClub.full_html"></p>
            </div>
          </div>

          <div class="menu right">
            <!-- <ul>
            <li style="background-color: #717a81; color:#fff;">
              <div @click="createNews()">
                <h6>Publicar nueva noticia</h6>
                <p>Publica una nueva noticia en tu club</p>
              </div>
            </li>
            <li style="background-color: #ed6a5a; color:#fff">
              <h6>Eliminar noticia</h6>
            </li>
          </ul> -->
          </div>
        </div>
      </div>

      <!-- <div class="footer">
      <p>
        Resize the browser window to see how the content respond to the
        resizing.
      </p>
    </div> -->
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

import { InitViewMixin } from '@/mixins/InitViewMixin.js'

export default {
  data() {
    return {
      selectedIndex: 0,
      title: '',
      body: '',
      dropLogo: null,
      logo: null,
      dropImages: [],
      images: []
    }
  },
  mixins: [InitViewMixin],
  methods: {
    basicMode() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      let club = {
        // html_design: '',
        full_html: ''
      }

      this.$store
        .dispatch('club/updateClub', {
          asocid: this.currentClub.nid,
          club: club
        })
        .then(() => {
          this.$store
            .dispatch('club/fetchClub', { asocid: this.currentClub.nid })
            .then(() => {
              loadingComponent.close()
            })
        })
    },
    showAdvancedBodyEditor() {
      this.$router.push({ name: 'htmleditor' })
    },
    publishProduct() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      let club = {
        body: this.body
      }
      if (this.logo) {
        club.logo = this.logo
      }
      if (this.images.length > 0) {
        club.images = this.images
      }
      this.$store
        .dispatch('club/updateClub', {
          asocid: this.currentClub.nid,
          club: club
        })
        .then(() => {
          this.$store.dispatch('club/fetchClubs').then(() => {
            loadingComponent.close()
          })
        })
    }
  },

  created() {
    this.validateAccess(['comunicacion'])
    this.validateRoles(['Administrador', 'Manager', 'Social'])
    this.$analytics.logEvent('ClubManager.vue')
    this.$store.dispatch('setShowNavigationBar', true)
    this.body = this.currentClub.body
  },
  watch: {
    currentClub() {
      this.validateAccess(['comunicacion'])
      this.validateRoles(['Administrador', 'Manager', 'Social'])
    },
    dropLogo() {
      const comp = this
      function readerOnload(e) {
        var base64 = btoa(e.target.result)
        comp.logo = base64
      }
      var reader = new FileReader()
      reader.onload = readerOnload
      reader.readAsBinaryString(this.dropLogo)
      console.log('entra')
      console.log(this.logo)
    },
    dropImages() {
      this.dropImages.forEach(file => {
        const comp = this
        function readerOnload(e) {
          var base64 = btoa(e.target.result)
          comp.images.push(base64)
        }
        var reader = new FileReader()
        reader.onload = readerOnload
        reader.readAsBinaryString(file)
      })
      console.log(this.images)
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.row::after {
  content: '';
  clear: both;
  // display: flex;
}

[class*='col-'] {
  float: left;
  padding: 15px;
}

html {
  font-family: 'Lucida Sans', sans-serif;
}

.my-table {
  // max-height: 100%;
  overflow-y: auto;
  height: calc(100vh - 150px);
  // height: 1000px;
}

.header {
  background-color: #9933cc;
  color: #ffffff;
  padding: 15px;
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  top: 10px;
  // height: 5vh;
  overflow: auto;
}

.menu li {
  padding: 8px;
  margin-bottom: 7px;
  background-color: #f7f3f3;
  color: #666666;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
  cursor: pointer;
}

.right li {
  // background-color: #33b5e5;
  // color: #fff;
  background-color: #f3f3f3;
}

.left li {
  background-color: #f3f3f3;
  // color: #fff;
}

.menu li:hover {
  background-color: #e1e1e1;
  // color: #fff;
}

.aside {
  background-color: #f3f3f3;
  padding: 15px;
  color: #666666;
  text-align: center;
  font-size: 14px;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
}

.footer {
  background-color: #0099cc;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  padding: 15px;
}

/* For desktop: */

.col-2 {
  width: 40%;
  background-color: #f9f9f9;
  border-radius: 6px;
  height: 100vh;
}
.col-3 {
  width: 60%;
  background-color: #f9f9f9;
  border-radius: 6px;
}

.consultsList {
  height: calc(100vh - 160px);
  overflow: auto;
}

.chat {
  height: calc(100vh - 190px);
  overflow: auto;
}

.actions {
  height: calc(100vh - 85px);
  overflow: auto;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*='col-'] {
    width: 100%;
  }
}
h1 {
  font-style: bold;
  font-size: x-large;
}
.is-active .al img {
  filter: grayscale(0%);
}
.al img {
  filter: grayscale(100%);
  width: 100px;
}
.bigimage img {
  width: 340px;
  height: auto;
  margin: auto;
}
.logo img {
  width: 100px;
  height: auto;
  margin: auto;
  border-radius: 50%;
  padding: 5px;
}
</style>
