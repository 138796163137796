import { SET_CONSULTS, SET_CURRENT_CONSULT, DELETE_CONSULT } from './types'
import APIConsults from '@/services/APIConsults.js'
import tools from '@/Tools/tools.js'

const initialState = {
  consults: [],
  currentConsult: {}
}

const mutations = {
  [SET_CONSULTS](state, consults) {
    state.consults = consults
  },
  [SET_CURRENT_CONSULT](state, currentConsult) {
    state.currentConsult = currentConsult
  },
  [DELETE_CONSULT](state, consult) {
    state.consults.splice(state.consults.indexOf(consult), 1)
  }
}

const actions = {
  fetchConsults({ commit }, asocid) {
    let promise = APIConsults.getConsults(asocid)
      .then(response => {
        if (response.data.length > 0) {
          response.data.forEach(consult => {
            consult.comment_date = response.data[0].created
            consult.comment.forEach(comment => {
              if (consult.comment_date < comment.created) {
                consult.comment_date = comment.created
              }
            })
          })
        }
        //Ordenamos por fecha del ultimo comentario
        response.data.sort((a, b) => (a.comment_date < b.comment_date ? 1 : -1))
        commit(SET_CONSULTS, response.data)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  fetchConsult({ commit }, { asocid, nid }) {
    let promise = APIConsults.getConsult(asocid, nid)
      .then(response => {
        console.log(response)
        if (response.data.length > 0) {
          commit(SET_CURRENT_CONSULT, response.data[0])
        }
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  setCurrentConsult({ commit }, consult) {
    commit(SET_CURRENT_CONSULT, consult)
  },

  deleteConsult({ commit }, consult) {
    let promise = APIConsults.deleteConsult(consult.nid)
      .then(response => {
        console.log(response.data)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    commit(DELETE_CONSULT, consult)
    return promise
  },

  postComment({ commit }, { asocid, nid, body }) {
    let json = {
      association: asocid,
      nid: nid,
      body: body
    }

    let promise = APIConsults.postComment(json)
      .then(response => {
        // commit(SET_USERS, response.data)
        console.log(response)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  }
}

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations
}
