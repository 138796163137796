import { SET_ACCESS_HISTORY, CLEAR_ACCESS_HISTORY } from './types'
import APIAccessControl from '@/services/APIAccessControl.js'
import tools from '@/Tools/tools.js'

const initialState = {
  access_history: []
}
const getters = {
  //   getCurrentClub: state => {
  //     return state.currentClub
  //   }
}
const mutations = {
  [SET_ACCESS_HISTORY](state, access_history) {
    state.access_history = access_history
  },
  [CLEAR_ACCESS_HISTORY](state) {
    state.access_history = []
  }
}
const actions = {
  initialize({ commit, state }) {},
  fetchAccessHitory({ commit, getters }, { asocid, start_date, end_date }) {
    let promise = APIAccessControl.getAccessControlHistory(
      asocid,
      start_date,
      end_date
    )
      .then(response => {
        commit(SET_ACCESS_HISTORY, response.data)
      })
      .catch(error => {
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  fetchAccessHitoryByPatient(
    { commit, getters },
    { asocid, patient_id, start_date, end_date }
  ) {
    let promise = APIAccessControl.getAccessControlHistoryByPatient(
      asocid,
      patient_id,
      start_date,
      end_date
    )
      .then(response => {
        commit(SET_ACCESS_HISTORY, response.data)
      })
      .catch(error => {
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  addNewAccess(
    { commit, getters },
    { patient_id, association, datetime, action }
  ) {
    const data = {
      association: association,
      datetime: datetime,
      action: action
    }
    let promise = APIAccessControl.addNewAccess(patient_id, data)
      .then(response => {
        console.log(response.data)
        // commit(SET_ACCESS_HISTORY, response.data)
      })
      .catch(error => {
        console.log('Ha habido un error:' + error)
      })
    return promise
  },

  clearAccessHistory({ commit }) {
    commit(CLEAR_ACCESS_HISTORY)
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}
