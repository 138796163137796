import session from '@/services/Session.js'

export default {
  getInventory(asocid) {
    return session.get('/api/inventory?asocid=' + asocid)
  },
  getInventorytItem(asocid, nid) {
    return session.get('/api/inventory?asocid=' + asocid + '&nid=' + nid)
  },
  createinventoryItem(json) {
    return session.post('/api/inventory', json)
  },
  doMovement(json) {
    return session.post('/api/inventory_movement', json)
  },
  getStock(asocid) {
    // return session.get(
    //   '/api/product_stock?asocid=' +
    //     asocid +
    //     '&unlimited_stock=false' +
    //     '&status=true'
    // )
    return session.get(
      '/api/product_stock?asocid=' + asocid + '&unlimited_stock=false'
    )
  },
  postStock(json) {
    return session.post('/api/product_stock', json)
  }
}
