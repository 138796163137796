<template>
  <div>
    <!-- <div class="header">
      <h1>Chania</h1>
    </div> -->
    <div class="row">
      <div class="col-2">
        <b-field :label="$t('Eventos')"> </b-field>
        <b-field>
          <b-table
            class="my-table"
            style="text-align: left;"
            :data="this.events"
            :sticky-header="true"
            :paginated="isPaginated"
            :per-page="perPage"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :default-sort-direction="defaultSortDirection"
            :pagination-rounded="isPaginationRounded"
            default-sort="start_date"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Actual"
            :selected.sync="selected"
            hoverable
            striped
            height="90%"
          >
            <b-table-column
              field="title"
              :label="$t('Título del evento')"
              sortable
              v-slot="props"
              searchable
            >
              {{ props.row.title }}
            </b-table-column>

            <b-table-column
              field="startDate"
              :label="$t('Fecha de inicio')"
              sortable
              centered
              v-slot="props"
            >
              <span class="tag is-success" v-if="props.row.start_date">
                {{ format_date(props.row.start_date, 'dd/MM/yyyy') }}
              </span>
            </b-table-column>

            <b-table-column
              field="endDate"
              :label="$t('Fecha de fin')"
              sortable
              centered
              v-slot="props"
            >
              <span class="tag is-success" v-if="props.row.end_date">
                {{ format_date(props.row.end_date, 'dd/MM/yyyy') }}
              </span>
            </b-table-column>
          </b-table>
        </b-field>
      </div>

      <div class="col-3 actions">
        <h4>{{ $t('Evento') }}</h4>
        <div class="aside">
          <div v-if="selected.images">
            <div v-if="selected.images.length > 0">
              <img
                style="border-radius:50%; margin-top:20px; max-height:20vh"
                :src="selected.images[0].img"
              />
            </div>
            <h3>{{ selected.title }}</h3>
            <p v-html="selected.body"></p>
            <h5>
              {{ $t('Socios apuntados al evento') }}: {{ selected.counter }}
            </h5>
          </div>
        </div>
        <hr />
        <div class="menu right">
          <ul>
            <li
              style="background-color: #717a81; color:#fff;"
              @click="createEvents()"
            >
              <div>
                <h6>{{ $t('Publicar nuevo evento') }}</h6>
              </div>
            </li>
            <li
              @click="deleteEvent()"
              style="background-color: #ed6a5a; color:#fff"
            >
              <h6>{{ $t('Eliminar evento') }}</h6>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- <div class="footer">
      <p>
        Resize the browser window to see how the content respond to the
        resizing.
      </p>
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'

export default {
  data() {
    return {
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'desc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 24,
      selected: {}
    }
  },
  computed: {
    ...mapState('club', ['currentClub']),
    ...mapState('events', ['events'])
  },
  mixins: [ToolsMixin, InitViewMixin],
  created() {
    this.validateRoles(['Administrador', 'Manager', 'Social'])
    this.$analytics.logEvent('Events.vue')
    this.$store.dispatch('setShowNavigationBar', true)

    this.downloadEvents()
  },
  methods: {
    deleteEvent() {
      this.$buefy.dialog.confirm({
        title: this.$t('Eliminar evento'),
        message: this.$t(
          '¿Estás seguro de que deseas eliminar el evento? (Esta acción no se puede deshacer)'
        ),
        confirmText: this.$t('Sí'),
        cancelText: this.$t('No'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          const loadingComponent = this.$buefy.loading.open({
            container: null
          })
          this.$store
            .dispatch('events/deleteEvent', {
              event: this.selected,
              asocid: this.currentClub.nid
            })
            .then(response => {
              // this.selectFirsNews()
              loadingComponent.close()
            })
            .catch(error => {
              console.log('Ha habido un error:' + error)
              loadingComponent.close()
            })
        }
      })
    },
    downloadEvents() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.$store
        .dispatch('events/fetchEvents', this.currentClub.nid)
        .then(response => {
          // this.selectFirsNews()
          loadingComponent.close()
        })
        .catch(error => {
          console.log('Ha habido un error:' + error)
          loadingComponent.close()
        })
    },
    selectFirsEvents() {
      if (this.events.length > 0) {
        this.selected = this.events[0]
      }
    },
    createEvents() {
      this.validateAccess(['comunicacion'])
      this.$router.push({ name: 'eventcreate' })
    }
  },
  watch: {
    currentClub() {
      this.validateAccess(['comunicacion'])
      this.validateRoles(['Administrador', 'Manager', 'Social'])
      this.downloadEvents()
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.row::after {
  content: '';
  clear: both;
  // display: flex;
}

[class*='col-'] {
  float: left;
  padding: 15px;
}

img {
  object-fit: cover;
  width: 230px;
  height: 230px;
}

html {
  font-family: 'Lucida Sans', sans-serif;
}

.my-table {
  // max-height: 100%;
  overflow-y: auto;
  height: calc(100vh - 150px);
  // height: 1000px;
}

.header {
  background-color: #9933cc;
  color: #ffffff;
  padding: 15px;
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  top: 10px;
  // height: 5vh;
  overflow: auto;
}

.menu li {
  padding: 8px;
  margin-bottom: 7px;
  background-color: #f7f3f3;
  color: #666666;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
  cursor: pointer;
}

.right li {
  // background-color: #33b5e5;
  // color: #fff;
  background-color: #f3f3f3;
}

.left li {
  background-color: #f3f3f3;
  // color: #fff;
}

.menu li:hover {
  background-color: #e1e1e1;
  // color: #fff;
}

.aside {
  background-color: #f3f3f3;
  padding: 15px;
  color: #666666;
  text-align: center;
  font-size: 14px;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
}

.footer {
  background-color: #0099cc;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  padding: 15px;
}

/* For desktop: */

.col-2 {
  width: 60%;
  background-color: #f9f9f9;
  border-radius: 6px;
}
.col-3 {
  width: 40%;
  background-color: #f9f9f9;
  border-radius: 6px;
}

.consultsList {
  height: calc(100vh - 160px);
  overflow: auto;
}

.chat {
  height: calc(100vh - 190px);
  overflow: auto;
}

.actions {
  height: calc(100vh - 85px);
  overflow: auto;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*='col-'] {
    width: 100%;
  }
}
</style>
