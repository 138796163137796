import session from '@/services/Session.js'
import axios from 'axios'

export default {
  changePassword(old, pass) {
    return session.post('/api/change_password', {
      old_password: old,
      password: pass
    })
  },
  fetchPatientsWith(parameters) {
    return session.get('/api/ficha_socio' + parameters)
  },
  getPatients(asocid, is_staff) {
    if (is_staff != null) {
      return session.get(
        '/api/ficha_socio/?association=' + asocid + '&is_staff=' + is_staff
      )
    }
    return session.get('/api/ficha_socio/?association=' + asocid)
  },
  getPatientByUid(asocid, uid) {
    return session.get('/api/ficha_socio?association=' + asocid + '&uid=' + uid)
  },
  getPatientByMembership(asocid, nid) {
    return session.get('/api/ficha_socio?association=' + asocid + '&nid=' + nid)
  },
  getPatientByDNI(asocid, dni) {
    return session.get(
      '/api/ficha_by_dni?association=' + asocid + '&dni=' + dni
    )
  },
  createPatient(patient) {
    return session.post('/api/ficha_socio', patient)
  },
  // editPatient(patient) {
  //   return session.put('/api/ficha_socio/' + patient.nid, patient)
  // },
  editPatient(patient) {
    return session.post('/api/ficha_socio', patient)
  },
  deletePatient(asocid, nid) {
    return session.delete('/api/ficha_socio/' + nid + '?association=' + asocid)
  },
  getCategories(asocid) {
    return session.get('/api/membership_category/?association=' + asocid)
  },
  createPatientCategory(patientCategory) {
    return session.post('/api/membership_category', patientCategory)
  },
  updatePatientCategory(patientCategory) {
    return session.put(
      '/api/membership_category/' + patientCategory.nid,
      patientCategory
    )
  },
  deletePatientCategory(asocid, nid) {
    return session.delete(
      '/api/membership_category/' + nid + '?association=' + asocid
    )
  },
  createFile(file, asocid) {
    const formData = new FormData()
    formData.append('files', file)
    formData.append('directory', 'document')
    formData.append('asocid', asocid)
    return session.post('/api/smokapp_file/create_raw', formData)
  },
  chargeWallet(asocid, patient, operation, amount, pay_type) {
    let obj = {
      association: asocid,
      operation: operation,
      amount: amount,
      pay_type: pay_type
    }
    console.log(obj)
    return session.put('/api/wallet/' + patient.nid, obj)
  },
  walletMovements(json) {
    if (json.card_id != null) {
      return session.get(
        '/api/wallet_history?asocid=' +
          json.asocid +
          '&start_date=' +
          json.start_date +
          '&end_date=' +
          json.end_date +
          '&card_id=' +
          json.card_id
      )
    } else {
      return session.get(
        '/api/wallet_history?asocid=' +
          json.asocid +
          '&start_date=' +
          json.start_date +
          '&end_date=' +
          json.end_date
      )
    }
  },
  reportSalesProduct(json) {
    return session.get(
      '/api/report_sales_product?asocid=' +
        json.asocid +
        '&start_date=' +
        json.start_date +
        '&end_date=' +
        json.end_date
    )
  },
  sendSMSCode(json) {
    return session.post('/api/tlf_resend_code', json)

    // session.post('/api/tlfvalidation', json, {
    //   transformRequest: (data, headers) => {
    //     delete headers.common['X-COOKIE']
    //     delete headers.common['X-CSRF-TOKEN']
    //     delete headers.common['CSRF-TOKEN']
    //     delete headers.common['Cookie']

    //     return data
    //   }
    // })
  }
}
