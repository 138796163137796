<template>
  <div>
    <div class="col-2">
      <b-field :label="$t('Cupones')"> </b-field>
      <b-field>
        <b-table
          v-if="cupons"
          style="text-align: left;"
          :data="this.cupons"
          :sticky-header="true"
          :paginated="isPaginated"
          :per-page="perPage"
          :current-page.sync="currentPage"
          :pagination-simple="isPaginationSimple"
          :pagination-position="paginationPosition"
          :default-sort-direction="defaultSortDirection"
          :pagination-rounded="isPaginationRounded"
          default-sort="created"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="Página"
          aria-current-label="Actual"
          :selected.sync="selected"
          hoverable
          striped
          height="calc(100vh - 133px)"
        >
          <b-table-column
            field="code"
            :label="$t('Código')"
            sortable
            v-slot="props"
            searchable
            width="50"
          >
            {{ props.row.code }}
          </b-table-column>

          <b-table-column
            field="title"
            :label="$t('Nombre')"
            sortable
            v-slot="props"
            searchable
            width="50"
          >
            {{ props.row.title }}
          </b-table-column>

          <b-table-column
            field="discount"
            :label="$t('Cantidad')"
            sortable
            v-slot="props"
            searchable
            width="50"
          >
            {{ props.row.discount }}
          </b-table-column>

          <b-table-column
            field="to_date"
            :label="$t('Caducidad')"
            sortable
            v-slot="props"
            width="50"
          >
            {{ format_date(props.row.to_date, 'dd/MM/yyyy') }}
          </b-table-column>

          <b-table-column
            field="use_number_coupon"
            :label="$t('Usos')"
            sortable
            v-slot="props"
            width="50"
          >
            {{ props.row.use_number_coupon }}
          </b-table-column>

          <b-table-column
            field="props.row.status"
            :label="$t('Activo')"
            v-slot="props"
            width="50"
          >
            <b-checkbox
              :value="Boolean(Number(props.row.status))"
              disabled
            ></b-checkbox>
          </b-table-column>
        </b-table>
      </b-field>
    </div>
    <div class="col-3 actions">
      <br />
      <div class="aside mt-3">
        <div v-if="selected">
          <h4 class="mb-4">{{ selected.title }}</h4>
          <qrcode-vue
            :value="selected.code"
            renderAs="svg"
            :size="120"
            level="H"
          ></qrcode-vue>
          <hr />
          <p>{{ $t('Código') }}: {{ selected.code }}</p>
          <p>{{ $t('Tipo') }}: {{ selected.type }}</p>
          <p>
            {{ $t('Caducidad') }}:
            {{ format_date(selected.to_date, 'dd/MM/yyyy') }}
          </p>
          <p>
            {{ $t('Inicio') }}:
            {{ format_date(selected.from_date, 'dd/MM/yyyy') }}
          </p>
          <p>{{ $t('Usos') }}: {{ selected.use_number_coupon }}</p>

          <p v-if="selected.status">
            {{ $t('Cupón activo') }}
          </p>
          <p v-else>
            {{ $t('Cupón inactivo') }}
          </p>

          <!-- <p>{{ selected.valoration }} <i class="far fa-star"></i></p> -->
        </div>
        <div v-else>{{ $t('SELECCIONA UN CUPÓN') }}</div>
      </div>
      <div class="menu right mt-3">
        <ul>
          <li @click="$router.push('CuponCreate')">
            <div>
              <h6>{{ $t('Crear cupón') }}</h6>
            </div>
          </li>
          <li v-if="selected" @click="cuponDuplicate()">
            <div>
              <h6>{{ $t('Duplicar cupón') }}</h6>
            </div>
          </li>
          <!-- <li v-if="selected" @click="createNewProduct()">
            <div>
              <h6>{{ $t('Modificar cupón') }}</h6>
            </div>
          </li> -->
          <li v-if="selected" @click="cuponUses(selected)">
            <div>
              <h6>{{ $t('Historial de usos') }}</h6>
            </div>
          </li>
          <li v-if="selected" @click="activateCupon(selected.status)">
            <div v-if="selected.status == '1'">
              <h6>{{ $t('Desactivar cupón') }}</h6>
            </div>
            <div v-else>
              <h6>{{ $t('Activar cupón') }}</h6>
            </div>
          </li>

          <li
            style="background-color: #ed6a5a; color:#fff"
            @click="deleteCupon(selected)"
            v-if="this.checkRoles(['Manager', 'Administrador']) && selected"
          >
            <div>
              <h6>{{ $t('Eliminar cupón') }}</h6>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import session from '@/services/Session.js'
import { mapState } from 'vuex'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'
import QrcodeVue from 'qrcode.vue'

export default {
  data() {
    return {
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'desc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 15,
      selected: null,
      cupons: []
    }
  },
  mixins: [ToolsMixin, InitViewMixin],
  computed: {
    ...mapState('club', ['currentClub'])
  },
  components: {
    QrcodeVue
  },
  methods: {
    cuponDuplicate() {
      this.$router.push({
        name: 'cuponcreate',
        params: { cupon: this.selected }
      })
    },
    downloadData() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      session
        .get('/api/smokapp_coupon?asocid=' + this.currentClub.nid)
        .then(response => {
          this.cupons = response.data
          loadingComponent.close()
        })
    },

    activateCupon(activate) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      var json = {
        asocid: this.currentClub.nid,
        coupon_id: [Number(this.selected.id)],
        activate: !Boolean(Number(activate))
      }
      session
        .post('/api/smokapp_coupon/activate_coupon', json)
        .then(response => {
          this.downloadData()
          loadingComponent.close()
          this.selected.status = !Boolean(Number(activate))
        })
        .catch(e => {
          loadingComponent.close()

          Dialog.alert({
            title: this.$t('Advertencia'),
            message: e.response.data.message,
            confirmText: 'OK',
            type: 'is-danger',
            hasIcon: false,
            onConfirm: () => {}
          })
        })
    },
    deleteCupon(cupon) {
      this.$buefy.dialog.confirm({
        title: 'Eliminar cupón',
        message: '¿Estás seguro de que deseas <b>ELIMINAR</b> el cupón?',
        confirmText: 'Eliminar',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: false,
        onConfirm: () => {
          session
            .delete(
              '/api/smokapp_coupon/' + this.currentClub.nid + '?id=' + cupon.id
            )
            .then(response => {
              console.log(response)

              this.downloadData()
            })
          this.$buefy.notification.open({
            message: 'El cupón se ha eliminado correctamente',
            position: 'is-top',
            hasIcon: false,
            duration: 3000,
            type: 'is-success'
          })
        }
      })
    },
    cuponUses(cupon) {
      this.$router.push({
        name: 'cuponUsesList',
        query: { coupon_id: cupon.id }
      })
    }
  },
  mounted() {
    this.validateAccess(['funciones_avanzadas_gestion', 'cupones'])
    this.validateRoles(['Administrador', 'Manager'])

    this.downloadData()
  },
  watch: {
    currentClub() {
      this.validateAccess(['funciones_avanzadas_gestion', 'cupones'])
      this.validateRoles(['Administrador', 'Manager'])

      this.downloadData()
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.row::after {
  content: '';
  clear: both;
  // display: flex;
}

[class*='col-'] {
  float: left;
  padding: 15px;
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  top: 10px;
  // height: 5vh;
  overflow: auto;
}

.menu li {
  padding: 8px;
  margin-bottom: 7px;
  background-color: #f7f3f3;
  color: #666666;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
  cursor: pointer;
}

.right li {
  // background-color: #33b5e5;
  // color: #fff;
  background-color: #f3f3f3;
}

.left li {
  background-color: #f3f3f3;
  // color: #fff;
}

.menu li:hover {
  background-color: #e1e1e1;
  // color: #fff;
}

.aside {
  background-color: #f3f3f3;
  padding: 15px;
  color: #666666;
  text-align: center;
  font-size: 14px;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
}

/* For desktop: */

.col-2 {
  width: 75%;
  background-color: #f9f9f9;
  border-radius: 6px;
  // height: calc(100vh - 10px);
}
.col-3 {
  width: 25%;
  background-color: #f9f9f9;
  border-radius: 6px;
}

.actions {
  height: 100vh;
  overflow: auto;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*='col-'] {
    width: 100%;
  }
}
</style>
