<template>
  <div
    style="display:flex; flex-direction:row-reverse;margin-bottom:14px; overflow:scroll; left:0px"
  >
    <div
      style="display:flex;width:200px;height:45px;margin-top: 7px;"
      v-for="(patient, index) in selectedPatients"
      :value="index"
      :key="patient.nid"
      class="hoverable"
    >
      <a
        class="userTag cloumns"
        @click="returnSelectedPatient(patient)"
        v-bind:class="{ patientSelected: isUserSelected(patient) }"
      >
        <img
          v-if="patient.foto"
          class="imgRigth mt-1 ml-1"
          style="border-radius:50%; height: 35px; width: 35px; object-fit:cover;"
          :src="patient.foto.url"
        />
        <div class="asideContent ">
          <b>
            {{ patient.nombre }}
          </b>
          <p v-if="patient.category_member">
            {{ patient.category_member.title }}
          </p>
          <!-- <p>{{ patient.email }}</p> -->
        </div>
      </a>
      <b-button
        class="mt-0"
        style="height:20px; width:5%; background-color: Transparent;background-repeat:no-repeat;border: none;cursor:pointer;overflow: hidden;outline:none;"
        @click="closePatient(patient)"
        ><i class="fas fa-times-circle" style="font-size:14px"></i
      ></b-button>
    </div>
    <b-button class="mt-3" @click="showSelectPatient()">
      <i class="fas fa-plus"></i>
    </b-button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PatientsList from '@/components/PatientsList.vue'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'

export default {
  mixins: [InitViewMixin],
  computed: {
    ...mapState('patients', ['patients', 'selectedPatients', 'currentPatient'])
  },
  methods: {
    isUserSelected(patient) {
      if (patient && this.currentPatient) {
        if (patient.nid == this.currentPatient.nid) {
          return true
        } else {
          return false
        }
      }
      return false
    },
    closePatient(patient) {
      this.$store.commit('patients/CLEAR_SELECTED_PATIENT', patient)
    },
    returnSelectedPatient(patient) {
      this.$emit('selectedPatient', patient)
    },
    showSelectPatient() {
      this.$buefy.modal.open({
        parent: this,
        component: PatientsList,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
        props: {
          users: this.patients,
          is_multiple: false,
          filterActived: true,
          currentClub: this.currentClub,
          autoselectWithOneResult: true
        },
        events: {
          seletedUsr: value => {
            if (!this.findPatient(value)) {
              this.selectedPatients.push(value)
              this.$store.dispatch(
                'patients/setSelectedPatients',
                this.selectedPatients
              )
            }

            this.$store.dispatch('patients/setCurrentPatient', value)
            this.$emit('selectedPatient', value)
          }
        }
      })
    },
    findPatient(p) {
      if (this.selectedPatients.length > 0) {
        console.log(this.selectedPatients)
        console.log(p)

        if (this.selectedPatients.find(n => n.nid === p.nid)) {
          return true
        }
        return false
      }
      return false
    },
    filterPatients(patients) {
      this.selectedPatients.forEach(patient => {
        //TODO: Descomenta esto para que vuelva a sacar al socio de la lista tras seleccionarlo
        // patients.splice(
        //   patients.findIndex(x => x.nid === patient.nid),
        //   1
        // )
      })
    }
  },
  created() {
    this.$analytics.logEvent('FetchPatients')
    this.$store.dispatch('patients/fetchSelectedPatients')
    this.$store.dispatch('patients/fetchCurrentPatient')
  }
}
</script>

<style lang="scss" scoped>
.userTag {
  background-color: #f3f3f3;
  padding: 2px;
  color: #666666;
  text-align: left;
  font-size: 14px;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
  display: flex;
  // justify-content: right;
  width: 200px;
  margin-left: 5px;
}

.hoverable {
  :hover {
    background-color: #e1e1e1;
  }
}
.patientSelected {
  background-color: #e1e1e1;
}

.asideContent {
  margin-left: 10px;
  color: #666666;
  text-align: left;
  font-size: 14px;
}
</style>
