<template>
  <div class="centerlogin">
    <img class="img" alt="Vue logo" src="../assets/smokapp_logo_blanco.png" />
    <p>{{ $t('BIENVENIDO A SMOKAPP') }}</p>
    <form
      @submit.prevent="doLogin"
      style="position:flex;justify-content: center; align-items: center;"
    >
      <div class="field" style="width:400px">
        <b-input
          v-model="username"
          type="text"
          placeholder="Nombre de usuario"
        />
      </div>
      <div class="field" style="width:400px;">
        <b-input v-model="password" type="password" placeholder="Contraseña" />
      </div>
      <div>
        <input
          type="submit"
          class="button -fill-gradient"
          :value="$t('Entrar')"
        />
      </div>
    </form>
    <hr />
    <footer>
      info@smokapp.com
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      username: '',
      password: ''
    }
  },

  computed: {
    ...mapState('auth', ['initialized'])
  },
  methods: {
    doLogin() {
      // this.$matomo.setUserId(this.username)
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.$store
        .dispatch('auth/doLogin', {
          username: this.username,
          password: this.password
        })
        .then(response => {
          loadingComponent.close()
          this.$router.replace({ name: 'club' })
        })
        .catch(e => {
          loadingComponent.close()
          if (e.response.status === 401 || e.response.status === 406) {
            if (
              //la super ñapa
              e.response.data[0] == 'CSRF validation failed' ||
              e.response.data[0].includes('Already logged in as')
            ) {
              if (this.$store.dispatch('auth/sessionToken')) {
                this.$router.replace({ name: 'club' })
              }
            } else {
              this.error = e
              this.$buefy.notification.open({
                message: e.response.data[0],
                position: 'is-top-right',
                type: 'is-danger',
                hasIcon: false
              })
            }
          } else {
            this.error = e
            this.$buefy.notification.open({
              message: e.response.data[0],
              position: 'is-top-right',
              type: 'is-danger',
              hasIcon: false
            })
          }
        })
    }
  },
  created() {
    this.$store.dispatch('setShowNavigationBar', false)
    this.$analytics.logEvent('Login.vue')

    // VALIDAMOS SI ESTAMOS LOGADOS
    const loadingComponent = this.$buefy.loading.open({
      container: null
    })
    this.$store
      .dispatch('auth/doLogin', {
        username: this.username,
        password: this.password
      })
      .then(response => {
        loadingComponent.close()
        this.$router.replace({ name: 'club' })
      })
      .catch(e => {
        loadingComponent.close()
        if (e.response.status === 401 || e.response.status === 406) {
          if (
            //la super ñapa
            e.response.data[0] == 'CSRF validation failed' ||
            e.response.data[0].includes('Already logged in as')
          ) {
            if (this.$store.dispatch('auth/sessionToken')) {
              this.$router.replace({ name: 'club' })
            }
          }
        }
      })
  }
}
</script>

<style scoped>
.img {
  width: 250pt;
}
.centerlogin {
  margin: auto;
}
.field {
  width: 150pt;
  margin: auto;
  width: 50%;
  padding: 2px;
}
</style>
