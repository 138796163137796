<template>
  <div>
    <div class="col-2 actions">
      <div>
        <h1 class="title has-text-centered">{{ $t('Detalles cupón') }}</h1>

        <div>
          <b-field :label="$t('Código')">
            <b-input
              v-model="code"
              :placeholder="$t('Código')"
              required
              :validation-message="$t('Obligatorio')"
            ></b-input>
          </b-field>

          <div class="mt-4">
            <b-field :label="$t('Descripción')">
              <b-input
                v-model="description"
                :placeholder="$t('Descripción')"
                required
                :validation-message="$t('Obligatorio')"
              ></b-input>
            </b-field>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <b-field :label="$t('Descuento')">
          <div class="columns is-gapless">
            <b-input
              class="column is-four-fifths"
              v-model="discount"
              :placeholder="$t('Cantidad')"
              required
              :validation-message="$t('Obligatorio')"
            ></b-input>
            <b-select class="column" v-model="type">
              <option value="0">{{ $t('€') }} </option>
              <option value="1">%</option>
            </b-select>
          </div>
        </b-field>
      </div>
      <div class="mt-4">
        <h1 class="title has-text-centered">{{ $t('Validez') }}</h1>
        <div class="columns is-1">
          <b-field :label="$t('Desde')" class="column ">
            <b-datepicker
              v-model="from_date"
              :show-week-number="false"
              :locale="this.$i18n.locale"
              :placeholder="$t('Fecha inicial')"
              :first-day-of-week="1"
              trap-focus
              required
              :validation-message="$t('Obligatorio')"
            >
            </b-datepicker>
          </b-field>
          <b-field :label="$t('Hasta')" class="column">
            <b-datepicker
              v-model="to_date"
              :show-week-number="false"
              :locale="this.$i18n.locale"
              :placeholder="$t('Fecha final')"
              :first-day-of-week="1"
              trap-focus
              required
              :validation-message="$t('Obligatorio')"
            >
            </b-datepicker>
          </b-field>
        </div>
      </div>
    </div>
    <div class="col-3 actions">
      <h1 class="title ">{{ $t('Restricciones') }}</h1>
      <div>
        <!-- <b-field :label="$t('Importe mínimo')">
          <b-input
            v-model="min_amount_order"
            :placeholder="$t('Importe mínimo')"
          ></b-input>
        </b-field> -->
        <div class="columns">
          <b-field class="column" :label="$t('Usos por cupón')">
            <b-input
              v-model="use_number_coupon"
              :placeholder="$t('Usos por cupón')"
            ></b-input>
          </b-field>
          <!-- <b-field class="column" label="Usos por socio">
            <b-input
              v-model="use_number_user"
              :placeholder="$t('Usos por socio')"
            ></b-input>
          </b-field> -->
        </div>

        <!-- <div>
          <div class="columns">
            <b-dropdown
              class="has-text-left column"
              v-model="category_member"
              multiple
              ref="dropdown"
              aria-role="list"
            >
              <b-button class="is-fullwidth" slot="trigger">
                <span>{{ $t('Categoría de socio') }}</span>
                <span v-if="category_member.length > 0" class="ml-1">
                  ({{ category_member.length }})
                </span>
                <b-icon icon="caret-down"></b-icon>
              </b-button>

              <b-dropdown-item
                v-for="category in categories"
                :key="category.nid"
                :value="category.nid"
                custom
                aria-role="listitem"
              >
                <b-checkbox @input="$refs.dropdown.selectItem(category)">
                  <span>{{ category.title }}</span>
                </b-checkbox>
              </b-dropdown-item>
            </b-dropdown>

            <b-dropdown
              class="has-text-left column"
              v-model="type_member"
              multiple
              ref="type_member_dropdown"
              aria-role="list"
            >
              <b-button class="is-fullwidth" slot="trigger">
                <span>{{ $t('Tipo de socio') }} </span>
                <span v-if="type_member.length > 0" class="ml-1">
                  ({{ type_member.length }})
                </span>
                <b-icon icon="caret-down"></b-icon>
              </b-button>

              <b-dropdown-item custom aria-role="listitem">
                <b-checkbox
                  @input="$refs.type_member_dropdown.selectItem('ludico')"
                >
                  <span>{{ $t('Lúdico') }}</span>
                </b-checkbox>
              </b-dropdown-item>

              <b-dropdown-item custom aria-role="listitem">
                <b-checkbox
                  @input="$refs.type_member_dropdown.selectItem('terapeutico')"
                >
                  <span>{{ $t('Terapéutico') }}</span>
                </b-checkbox>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div>
          <div class="columns">
            <b-dropdown
              class="has-text-left column"
              v-model="category_product"
              multiple
              ref="category_product_dropdown"
              aria-role="list"
            >
              <b-button class="is-fullwidth " slot="trigger">
                <span>{{ $t('Categorías de producto') }} </span>
                <span v-if="category_product.length > 0" class="ml-1">
                  ({{ category_product.length }})
                </span>
                <b-icon icon="caret-down"></b-icon>
              </b-button>

              <b-dropdown-item
                v-for="category in product_categories"
                :key="category.nid"
                :value="category.nid"
                custom
                aria-role="listitem"
              >
                <b-checkbox
                  @input="$refs.category_product_dropdown.selectItem(category)"
                >
                  <span>{{ category.title }}</span>
                </b-checkbox>
              </b-dropdown-item>
            </b-dropdown>

            <b-dropdown
              class="has-text-left column"
              v-model="products"
              multiple
              ref="products_dropdown"
              aria-role="list"
            >
              <b-button class="is-fullwidth" slot="trigger">
                <span>{{ $t('Productos') }}</span>
                <span v-if="products.length > 0" class="ml-1">
                  ({{ products.length }})
                </span>
                <b-icon icon="caret-down"></b-icon>
              </b-button>

              <b-dropdown-item
                v-for="product in dispensary"
                :key="product.nid"
                :value="product.nid"
                custom
                aria-role="listitem"
              >
                <b-checkbox
                  @input="$refs.products_dropdown.selectItem(product)"
                >
                  <span>{{ product.titulo }}</span>
                </b-checkbox>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>

        <b-switch v-model="cumulative" class="mt-4">
          Acumulable a otros cupones
        </b-switch> -->
      </div>
      <b-input
        class="mt-4"
        maxlength="200"
        type="textarea"
        :placeholder="$t('Notas')"
        v-model="notes"
      ></b-input>

      <b-button
        style="width:100%"
        class="is-primary mt-4"
        @click="createCupon()"
      >
        {{ $t('Crear cupón') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'
import session from '@/services/Session.js'

export default {
  props: {
    cupon: Object
  },
  mixins: [InitViewMixin],
  computed: {
    ...mapState('club', ['currentClub']),
    ...mapState('patients', ['patients', 'categories']),
    ...mapState('dispensaryPro', ['dispensary']),
    userType() {
      var tmp = []
      this.type_member.forEach(element => {
        if (element == 'ludico') {
          tmp.push(1)
        } else {
          tmp.push(2)
        }
      })
      if (tmp.length > 0) {
        return tmp
      } else {
        return null
      }
    },
    userCategories() {
      var tmp = []
      this.category_member.forEach(element => {
        tmp.push(element.nid)
      })
      if (tmp.length > 0) {
        return tmp
      } else {
        return null
      }
    },
    productCategories() {
      var tmp = []
      this.category_product.forEach(element => {
        tmp.push(element.nid)
      })
      if (tmp.length > 0) {
        return tmp
      } else {
        return null
      }
    },
    selectedProducts() {
      var tmp = []
      this.products.forEach(element => {
        console.log(element)
        tmp.push(element.pid)
      })
      if (tmp.length > 0) {
        return tmp
      } else {
        return null
      }
    }
  },
  data() {
    return {
      category_member: [],
      code: undefined,
      description: undefined,
      discount: undefined,
      notes: undefined,
      status: true,
      use_number_coupon: undefined,
      use_number_user: undefined,
      min_amount_order: undefined,
      type: 0,
      locale: 'es-ES',
      from_date: null,
      to_date: null,
      cumulative: false,
      type_member: [],
      category_product: [],
      product_categories: [],
      products: []
    }
  },

  methods: {
    createCupon() {
      if (this.validateData() == false) {
        return
      }
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      let json = {
        asocid: this.currentClub.nid,
        code: this.code,
        title: this.description,
        type: this.type,
        discount: Number(this.discount),
        status: this.status
      }

      if (this.from_date) {
        json = Object.assign({ from: Date.parse(this.from_date) / 1000 }, json)
      }
      if (this.to_date) {
        json = Object.assign({ to: Date.parse(this.to_date) / 1000 }, json)
      }
      if (this.use_number_coupon) {
        json = Object.assign(
          { use_number_coupon: Number(this.use_number_coupon) },
          json
        )
      } else {
        json = Object.assign({ use_number_coupon: 0 }, json)
      }
      if (this.use_number_user) {
        json = Object.assign(
          { use_number_user: Number(this.use_number_user) },
          json
        )
      } else {
        json = Object.assign({ use_number_user: 0 }, json)
      }
      if (this.userType) {
        json = Object.assign({ use_user_type: this.userType }, json)
      }
      if (this.userCategories) {
        json = Object.assign({ use_user_category: this.userCategories }, json)
      }
      if (this.productCategories) {
        json = Object.assign(
          { product_categories: this.productCategories },
          json
        )
      }
      if (this.selectedProducts) {
        json = Object.assign({ use_products: this.selectedProducts }, json)
      }
      if (this.min_amount_order) {
        json = Object.assign(
          { min_amount_order: Number(this.min_amount_order) },
          json
        )
      }
      if (this.cumulative) {
        json = Object.assign({ cumulative: Number(this.cumulative) }, json)
      }
      if (this.notes) {
        json = Object.assign({ notes: this.notes }, json)
      }

      console.log(json)

      session.post('/api/smokapp_coupon', json).then(response => {
        this.cupons = response.data
        loadingComponent.close()
        this.$router.go(-1)
      })
    },
    validateData() {
      if (this.code && this.description && this.discount && this.products) {
        return true
      } else {
        this.$buefy.notification.open({
          message: this.$t('Debes completar los campos'),
          position: 'is-top-right',
          type: 'is-danger',
          hasIcon: false
        })
        return false
      }
    },
    downloadDispensary() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })

      this.$store
        .dispatch('dispensaryPro/fetchDispensary', {
          asocid: this.currentClub.nid,
          status: null
        })
        .then(() => {
          loadingComponent.close()
        })
    },
    isNumeric(str) {
      if (typeof str == 'number') return true
      if (typeof str != 'string') return false
      return (
        !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      ) // ...and ensure strings of whitespace fail
    }
  },
  created() {
    // console.log(this.cupon)
    this.validateAccess(['socios', 'comunicacion', 'cupones'])
    this.validateRoles(['Administrador', 'Manager', 'Recepción'])
    this.$store.dispatch(
      'patients/fetchPatientCategories',
      this.currentClub.nid
    )
    this.$store
      .dispatch('dispensaryPro/fetchCategories', {
        asocid: this.currentClub.nid,
        type: 'dispensary'
      })
      .then(() => {
        this.product_categories = this.$store.state.dispensaryPro.categories
      })
    this.downloadDispensary()

    if (this.cupon) {
      this.code = this.cupon.code
      this.description = this.cupon.description
      this.discount = Number(this.cupon.discount)
      this.type = this.cupon.type
      this.status = this.cupon.status
      this.use_number_coupon = this.cupon.use_number_coupon
      this.min_amount_order = this.cupon.min_amount_order
      this.cumulative = this.cupon.cumulative
      this.notes = this.cupon.notes
      this.from_date = new Date(Number(this.cupon.from_date) * 1000)
      this.to_date = new Date(Number(this.cupon.to_date) * 1000)
    }
  },
  watch: {
    discount() {
      if (this.discount != '' && !this.isNumeric(this.discount)) {
        this.$buefy.snackbar.open({
          duration: 2000,
          message: 'Introduce valores numéricos',
          type: 'is-primary',
          position: 'is-top',
          actionText: 'Ok',
          queue: false
        })
        return
      }
    }
  }
}
</script>

<style lang="scss" scoped>
[class*='col-'] {
  float: left;
  padding: 15px;
}
.col-2 {
  width: 50%;
  background-color: #f9f9f9;
  border-radius: 6px;
  // height: calc(100vh - 10px);
}
.col-3 {
  width: 50%;
  background-color: #f9f9f9;
  border-radius: 6px;
}

.actions {
  height: 100vh;
  overflow: auto;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*='col-'] {
    width: 100%;
  }
}
</style>
