<template>
  <div>
    <div id="app">
      <div class="container">
        <div id="bar">
          <h4>{{ $t('Editor de contenido html avanzado') }}</h4>
          <!-- <b-button v-on:click="exportHtml">Export HTML</b-button> -->
        </div>

        <EmailEditor
          ref="emailEditor"
          v-on:load="editorLoaded"
          style="height:80vh"
        />

        <b-button style="top:30px" type="is-primary" v-on:click="saveDesign">
          {{ $t('Guardar') }}</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { EmailEditor } from 'vue-email-editor'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'
import defaultDesign from '@/views/Utils/defaultDesign.json'

export default {
  components: {
    EmailEditor
  },
  mixins: [InitViewMixin],

  methods: {
    editorLoaded() {
      //   this.$refs.emailEditor.editor.loadDesign({})
      console.log(this.currentClub)
      console.log(this.currentClub.html_design)
      // console.log(JSON.parse(this.currentClub.html_design))
      if (this.currentClub.html_design != '') {
        this.$refs.emailEditor.editor.loadDesign(
          JSON.parse(this.currentClub.html_design)
        )
      } else {
        console.log('DEFAULT DESIGN')
        this.$refs.emailEditor.editor.loadDesign(defaultDesign)
      }

      // this.$refs.emailEditor.editor.loadDesign(this.aa)
    },
    saveDesign() {
      console.log(this.currentClub)
      this.$refs.emailEditor.editor.saveDesign(design => {
        console.log('saveDesign', design)

        this.$refs.emailEditor.editor.exportHtml(data => {
          console.log('exportHtml', data.html)
          const loadingComponent = this.$buefy.loading.open({
            container: null
          })
          let club = {
            html_design: JSON.stringify(design),
            full_html: data.html
          }

          this.$store
            .dispatch('club/updateClub', {
              asocid: this.currentClub.nid,
              club: club
            })
            .then(() => {
              this.$store
                .dispatch('club/fetchClub', { asocid: this.currentClub.nid })
                .then(() => {
                  loadingComponent.close()
                  this.$router.go(-1)
                })
            })
        })
      })
    },
    exportHtml() {
      this.$refs.emailEditor.editor.exportHtml(data => {
        console.log('exportHtml', data)
        this.publishProduct(data)
      })
    },
    publishProduct(data) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      let club = {
        body: data
      }

      this.$store
        .dispatch('club/updateClub', {
          asocid: this.currentClub.nid,
          club: club
        })
        .then(() => {
          this.$store.dispatch('club/fetchClubs').then(() => {
            loadingComponent.close()
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
