import { SET_TV_CAROUSEL_SETTINGS } from './types'

const initialState = {
  carouselSettings: {
    backgroundcolor: '#FFFFFF',
    textcolor: '#000000',
    interval: 15000,
    showItemSubtitle: true,
    showItemDescription: true,
    showItemQr: true
  }
}

const mutations = {
  [SET_TV_CAROUSEL_SETTINGS](state, settings) {
    localStorage.setItem('carouselSettings', JSON.stringify(settings))
    state.carouselSettings = settings
    console.log(settings)
  }
}
const actions = {
  initialize({ commit, state }) {
    const settings = JSON.parse(localStorage.getItem('carouselSettings'))
    if (settings) {
      commit(SET_TV_CAROUSEL_SETTINGS, settings)
    }
  },
  setCarouselSettings({ commit }, settings) {
    commit(SET_TV_CAROUSEL_SETTINGS, settings)
  }
}

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations
}
