<template>
  <div>
    <h2 class="mt-3 mb-2" style="text-align: left">
      {{ $t('INFORME DE CIERRE') }}
    </h2>
    <div style="display:flex">
      <b-field :label="$t('Fecha')">
        <b-datepicker
          v-model="selected_date"
          :show-week-number="false"
          :locale="this.$i18n.locale"
          :placeholder="$t('Fecha')"
          trap-focus
          :first-day-of-week="1"
        >
        </b-datepicker>
      </b-field>
      <!-- <b-field :label="$t('Fecha')" class="ml-4">
        <b-datepicker
          v-model="end_date"
          :show-week-number="true"
          :locale="this.$i18n.locale"
          :placeholder="$t('Fecha final')"
          trap-focus
        >
        </b-datepicker>
      </b-field> -->
    </div>
    <div v-if="reportCash" class="flex-gap">
      <div class="rcorners1">
        <b-field :label="$t('Fecha del primer movimiento')">
          <h5>
            {{
              format_date(reportCash.summary.first_movement, 'hh:mm dd/MM/yyyy')
            }}
          </h5>
        </b-field>
        <b-field :label="$t('Fecha del último movimiento')">
          <h5>
            {{
              format_date(reportCash.summary.end_movement, 'hh:mm dd/MM/yyyy')
            }}
          </h5>
        </b-field>
      </div>

      <div class="rcorners1">
        <b-field :label="$t('Manual añadido')">
          <h5>
            {{
              reportCash.summary.manual_amount
                ? reportCash.summary.manual_amount.toFixed(2)
                : '0.0'
            }}
            {{ $t('€') }}
          </h5>
        </b-field>
      </div>

      <div class="rcorners1" v-if="cashPermission">
        <b-field :label="$t('Efectivo')">
          <h5>
            {{
              reportCash.summary.cash_order_amount
                ? reportCash.summary.cash_order_amount.toFixed(2)
                : '0.0'
            }}
            {{ $t('€') }}
          </h5>
        </b-field>
        <b-field :label="$t('Nº pedidos efectivo')">
          <h5>
            {{
              reportCash.summary.cash_order_movement
                ? reportCash.summary.cash_order_movement
                : '0'
            }}
          </h5>
        </b-field>
      </div>

      <div class="rcorners1" v-if="cashPermission">
        <b-field :label="$t('Efectivo Cancelaciones')">
          <h5>
            {{
              reportCash.summary.cash_order_refund_amount
                ? reportCash.summary.cash_order_refund_amount.toFixed(2)
                : '0.0'
            }}
            {{ $t('€') }}
          </h5>
        </b-field>
        <b-field :label="$t('Nº pedidos efectivo')">
          <h5>
            {{
              reportCash.summary.cash_order_refund_movement
                ? reportCash.summary.cash_order_refund_movement
                : '0'
            }}
          </h5>
        </b-field>
      </div>

      <div
        v-if="
          (this.currentClub.functionalities.funciones_avanzadas_gestion ||
            this.currentClub.functionalities.monedero) &&
            walletPermission
        "
        class="rcorners1"
      >
        <b-field :label="$t('Wallet')">
          <h5>
            {{
              reportCash.summary.wallet_order_amount
                ? reportCash.summary.wallet_order_amount.toFixed(2)
                : '0.0'
            }}
            {{ $t('€') }}
          </h5>
        </b-field>
        <b-field :label="$t('Nº pedidos wallet')">
          <h5>
            {{
              reportCash.summary.wallet_order_movement
                ? reportCash.summary.wallet_order_movement
                : '0'
            }}
          </h5>
        </b-field>
      </div>

      <div
        v-if="
          (this.currentClub.functionalities.funciones_avanzadas_gestion ||
            this.currentClub.functionalities.monedero) &&
            walletPermission
        "
        class="rcorners1"
      >
        <b-field :label="$t('Wallet Cancelaciones')">
          <h5>
            {{
              reportCash.summary.wallet_order_refund_amount
                ? reportCash.summary.wallet_order_refund_amount.toFixed(2)
                : '0.0'
            }}
            {{ $t('€') }}
          </h5>
        </b-field>
        <b-field :label="$t('Nº pedidos wallet')">
          <h5>
            {{
              reportCash.summary.wallet_order_refund_movement
                ? reportCash.summary.wallet_order_refund_movement
                : '0'
            }}
          </h5>
        </b-field>
      </div>

      <div class="rcorners1" v-if="cardPermission">
        <b-field :label="$t('Tarjeta')">
          <h5>
            {{
              reportCash.summary.tpv_order_amount
                ? reportCash.summary.tpv_order_amount.toFixed(2)
                : '0.0'
            }}
            {{ $t('€') }}
          </h5>
        </b-field>
        <b-field :label="$t('Nº pedidos tarjeta')">
          <h5>
            {{
              reportCash.summary.tpv_order_movement
                ? reportCash.summary.tpv_order_movement
                : '0'
            }}
          </h5>
        </b-field>
      </div>

      <div class="rcorners1" v-if="cardPermission">
        <b-field :label="$t('Tarjeta Cancelaciones')">
          <h5>
            {{
              reportCash.summary.tpv_order_refund_amount
                ? reportCash.summary.tpv_order_refund_amount.toFixed(2)
                : '0.0'
            }}
            {{ $t('€') }}
          </h5>
        </b-field>
        <b-field :label="$t('Nº pedidos tarjeta')">
          <h5>
            {{
              reportCash.summary.tpv_order_refund_movement
                ? reportCash.summary.tpv_order_refund_movement
                : '0'
            }}
          </h5>
        </b-field>
      </div>

      <div
        v-if="
          (this.currentClub.functionalities.funciones_avanzadas_gestion ||
            this.currentClub.functionalities.monedero) &&
            cashPermission &&
            walletPermission
        "
        class="rcorners1"
      >
        <b-field :label="$t('Recarga monedero con cash')">
          <h5>
            {{
              reportCash.summary.wallet_cash_amount
                ? reportCash.summary.wallet_cash_amount.toFixed(2)
                : '0.0'
            }}
            {{ $t('€') }}
          </h5>
        </b-field>
        <b-field :label="$t('Nº recargas monedero con cash')">
          <h5>
            {{
              reportCash.summary.wallet_cash_movement
                ? reportCash.summary.wallet_cash_movement
                : '0'
            }}
          </h5>
        </b-field>
      </div>

      <div
        v-if="
          (this.currentClub.functionalities.funciones_avanzadas_gestion ||
            this.currentClub.functionalities.monedero) &&
            walletPermission &&
            cardPermission
        "
        class="rcorners1"
      >
        <b-field :label="$t('Recarga monedero con TPV')">
          <h5>
            {{
              reportCash.summary.wallet_tpv_amount
                ? reportCash.summary.wallet_tpv_amount.toFixed(2)
                : '0.0'
            }}
            {{ $t('€') }}
          </h5>
        </b-field>
        <b-field :label="$t('Nº recargas monedero con TPV')">
          <h5>
            {{
              reportCash.summary.wallet_tpv_movement
                ? reportCash.summary.wallet_tpv_movement
                : '0'
            }}
          </h5>
        </b-field>
      </div>

      <!-- <div class="rcorners1">
        <b-field :label="$t('Cantidad inicial en caja')">
          <h5>
            {{
              reportCash.summary.amount_initial
                ? reportCash.summary.amount_initial.toFixed(2)
                : '0'
            }}
            {{ $t('€') }}
          </h5>
        </b-field>
        <b-field :label="$t('Cantidad total en caja')">
          <h5>
            {{
              reportCash.summary.cash_box_total_amount
                ? reportCash.summary.cash_box_total_amount.toFixed(2)
                : '0'
            }}
            {{ $t('€') }}
          </h5>
        </b-field>
      </div> -->

      <div class="rcorners1">
        <b-field :label="$t('Movimientos')">
          <b-field label="Manual" class="mr-2">
            <h5>
              {{
                reportCash.summary.type_manual
                  ? reportCash.summary.type_manual
                  : '0'
              }}
            </h5>
          </b-field>
          <b-field :label="$t('Automático')" class="mr-2">
            <h5>
              {{
                reportCash.summary.type_automatic
                  ? reportCash.summary.type_automatic
                  : '0'
              }}
            </h5>
          </b-field>
          <b-field
            v-if="
              (this.currentClub.functionalities.funciones_avanzadas_gestion ||
                this.currentClub.functionalities.monedero) &&
                walletPermission
            "
            :label="$t('Monedero')"
            class="mr-2"
          >
            <h5>
              {{
                reportCash.summary.type_wallet
                  ? reportCash.summary.type_wallet
                  : '0'
              }}
            </h5>
          </b-field>
          <b-field :label="$t('Pedido')">
            <h5>
              {{
                reportCash.summary.type_order
                  ? reportCash.summary.type_order
                  : '0'
              }}
            </h5>
          </b-field>
        </b-field>
      </div>
      <div class="rcorners1">
        <b-field :label="$t('Ingresos brutos')">
          <h5>
            {{
              (
                Number(
                  reportCash.summary.cash_box_balance
                    ? reportCash.summary.cash_box_balance
                    : '0'
                ) +
                Number(
                  reportCash.summary.tpv_order_amount
                    ? reportCash.summary.tpv_order_amount
                    : '0'
                )
              ).toFixed(2)
            }}
            €
          </h5>
        </b-field>
        <b-field :label="$t('Devoluciones')">
          <h5>
            {{
              (
                Number(
                  reportCash.summary.cash_order_refund_amount
                    ? reportCash.summary.cash_order_refund_amount
                    : '0'
                ) +
                Number(
                  reportCash.summary.tpv_order_refund_amount
                    ? reportCash.summary.tpv_order_refund_amount
                    : '0'
                )
              ).toFixed(2)
            }}
            €
          </h5>
        </b-field>
      </div>
      <div class="rcorners1">
        <b-field :label="$t('Total ingresos')">
          <h5>
            {{
              Number(
                reportCash.summary.incomes_total_amount
                  ? reportCash.summary.incomes_total_amount.toFixed(2)
                  : '0'
              )
            }}
            €
          </h5>
        </b-field>
      </div>
    </div>

    <hr />
    <h1>{{ $t('MOVIMIENTOS') }}</h1>
    <b-table
      v-if="reportCash"
      style="text-align: left;"
      :data="this.reportCash.movements"
      :default-sort-direction="defaultSortDirection"
      default-sort="created"
    >
      <b-table-column
        field="mid"
        :label="$t('ID Movimiento')"
        v-slot="props"
        width="50"
      >
        {{ props.row.mid }}
      </b-table-column>

      <b-table-column
        field="created"
        :label="$t('Fecha')"
        v-slot="props"
        width="50"
      >
        {{ format_date(props.row.created, 'hh:mm dd/MM/yyyy') }}
      </b-table-column>

      <b-table-column
        field="action"
        :label="$t('Tipos')"
        v-slot="props"
        width="50"
      >
        {{ props.row.action }}
      </b-table-column>

      <b-table-column
        field="employee_name"
        :label="$t('Empleado')"
        v-slot="props"
        width="150"
      >
        {{ props.row.employee_name }}
      </b-table-column>

      <b-table-column
        field="amount"
        :label="$t('Cantidad')"
        v-slot="props"
        width="50"
      >
        {{ props.row.amount }}
      </b-table-column>

      <b-table-column
        field="status"
        :label="'Status'"
        v-slot="props"
        width="50"
      >
        {{ props.row.status }}
      </b-table-column>

      <!-- <b-table-column
        field="uid"
        :label="$t('Trabajador')"
        v-slot="props"
        width="50"
      >
        <b-button :label="props.row.uid"> </b-button>
      </b-table-column> -->

      <b-table-column
        field="note"
        :label="$t('Nota')"
        v-slot="props"
        width="50"
      >
        {{ props.row.note }}
      </b-table-column>
    </b-table>

    <hr />
    <h1>{{ $t('PEDIDOS') }}</h1>
    <b-table
      v-if="reportCash"
      style="text-align: left;"
      :data="this.reportCash.orders ? this.reportCash.orders : []"
      :default-sort-direction="defaultSortDirection"
      default-sort="date"
    >
      <b-table-column
        field="oid"
        :label="$t('ID Pedido')"
        v-slot="props"
        width="50"
      >
        {{ props.row.oid }}
      </b-table-column>

      <b-table-column
        field="date"
        :label="$t('Fecha')"
        v-slot="props"
        width="50"
      >
        {{ format_date(props.row.date, 'hh:mm dd/MM/yyyy') }}
      </b-table-column>

      <b-table-column field="name" :label="$t('Nombre')" v-slot="props">
        {{ props.row.name }}
      </b-table-column>

      <b-table-column field="surname" :label="$t('Apellidos')" v-slot="props">
        {{ props.row.surname }}
      </b-table-column>

      <b-table-column
        field="order_base_price"
        :label="$t('Precio base')"
        v-slot="props"
      >
        {{
          (props.row.order_base_price ? props.row.order_base_price : 0).toFixed(
            2
          )
        }}
      </b-table-column>

      <b-table-column
        field="order_discount"
        :label="$t('Descuento')"
        v-slot="props"
      >
        {{
          (props.row.order_discount ? props.row.order_discount : 0).toFixed(2)
        }}
      </b-table-column>

      <b-table-column
        field="pay_method"
        :label="$t('Método de pago')"
        v-slot="props"
      >
        {{ props.row.pay_method }}
      </b-table-column>
    </b-table>

    <hr />
    <h1>{{ $t('PRODUCTOS') }}</h1>
    <b-table
      v-if="reportCash"
      style="text-align: left;"
      :data="this.reportCash.products"
      :default-sort-direction="defaultSortDirection"
      default-sort="code"
    >
      <b-table-column field="code" :label="$t('SKU')" v-slot="props" width="50">
        {{ props.row.code }}
      </b-table-column>

      <b-table-column field="title" :label="$t('Nombre')" v-slot="props">
        {{ props.row.title }}
      </b-table-column>

      <b-table-column field="quantity" :label="$t('Cantidad')" v-slot="props">
        {{ props.row.quantity.toFixed(2) }}
      </b-table-column>

      <b-table-column field="amount" :label="$t('Importe')" v-slot="props">
        {{ props.row.amount.toFixed(2) }}
      </b-table-column>

      <b-table-column field="discount" :label="$t('Descuento')" v-slot="props">
        {{ props.row.discount.toFixed(2) }}
      </b-table-column>

      <b-table-column field="order" :label="$t('Pedidos')" v-slot="props">
        {{ props.row.order }}
      </b-table-column>
    </b-table>

    <hr />
    <h1>{{ $t('SOCIOS') }}</h1>
    <b-table
      v-if="reportCash"
      style="text-align: left;"
      :data="this.reportCash.members"
      :default-sort-direction="defaultSortDirection"
      default-sort="name"
    >
      <b-table-column field="name" :label="$t('Nombre')" v-slot="props">
        {{ props.row.name }}
      </b-table-column>

      <b-table-column field="amount" :label="$t('Importe')" v-slot="props">
        {{ props.row.amount.toFixed(2) }}
      </b-table-column>

      <b-table-column field="discount" :label="$t('Descuento')" v-slot="props">
        {{ props.row.discount.toFixed(2) }}
      </b-table-column>

      <b-table-column field="orders" :label="$t('Pedidos')" v-slot="props">
        {{ props.row.orders }}
      </b-table-column>
    </b-table>

    <hr />
    <h1>{{ $t('EMPLEADOS') }}</h1>
    <b-table
      v-if="reportCash"
      style="text-align: left;"
      :data="this.reportCash.employees"
      :default-sort-direction="defaultSortDirection"
      default-sort="name"
    >
      <b-table-column field="uid" :label="$t('UID')" v-slot="props">
        {{ props.row.uid }}
      </b-table-column>
      <b-table-column
        field="employee_name"
        :label="$t('Nombre')"
        v-slot="props"
      >
        {{ props.row.employee_name }}
      </b-table-column>

      <b-table-column field="amount" :label="$t('Importe')" v-slot="props">
        {{ props.row.amount }}
      </b-table-column>

      <b-table-column field="orders" :label="$t('Pedidos')" v-slot="props">
        {{ props.row.orders }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'

export default {
  data() {
    return {
      start_date: new Date(new Date().setHours(0, 0, 0, 0)),
      end_date: new Date(),
      selected_date: new Date(),
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'desc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 15,
      selected: null,
      locale: 'es-ES',
      is_editing: false,
      note: null,
      currentAmount: null
    }
  },
  mixins: [ToolsMixin, InitViewMixin],

  computed: {
    ...mapState('club', ['currentClub']),
    ...mapState('register', ['reportCash']),
    ...mapState('club', [
      'cashPermission',
      'cardPermission',
      'walletPermission'
    ])
  },
  methods: {
    restCalculate() {
      return this.currentAmount - this.reportCash.summary.total_amount
    },
    closeRegister() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      let json = {
        asocid: this.currentClub.nid,
        currentAmount: this.currentAmount,
        note: this.note
      }
      this.$store.dispatch('register/closeRegister', json).then(() => {
        loadingComponent.close()
        this.$emit('close')
      })
    },
    downloadData() {
      this.validateAccess(['software_gestion_club', 'monedero'])
      this.validateRoles(['Administrador', 'Manager', 'Caja', 'Budtender'])
      this.$store.dispatch('register/fetechReportCash', {
        asocid: this.currentClub.nid,
        start_date: Date.parse(this.start_date) / 1000,
        end_date: Date.parse(this.end_date) / 1000,
        status: 'all'
      })
    }
  },
  created() {
    this.downloadData()
  },
  watch: {
    currentClub() {
      this.downloadData()
    },
    selected_date() {
      this.start_date = new Date(this.selected_date)
      this.end_date = new Date(this.selected_date)
      this.start_date.setHours(0, 0, 0, 0)
      this.end_date.setHours(23, 59, 59)
      this.downloadData()
    }
  }
}
</script>

<style lang="scss" scoped>
.my-table {
  // max-height: 50vh;
  overflow-y: visible;
}
.flex-gap {
  display: inline-flex;
  flex-wrap: wrap;
}

.flex-gap > div {
  margin: 6px;
}

.rcorners1 {
  border-radius: 12px;
  background: #f1f1f1;
  padding: 14px;
  //   width: 200px;
  //   height: 150px;
}
</style>
