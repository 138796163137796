import APIOrders from '../services/APIOrders'
import {
  ADD_ORDER,
  REMOVE_ORDER,
  REMOVE_ALL_ORDERS,
  SET_ORDERS,
  SET_CURRENT_ORDER,
  CLEAR_CURRENT_ORDER,
  SET_CURRENT_PATIENT
} from './types'
import tools from '@/Tools/tools.js'

const initialState = {
  orders: [],
  ordersData: null,
  currentOrder: null
}
const getters = {
  getCart: state => {
    return state.cart
  },
  getOrderById: state => id => {
    return state.orders.find(order => order.order_id === id)
  }
}
const mutations = {
  [REMOVE_ORDER](state, order) {
    state.currentOrder = null
    state.orders.splice(
      state.orders.indexOf(state.orders.find(ord => ord.order_id == order.oid)),
      1
    )
    localStorage.setItem('orders', JSON.stringify(state.orders))
  },
  [REMOVE_ALL_ORDERS](state) {
    state.cart = []
    localStorage.removeItem('cart')
  },
  [SET_ORDERS](state, orders) {
    state.orders = orders.data
    state.ordersData = orders
    localStorage.setItem('orders', JSON.stringify(state.orders))
  },
  [SET_CURRENT_ORDER](state, currentOrder) {
    state.currentOrder = currentOrder
  },
  [CLEAR_CURRENT_ORDER](state) {
    state.currentOrder = null
  },
  [ADD_ORDER](state, order) {
    state.orders.push(order)
    localStorage.setItem('orders', JSON.stringify(state.orders))
  },
  ['SET_CURRENT_ORDER_OPERATION_ID'](state, operationId) {
    state.currentOrder.operation_id = operationId
  }
}
const actions = {
  initialize({ commit }) {
    // const orders = JSON.parse(localStorage.getItem('orders'))
    // if (orders) {
    //   commit(SET_ORDERS, orders)
    // }
  },
  setOrderOperationId({ commit }, operationId) {
    commit('SET_CURRENT_ORDER_OPERATION_ID', operationId)
  },

  fetchClubOrders(
    { commit },
    {
      asocid,
      start_date,
      end_date,
      membership_id,
      limit,
      page,
      operation_id,
      operation_status
    }
  ) {
    console.log(start_date)
    console.log(end_date)
    let promise = APIOrders.listClubOrders(
      asocid,
      start_date,
      end_date,
      membership_id,
      limit,
      page,
      operation_id,
      operation_status
    )
      .then(response => {
        commit(SET_ORDERS, response.data)
      })
      .catch(error => {
        commit(REMOVE_ALL_ORDERS)
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  fetchClubOrdersInProcess({ commit }, { asocid }) {
    let promise = APIOrders.listClubOrdersInProcess(asocid)
      .then(response => {
        commit(SET_ORDERS, response.data)
      })
      .catch(error => {
        commit(REMOVE_ALL_ORDERS)
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  fetchClubOrdersCompleted({ commit }, { asocid, start_date, end_date }) {
    let promise = APIOrders.listClubOrdersCompleted(
      asocid,
      start_date,
      end_date
    )
      .then(response => {
        commit(SET_ORDERS, response.data)
      })
      .catch(error => {
        commit(REMOVE_ALL_ORDERS)
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  fetchPatientOrders({ commit }, { asocid, fichaid }) {
    console.log(asocid)
    console.log(fichaid)
    commit(CLEAR_CURRENT_ORDER)

    let promise = APIOrders.listOrdersStatus(asocid, fichaid, 'cart')
      .then(response => {
        commit(SET_ORDERS, response.data)
      })
      .catch(error => {
        // tools.manageAxiosError(error)
        console.log('Ha habido un error en fetchPatientOrders:' + error)
      })
    return promise
  },
  postOrder({ commit, state }, order) {
    console.log('POST ORDER')
    console.log(order)
    let promise = APIOrders.postOrder(order)
      .then(response => {
        console.log(response)
        // commit(SET_ORDERS, response.data.data)
        // if (!this.currentOrder) {
        //   commit(ADD_ORDER, state.currentOrder)
        // }else{
        //   commit(ADD_ORDER, state.currentOrder)
        // }
        let promise = APIOrders.listOrdersStatus(
          order.asocid,
          order.membership_id,
          'cart'
        )
          .then(response => {
            console.log(response.data)
            commit(SET_ORDERS, response.data)
          })
          .catch(error => {
            console.log('Ha habido un error en postOrder:' + error)
            tools.manageAxiosError(error)
          })
      })
      .catch(error => {
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  setCurrentOrder({ commit }, order) {
    commit(SET_CURRENT_ORDER, order)
  },
  deleteOrder({ commit }, order) {
    let promise = APIOrders.deleteOrder(order)
      .then(response => {
        console.log(order)
        commit(REMOVE_ORDER, order)
      })
      .catch(error => {
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  payOrder({ commit, state }, payment) {
    let promise = APIOrders.payOrder(payment)
    // .then(response => {
    //   Toast.open({
    //     duration: 4000,
    //     message: `El pago se ha realizado correctamente`,
    //     position: 'is-top',
    //     type: 'is-success'
    //   })

    //   commit('patients/CLEAR_CURRENT_PATIENT', null, {
    //     root: true
    //   })

    //   let promise = APIOrders.listOrdersStatus(
    //     payment.asocid,
    //     payment.membership_id,
    //     'cart'
    //   )
    //     .then(response => {
    //       console.log(response.data)
    //     })
    //     .catch(error => {
    //       // tools.manageAxiosError(error)
    //       console.log('Ha habido un error en fetchPatientOrders:' + error)
    //     })
    // })
    // .catch(error => {
    //   console.log('Ha habido un error durante el pago:' + error)
    //   tools.manageAxiosError(error)
    // })
    return promise
  },
  refundOrder({ commit, state }, json) {
    let promise = APIOrders.refundOrder(json)
    // .then(response => {
    //   Toast.open({
    //     duration: 4000,
    //     message: `La devolución se ha realizado correctamente`,
    //     position: 'is-top',
    //     type: 'is-success'
    //   })
    // })
    // .catch(error => {
    //   console.log('Ha habido un error durante la devolución:' + error)
    //   tools.manageAxiosError(error)
    // })
    return promise
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}
