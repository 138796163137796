<template>
  <div>
    <h2 class="mt-3 mb-2" style="text-align: left">
      {{ $t('CONTROL DE STOCK') }}
    </h2>
    <div style="display: flex; justify-content: flex-end;">
      <b-button
        class="mr-4 mb-4"
        :label="$t('Guardar cambios')"
        icon-left="save"
        @click="saveChanges()"
      ></b-button>
    </div>

    <b-table
      style="text-align: left;"
      :data="this.data"
      :sticky-header="true"
      :paginated="isPaginated"
      :per-page="perPage"
      :current-page.sync="currentPage"
      :pagination-simple="isPaginationSimple"
      :pagination-position="paginationPosition"
      :default-sort-direction="defaultSortDirection"
      :pagination-rounded="isPaginationRounded"
      default-sort="list_name"
      aria-next-label="Siguiente"
      aria-previous-label="Anterior"
      aria-page-label="Página"
      aria-current-label="Actual"
      hoverable
      striped
      height="calc(100vh - 230px)"
    >
      <b-table-column
        field="code"
        :label="$t('SKU')"
        sortable
        v-slot="props"
        searchable
        width="50"
      >
        {{ props.row.code }}
      </b-table-column>

      <b-table-column
        field="title"
        :label="$t('Producto')"
        sortable
        v-slot="props"
        searchable
      >
        {{ props.row.title }}
      </b-table-column>

      <b-table-column
        field="category"
        :label="$t('Categoría')"
        sortable
        v-slot="props"
        searchable
      >
        {{ props.row.category }}
      </b-table-column>

      <b-table-column
        field="stock"
        :label="$t('Stock')"
        sortable
        v-slot="props"
      >
        {{ props.row.stock }}
      </b-table-column>

      <b-table-column
        field="jar_weight"
        :label="$t('Peso del bote')"
        sortable
        v-slot="props"
      >
        {{ props.row.jar_weight ? props.row.jar_weight : 0 }}
      </b-table-column>

      <b-table-column
        field="final_stock"
        :label="$t('Nuevo stock')"
        sortable
        v-slot="props"
        width="200"
      >
        <b-input
          v-model="props.row.final_stock"
          type="number"
          step=".01"
          :placeholder="
            (
              Number(props.row.stock) +
              Number(props.row.jar_weight ? props.row.jar_weight : 0)
            ).toFixed(2)
          "
        >
        </b-input>
      </b-table-column>

      <b-table-column
        field="stock"
        :label="$t('Diferencia')"
        sortable
        v-slot="props"
      >
        <span
          v-if="props.row.final_stock"
          :class="{
            red:
              Number(props.row.final_stock ? props.row.final_stock : 0) -
                Number(props.row.stock) -
                Number(props.row.jar_weight ? props.row.jar_weight : 0) <
              0,
            green:
              Number(props.row.final_stock ? props.row.final_stock : 0) -
                Number(props.row.stock) -
                Number(props.row.jar_weight ? props.row.jar_weight : 0) >
              0
          }"
        >
          {{
            (
              Number(props.row.final_stock ? props.row.final_stock : 0) -
              Number(props.row.stock) -
              Number(props.row.jar_weight ? props.row.jar_weight : 0)
            ).toFixed(2)
          }}
        </span>
      </b-table-column>

      <!-- <b-table-column
        field="props.row.unlimited_stock"
        :label="$t('Stock ilimitado')"
        v-slot="props"
        width="50"
      >
        <b-checkbox v-model="props.row.unlimited_stock" disabled></b-checkbox>
      </b-table-column> -->

      <b-table-column
        field="note"
        :label="$t('Nota')"
        sortable
        v-slot="props"
        width="200"
      >
        <b-input v-model="props.row.note" maxlength="200"> </b-input>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'

export default {
  data() {
    return {
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 19,
      selected: null,
      qtyTotal: 0,
      totalTotal: 0,
      locale: 'es-ES',
      data: []
    }
  },
  mixins: [InitViewMixin, ToolsMixin],
  computed: {
    ...mapState('inventory', ['stock'])
  },
  methods: {
    isred() {
      return true
    },
    isgreen() {
      return false
    },
    saveChanges() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.data.forEach(element => {
        if (element.final_stock) {
          element.new_stock =
            Number(element.final_stock ? element.final_stock : 0) -
            Number(element.stock) -
            Number(element.jar_weight)
        }
      })
      this.$store
        .dispatch('inventory/saveStockChanges', {
          asocid: this.currentClub.nid,
          data: this.data,
          stock_type: 'stock'
        })
        .then(() => {
          loadingComponent.close()
          this.downloadData()
        })
    },
    downloadData() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.$store
        .dispatch('inventory/fetchStock', this.currentClub.nid)
        .then(() => {
          this.data = this.stock
          console.log(this.stock)
          // this.data.forEach(element => {
          //   element.final_stock = element.stock
          // })
          loadingComponent.close()
        })
    }
  },
  created() {
    this.validateAccess(['software_gestion_club'])
    this.validateRoles(['Administrador', 'Manager', 'Inventario'])
    this.$analytics.logEvent('StockManager.vue')
    this.$store.dispatch('setShowNavigationBar', true)
    this.downloadData()
  },
  watch: {
    currentClub() {
      this.validateAccess(['software_gestion_club'])
      this.validateRoles(['Administrador', 'Manager', 'Inventario'])
    }
  }
}
</script>

<style lang="scss" scoped>
.red {
  color: red;
}
.green {
  color: green;
}
</style>
