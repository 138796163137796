<template>
  <div>
    <div v-if="showUserNotFoundCard"><UserNotFoundCard></UserNotFoundCard></div>
    <div v-if="showUserCard">
      <UserCard
        :mjProfile="mjProfile"
        :smokappProfile="smokappProfile"
      ></UserCard>
    </div>
    <div v-if="showDNIReader"><DNIRead> </DNIRead></div>
    <div
      v-if="showQRReader"
      style="display: flex; align-items: center;justify-content: center;"
    >
      <QRReader @result="qrreslut"> </QRReader>
    </div>
    <b-button
      @click="qrtoggle()"
      style="width:80%;  height:200px; background:#42b983; color:#ffffff"
      ><i class="fas fa-qrcode fa-10x"></i>
    </b-button>
    <!-- <b-button
      @click="dnirReaderToggle()"
      style="width:80%; height:200px; top:10px; background: #247ba0;; color: #ffffff"
      ><i class="fas fa-id-card fa-10x"></i
    ></b-button>
    <dnirecognizer></dnirecognizer> -->
  </div>
</template>

<script>
import QRReader from '@/components/QRReader.vue'
import DNIRead from '@/components/DNIRead.vue'
import UserCard from '@/components/UserValidationCard.vue'
import UserNotFoundCard from '@/components/UserNotFoundCard.vue'
import { mapState } from 'vuex'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'

export default {
  data() {
    return {
      showDNIReader: false,
      showQRReader: false,
      showUserCard: false,
      showUserNotFoundCard: false,
      mjProfile: null,
      smokappProfile: null
    }
  },
  components: {
    QRReader,
    DNIRead,
    UserCard: UserCard,
    UserNotFoundCard
  },
  mixins: [ToolsMixin, InitViewMixin],

  computed: {
    ...mapState('auth', ['username', 'email']),
    ...mapState(['qrValue'])
  },
  created() {
    this.$analytics.logEvent('UsersValidation.vue')
    this.$store.dispatch('setShowNavigationBar', true)

    // const loadingComponent = this.$buefy.loading.open({
    //   container: null
    // })
  },
  methods: {
    qrreslut(result) {
      console.log('este es el resultado')
      console.log(result)
    },
    dnirReaderToggle() {
      this.showUserCard = false
      this.showUserNotFoundCard = false
      // if (!this.showDNIReader) {
      //   this.qrButtonTitle = 'Cerrar QR'
      // } else {
      //   this.qrButtonTitle = 'Validar mediante QR'
      // }
      this.showDNIReader = !this.showDNIReader
      this.$matomo.trackEvent('User Validation', 'Click', 'dniReader', 1)
    },
    qrtoggle() {
      if (!this.showQRReader) {
        this.qrButtonTitle = 'Cerrar QR'
      } else {
        this.qrButtonTitle = 'Validar mediante QR'
      }
      this.showQRReader = !this.showQRReader
      this.$matomo.trackEvent('User Validation', 'Click', 'qrReader', 1)
    }
  },
  watch: {
    qrValue() {
      if (this.qrValue != '') {
        this.showDNIReader = false
        this.showQRReader = false
        const loadingComponent = this.$buefy.loading.open({
          container: null
        })
        this.$store
          .dispatch('users/fetchUserByDni', {
            asocid: this.currentClub.nid,
            dni: this.qrValue
          })
          .then(response => {
            if (Array.isArray(response.data)) {
              // Entra por aqui cuando es user de MJ
              if (response.data.length > 0) {
                this.mjProfile = response.data[0]
              }
            } else {
              this.smokappProfile = response.data
            }

            this.showUserCard = true
            this.showUserNotFoundCard = false
            loadingComponent.close()
            console.log(response)
          })
          .catch(error => {
            this.showUserCard = false
            this.showUserNotFoundCard = true
            loadingComponent.close()
            console.log('Ha habido un error:' + error)
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
