import session from '@/services/Session.js'

export default {
  dispensary(asocid) {
    return session.get('/api/mobile_dispensario?asocid=' + asocid)
  },
  getDispensaryItem(asocid, itemId) {
    return session.get(
      '/api/list_item/retrieve?association=' + asocid + '&item=' + itemId
    )
  },
  createDispensaryProduct(json) {
    return session.post('/api/item', json)
  },

  getCategories(asocid) {
    return session.get('/api/association_category/?association=' + asocid)
  },
  getCategories(asocid, type) {
    return session.get(
      '/api/association_category/?association=' + asocid + '&type=' + type
    )
  },
  createCategory(category) {
    return session.post('/api/association_category', category)
  },
  updateCategory(category) {
    return session.put('/api/association_category/' + category.nid, category)
  },
  deleteCategory(asocid, nid) {
    return session.delete(
      '/api/association_category/' + nid + '?association=' + asocid
    )
  },

  getFeeCategories(asocid) {
    return session.get('/api/fee/?asocid=' + asocid)
  },
  createFeeCategory(feeCategory) {
    return session.post('/api/fee', feeCategory)
  },
  updateFeeCategory(feeCategory) {
    return session.post('/api/fee/', feeCategory)
  }
}
