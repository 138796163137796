<template>
  <div>
    <h2 class="mt-3 mb-2" style="text-align: left">
      {{ $t('INFORME DE MONEDEROS') }}
    </h2>
    <div style="display:flex">
      <b-field :label="$t('Fecha inicial')">
        <b-datetimepicker
          v-model="start_date"
          :show-week-number="false"
          :first-day-of-week="1"
          :locale="this.$i18n.locale"
          :placeholder="$t('Fecha inicial')"
          trap-focus
        >
        </b-datetimepicker>
      </b-field>
      <b-field :label="$t('Fecha final')" class="ml-4">
        <b-datetimepicker
          v-model="end_date"
          :show-week-number="false"
          :first-day-of-week="1"
          :locale="this.$i18n.locale"
          :placeholder="$t('Fecha final')"
          trap-focus
        >
        </b-datetimepicker>
      </b-field>
      <b-field
        :label="$t('Descargar')"
        class="ml-4"
        style=" position: absolute;right: .5em;top: 40px;"
      >
        <download-excel
          :data="this.elements"
          :fields="json_fields"
          :header="xlsHeader()"
          name="wallet.xls"
        >
          <b-icon icon="file-excel" size="is-medium" type="is-success"></b-icon>
        </download-excel>
        <download-excel
          :data="this.elements"
          type="csv"
          :fields="json_fields"
          name="wallet.csv"
          :header="csvHeader()"
        >
          <b-icon icon="file-csv" size="is-medium" type="is-success"></b-icon>
        </download-excel>
      </b-field>
    </div>
    <div>
      <b-table
        class="mb-4 mr-2"
        style="text-align: left"
        :data="elements"
        :checkable="false"
        :paginated="true"
        :per-page="perPage"
      >
        <b-table-column
          field="date"
          :label="$t('Fecha')"
          sortable
          centered
          v-slot="props"
        >
          <span>
            <span v-if="props.row.date">
              {{ format_date(props.row.date, 'hh:mm dd/MM/yyyy') }}
            </span>
          </span>
        </b-table-column>

        <b-table-column
          field="transaction"
          :label="$t('Transacción')"
          sortable
          v-slot="props"
        >
          {{ props.row.transaction }}
        </b-table-column>

        <b-table-column
          field="amount"
          :label="$t('Importe')"
          sortable
          v-slot="props"
        >
          {{ props.row.amount }}
        </b-table-column>

        <b-table-column
          field="accumulated_value"
          :label="$t('Total')"
          sortable
          v-slot="props"
        >
          {{ props.row.accumulated_value }}
        </b-table-column>

        <b-table-column
          field="pay_type"
          :label="$t('Tipo de pago')"
          sortable
          v-slot="props"
        >
          {{ props.row.pay_type }}
        </b-table-column>

        <b-table-column
          field="membercard.nombre"
          :label="$t('Nombre socio')"
          sortable
          v-slot="props"
        >
          {{ props.row.membercard.nombre }}
        </b-table-column>

        <b-table-column
          field="membercard.number"
          :label="$t('NºSoc')"
          sortable
          v-slot="props"
        >
          {{ props.row.membercard.number }}
        </b-table-column>

        <b-table-column
          field="staffcard.nombre"
          :label="$t('Nombre staff')"
          sortable
          v-slot="props"
        >
          {{ props.row.staffcard.nombre }}
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
import APIPatients from '@/services/APIPatients.js'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'

export default {
  data() {
    return {
      json_fields: {},
      elements: [],
      totalElements: 0,
      perPage: 15,
      page: 1,
      start_date: new Date(new Date().setDate(new Date().getDate() - 7)),
      // end_date: new Date(new Date().setDate(new Date().getHours() + 1)),
      end_date: new Date(),

      locale: 'es-ES'
    }
  },
  mixins: [ToolsMixin, InitViewMixin],
  methods: {
    xlsHeader() {
      let fi =
        this.$t('Fecha inicial') + ': ' + this.datestring(this.start_date)
      let ff = this.$t('FSecha final') + ': ' + this.datestring(this.end_date)
      return [this.$t('INFORME DE MONEDEROS'), fi, ff]
    },
    csvHeader() {
      let fi = this.datestring(this.start_date)
      let ff = this.datestring(this.end_date)
      return this.$t('INFORME DE MONEDEROS') + '   ' + fi + ' / ' + ff
    },
    setupJsonFields() {
      this.json_fields[this.$t('Fecha')] = {
        field: 'date',
        callback: value => {
          return this.format_date(value, 'hh:mm dd/MM/yyyy')
        }
      }

      this.json_fields[this.$t('Transacción')] = 'transaction'
      this.json_fields[this.$t('Importe')] = 'amount'
      this.json_fields[this.$t('Tipo de pago')] = 'pay_type'
      this.json_fields[this.$t('Nombre socio')] = 'membercard.nombre'
      this.json_fields[this.$t('Nº socio')] = 'membercard.number'
      this.json_fields[this.$t('Nombre staff')] = 'staffcard.nombre'
    },
    downloadData(card_id) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.validateAccess(['funciones_avanzadas_gestion', 'monedero'])
      this.validateRoles(['Administrador', 'Manager', 'Budtender', 'Recepción'])
      let json = {
        asocid: this.currentClub.nid,
        start_date: Date.parse(this.start_date) / 1000,
        end_date: Date.parse(this.end_date) / 1000
      }
      if (card_id) {
        json.card_id = card_id
      }
      APIPatients.walletMovements(json)
        .then(response => {
          console.log(response.data)
          this.elements = response.data
          loadingComponent.close()
          this.setupJsonFields()
        })
        .catch(error => {
          loadingComponent.close()
        })
    }
  },
  created() {
    // this.downloadData()
  },
  watch: {
    '$route.params.ms_id': {
      handler: function(search) {
        console.log(search)
        // const loadingComponent = this.$buefy.loading.open({
        //   container: null
        // })
        this.downloadData(this.$route.query.ms_id)
      },
      deep: true,
      immediate: true
    },
    currentClub() {
      this.downloadData(this.$route.query.ms_id)
    },
    start_date() {
      this.downloadData(this.$route.query.ms_id)
    },
    end_date() {
      this.downloadData(this.$route.query.ms_id)
    }
  }
}
</script>

<style lang="scss" scoped></style>
