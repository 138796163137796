import { render, staticRenderFns } from "./OrdersInProcess.vue?vue&type=template&id=0317282a&scoped=true&"
import script from "./OrdersInProcess.vue?vue&type=script&lang=js&"
export * from "./OrdersInProcess.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0317282a",
  null
  
)

export default component.exports