<template>
  <div>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t('Crear item de inventario') }}</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <b-field :label="$t('Título')">
          <b-input v-model="title" :placeholder="$t('Título')"> </b-input>
          <b-select :placeholder="$t('Categoría')">
            <option v-for="(category, i) in categories" :key="i">
              {{ category.title }}
            </option>
          </b-select>
          <b-button icon-left="pen" @click="editCategories()"></b-button>
          <b-input v-model="dominance" placeholder="dominance"> </b-input>
          <b-select :placeholder="$t('Tipo')" icon="sun" v-model="type">
            <option value="indoor">Interior</option>
            <option value="outdoor">Exterior</option>
            <option value="eco">Ecológica</option>
          </b-select>
        </b-field>
        <b-checkbox :v-model="status">Status</b-checkbox>
      </section>
      <footer class="modal-card-foot">
        <b-button :label="$t('Cerrar')" @click="$emit('close')" />
        <b-button :label="$t('Enviar')" type="is-primary" @click="sendData()" />
      </footer>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DispensaryCategories from '@/views/Dispensary/DispensaryCategories.vue'

export default {
  data() {
    return {
      title: '',
      dominance: '',
      type: '',
      status: true
    }
  },
  computed: {
    ...mapState('club', ['currentClub']),
    ...mapState('dispensaryPro', ['categories'])
  },
  methods: {
    sendData() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      console.log(this.title)
      this.$store
        .dispatch('inventory/createInventoryItem', {
          asocid: this.currentClub.nid,
          title: this.title,
          category: this.category,
          dominance: this.dominance,
          type: this.type,
          status: this.status
        })
        .then(() => {
          this.$store.dispatch('inventory/clearInventory')
          this.$store
            .dispatch('inventory/fetchInventory', this.currentClub.nid)
            .then(() => {
              loadingComponent.close()
              this.$emit('close')
            })
        })
    },
    editCategories() {
      this.$buefy.modal.open({
        parent: this,
        component: DispensaryCategories,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true
      })
    }
  },
  created() {
    this.$store.dispatch('dispensaryPro/fetchCategories', {
      asocid: this.currentClub.nid,
      type: 'dispensary'
    })
  }
}
</script>

<style lang="scss" scoped></style>
