<template>
  <div>
    <p class="error">{{ error }}</p>
    <!-- <qrcode-stream @decode="onDecode"></qrcode-stream> -->
    <qrcode-stream
      :key="_uid"
      :track="selected.value"
      @decode="onDecode"
      @init="onInit"
      style="width:300px; height:300px"
    />
    <!-- <StreamBarcodeReader
      @decode="onDecode"
      @loaded="onLoaded"
    ></StreamBarcodeReader> -->
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'
// import { StreamBarcodeReader } from 'vue-barcode-reader'

export default {
  components: { QrcodeStream },
  // components: { StreamBarcodeReader },

  data() {
    const options = [
      { text: 'nothing (default)', value: undefined },
      { text: 'outline', value: this.paintOutline },
      { text: 'centered text', value: this.paintCenterText },
      { text: 'bounding box', value: this.paintBoundingBox }
    ]

    const selected = options[1]

    return {
      showQRReader: false,
      error: '',
      selected,
      options
    }
  },

  methods: {
    onDecode(result) {
      this.$emit('qrResult', result)
      this.$store.dispatch('setQRValue', result)
    },
    onLoaded() {},
    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

        ctx.strokeStyle = 'red'

        ctx.beginPath()
        ctx.moveTo(firstPoint.x, firstPoint.y)
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y)
        }
        ctx.lineTo(firstPoint.x, firstPoint.y)
        ctx.closePath()
        ctx.stroke()
      }
    },
    async onInit(promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = 'ERROR: you need to grant camera access permisson'
        } else if (error.name === 'NotFoundError') {
          this.error = 'ERROR: no camera on this device'
        } else if (error.name === 'NotSupportedError') {
          this.error = 'ERROR: secure context required (HTTPS, localhost)'
        } else if (error.name === 'NotReadableError') {
          this.error = 'ERROR: is the camera already in use?'
        } else if (error.name === 'OverconstrainedError') {
          this.error = 'ERROR: installed cameras are not suitable'
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = 'ERROR: Stream API is not supported in this browser'
        }
      }
    }
  }
}
</script>

<style scoped>
.error {
  font-weight: bold;
  color: red;
}
</style>
