<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">
        {{ $t('Cierre de caja') }}
      </p>
      <button type="button" class="delete ml-4" @click="$emit('close')" />
    </header>
    <section class="modal-card-body">
      <div style="display:flex; text-align: center;space-between:20px">
        <div>
          <h4>
            {{ $t('Efectivo disponible') }}
          </h4>
          <hr />
          <b-input
            v-model="currentAmount"
            :placeholder="$t('Efectivo disponible')"
            type="number"
            style="width:60vh; color:green"
            step="0.01"
          >
          </b-input>
        </div>
        <div
          class="ml-4 mr-4"
          v-if="this.checkRoles(['Manager', 'Administrador', 'Caja'])"
        >
          <h4>
            {{ $t('Saldo en caja') }}
          </h4>
          <hr />
          <h4 v-if="cashMovements">
            {{ cashMovements.summary.cash_box_total_amount.toFixed(2) }}
          </h4>
        </div>
        <div v-if="this.checkRoles(['Manager', 'Administrador', 'Caja'])">
          <h4>
            {{ $t('Diferencia') }}
          </h4>
          <hr />
          <h4 v-if="cashMovements" style="color:red">
            {{ restCalculate() }}
          </h4>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <!-- <b-button :label="$t('Cerrar')" @click="$emit('close')" /> -->
      <div style="flex-direction: row;">
        <b-field :label="$t('Añadir nota')">
          <b-input
            v-model="note"
            :placeholder="$t('Añadir nota')"
            type="textarea"
            style="width:60vh"
          >
          </b-input>
        </b-field>

        <b-button
          :label="$t('Cerrar caja')"
          type="is-primary"
          @click="sendData()"
        />
      </div>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'
export default {
  data() {
    return {
      is_editing: false,
      note: null,
      currentAmount: null
    }
  },
  computed: {
    ...mapState('club', ['currentClub']),
    ...mapState('register', ['cashMovements'])
  },
  mixins: [InitViewMixin],
  methods: {
    restCalculate() {
      return (
        this.currentAmount - this.cashMovements.summary.cash_box_total_amount
      ).toFixed(2)
    },
    sendData() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      let json = {
        asocid: this.currentClub.nid,
        current_amount: this.currentAmount,
        note: this.note
      }
      this.$store.dispatch('register/closeRegister', json).then(() => {
        this.$store
          .dispatch('register/fetechCashMovement', {
            asocid: this.currentClub.nid
          })
          .then(() => {
            loadingComponent.close()
            this.$emit('close')
          })
      })
    }
  },
  created() {
    this.$store.dispatch('register/fetechCashMovement', {
      asocid: this.currentClub.nid
    })
  }
}
</script>

<style lang="scss" scoped></style>
