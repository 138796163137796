<template>
  <div>
    <p class="error">{{ error }}</p>
    <!-- <qrcode-stream @decode="onDecode"></qrcode-stream> -->
    <!-- <qrcode-stream @decode="onDecode" @init="onInit" /> -->
    <StreamBarcodeReader
      @decode="onDecode"
      @loaded="onLoaded"
    ></StreamBarcodeReader>
  </div>
</template>

<script>
// import { QrcodeStream } from 'vue-qrcode-reader'
import { StreamBarcodeReader } from 'vue-barcode-reader'

export default {
  //   components: { QrcodeStream },
  components: { StreamBarcodeReader },
  data() {
    return {
      showQRReader: false,
      error: ''
    }
  },

  methods: {
    onDecode(result) {
      this.$emit('qrResult', result)
      this.$store.dispatch('setQRValue', result)
    },
    onLoaded() {},
    async onInit(promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = 'ERROR: you need to grant camera access permisson'
        } else if (error.name === 'NotFoundError') {
          this.error = 'ERROR: no camera on this device'
        } else if (error.name === 'NotSupportedError') {
          this.error = 'ERROR: secure context required (HTTPS, localhost)'
        } else if (error.name === 'NotReadableError') {
          this.error = 'ERROR: is the camera already in use?'
        } else if (error.name === 'OverconstrainedError') {
          this.error = 'ERROR: installed cameras are not suitable'
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = 'ERROR: Stream API is not supported in this browser'
        }
      }
    }
  }
}
</script>

<style scoped>
.error {
  font-weight: bold;
  color: red;
}
</style>
