<template>
  <div>
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css?family=Roboto"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
    />
    <!-- Page Container -->
    <div class="w3-content w3-margin-top" style="max-width:1400px;">
      <!-- The Grid -->
      <div class="w3-row-padding">
        <!-- Left Column -->
        <div class="w3-third">
          <div class="w3-white w3-text-grey w3-card-4">
            <div class="w3-display-container"></div>
            <div class="w3-container">
              <hr />
              <div>
                <p>
                  {{ smokappProfile.field_user_name }}
                  {{ smokappProfile.field_user_surname }}
                </p>
                <p>
                  {{ smokappProfile.field_user_dni }}
                </p>
                <p>
                  {{ smokappProfile['0'].mail }}
                </p>
                <p>
                  <i
                    class="fa fa-phone fa-fw w3-margin-right w3-large w3-text-teal"
                  ></i
                  >---------
                </p>
                <hr />
              </div>
              <hr />

              <p class="w3-large w3-text-theme">
                <b class="logo" v-if="currentClub != Object">
                  <!-- <i class="fa fa-globe fa-fw w3-margin-right w3-text-teal"></i> -->

                  <img :src="currentClub.logo.img" style="width:23px" />
                  Club</b
                >
              </p>
              <p>{{ currentClub.title }}</p>
              <div class="w3-light-grey w3-round-xlarge">
                <div
                  class="w3-round-xlarge w3-teal"
                  style="height:24px;width:100%"
                ></div>
              </div>

              <br />
            </div>
          </div>
          <br />

          <!-- End Left Column -->
        </div>

        <!-- Right Column -->
        <div class="w3-twothird">
          <div class="w3-container w3-card w3-white w3-margin-bottom">
            <h2 class="w3-text-grey w3-padding-14">
              <i
                class="fa fa-suitcase fa-fw w3-margin-right w3-xxlarge w3-text-teal"
              ></i
              >Cartera
            </h2>
            <div class="w3-container">
              <!-- <div v-if="mjProfile">
                <h5 class="w3-opacity">
                  <b>Saldo monedero: {{ mjProfile.monedero }}€</b>
                </h5>
                <h5 class="w3-opacity">
                  Caducidad cuota:
                  <h6 class="w3-text-teal">
                    <i class="fa fa-calendar fa-fw w3-margin-right"></i
                    >{{ mjProfile.cuota_expire }} -
                    <span class="w3-tag w3-teal w3-round">Válida</span>
                  </h6>
                  <p>Previsión: {{ mjProfile.consumption }}</p>
                  <p>
                    Puntos de recompensa:
                    {{ mjProfile.reward_points_available }}
                  </p>
                </h5>
              </div> -->
              <hr />
            </div>
          </div>

          <!-- End Right Column -->
        </div>

        <!-- End Grid -->
      </div>

      <!-- End Page Container -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
// import QrcodeVue from 'qrcode.vue'

export default {
  props: {
    smokappProfile: Object
  },
  // components: {
  //   QrcodeVue
  // },
  mixins: [ToolsMixin],
  computed: {
    ...mapState('club', ['currentClub'])
  }
}
</script>

<style lang="scss" scoped></style>
