<template>
  <div>
    <div style="display:flex; justify-content:space-between" class="mt-2">
      <div>
        <h2 class="mt-3 mb-2 ml-2 " style="text-align: left">
          {{ $t('Informe de movimientos de stock').toUpperCase() }}
        </h2>
        <div style="display:flex" class="ml-2">
          <b-field :label="$t('Fecha inicial')">
            <b-datetimepicker
              v-model="start_date"
              :show-week-number="false"
              :first-day-of-week="1"
              :locale="this.$i18n.locale"
              :placeholder="$t('Fecha inicial')"
              trap-focus
            >
            </b-datetimepicker>
          </b-field>
          <b-field :label="$t('Fecha final')" class="ml-4">
            <b-datetimepicker
              v-model="end_date"
              :show-week-number="false"
              :first-day-of-week="1"
              :locale="this.$i18n.locale"
              :placeholder="$t('Fecha final')"
              trap-focus
            >
            </b-datetimepicker>
          </b-field>
          <b-field
            :label="$t('Descargar')"
            class="ml-4"
            style=" position: absolute;right: .5em;top: 40px;"
          >
            <download-excel
              :data="elements"
              :fields="json_fields"
              name="stockHistoryReport.xls"
            >
              <b-icon icon="file-excel" size="is-medium" type="is-success">
              </b-icon>
            </download-excel>
            <download-excel
              :data="elements"
              type="csv"
              :fields="json_fields"
              name="stockHistoryReport.csv"
            >
              <b-icon icon="file-csv" size="is-medium" type="is-success">
              </b-icon>
            </download-excel>
          </b-field>
        </div>
      </div>
    </div>

    <div>
      <b-table
        class="mb-4 mr-2"
        style="text-align: left"
        :data="elements"
        :checkable="false"
        :paginated="true"
        :per-page="perPage"
      >
        <b-table-column
          field="product_title"
          :label="$t('Producto')"
          sortable
          v-slot="props"
          searchable
        >
          {{ props.row.product_title }}
        </b-table-column>

        <b-table-column
          field="product_category"
          :label="$t('Categoría')"
          sortable
          v-slot="props"
          searchable
        >
          {{ props.row.product_category }}
        </b-table-column>

        <b-table-column
          field="product_type"
          :label="$t('Tipo Producto')"
          sortable
          v-slot="props"
          searchable
        >
          {{ props.row.product_type }}
        </b-table-column>

        <b-table-column
          field="employee_name"
          :label="$t('Empleado')"
          sortable
          v-slot="props"
          searchable
        >
          {{ props.row.employee_name }}
        </b-table-column>

        <b-table-column
          field="created"
          :label="$t('Fecha')"
          sortable
          centered
          v-slot="props"
        >
          <span>
            <span v-if="props.row.created">
              {{ format_date(props.row.created, 'hh:mm dd/MM/yyyy') }}
            </span>
          </span>
        </b-table-column>

        <b-table-column
          field="type"
          :label="$t('Tipo')"
          sortable
          v-slot="props"
          searchable
        >
          {{ props.row.type }}
        </b-table-column>

        <b-table-column
          field="quantity"
          :label="$t('Cantidad')"
          sortable
          v-slot="props"
          searchable
        >
          {{ props.row.quantity }}
        </b-table-column>

        <b-table-column
          field="new_level"
          :label="$t('Total')"
          sortable
          v-slot="props"
          searchable
        >
          {{ props.row.new_level }}
        </b-table-column>

        <b-table-column
          field="note"
          :label="$t('Nota')"
          sortable
          v-slot="props"
          searchable
        >
          {{ props.row.note }}
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
import APIDispensaryPro from '@/services/APIDispensaryPro.js'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'

export default {
  data() {
    return {
      json_fields: {},
      product: undefined,
      elements: [],
      totalElements: 0,
      perPage: 15,
      page: 1,
      start_date: new Date(new Date().setDate(new Date().getDate() - 7)),
      // end_date: new Date(new Date().setDate(new Date().getHours() + 1)),
      end_date: new Date(),

      locale: 'es-ES'
    }
  },
  mixins: [ToolsMixin, InitViewMixin],
  methods: {
    setupJsonFields() {
      this.json_fields['ID'] = 'id'
      this.json_fields[this.$t('Fecha')] = {
        field: 'created',
        callback: value => {
          return this.format_date(value, 'hh:mm-dd/MM/yyyy')
        }
      }
      this.json_fields[this.$t('Producto')] = 'product_title'
      this.json_fields[this.$t('Categoría')] = 'product_category'
      this.json_fields[this.$t('Tipo Producto')] = 'product_type'
      this.json_fields[this.$t('Empleado')] = 'employee_name'
      this.json_fields[this.$t('Tipo')] = 'type'
      this.json_fields[this.$t('Cantidad')] = 'quantity'
      this.json_fields[this.$t('Total')] = 'new_level'
      this.json_fields[this.$t('Nota')] = 'note'
    },
    xlsHeader() {
      let fi =
        this.$t('Fecha inicial') + ': ' + this.datestring(this.start_date)
      let ff = this.$t('Fecha final') + ': ' + this.datestring(this.end_date)
      return [this.$t('MOVIMIENTOS STOCK'), fi, ff]
    },
    downloadData() {
      this.validateAccess(['software_gestion_club'])
      this.validateRoles(['Administrador', 'Manager'])

      APIDispensaryPro.getStockHistoryReport(
        Date.parse(this.start_date) / 1000,
        Date.parse(this.end_date) / 1000,
        this.currentClub.nid
      ).then(response => {
        this.elements = response.data.data
        this.setupJsonFields()
      })
    }
  },
  created() {
    this.downloadData()
  },
  watch: {
    currentClub() {
      this.downloadData()
    },
    start_date() {
      this.downloadData()
    },
    end_date() {
      this.downloadData()
    }
  }
}
</script>

<style lang="scss" scoped></style>
