<template>
  <fullscreen ref="fullscreen" wrap="true" background="#000000">
    <p class="logo" v-if="currentClub != Object">
      <img :src="currentClub.logo.img" />
    </p>
    <section
      v-if="this.dispensary.length > 0"
      class="outerSection"
      :style="
        'background:' +
          carouselSettings.backgroundcolor +
          ';color:' +
          carouselSettings.textcolor
      "
    >
      <b-carousel
        :indicator="false"
        :interval="carouselSettings.interval"
        :autoplay="true"
        :repeat="true"
        :pauseHover="false"
        :arrow="false"
      >
        <b-carousel-item v-for="(item, i) in this.dispensary" :key="i">
          <div>
            <div class="item" v-if="item.imagenes.length > 0">
              <img :src="item.imagenes[0].img" />
            </div>

            <div>
              <h1>{{ item.titulo }}</h1>
              <h2 v-if="carouselSettings.showItemSubtitle">
                {{ item.subtititulo }}
              </h2>
              <h3
                v-if="carouselSettings.showItemDescription"
                v-html="item.cuerpo.substring(0, 250)"
              ></h3>
            </div>
          </div>
          <!-- <qrcode-vue
            v-if="carouselSettings.showItemQr"
            class="qr"
            :value="urlQR(item)"
            :size="200"
            level="H"
            :background="carouselSettings.backgroundcolor"
            :foreground="carouselSettings.textcolor"
          ></qrcode-vue> -->
        </b-carousel-item>
      </b-carousel>
    </section>
  </fullscreen>
</template>

<script>
import { mapState } from 'vuex'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import QrcodeVue from 'qrcode.vue'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'

export default {
  // components: {
  //   QrcodeVue
  // },
  data() {
    return {
      allItemsArray: []
    }
  },
  methods: {
    urlQR(item) {
      console.log(item)
      return (
        'https://app.smokapp.com/app/#/dispensary_item?id=' +
        item.pid +
        '&asocid=' +
        this.currentClub.nid
      )
    },
    fetchDispensary() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.$store
        .dispatch('dispensaryPro/fetchDispensary', {
          asocid: this.currentClub.nid,
          status: null
        })
        .then(() => {
          loadingComponent.close()
          this.confirmFullScreen()
        })
    }
  },
  mixins: [ToolsMixin, InitViewMixin],
  computed: {
    ...mapState('dispensaryPro', ['dispensary']),
    ...mapState('tvshow', ['carouselSettings'])
  },
  created() {
    this.validateAccess(['comunicacion'])
    this.$analytics.logEvent('TVShow2.vue')

    this.$store.dispatch('tvshow/initialize')
    this.fetchDispensary()
  },
  watch: {
    currentClub() {
      this.validateAccess(['comunicacion'])
      this.fetchDispensary()
    }
  }
}
</script>
<style lang="scss">
$bg-classroom: #000000;
.main {
  background: $bg-classroom;
}
</style>
<style scoped>
h1 {
  padding: 20px;
  bottom: 0px;
  font-size: 4vw;
  /* width: 65%; */
  right: 0px;
}
h2 {
  padding: 20px;
  bottom: 0px;
  font-size: 3vw;
  /* width: 65%; */
  right: 0px;
}
h3 {
  padding: 20px;
  bottom: 0px;
  font-size: 2vw;
  /* width: 65%; */
  right: 0px;
}
.carousel {
  /* position: absolute; */
  top: 50%;
  transform: translateY(-50%);
}
.outerSection {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.logo img {
  width: 120px;
  height: auto;
  margin: auto;
  padding: 20px;
  float: right;
}
.item img {
  width: 45%;
  height: 40%;
  float: left;
  padding: 10px;
  border-radius: 4%;

  margin: auto;
}
.qr {
  height: auto;
  margin: auto;
  padding: 6px;
  bottom: 0px;
  float: right;
  /* background: white; */
}
</style>
