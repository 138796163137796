import router from '@/router/index.js'
import { DialogProgrammatic as Dialog } from 'buefy'
import i18n from '../i18n'

export default {
  manageAxiosError(error) {
    // VueMatomo.trackEvent(error.response.status, error.response.data, 'err', 1)
    if (error.response.status === 403) {
      console.log(error.response.statusText)
      if (error.response.statusText == ': Access denied for user anonymous') {
        Dialog.alert({
          title: 'La sesión ha caducado',
          message: 'Necesitas hacer login de nuevo',
          confirmText: 'OK',
          type: 'is-danger',
          hasIcon: false,
          onConfirm: () => {
            router.replace('/')
          }
        })
      }
      if (error.response.statusText.includes('Access denied for user')) {
        Dialog.alert({
          title: 'Permisos requeridos',
          message:
            'No tienes suficientes permisos para acceder a esta sección. Por favor consulta con tu administrador',
          confirmText: 'OK',
          type: 'is-danger',
          hasIcon: false,
          onConfirm: () => {
            // router.replace('/')
          }
        })
      }
    } else {
      if (error.response.status === 401) {
        Dialog.alert({
          title: i18n.t('Ha habido un problemilla'),
          message: error.response.data.lang
            ? error.response.data.lang[i18n.locale]
            : error.response.data.message,
          confirmText: 'OK',
          type: 'is-danger',
          hasIcon: false,
          onConfirm: () => {
            // router.replace('/')
          }
        })
      } else {
        Dialog.alert({
          title: i18n.t('Ha habido un problemilla'),
          message: error.response.data.lang
            ? error.response.data.lang[i18n.locale]
            : error.response.data.message,
          confirmText: 'OK',
          type: 'is-danger',
          hasIcon: false,
          onConfirm: () => {
            // router.replace('/')
          }
        })
      }
    }
  }
}
