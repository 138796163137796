<template>
  <div>
    <h2 class="mt-3 mb-2" style="text-align: left">
      {{ $t('INFORME DE CONTROL DE ACCESO') }}
    </h2>
    <div style="display:flex">
      <b-field :label="$t('Fecha inicial')">
        <b-datetimepicker
          v-model="start_date"
          :show-week-number="false"
          :first-day-of-week="1"
          :locale="this.$i18n.locale"
          :placeholder="$t('Fecha inicial')"
          trap-focus
        >
        </b-datetimepicker>
      </b-field>
      <b-field :label="$t('Fecha final')" class="ml-4">
        <b-datetimepicker
          v-model="end_date"
          :show-week-number="false"
          :first-day-of-week="1"
          :locale="this.$i18n.locale"
          :placeholder="$t('Fecha final')"
          trap-focus
        >
        </b-datetimepicker>
      </b-field>
      <b-field
        :label="$t('Descargar')"
        class="ml-4"
        style=" position: absolute;right: .5em;top: 40px;"
      >
        <download-excel
          :data="this.access_history"
          :fields="json_fields"
          :header="xlsHeader()"
          name="accesscontrol.xls"
        >
          <b-icon icon="file-excel" size="is-medium" type="is-success"></b-icon>
        </download-excel>
        <download-excel
          :data="this.access_history"
          type="csv"
          :fields="json_fields"
          name="accesscontrol.csv"
          :header="csvHeader()"
        >
          <b-icon icon="file-csv" size="is-medium" type="is-success"></b-icon>
        </download-excel>
      </b-field>
    </div>

    <b-table
      class="mr-2"
      v-if="this.access_history"
      style="text-align: left;"
      :data="this.access_history"
      :sticky-header="true"
      :paginated="isPaginated"
      :per-page="perPage"
      :current-page.sync="currentPage"
      :pagination-simple="isPaginationSimple"
      :pagination-position="paginationPosition"
      :default-sort-direction="defaultSortDirection"
      :pagination-rounded="isPaginationRounded"
      default-sort="datetime"
      aria-next-label="Siguiente"
      aria-previous-label="Anterior"
      aria-page-label="Página"
      aria-current-label="Actual"
      :selected.sync="selected"
      hoverable
      striped
      height="calc(100vh - 205px)"
    >
      <b-table-column
        field="id"
        label="ID"
        sortable
        v-slot="props"
        searchable
        width="50"
      >
        {{ props.row.id }}
      </b-table-column>

      <b-table-column
        field="datetime"
        :label="$t('Fecha')"
        sortable
        v-slot="props"
        searchable
        width="50"
      >
        {{ format_date(props.row.datetime, 'hh:mm dd/MM/yyyy') }}
      </b-table-column>

      <b-table-column
        field="action"
        label="Action"
        sortable
        v-slot="props"
        searchable
        width="50"
      >
        {{ props.row.action }}
      </b-table-column>

      <b-table-column
        field="membercard.number"
        :label="$t('NºSoc')"
        sortable
        v-slot="props"
        searchable
        width="50"
      >
        {{ props.row.membercard.number }}
      </b-table-column>

      <b-table-column
        field="membercard.nombre"
        :label="$t('Nombre')"
        sortable
        v-slot="props"
        searchable
        width="50"
      >
        {{ props.row.membercard.nombre }}
      </b-table-column>

      <b-table-column
        field="membercard.surname"
        :label="$t('Ape1')"
        sortable
        v-slot="props"
        searchable
        width="50"
      >
        {{ props.row.membercard.surname }}
      </b-table-column>

      <b-table-column
        field="membercard.surname2"
        :label="$t('Ape2')"
        sortable
        v-slot="props"
        searchable
        width="50"
      >
        {{ props.row.membercard.surname2 }}
      </b-table-column>

      <b-table-column
        field="membercard.dni"
        :label="$t('DNI')"
        sortable
        v-slot="props"
        searchable
        width="50"
      >
        {{ props.row.membercard.dni }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'

export default {
  data() {
    return {
      json_fields: {},
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'desc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 19,
      selected: null,
      data: [],
      qtyTotal: 0,
      totalTotal: 0,
      start_date: new Date(new Date().setDate(new Date().getDate() - 7)),
      end_date: new Date(),
      locale: 'es-ES'
    }
  },
  mixins: [InitViewMixin, ToolsMixin],
  computed: {
    ...mapState('accesscontrol', ['access_history'])
  },
  methods: {
    xlsHeader() {
      let fi =
        this.$t('Fecha inicial') + ': ' + this.datestring(this.start_date)
      let ff = this.$t('Fecha final') + ': ' + this.datestring(this.end_date)
      return [this.$t('CONTROL DE ACCESO'), fi, ff]
    },
    csvHeader() {
      let fi = this.datestring(this.start_date)
      let ff = this.datestring(this.end_date)
      return this.$t('CONTROL DE ACCESO') + '   ' + fi + ' / ' + ff
    },
    setupJsonFields() {
      this.json_fields['ID'] = 'id'
      this.json_fields[this.$t('Fecha')] = {
        field: 'datetime',
        callback: value => {
          return this.format_date(value, 'hh:mm dd/MM/yyyy')
        }
      }
      this.json_fields[this.$t('Action')] = 'action'
      this.json_fields[this.$t('Nº Socio')] = 'membercard.number'
      this.json_fields[this.$t('Nombre')] = 'membercard.nombre'
      this.json_fields[this.$t('Ape1')] = 'membercard.surname'
      this.json_fields[this.$t('Ape2')] = 'membercard.surname2'
      this.json_fields[this.$t('DNI')] = 'membercard.dni'
    },
    downloadData() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.$store
        .dispatch('accesscontrol/fetchAccessHitory', {
          asocid: this.currentClub.nid,
          start_date: Date.parse(this.start_date) / 1000,
          end_date: Date.parse(this.end_date) / 1000
        })
        .then(() => {
          loadingComponent.close()
          this.setupJsonFields()
        })
        .catch(error => {
          loadingComponent.close()
        })
    }
  },
  created() {
    this.validateAccess(['software_gestion_club'])
    this.validateRoles(['Administrador', 'Manager'])
    this.$analytics.logEvent('AccessControlReport.vue')
    this.$store.dispatch('setShowNavigationBar', true)
    this.downloadData()
  },
  watch: {
    currentClub() {
      this.validateAccess(['software_gestion_club'])
      this.validateRoles(['Administrador', 'Manager'])
      this.downloadData()
    },
    start_date() {
      this.downloadData()
    },
    end_date() {
      this.downloadData()
    }
  }
}
</script>

<style lang="scss" scoped>
.my-table {
  // max-height: 100%;
  overflow-y: auto;
  height: calc(100vh - 10px);
  // height: 1000px;
}
</style>
