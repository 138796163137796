<template>
  <div>
    <div class="buttonContainer">
      <b-button class="button" @click="tvShow2()"
        ><i class="fas fa-images "></i>
        {{ $t('Carrusel de productos') }}</b-button
      >
    </div>
    <div class="buttonContainer">
      <b-button class="button" @click="tvShow3()"
        ><i class="fas fa-scroll"></i>{{ $t('Carta sencilla') }}</b-button
      >
    </div>
    <!-- <div class="buttonContainer">
      <b-button @click="tvShow1()"
        ><i class="far fa-list-alt "> </i> Listado completo de
        productos</b-button
      >
    </div> -->
    <div class="buttonContainer">
      <b-button class="button" @click="tvShowSettings()"
        ><i class="fas fa-tools"></i>
        {{ $t('Panel de configuración') }}</b-button
      >
    </div>
  </div>
</template>

<script>
import { InitViewMixin } from '@/mixins/InitViewMixin.js'
export default {
  mixins: [InitViewMixin],
  methods: {
    tvShow1() {
      this.$router.push({ name: 'tvshow1' })
    },
    tvShow2() {
      this.$router.push({ name: 'tvshow2' })
    },
    tvShow3() {
      this.$router.push({ name: 'tvshow3' })
    },
    tvShowSettings() {
      this.$router.push({ name: 'tvshowsettings' })
    }
  },
  created() {
    this.validateAccess(['comunicacion'])
    this.validateRoles(['Administrador', 'Manager', 'Social'])
    this.$analytics.logEvent('TVShows.vue')
    this.$store.dispatch('setShowNavigationBar', true)
  },
  watch: {
    currentClub() {
      this.validateAccess(['comunicacion'])
      this.validateRoles(['Administrador', 'Manager', 'Social'])
      this.fetchDispensary()
    }
  }
}
</script>

<style lang="scss" scoped>
.buttonContainer {
  padding: 7px;
}
.button {
  // background-color: rgb(17, 134, 117); /* Green */
  // border: none;
  // color: white;
  // padding: 15px 32px;
  text-align: center;
  // text-decoration: none;
  display: inline-block;
  font-size: 16px;
  width: 70%;
  height: 100px;
}
</style>
