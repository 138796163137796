<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">
        {{ $t('Movimiento de Efectivo') }}
      </p>
      <button type="button" class="delete ml-4" @click="$emit('close')" />
    </header>
    <section class="modal-card-body">
      <b-field :label="$t('Importe')">
        <b-input
          v-model="currentAmount"
          :placeholder="$t('€')"
          type="number"
          step="0.01"
        >
        </b-input>
        <b-select v-model="action">
          <option value="add">{{ $t('Añadir') }}</option>
          <option value="remove">{{ $t('Retirar') }}</option>
        </b-select>
        <div
          class="ml-4 mr-4"
          v-if="this.checkRoles(['Manager', 'Administrador'])"
        >
          <h4>
            {{ $t('Saldo en caja') }}
          </h4>

          <h4 v-if="cashMovements">
            {{ cashMovements.summary.cash_box_total_amount.toFixed(2) }}
          </h4>
        </div>
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <!-- <b-button :label="$t('Cerrar')" @click="$emit('close')" /> -->
      <div style="flex-direction: row;">
        <b-field :label="$t('Añadir nota')">
          <b-input
            v-model="note"
            :placeholder="$t('Nota')"
            type="textarea"
            style="width:50vh"
          >
          </b-input>
        </b-field>

        <b-button
          :label="$t('Ajustar')"
          type="is-primary"
          @click="sendData()"
        />
      </div>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'

export default {
  data() {
    return {
      is_editing: false,
      note: null,
      currentAmount: null,
      action: 'add'
    }
  },
  computed: {
    ...mapState('club', ['currentClub']),
    ...mapState('register', ['cashMovements'])
  },
  mixins: [InitViewMixin],
  methods: {
    sendData() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      let json = {
        asocid: this.currentClub.nid,
        note: this.note
      }
      if (this.action == 'add') {
        json.amount = Number(this.currentAmount)
      } else {
        json.amount = Number(this.currentAmount) * -1
      }
      console.log(json)
      this.$store.dispatch('register/createMovement', json).then(() => {
        this.$store
          .dispatch('register/fetechCashMovement', {
            asocid: this.currentClub.nid
          })
          .then(() => {
            loadingComponent.close()
            this.$emit('close')
          })
      })
    }
  },
  created() {
    this.$store.dispatch('register/fetechCashMovement', {
      asocid: this.currentClub.nid
    })
  }
}
</script>

<style lang="scss" scoped></style>
