import session from '@/services/Session.js'
// import axios from 'axios'

export default {
  getAccessControlHistory(asocid, start_date, end_date) {
    return session.get(
      '/api/checkin_checkout?association=' +
        asocid +
        '&start_date=' +
        start_date +
        '&end_date=' +
        end_date
    )
  },
  getAccessControlHistoryByPatient(asocid, patient_id, start_date, end_date) {
    if (start_date && end_date) {
      return session.get(
        '/api/checkin_checkout?association=' +
          asocid +
          '&card_id=' +
          patient_id +
          '&start_date=' +
          start_date +
          '&end_date=' +
          end_date
      )
    } else {
      return session.get(
        '/api/checkin_checkout?association=' + asocid + '&card_id=' + patient_id
      )
    }
  },
  addNewAccess(patient_id, data) {
    return session.post('/api/checkin_checkout/' + patient_id, data)
  }
}
