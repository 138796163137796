<template>
  <div class="modal-card" style="width: 500px">
    <header class="modal-card-head">
      <p class="modal-card-title">Foto del socio</p>
      <button type="button" class="delete" @click="$emit('close')" />
    </header>
    <section class="modal-card-body">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <b-select v-model="camera">
              <option>-- Select Device --</option>
              <option
                v-for="device in devices"
                :key="device.deviceId"
                :value="device.deviceId"
                >{{ device.label }}</option
              >
            </b-select>
            <!-- <code v-if="device">{{ device.label }}</code> -->
            <div class="border">
              <vue-web-cam
                ref="webcam"
                :device-id="deviceId"
                width="100%"
                @started="onStarted"
                @stopped="onStopped"
                @error="onError"
                @cameras="onCameras"
                @camera-change="onCameraChange"
              />
            </div>

            <div class="row">
              <div class="col-md-12">
                <b-button
                  type="button"
                  class="btn btn-primary"
                  @click="onCapture"
                  style="width:100%"
                >
                  Hacer foto
                </b-button>
                <!-- <button type="button" class="btn btn-danger" @click="onStop">
                  Stop Camera
                </button>
                <button type="button" class="btn btn-success" @click="onStart">
                  Start Camera
                </button> -->
              </div>
            </div>
          </div>
          <div v-if="img" class="col-md-6">
            <h2>Foto capturada</h2>
            <figure class="figure">
              <img :src="img" class="img-responsive" />
            </figure>
            <b-button
              type="button"
              class="btn btn-primary"
              @click="$emit('close')"
              style="width:100%"
            >
              Terminar
            </b-button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { WebCam } from 'vue-web-cam'

export default {
  components: {
    'vue-web-cam': WebCam
  },
  data() {
    return {
      img: null,
      camera: null,
      deviceId: null,
      devices: []
    }
  },
  computed: {
    device: function() {
      return this.devices.find(n => n.deviceId === this.deviceId)
    }
  },
  watch: {
    camera: function(id) {
      this.deviceId = id
    },
    devices: function() {
      // Once we have a list select the first one
      const [first, ...tail] = this.devices
      if (first) {
        this.camera = first.deviceId
        this.deviceId = first.deviceId
      }
    }
  },
  methods: {
    onCapture() {
      this.img = this.$refs.webcam.capture()
      this.$emit('onCapture', this.img)

      console.log(this.img)
      console.log('emit onCapture')
      console.log(this.img.split(',')[1])
    },
    onStarted(stream) {
      console.log('On Started Event', stream)
    },
    onStopped(stream) {
      console.log('On Stopped Event', stream)
    },
    onStop() {
      this.$refs.webcam.stop()
    },
    onStart() {
      this.$refs.webcam.start()
    },
    onError(error) {
      console.log('On Error Event', error)
    },
    onCameras(cameras) {
      this.devices = cameras
      console.log('On Cameras Event', cameras)
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId
      this.camera = deviceId
      console.log('On Camera Change Event', deviceId)
    }
  }
}
</script>

<style lang="scss" scoped></style>
