<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ $t('Gestionar categorías') }}</p>
      <button type="button" class="delete" @click="$emit('close')" />
    </header>
    <section class="modal-card-body">
      <b-table :data="categories">
        <b-table-column
          field="title"
          :label="$t('Categoría')"
          sortable
          v-slot="props"
        >
          {{ props.row.title }}
        </b-table-column>

        <b-table-column v-slot="props">
          <b-button
            class="is-small"
            icon-left="pen"
            @click="editCategory(props.row)"
          ></b-button>
          <b-button
            class="is-small"
            icon-left="trash-alt"
            @click="deleteCategory(props.row)"
          ></b-button>
        </b-table-column>
      </b-table>
    </section>
    <footer class="modal-card-foot">
      <!-- <b-button :label="$t('Cerrar')" @click="$emit('close')" /> -->
      <b-field :label="$t('Crear nueva categoría')">
        <b-input v-model="categoryName" :placeholder="$t('Nombre')"> </b-input>
        <b-button
          v-if="is_editing"
          :label="$t('Modificar')"
          type="is-primary"
          @click="updateData()"
        />
        <b-button
          v-else
          :label="$t('Crear')"
          type="is-primary"
          @click="sendData()"
        />
      </b-field>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      is_editing: false,
      categoryName: null,
      categoryNid: null,
      categoryToModify: null
    }
  },
  computed: {
    ...mapState('club', ['currentClub']),
    ...mapState('dispensaryPro', ['categories'])
  },
  methods: {
    deleteCategory(category) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.$store
        .dispatch('dispensaryPro/deleteCategory', {
          asocid: this.currentClub.nid,
          category: category
        })
        .then(() => {
          loadingComponent.close()
          //   this.$emit('close')
        })
    },
    editCategory(category) {
      this.categoryToModify = category
      this.categoryName = category.title
      this.categoryNid = category.nid
      this.is_editing = true
    },
    updateData() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      let json = {
        association: this.currentClub.nid,
        type: 'dispensary',
        title: this.categoryName,
        nid: this.categoryNid
      }
      this.$store
        .dispatch('dispensaryPro/updateCategory', {
          category: this.categoryToModify,
          newcategory: json
        })
        .then(() => {
          loadingComponent.close()
          this.is_editing = false
          this.categoryName = null

          //   this.$emit('close')
        })
    },
    sendData() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      let json = {
        association: this.currentClub.nid,
        type: 'dispensary',
        title: this.categoryName
      }
      this.$store.dispatch('dispensaryPro/createCategory', json).then(() => {
        loadingComponent.close()
        this.categoryName = null
        //   this.$emit('close')
      })
    }
  },
  created() {
    this.$store.dispatch('dispensaryPro/fetchCategories', {
      asocid: this.currentClub.nid,
      type: 'dispensary'
    })
  }
}
</script>

<style lang="scss" scoped></style>
