import {
  SET_CASHMOVEMENTS,
  SET_CLOSE_REGISTER_HISOTRY,
  SET_REPORT_CASH
} from './types'
import tools from '@/Tools/tools.js'
import APIRegister from '../services/APIRegister'

const initialState = {
  cashMovements: null,
  reportCash: null,
  closeRegisterHistory: null
}

const mutations = {
  [SET_CASHMOVEMENTS](state, cashMovements) {
    state.cashMovements = cashMovements
  },
  [SET_REPORT_CASH](state, reportCash) {
    state.reportCash = null
    state.reportCash = reportCash
  },
  [SET_CLOSE_REGISTER_HISOTRY](state, closeRegisterHistory) {
    state.closeRegisterHistory = closeRegisterHistory
  }
}

const actions = {
  fetechCashMovement({ commit }, asocid) {
    let promise = APIRegister.fetechCashMovement(asocid)
      .then(response => {
        console.log(response.data)
        //Ordenamos por fecha del ultimo comentario
        // response.data.sort((a, b) => (a.comment_date < b.comment_date ? 1 : -1))
        commit(SET_CASHMOVEMENTS, response.data)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },

  fetechCloseRegisterhistory({ commit }, asocid) {
    let promise = APIRegister.fetechCloseRegisterhistory(asocid)
      .then(response => {
        console.log(response.data)
        //Ordenamos por fecha del ultimo comentario
        // response.data.sort((a, b) => (a.comment_date < b.comment_date ? 1 : -1))
        commit(SET_CLOSE_REGISTER_HISOTRY, response.data)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },

  closeRegister({ commit }, json) {
    let promise = APIRegister.closeRegister(json)
      .then(response => {
        // commit(SET_USERS, response.data)
        console.log(response)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },

  createMovement({ commit }, movement) {
    console.log(movement)
    let promise = APIRegister.createMovement(movement)
      .then(response => {
        // commit(SET_USERS, response.data)
        console.log(response)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },

  fetechReportCash({ commit }, { asocid, start_date, end_date, status, cid }) {
    let promise = APIRegister.fetechReportCash(
      asocid,
      start_date,
      end_date,
      status,
      cid
    )
      .then(response => {
        console.log(response.data)
        //Ordenamos por fecha del ultimo comentario
        // response.data.sort((a, b) => (a.comment_date < b.comment_date ? 1 : -1))
        commit(SET_REPORT_CASH, response.data)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  }
}

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations
}
