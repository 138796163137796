<template>
  <div>
    <img class="img" alt="Vue logo" src="@/assets/smokapp_logo_blanco.png" />
  </div>
</template>

<script>
import { isIOS, isAndroid } from 'mobile-device-detect'

export default {
  created() {
    if (isIOS) {
      window.location = 'https://apps.apple.com/es/app/smokapp/id1019889040'
    }
    if (isAndroid) {
      window.location =
        'https://play.google.com/store/apps/details?id=com.easycsc.smokappandroid'
    }
  }
}
</script>

<style lang="scss" scoped></style>
