<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">
        {{ $t('Previsión de consumo por defecto') }}
      </p>
      <button type="button" class="delete ml-4" @click="$emit('close')" />
    </header>
    <!-- <section class="modal-card-body">
      <b-table :data="categories">
        <b-table-column field="title" :label="$t('Categoría')" sortable v-slot="props">
          {{ props.row.title }}
        </b-table-column>

        <b-table-column v-slot="props">
          <b-button
            class="is-small"
            icon-left="pen"
            @click="editCategory(props.row)"
          ></b-button>
          <b-button
            class="is-small"
            icon-left="trash-alt"
            @click="deleteCategory(props.row)"
          ></b-button>
        </b-table-column>
      </b-table>
    </section> -->
    <footer class="modal-card-foot">
      <!-- <b-button :label="$t('Cerrar')" @click="$emit('close')" /> -->
      <b-field :label="$t('Crear nueva previsión por defecto')">
        <b-input
          v-model="categoryName"
          :placeholder="$t('grs/mes')"
          type="number"
          min="0"
        >
        </b-input>
        <b-button
          v-if="is_editing"
          :label="$t('Modificar')"
          type="is-primary"
          @click="update()"
        />
        <b-button
          v-else
          :label="$t('Modificar')"
          type="is-primary"
          @click="update()"
        />
      </b-field>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      is_editing: false,
      categoryName: null,
      categoryNid: null,
      categoryToModify: null,
      categories: []
    }
  },
  computed: {
    ...mapState('club', ['currentClub'])
  },
  methods: {
    update() {
      if (this.categories.length == 0) {
        this.sendData()
      } else {
        this.updateData()
      }
    },
    editCategory(category) {
      console.log(category)
      this.categoryToModify = category
      if (category.title) {
        this.categoryName = category.title
      }
      this.categoryNid = category.nid
      this.is_editing = true
    },
    updateData() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      let json = {
        association: this.currentClub.nid,
        type: 'consumptionforecast',
        title: this.categoryName,
        nid: this.categoryNid
      }
      this.$store
        .dispatch('club/updateCategory', {
          category: this.categoryToModify,
          newcategory: json
        })
        .then(() => {
          loadingComponent.close()
          this.is_editing = false
          //   this.categoryName = null

          //   this.$emit('close')
        })
    },
    sendData() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      let json = {
        association: this.currentClub.nid,
        type: 'consumptionforecast',
        title: this.categoryName
      }
      this.$store.dispatch('club/createCategory', json).then(response => {
        loadingComponent.close()
        this.editCategory(response.data)
      })
    }
  },
  created() {
    this.$store
      .dispatch('club/fetchCategories', {
        asocid: this.currentClub.nid,
        type: 'consumptionforecast'
      })
      .then(response => {
        console.log(response)
        this.categories = response.data
        if (this.categories.length != 0) {
          //   this.categoryName = this.categories[0].title
          this.editCategory(this.categories[0])
        }
        //   this.$emit('close')
      })
  }
}
</script>

<style lang="scss" scoped></style>
