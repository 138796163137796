<template>
  <div>
    <div class="row">
      <div class="col-2">
        <b-field label="Dispensario"> </b-field>
        <b-field>
          <b-table
            class="my-table"
            style="text-align: left;"
            :data="this.products"
            :sticky-header="true"
            :paginated="isPaginated"
            :per-page="perPage"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :default-sort-direction="defaultSortDirection"
            :pagination-rounded="isPaginationRounded"
            default-sort="list_name"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="Página"
            aria-current-label="Actual"
            :selected.sync="selected"
            hoverable
            striped
            height="90%"
          >
            <b-table-column
              field="title"
              label="Nombre del producto"
              sortable
              v-slot="props"
              searchable
            >
              <img
                class="imgRigth"
                style="border-radius:50%; max-height:4vh; max-width:4vh"
                :src="props.row.images[0].img"
              />
              {{ props.row.title }}
            </b-table-column>

            <b-table-column
              field="list_name"
              label="Lista"
              sortable
              v-slot="props"
              searchable
            >
              {{ props.row.list_name }}
            </b-table-column>

            <b-table-column
              field="props.row.showintv"
              label="TV"
              sortable
              v-slot="props"
              width="50"
            >
              <b-checkbox
                v-model="props.row.showintv"
                true-value="Mostrar"
                false-value="No"
                >{{ props.row.showintv }}</b-checkbox
              >
            </b-table-column>

            <b-table-column
              field="valoration"
              label="Valoración"
              sortable
              v-slot="props"
              searchable
              width="50"
            >
              {{ props.row.valoration.toFixed(2) }}
              <i class="far fa-star"></i>
            </b-table-column>
          </b-table>
        </b-field>
      </div>

      <div class="col-3 actions">
        <h4>Producto</h4>
        <div class="aside">
          <div v-if="selected.images">
            <div v-if="selected.images.length > 0">
              <img
                class="imgRigth"
                style="border-radius:50%; margin-top:20px; max-height:20vh"
                :src="selected.images[0].img"
              />
            </div>
            <h4>{{ selected.title }}</h4>
            <h3>{{ selected.subtitle }}</h3>
            <p v-html="selected.body"></p>
            <p>{{ selected.valoration }} <i class="far fa-star"></i></p>
          </div>
        </div>
        <hr />
        <div class="menu right">
          <ul>
            <li @click="createNewList()">
              <div>
                <h6>{{ $t('Crear nueva categoría') }}</h6>
              </div>
            </li>
            <li @click="createNewProduct()">
              <div>
                <h6>{{ $t('Publicar nuevo producto') }}</h6>
                <p>Publica un nuevo producto en tu dispensario</p>
              </div>
            </li>
            <li style="background-color: #ed6a5a; color:#fff">
              <h6>Eliminar producto</h6>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- <div class="footer">
      <p>
        Resize the browser window to see how the content respond to the
        resizing.
      </p>
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'

export default {
  data() {
    return {
      listIndex: 0,
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 24,
      selected: {},
      selectedOptions: [],
      products: []
    }
  },
  mixins: [ToolsMixin, InitViewMixin],
  computed: {
    ...mapState('dispensaryBasic', ['dispensary', 'current_list_index'])
  },
  methods: {
    selectedList(event) {
      this.$store.dispatch(
        'dispensaryBasic/setCurrentListIndex',
        this.listIndex
      )
    },
    createNewProduct() {
      this.$router.push({ name: 'dispensaryproductcreatebasic' })
    },
    createNewList() {},
    parseProducts(dispensary) {
      var tmpArr = []
      dispensary.forEach(list => {
        list.item.forEach(item => {
          item.list_name = list.title
          tmpArr.push(item)
        })
      })
      return tmpArr
    },
    downloadDispensary() {
      this.listIndex = this.current_list_index
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.products = this.parseProducts(this.dispensary)
      this.$store
        .dispatch('dispensaryBasic/fetchDispensary', this.currentClub.nid)
        .then(() => {
          loadingComponent.close()
        })
    }
  },
  created() {
    this.$analytics.logEvent('DispensaryManagerBasic.vue')
    this.$store.dispatch('setShowNavigationBar', true)

    this.downloadDispensary()
  },
  watch: {
    currentClub() {
      this.downloadDispensary()
    },
    dispensary() {
      this.products = this.parseProducts(this.dispensary)
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.row::after {
  content: '';
  clear: both;
  // display: flex;
}

[class*='col-'] {
  float: left;
  padding: 15px;
}

.imgRigth {
  object-fit: cover;
  width: 230px;
  height: 230px;
}

html {
  font-family: 'Lucida Sans', sans-serif;
}

.my-table {
  // max-height: 100%;
  overflow-y: auto;
  height: calc(100vh - 150px);
  // height: 1000px;
}

.header {
  background-color: #9933cc;
  color: #ffffff;
  padding: 15px;
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  top: 10px;
  // height: 5vh;
  overflow: auto;
}

.menu li {
  padding: 8px;
  margin-bottom: 7px;
  background-color: #f7f3f3;
  color: #666666;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
  cursor: pointer;
}

.right li {
  // background-color: #33b5e5;
  // color: #fff;
  background-color: #f3f3f3;
}

.left li {
  background-color: #f3f3f3;
  // color: #fff;
}

.menu li:hover {
  background-color: #e1e1e1;
  // color: #fff;
}

.aside {
  background-color: #f3f3f3;
  padding: 15px;
  color: #666666;
  text-align: center;
  font-size: 14px;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
}

.footer {
  background-color: #0099cc;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  padding: 15px;
}

/* For desktop: */

.col-2 {
  width: 70%;
  background-color: #f9f9f9;
  border-radius: 6px;
}
.col-3 {
  width: 30%;
  background-color: #f9f9f9;
  border-radius: 6px;
}

.consultsList {
  height: calc(100vh - 160px);
  overflow: auto;
}

.chat {
  height: calc(100vh - 190px);
  overflow: auto;
}

.actions {
  height: calc(100vh - 85px);
  overflow: auto;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*='col-'] {
    width: 100%;
  }
}
</style>
