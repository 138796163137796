<template>
  <fullscreen ref="fullscreen" wrap="true" background="#FFFFFF">
    <section
      :style="
        'background:' +
          carouselSettings.backgroundcolor +
          ';color:' +
          carouselSettings.textcolor
      "
    >
      <div
        class="w3-row-padding"
        v-if="this.dispensary.length > 0"
        style="display:flex;flex-wrap: wrap;text-align: left; justify-content:space-around; column-gap: 20px;"
      >
        <div
          class="w3-third w3-container w3-margin-bottom cell"
          v-for="item in this.dispensary"
          :key="item.nid"
          :item="item"
        >
          <router-link
            class="dispensary-link"
            :to="{ name: 'DispensaryDetail', params: { id: item.nid } }"
          >
            <div v-if="item.imagenes.length > 0">
              <img
                :src="item.imagenes[0].img"
                style="max-height:150px"
                class="w3-hover-opacity"
              />
            </div>

            <div style="height:50px">
              <h2>{{ item.titulo }}</h2>
              <h4>{{ item.subtitle }}</h4>
              <h6 v-html="item.cuerpo.substring(0, 100)"></h6>
            </div>
          </router-link>
        </div>
      </div>
    </section>
  </fullscreen>
</template>

<script>
import { mapState } from 'vuex'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'

export default {
  data() {
    return {
      allItemsArray: []
    }
  },
  methods: {
    fetchDispensary() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.$store
        .dispatch('dispensaryPro/fetchDispensary', {
          asocid: this.currentClub.nid,
          status: null
        })
        .then(() => {
          loadingComponent.close()
          this.confirmFullScreen()
        })
    }
  },
  mixins: [ToolsMixin, InitViewMixin],
  computed: {
    ...mapState('dispensaryPro', ['dispensary']),
    ...mapState('tvshow', ['carouselSettings'])
  },
  created() {
    this.validateAccess(['comunicacion'])
    this.$analytics.logEvent('TVShow1.vue')

    this.$store.dispatch('tvshow/initialize')
    this.$store.dispatch('setShowNavigationBar', false)
    this.fetchDispensary()
  },
  watch: {
    currentClub() {
      this.validateAccess(['comunicacion'])
      this.fetchDispensary()
    }
  }
}
</script>
<style lang="scss">
$bg-classroom: #232323;
.classroom {
  background: $bg-classroom;
}
.cell {
  height: 300px;
  // width: 25%;
}
</style>
<style scoped></style>
