<template>
  <div>
    <div class="aside cloumns" v-if="currentPatient">
      <div class="column is-5" style="display:flex;">
        <!-- <img v-auth-image="currentPatient.foto.url" /> -->
        <!-- <img
          v-if="currentPatient.foto"
          class="imgRigth mr-2"
          style="border-radius:50%; height: 100px; width: 100px; object-fit:cover;"
          :src="currentPatient.foto.url"
          @click="clickPatient"
        /> -->

        <div class="asideContent" @click="clickPatient">
          <img
            v-if="currentPatient.foto"
            :src="currentPatient.foto.url"
            class="image mb-2"
            @click="clickPatient"
          />
          <b> {{ currentPatient.nombre }} {{ currentPatient.surname }} </b>
          <!-- <p>{{ currentPatient.email }}</p> -->
          <!-- <p>{{ $t('Teléfono') }}: {{ currentPatient.telefono }}</p> -->
          <p class="hidelement">
            {{ $t('NºSoc') }}: {{ currentPatient.number }}
          </p>
          <p class="hidelement">
            {{ $t('Previsión') }}: {{ currentPatient.consum_forecast }}
          </p>
          <p>{{ $t('Tipo') }}: {{ translateType(currentPatient.type) }}</p>
          <p v-if="currentPatient.category_member">
            {{ $t('Categoría') }}: {{ currentPatient.category_member.title }}
          </p>
          <p v-if="currentPatient.category_member">
            {{ $t('Descuento') }}: -{{
              currentPatient.category_member.field_member_category_descuento
            }}%
          </p>
          <p v-if="currentPatient.online_account" class="hidelement">
            {{ $t('Con cuenta online') }}
          </p>
          <p v-else class="hidelement">
            {{ $t('Sin cuenta online') }}
          </p>
          <p v-if="currentPatient.notes" class="hidelement">
            {{ $t('Notas') }}:
            {{ currentPatient.notes.substring(0, 50) }}
          </p>
        </div>
      </div>

      <div class="column menu right">
        <ul>
          <li
            v-if="
              (this.currentClub.functionalities.funciones_avanzadas_gestion ||
                this.currentClub.functionalities.monedero) &&
                walletPermission
            "
            style="min-height:55px"
            @click="walletRecharge()"
          >
            <div>
              <b>{{ $t('Monedero') }} </b>
              <p>
                {{ currentPatient.monedero ? currentPatient.monedero : 0 }} €
              </p>
            </div>
          </li>
          <li style="min-height:55px" @click="feeManager()">
            <div>
              <b>{{ $t('Cuota') }} </b>
              <!-- <div v-if="currentPatient.end_date">
                <p>{{ format_date(currentPatient.end_date, 'dd/MM/yy') }}</p>
              </div>
              <div v-else>
                <p>Expirada</p>
              </div> -->
              <br />
              <span
                class="mt-2"
                v-if="
                  currentPatient.end_date &&
                    currentPatient.end_date < Math.floor(Date.now() / 1000)
                "
              >
                <span v-if="currentPatient.end_date" class="tag is-danger mt-2">
                  {{ format_date(currentPatient.end_date, 'dd/MM/yyyy') }}
                </span>
              </span>
              <span v-else>
                <div v-if="currentPatient.end_date" class="tag is-success mt-2">
                  {{ format_date(currentPatient.end_date, 'dd/MM/yyyy') }}
                </div>
              </span>
            </div>
          </li>
        </ul>
      </div>
      <div class="column menu right">
        <ul>
          <li
            style="min-height:55px"
            @click="sendOrderAlert()"
            v-if="this.currentClub.functionalities.comunicacion_avanzada"
          >
            <div>
              <p><i class="fas fa-exclamation-triangle"></i></p>
              <b>{{ $t('Avisador') }}</b>
            </div>
          </li>
          <li
            v-if="
              this.currentClub.functionalities.funciones_avanzadas_gestion ||
                this.currentClub.functionalities.cupones
            "
            style="min-height:55px"
            @click="showCuponConsume()"
          >
            <div>
              <p><i class="fas fa-qrcode"></i></p>
              <b>{{ $t('Cupón') }}</b>
            </div>
          </li>
          <li
            v-if="this.checkRoles(['Administrador', 'Manager', 'Budtender'])"
            style="min-height:55px"
            @click="showOrdersReport()"
          >
            <div>
              <p><i class="fas fa-list"></i></p>
              <b>{{ $t('Historial de pedidos') }}</b>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import WalletRecharge from '@/components/WalletRecharge.vue'
import FeeManager from '@/components/FeeManager.vue'
import APINotifications from '@/services/APINotifications.js'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'
import CuponConsume from '@/views/Cupons/CuponConsume.vue'
import PatientDetail from '@/views/Users/PatientDetail.vue'

export default {
  // props: {
  //   currentClub: null
  // },

  computed: {
    ...mapState('patients', ['currentPatient']),
    ...mapState('orders', ['currentOrder']),
    ...mapState('club', [
      'cashPermission',
      'cardPermission',
      'walletPermission'
    ])
  },

  methods: {
    translateType(type) {
      if (type === 'ludico') {
        return this.$t('Lúdico')
      } else if (type === 'terapeutico') {
        return this.$t('Terapéutico')
      }
    },
    clickPatient() {
      this.$buefy.modal.open({
        parent: this,
        component: PatientDetail,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
        props: {
          currentPatient: this.currentPatient,
          currentClub: this.currentClub
        },
        onCancel: function() {},
        events: {
          reloadPatients: value => {
            this.fetchPatients()
          }
        }
      })
    },
    showOrdersReport() {
      this.$emit('close')
      this.$router.push({
        name: 'ordersReport',
        query: { ms_id: this.currentPatient.nid }
      })
    },
    walletRecharge() {
      this.$buefy.modal.open({
        parent: this,
        component: WalletRecharge,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
        props: {
          currentPatient: this.currentPatient,
          currentClub: this.currentClub
        },
        onCancel: function() {},
        events: {
          onCapture: value => {}
        }
      })
    },
    feeManager() {
      this.$buefy.modal.open({
        parent: this,
        component: FeeManager,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
        props: {
          currentPatient: this.currentPatient,
          currentClub: this.currentClub
        },
        onCancel: function() {},
        events: {
          onCapture: value => {}
        }
      })
    },
    sendOrderAlert() {
      if (this.currentPatient.user == null) {
        this.$buefy.notification.open({
          message:
            this.$t('No se puede enviar push porque el socio no tiene usuario asociado'),
          position: 'is-top',
          type: 'is-danger',
          hasIcon: false,
          duration: 5000
        })
        return
      }
      let uids = []
      uids.push(this.currentPatient.user.uid)
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      APINotifications.sendPushNotification(
        this.currentClub.nid,
        this.$t('Tienes el pedido disponible, por favor pasa a recogerlo.'),
        // 'mis cojones 33',

        uids
      )
        .then(response => {
          loadingComponent.close()
          this.$buefy.notification.open({
            message: this.$t('Notificación enviada correctamente'),
            position: 'is-top',
            type: 'is-success',
            hasIcon: false,
            duration: 5000
          })
        })
        .catch(error => {
          console.log('Ha habido un error:' + error)
          loadingComponent.close()
          this.$buefy.notification.open({
            message: error,
            position: 'is-top-right',
            type: 'is-danger',
            hasIcon: false,
            duration: 5000
          })
        })
    },
    showCuponConsume() {
      this.$buefy.modal.open({
        parent: this,
        component: CuponConsume,
        hasModalCard: true,
        customClass: 'custom-class custom-class-2',
        trapFocus: true,
        props: {
          order: this.currentOrder,
          currentPatient: this.currentPatient
        }
      })
    }
  },
  mixins: [ToolsMixin, InitViewMixin]
}
</script>

<style lang="scss" scoped>
.aside {
  background-color: #f3f3f3;
  // padding: 2px;
  color: #666666;
  text-align: left;
  font-size: 14px;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
  display: flex;
  justify-content: left;
  min-width: 300px;
  margin-top: 5px;
}

.asideContent {
  color: #666666;
  text-align: left;
  font-size: 14px;
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  top: 10px;
  // height: 5vh;
  overflow: auto;
}

.menu li {
  padding: 8px;
  margin-bottom: 4px;
  background-color: #f7f3f3;
  color: #666666;
  box-shadow: 0 1px 3px rgba(78, 78, 78, 0.12), 0 1px 2px rgba(71, 71, 71, 0.24);
  border-radius: 6px;
  cursor: pointer;
}

.right li {
  // background-color: #33b5e5;
  // color: #fff;
  background-color: #fff;
}

.left li {
  background-color: #f3f3f3;
  // color: #fff;
}

.menu li:hover {
  background-color: #e1e1e1;
  // color: #fff;
}

.image {
  opacity: 1;
  display: block;

  transition: 0.5s ease;
  backface-visibility: hidden;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  object-fit: cover;
}

.aside:hover .image {
  opacity: 0.3;
}

.aside:hover .middle {
  opacity: 1;
}

@media screen and (max-width: 809px) {
  .hidelement {
    display: none;
  }
}
</style>
