<template>
  <div>
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css?family=Roboto"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
    />

    <!-- Page Container -->
    <div class="w3-content w3-margin-top" style="max-width:1400px;">
      <!-- The Grid -->
      <div class="w3-row-padding">
        <!-- Left Column -->
        <div class="w3-third">
          <div class="w3-white w3-text-grey w3-card-4">
            <div class="w3-display-container">
              <!-- <img src="@/assets/avatar.jpg" style="width:100%" alt="Avatar" /> -->

              <a
                href="https://secure.gravatar.com/accounts/force-by-email/wpcom/username/somecode?TB_iframe=true&amp;height=475&amp;width=960"
                id="gravatar_ui"
                class="thickbox"
                title="gravatar.com"
              >
                <v-gravatar
                  :email="smokappProfile.user.mail"
                  alt="Nobody"
                  :size="200"
                  default-img="mm"
                  style="border-radius:50%; margin-top:20px;"
                />
                <p style="text-align: center;">Cambia tu avatar</p>
              </a>

              <div class="w3-display-bottomleft w3-container w3-text-black">
                <h2>{{ username }}</h2>
              </div>
            </div>
            <div class="w3-container">
              <hr />

              <div>
                <p>
                  {{ smokappProfile.field_user_name }}
                </p>
                <p>
                  {{ smokappProfile.field_user_surname }}
                </p>
                <p>
                  {{ smokappProfile.field_user_dni }}
                </p>
                <p>
                  {{ smokappProfile.user.mail }}
                </p>
                <p>
                  {{
                    format_date(
                      smokappProfile.field_user_birthdate,
                      'dd/MM/yyyy'
                    )
                  }}
                </p>
                <p>
                  <i
                    class="fa fa-phone fa-fw w3-margin-right w3-large w3-text-teal"
                  ></i
                  >{{ smokappProfile.field_user_tlf }}
                </p>
              </div>
              <hr />

              <p class="w3-large w3-text-theme">
                <b class="logo" v-if="currentClub != Object">
                  <!-- <i class="fa fa-globe fa-fw w3-margin-right w3-text-teal"></i> -->

                  <img :src="currentClub.logo.img" style="width:23px" />
                  Club</b
                >
              </p>
              <p>{{ currentClub.title }}</p>
              <div class="w3-light-grey w3-round-xlarge">
                <div
                  class="w3-round-xlarge w3-teal"
                  style="height:24px;width:100%"
                ></div>
              </div>

              <br />
            </div>
          </div>
          <br />

          <!-- End Left Column -->
        </div>

        <!-- Right Column -->
        <div class="w3-twothird">
          <div class="w3-container w3-card w3-white w3-margin-bottom">
            <h2 class="w3-text-grey w3-padding-14">
              <i
                class="fa fa-suitcase fa-fw w3-margin-right w3-xxlarge w3-text-teal"
              ></i
              >Cartera
            </h2>
            <div class="w3-container">
              <h5 class="w3-opacity">
                <!-- <b>Saldo monedero: {{ mjProfile.monedero }}€</b> -->
              </h5>
              <h5 class="w3-opacity">
                Caducidad cuota:
                <!-- <h6 class="w3-text-teal">
                  <i class="fa fa-calendar fa-fw w3-margin-right"></i
                  >{{ format_date(mjProfile.cuota_expire, 'dd MM yyyy') }} -
                  <span class="w3-tag w3-teal w3-round">Válida</span>
                </h6> -->
                <!-- <p>Previsión: {{ mjProfile.consumption }}</p> -->
                <p>
                  <!-- Puntos de recompensa: {{ mjProfile.reward_points_available }} -->
                </p>
              </h5>
              <p>
                A partir del xx/xx/xxxx se caducará tu cuota y tendrás que
                renovarla para seguir formando parte del club.
              </p>
              <hr />
            </div>
          </div>

          <div class="w3-container w3-card w3-white">
            <h2 class="w3-text-grey w3-padding-14">
              <i
                class="fa fa-certificate fa-fw w3-margin-right w3-xxlarge w3-text-teal"
              ></i
              >Identificación
            </h2>
            <div class="w3-container">
              <qrcode-vue
                :value="smokappProfile.field_user_dni"
                :size="230"
                level="H"
              ></qrcode-vue>
              <hr />
            </div>
          </div>

          <!-- End Right Column -->
        </div>

        <!-- End Grid -->
      </div>

      <!-- End Page Container -->
    </div>

    <b-button @click="logout">Log out</b-button>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import { mapState } from 'vuex'
import moment from 'moment'
import { ToolsMixin } from '@/mixins/ToolsMixin.js'
import { InitViewMixin } from '@/mixins/InitViewMixin.js'

export default {
  components: {
    QrcodeVue
  },
  mixins: [ToolsMixin, InitViewMixin],

  methods: {
    logout() {
      this.$store.dispatch('auth/doLogout')
      this.$router.replace({ name: 'Login' })
    },
    downloadProfile() {
      // this.$store
      //   .dispatch('auth/getMJProfile', this.currentClub.nid)
      //   .then(response => {
      //     // loadingComponent.close()
      //     if (response.data.length == 0) {
      //       this.$store.dispatch('auth/clearMJProfile')
      //       this.$buefy.notification.open({
      //         message:
      //           'Habla con algún responsable en tu club para que te activen el carnet de socio virtual',
      //         position: 'is-bottom',
      //         type: 'is-danger',
      //         hasIcon: false,
      //         duration: 5000
      //       })
      //     }
      //   })
    }
  },
  computed: {
    ...mapState('auth', ['username', 'email', 'smokappProfile'])
  },
  created() {
    this.$analytics.logEvent('UserProfile.vue')
    this.$store.dispatch('setShowNavigationBar', true)

    this.downloadProfile()
  },
  watch: {
    currentClub() {
      this.downloadProfile()
    }
  }
}
</script>

<style lang="scss" scoped></style>
