import { SET_EVENTS, SET_CURRENT_EVENT, DELETE_EVENT } from './types'
import APIEvents from '@/services/APIEvents.js'
import tools from '@/Tools/tools.js'

const initialState = {
  events: [],
  currentEvent: {}
}

const mutations = {
  [SET_EVENTS](state, events) {
    state.events = events
  },
  [SET_CURRENT_EVENT](state, currentEvent) {
    state.currentEvent = currentEvent
  },
  [DELETE_EVENT](state, event) {
    state.events.splice(state.events.indexOf(event), 1)
  }
}

const actions = {
  fetchEvents({ commit }, asocid) {
    let promise = APIEvents.getEvents(asocid)
      .then(response => {
        //Ordenamos por fecha del ultimo comentario
        // response.data.sort((a, b) => (a.comment_date < b.comment_date ? 1 : -1))
        commit(SET_EVENTS, response.data)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  fetchEvent({ commit }, { asocid, nid }) {
    let promise = APIEvents.getEvent(asocid, nid)
      .then(response => {
        console.log(response)
        if (response.data.length > 0) {
          commit(SET_CURRENT_EVENT, response.data[0])
        }
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    return promise
  },
  setCurrentEvent({ commit }, event) {
    commit(SET_CURRENT_EVENT, event)
  },

  deleteEvent({ commit }, { event, asocid }) {
    let promise = APIEvents.deleteEvent(event.nid, asocid)
      .then(response => {
        console.log(response.data)
      })
      .catch(error => {
        tools.manageAxiosError(error)
        console.log('Ha habido un error:' + error)
      })
    commit(DELETE_EVENT, event)
    return promise
  },

  postEvent({ commit }, json) {
    let promise = APIEvents.postEvent(json)

    return promise
  }
}

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations
}
