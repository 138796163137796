import { mapState } from 'vuex'
import { DialogProgrammatic as Dialog } from 'buefy'
// import session from '@/services/Session.js'
// import axios from 'axios'

export const InitViewMixin = {
  data() {
    return {
      selectedIndex: 0
    }
  },
  computed: {
    ...mapState('club', ['clubs', 'currentClub']),
    ...mapState('auth', ['smokappProfile']),
    ...mapState('patients', ['myMembershipCard'])
  },
  methods: {
    initview() {
      console.log('Entra en initview')
      this.$store.dispatch('club/initialize')
      this.$store.dispatch('auth/initialize')
      this.$store.dispatch('auth/sessionToken')
      // .then(response => {
      //   console.log('X-CSRF-TOKEN')
      //   console.log(response.data)
      //   session.defaults.headers.common['X-CSRF-TOKEN'] = response.data
      //   axios.defaults.headers.common['X-CSRF-TOKEN'] = response.data
      // })
      if (this.currentClub != Object) {
        this.downloadMyMembershipCard()
        if (this.clubs.length > 0) {
          this.selectedIndex = this.clubs.findIndex(
            club => club.nid === this.currentClub.nid
          )
        } else {
          const loadingComponent = this.$buefy.loading.open({
            container: null
          })
          this.$store.dispatch('club/fetchClubs').then(() => {
            this.selectedIndex = this.clubs.findIndex(
              club => club.nid === this.currentClub.nid
            )
            this.$store.dispatch('club/fetchClubPermissions', {
              asocid: this.currentClub.nid
            })
            loadingComponent.close()
          })
        }
      } else {
        this.$store
          .dispatch('club/fetchClubs')
          .then(() => {
            this.downloadMyMembershipCard()
            this.$store.dispatch('club/fetchClubPermissions', {
              asocid: this.currentClub.nid
            })
          })
          .catch(error => {
            console.log('Ha habido un error:' + error)
            // this.$store.dispatch('auth/doLogout')
            // this.$router.replace('/')
          })
      }
    },
    selectedClub(index) {
      console.log('Entra en selectedClub(index)')
      this.$store.commit('patients/CLEAR_CURRENT_PATIENT')
      this.$store.commit('patients/CLEAR_SELECTED_PATIENTS')
      this.$store.commit('orders/CLEAR_CURRENT_ORDER')

      this.selectedIndex = index
      this.$store.dispatch('dispensaryBasic/clearDispensary')

      this.$store
        .dispatch('club/setClub', {
          club: this.clubs[this.selectedIndex]
        })
        .then(() => {
          this.downloadMyMembershipCard()
          this.$store.dispatch('club/fetchClubPermissions', {
            asocid: this.currentClub.nid
          })
        })
    },
    downloadMyMembershipCard() {
      this.$store.dispatch('patients/fetchMyMembershipCard', {
        asocid: this.currentClub.nid,
        uid: this.smokappProfile.user.uid,
        router: this.$router
      })
      // .catch(error => {
      //   if (error.response.status === 400) {
      //     Dialog.alert({
      //       title: 'Error',
      //       message: 'Este usuario no dispone de ficha de socio en este club',
      //       confirmText: 'OK',
      //       type: 'is-danger',
      //       hasIcon: false,
      //       onConfirm: () => {
      //         this.$router.replace('/')
      //       }
      //     })
      //   }
      //   console.log('Ha habido un error:' + error)
      // })
    },

    validateAccess(functionalities) {
      var out = false
      functionalities.forEach(f => {
        if (this.currentClub.functionalities[f] == true) {
          out = true
        }
      })
      if (!out) {
        alert(
          'Este club no tiene accesso a esta sección. Póngase en contacto con su proveedor de Smokapp.'
        )
        this.$router.replace('/')
      }
    },

    checkRoles(roles) {
      // console.log(roles)
      // return true
      if (
        this.myMembershipCard == null ||
        roles.length == 0 ||
        this.myMembershipCard.memcard_roles.length == 0
      ) {
        return false
      }

      let out = 0

      roles.forEach(rol => {
        if (!this.myMembershipCard) {
          return true
        }
        if (
          this.myMembershipCard &&
          this.myMembershipCard.memcard_roles.indexOf(rol) != -1
        ) {
          out = out + 1
        }
      })

      return out > 0
    },

    validateRoles(roles) {
      if (this.myMembershipCard == null) {
        this.$store
          .dispatch('patients/fetchMyMembershipCard', {
            asocid: this.currentClub.nid,
            uid: this.smokappProfile.user.uid,
            router: this.$router
          })
          .then(() => {
            if (!this.checkRoles(roles)) {
              alert(
                'No tiene accesso a esta sección. Póngase en contacto con su administrador.'
              )
              this.$router.replace('/')
            }
          })
          .catch(error => {
            if (error.response.status === 400) {
              Dialog.alert({
                title: 'Error',
                message:
                  'Este usuario no dispone de ficha de socio en este club',
                confirmText: 'OK',
                type: 'is-danger',
                hasIcon: false,
                onConfirm: () => {
                  this.$router.replace('/')
                }
              })
            }
            console.log('Ha habido un error:' + error)
          })
      } else {
        if (!this.checkRoles(roles)) {
          alert(
            'No tiene accesso a esta sección. Póngase en contacto con su administrador.'
          )
          this.$router.replace('/')
        }
      }
    }
  }
}
