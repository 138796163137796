import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import VueAuthImage from 'vue-auth-image'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import fullscreen from 'vue-fullscreen'
import firebase from 'firebase/app'
import 'firebase/messaging'
import 'firebase/analytics'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import Gravatar from 'vue-gravatar'
import { isIOS, isChrome } from 'mobile-device-detect'
import VueMatomo from 'vue-matomo'
import VueHtmlToPaper from 'vue-html-to-paper'
import VueSignature from 'vue-signature-pad'
import * as TastyBurgerButton from 'vue-tasty-burgers'
import VueTimers from 'vue-timers'
import i18n from './i18n'
import JsonExcel from 'vue-json-excel'
import device from 'vue-device-detector'

Vue.component('downloadExcel', JsonExcel)
Vue.use(device)

const optionshtmltopaper = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ]
}
Vue.use(VueHtmlToPaper, optionshtmltopaper)
Vue.use(TastyBurgerButton)
library.add(fas)
Vue.component('vue-fontawesome', FontAwesomeIcon)
Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas'
})
Vue.use(VueSignature)
Vue.use(VueTimers)

Vue.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: 'https://matomo.smokapp.es',
  siteId: 1,

  // Changes the default .js and .php endpoint's filename
  // Default: 'matomo'
  trackerFileName: 'matomo',

  // Overrides the autogenerated tracker endpoint entirely
  // Default: undefined
  // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

  // Overrides the autogenerated tracker script path entirely
  // Default: undefined
  // trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',

  // Enables automatically registering pageviews on the router
  router: router,

  // Enables link tracking on regular links. Note that this won't
  // work for routing links (ie. internal Vue router links)
  // Default: true
  enableLinkTracking: true,

  // Require consent before sending tracking information to matomo
  // Default: false
  requireConsent: false,

  // Whether to track the initial page view
  // Default: true
  trackInitialView: true,

  // Run Matomo without cookies
  // Default: false
  disableCookies: false,

  // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
  // Default: false
  enableHeartBeatTimer: false,

  // Set the heartbeat timer interval
  // Default: 15
  heartBeatTimerInterval: 15,

  // Whether or not to log debug information
  // Default: false
  debug: false,

  // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
  // Default: undefined
  userId: undefined,

  // Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
  // Default: undefined, example '*.example.com'
  cookieDomain: undefined,

  // Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
  // Default: undefined, example: '*.example.com'
  domains: '*.smokapp.com',

  // A list of pre-initialization actions that run before matomo is loaded
  // Default: []
  // Example: [
  //   ['API_method_name', parameter_list],
  //   ['setCustomVariable','1','VisitorType','Member'],
  //   ['appendToTrackingUrl', 'new_visit=1'],
  //   etc.
  // ]
  preInitActions: []
})

const firebaseConfig = {
  apiKey: 'AIzaSyBOyxSOT0-lt5dDDhPhAOhE-Gzd-YJfQkU',
  authDomain: 'smokapp-52017.firebaseapp.com',
  databaseURL: 'https://smokapp-52017.firebaseio.com',
  projectId: 'smokapp-52017',
  storageBucket: 'smokapp-52017.appspot.com',
  messagingSenderId: '979929480172',
  appId: '1:979929480172:web:81699566abeecf59f39ec9',
  measurementId: 'G-1W2K0P04MV'
}
// var firebaseConfig = {
//   apiKey: 'AIzaSyBOyxSOT0-lt5dDDhPhAOhE-Gzd-YJfQkU',
//   authDomain: 'smokapp-52017.firebaseapp.com',
//   databaseURL: 'https://smokapp-52017.firebaseio.com',
//   projectId: 'smokapp-52017',
//   storageBucket: 'smokapp-52017.appspot.com',
//   messagingSenderId: '979929480172',
//   appId: '1:979929480172:web:8b411c59b1d1c7dcf39ec9',
//   measurementId: 'G-FFZ74RD349'
// }

firebase.initializeApp(firebaseConfig)
firebase.analytics()
Vue.prototype.$analytics = firebase.analytics()

if (isChrome) {
  console.log('IS CHROME')
  try {
    Notification.requestPermission(function(status) {
      console.log('Notification permission status:', status)
    })
    navigator.serviceWorker
      .register('firebase-messaging-sw.js', {
        scope: 'firebase-cloud-messaging-push-scope'
      })
      .then(registration => {
        const messaging = firebase.messaging()
        messaging.useServiceWorker(registration)
      })
      .catch(err => {
        console.log(err)
      })
    // function displayNotification() {
    //   if (Notification.permission == 'granted') {
    //     navigator.serviceWorker.getRegistration().then(function(reg) {
    //       reg.showNotification('Hello world!')
    //     })
    //   }
    // }
    const messaging = firebase.messaging()

    messaging
      .getToken({
        vapidKey:
          'BFVaxdTDqsads6ugtPx97AUF7FCrGId6vUS1J88NqiSMv1k0NAytFMUyW6S4jx7bRffAeKtUZCVsNkKu3bg24vY'
      })
      .then(currentToken => {
        if (currentToken) {
          console.log(currentToken)
          // sendTokenToServer(currentToken)
          // updateUIForPushEnabled(currentToken)
        } else {
          // Show permission request.
          console.log(
            'No registration token available. Request permission to generate one.'
          )
          // Show permission UI.
          updateUIForPushPermissionRequired()
          // setTokenSentToServer(false)
        }
      })
      .catch(err => {
        console.log('An error occurred while retrieving token. ', err)
        // setTokenSentToServer(false)
      })
  } catch (err) {
    console.log(err)
  }
}

// navigator.serviceWorker
//   .register('firebase-messaging-sw.js', {
//     scope: 'firebase-cloud-messaging-push-scope'
//   })
//   .then(registration => {
//     const messaging = firebase.messaging()
//     messaging.useServiceWorker(registration)
//   })
//   .catch(err => {
//     console.log(err)
//   })

Vue.component('v-gravatar', Gravatar)
// import VueGtag from 'vue-gtag'

// Vue.use(VueGtag, {
//   config: {
//     id: 'GTM-W4PZMNK',
//     bootstrap: false
//   }
// })

Vue.config.productionTip = false
// Vue.config.devServer = {
//   proxy: 'http://192.168.0.31'
// }
Vue.use(fullscreen)

Vue.use(require('vue-moment'))
Vue.use(Buefy)
// import axios from 'axios'

// axios.defaults.headers.common['Authorization'] =
//   'Basic c21va2FwcEBzdGcuc21va2FwcC5lczpwYXRhbGllYnJl'
// axios.defaults.headers.common['X-COOKIE'] =
//   'SSESS5059654def5c157c407af4c5827cea6c=PtSP2htEkUlO4odC-Y81xM6Kejx5kP5r5FktNF0fzKs'
// axios.defaults.headers.common['X-CSRF-TOKEN'] =
//   'clJWtd0O5qCwAra6BAprmTZvMuCrRh3Jq1XN4VJBxKA'

Vue.use(VueAuthImage)
Vue.use(VueLodash, { lodash: lodash })

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
