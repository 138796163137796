import APIAuth from '@/services/APIAuth.js'
import session from '@/services/Session.js'
import axios from 'axios'
import { DialogProgrammatic as Dialog } from 'buefy'

import {
  SET_USER,
  SET_CREDENTIALS,
  REMOVE_TOKEN,
  SET_SMOKAPP_PROFILE,
  CLEAR_SMOKAPP_PROFILE
} from './types'
const initialState = {
  username: String,
  password: String,
  email: String,
  X_CSRF_TOKEN: '',
  X_COOKIE: '',
  credentials: {},
  initialized: false,
  smokappProfile: Object
}
const getters = {}
const mutations = {
  [SET_USER](state, username, password) {
    state.username = username
    state.password = password
  },

  [SET_SMOKAPP_PROFILE](state, smokappProfile) {
    localStorage.setItem('smokappProfile', JSON.stringify(smokappProfile))
    state.smokappProfile = smokappProfile
    state.username = smokappProfile.user.name
    state.email = smokappProfile.user.mail
    // this.$matomo.setCustomVariable(1, 'email', state.email, 'profile')
    _paq.push(['setUserId', state.username])
    _paq.push(['setCustomVariable', 1, 'email', state.email, 'visit'])
    _paq.push([
      'setCustomVariable',
      2,
      'tlf',
      smokappProfile.field_user_tlf,
      'visit'
    ])
    _paq.push([
      'setCustomVariable',
      3,
      'name',
      smokappProfile.field_user_name,
      'visit'
    ])
    _paq.push([
      'setCustomVariable',
      4,
      'surname',
      smokappProfile.field_user_surname,
      'visit'
    ])
    _paq.push(['setCustomVariable', 5, 'uid', smokappProfile.user.uid, 'visit'])
    _paq.push(['trackPageView'])
    _paq.push(['trackAllContentImpressions'])
  },
  [SET_CREDENTIALS](state, data) {
    localStorage.setItem('credentials', JSON.stringify(data))
    state.credentials = data
    state.X_CSRF_TOKEN = data.token
    state.X_COOKIE = data.session_name + '=' + data.sessid
    localStorage.setItem('sessiontoken', data.token)
    // session.defaults.headers.common['X-COOKIE'] = state.X_COOKIE
    // session.defaults.headers.common['X-CSRF-TOKEN'] = data.token
    console.log(state.X_COOKIE)
    console.log(data.token)

    axios.defaults.headers.common['Authorization'] =
      'Basic c21va2FwcEBzdGcuc21va2FwcC5lczpwYXRhbGllYnJl'
    // axios.defaults.headers.common['X-COOKIE'] = state.X_COOKIE
    // axios.defaults.headers.common['X-CSRF-TOKEN'] = data.token

    state.initialized = true
  },
  [REMOVE_TOKEN](state) {
    // delete session.defaults.headers.common['X-COOKIE']
    // delete session.defaults.headers.common['X-CSRF-TOKEN']
    // delete session.defaults.headers.common['CSRF-TOKEN']
    // delete session.defaults.headers.common['Cookie']
    // state.credentials = null
    // localStorage.removeItem('credentials')
    // localStorage.removeItem('smokappProfile')
    // // state.sessionid = getAnonymousSessionid()
    // state.smokappProfile = Object
    // state.initialized = false
  },
  [CLEAR_SMOKAPP_PROFILE](state) {
    // state.smokappProfile = Object
    // localStorage.removeItem('smokappProfile')
  }
}

const actions = {
  initialize({ commit, state }) {
    if (state.initialized === true) return
    this.dispatch('orders/initialize')

    if (state.credentials) {
      const credentials = JSON.parse(localStorage.getItem('credentials'))
      if (credentials) {
        commit(SET_CREDENTIALS, credentials)
      }
    } else {
      commit(REMOVE_TOKEN)
    }
    if (state.smokappProfile) {
      const smokappProfile = JSON.parse(localStorage.getItem('smokappProfile'))
      if (smokappProfile) {
        commit(SET_SMOKAPP_PROFILE, smokappProfile)
      }
    } else {
      commit(REMOVE_TOKEN)
    }
  },
  sessionToken({ commit }) {
    // var savedtoken = localStorage.getItem('sessiontoken')
    // if (savedtoken == null) {
    //   return false
    // } else {
    //   APIAuth.sessiontoken().then(response => {
    //     if (savedtoken == response.data) {
    //       session.defaults.headers.common['X-CSRF-TOKEN'] = savedtoken
    //       axios.defaults.headers.common['X-CSRF-TOKEN'] = savedtoken
    //       return true
    //     } else {
    //       return false
    //     }
    //   })
    // }
    APIAuth.sessiontoken().then(response => {
      session.defaults.headers.common['X-CSRF-TOKEN'] = response.data
      axios.defaults.headers.common['X-CSRF-TOKEN'] = response.data
      return true
    })
  },
  doLogin({ commit }, { username, password }) {
    commit(REMOVE_TOKEN)
    commit(CLEAR_SMOKAPP_PROFILE)
    this.dispatch('club/clearClub')
    this.dispatch('dispensaryPro/clearDispensary')
    this.dispatch('dispensaryPro/clearCategories')
    this.dispatch('dispensaryPro/clearCurrentCategory')
    commit('patients/CLEAR_PATIENTS', null, { root: true })
    commit('patients/CLEAR_SELECTED_PATIENTS', null, { root: true })
    commit('patients/CLEAR_CURRENT_PATIENT', null, { root: true })

    commit(SET_USER, username, password)
    let responsePromise = APIAuth.login(username, password)
    responsePromise.then(response => {
      if (response.status == 200) {
        commit(SET_CREDENTIALS, response.data)
        commit(SET_SMOKAPP_PROFILE, response.data)
      }
    })
    // responsePromise.catch(error => {
    //   console.log('Ha habido un error:' + error)
    //   console.log(error.response.data.title)
    //   console.log(error.response.status)
    //   Dialog.alert({
    //     title: 'Tenemos un problema',
    //     message: error.response.data,
    //     confirmText: 'OK',
    //     type: 'is-danger',
    //     hasIcon: false,
    //     onConfirm: () => {}
    //   })
    // })
    return responsePromise
  },
  doLogout({ commit }) {
    let responsePromise = APIAuth.logout()
    // responsePromise.then(() => {})
    responsePromise.catch(error => {
      console.log('Ha habido un error:' + error)
      console.log(error.response.data.title)
      console.log(error.response.status)
    })
    commit(REMOVE_TOKEN)
    commit(CLEAR_SMOKAPP_PROFILE)
    this.dispatch('club/clearClub')
    this.dispatch('dispensaryPro/clearDispensary')
    this.dispatch('dispensaryPro/clearCategories')
    this.dispatch('dispensaryPro/clearCurrentCategory')
    commit('patients/CLEAR_PATIENTS', null, { root: true })
    commit('patients/CLEAR_SELECTED_PATIENTS', null, { root: true })

    return responsePromise
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}
